import { useState, MouseEvent, useEffect } from "react";
import BeginTour from "./components/BeginTour";

export default function Demo() {

    const [ isDemoOpen, setIsDemoOpen ] = useState(false);

    const toggleDemo = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsDemoOpen(!isDemoOpen);
    }

    useEffect(() => {
        setIsDemoOpen(true);
    }, []);


    return (
        <>
            {isDemoOpen && <BeginTour togglePopup={toggleDemo} />}
            <button className="absolute bottom-[48px] left-[52px] z-10  bg-tertiary rounded py-2 px-4 text-white font-semibold">PRODUCT TOUR</button>
        </>
    )
}