import { ReactNode, useRef } from "react";
import { Link } from "react-router-dom";
import useLazyLoad from "../../../hooks/useLazyLoad";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function TutorialBox({children, pathname, title}: {children: ReactNode, pathname: string, title: string}) {
    const containerRef = useRef<HTMLAnchorElement>(null);
    const isVisible = useLazyLoad(containerRef, {threshold: 0.1});


    return (
        <Link ref={containerRef} to={pathname} className="flex flex-col rounded bg-white drop-shadow-default hover:text-tertiary hover:bg-secondary/[0.2] hover:drop-shadow-none">
            <div className="w-full h-[300px] bg-[#eeeeee]">
                {!isVisible ? (
                    <Skeleton height='100%'/>
                ) : (
                    <>{children}</>
                )}
            </div>
            <div className="flex flex-col p-4 justify-start items-start">
                <p className="font-semibold text-[18px]">{title}</p>
                {/* <p className="text-[#8e8e8e] font-medium mt-2">Learn how to upload files into Assay Central for creating datasets, training models and forming predictions.</p> */}
            </div>
        </Link>
    )
} 