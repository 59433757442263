// import { Link } from 'react-router-dom';
import ErrorImage from '../../assets/no-results.png';

export default function NotFound() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <div className='max-w-[300px] w-full mb-6'>
                    <img src={ErrorImage} className='object-cover w-full' alt='404-error-icon'/>
                </div>
                
                <p className='text-[20px] mb-3 font-medium text-secondary'>404 - PAGE NOT FOUND</p>
                <p className='mb-6'>The page you are looking does not exist.</p>
                {/* <Link to='/dashboard' className='rounded-full py-3 px-10 bg-tertiary hover:bg-secondary font-medium text-white transition-all'>Return to Dashboard</Link> */}
            </div>
        </div>
    )
}