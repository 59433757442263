import { MouseEvent } from 'react';
import { useState, useEffect } from 'react';

import ToggleArrows from './ToggleArrows';

export default function ChangePage({ controls, currentGraph, graphIndices, name }: {
    controls: {
        next: (e:MouseEvent<HTMLButtonElement>, name:string) => void,
        back: (e:MouseEvent<HTMLButtonElement>, name:string) => void,
    },
    currentGraph?: {name:string, graph: number}[],
    graphIndices?: number[],
    name: string,
}) {

    const [ isBackDisabled, setIsBackDisabled ] = useState(true);
    const [ isNextDisabled, setIsNextDisabled ] = useState(false);
    // const [ graph, setGraph ] = useState<any>();

    useEffect(() => {
        if (currentGraph !== undefined && graphIndices !== undefined) {
            const obj = currentGraph.find(g => g.name === name);
            if (obj && obj.graph === 0) {
                setIsNextDisabled(false);
                setIsBackDisabled(true);
            } else if (obj && obj.graph === graphIndices[graphIndices.length - 1]) {
                setIsNextDisabled(true);
                setIsBackDisabled(false);
            } else {
                setIsNextDisabled(false);
                setIsBackDisabled(false);
            }
        }
    }, [currentGraph, graphIndices, name]);


    // useEffect(() => {

    //     if (currentGraph) {
    //         const obj = currentGraph.find(g => g.name === name);
    //         if (obj) {
    //             setGraph(obj);
    //         }
    //     }
    // }, [currentGraph, name]);



    // const getProgress = (value: string) => {
    //     if (currentGraph && graphIndices && graph) {
    //         if (value === 'back') {
    //             if (graph.graph < graphIndices.length-1) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //     }
    // }



    return (
        <ToggleArrows firstCondition={!isBackDisabled} secondCondition={!isNextDisabled} firstAction={(e) => controls.back(e, name)} secondAction={(e) => controls.next(e, name)}/>
    )
}