import { Link } from "react-router-dom"

export default function TutorialHeader({ page }: { page: {path: string, label: string} }) {
    return (
        <div className="w-full flex justify-start items-center gap-3 font-medium mb-2">
            <Link to='/help' className="hover:underline">Tutorials</Link>
            <p>{`>`}</p>
            <Link to={`/help/${page.path}`} className="text-tertiary hover:text-secondary hover:underline">{page.label}</Link>
        </div>
    )
}