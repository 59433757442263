import { createSlice } from "@reduxjs/toolkit";

export interface NavState {
    isNavOpen: boolean,
}

const initialState: NavState = {
    isNavOpen: false,
}

export const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        toggleNav: (state, action) => {
            state.isNavOpen = !state.isNavOpen;
        },
        openNav: (state, action) => {
            state.isNavOpen = true;
        }
    }
})

export const { toggleNav, openNav } = navSlice.actions;

export const navReducer = navSlice.reducer;
