

export default function TruthTable({data}: {data: {name: string, value: number}[]}) {


    return (
        <div className="flex flex-col justify-start items-center gap-[10px] pt-8 pb-4 aspect-square">
            <p>Model Truth Table</p>
            <div className="grid grid-cols-2 grid-rows-2 w-full grow border">
            {data.map((d,i) => (
                <div className={`flex flex-col gap-2 p-2 justify-center items-center border`}>
                    <p className="text-center">{d.name}</p>
                    <p>{d.value}</p>
                </div>
            ))}
            </div>
        </div>
    )
}