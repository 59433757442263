import Plot from 'react-plotly.js';
import { Config } from 'plotly.js';


export default function RadarChart({data}: {data: Plotly.Data[]}) {


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    

    const layout_radar: () => Partial<Plotly.Layout> = function() {
        return {
            polar: {
                radialaxis: {
                    visible: true,
                    range: [-1,1],
                }
            },
            legend: {
                font: {
                    size: 16,
                }
            }
        }
    }
    
   




    return (
        <Plot data={data} layout={layout_radar()} config={config} className='w-full h-[600px]'/>
    )
}