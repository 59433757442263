import { MouseEvent, Dispatch, SetStateAction } from "react";
import { FilterType, Header } from "../types/all.types";

const useModifyTableItems = ({ 
    modifyItems, 
    setHeaders, 
    filter, 
    setFilter, 
    searchInput, 
    selectedHeader, 
    setSelectedHeader 
}: {
    modifyItems: (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType, ) => void,
    setHeaders: Dispatch<SetStateAction<Header[]>>,
    filter?: FilterType,
    setFilter?: Dispatch<SetStateAction<FilterType>>,
    searchInput: string,
    selectedHeader: string,
    setSelectedHeader: Dispatch<SetStateAction<string>>,
    
}) => {

    const selectHeaders = (e:MouseEvent<HTMLButtonElement>, label: string, headers: Header[]) => {
        e.preventDefault();
        const updatedHeaders = headers.map(h => {
            if (h.label === label) {
                return { ...h, isSelected: !h.isSelected }
            } else {
                return { ...h }
            }
        });

        setHeaders(updatedHeaders);
    };

    const setSortedColumn = (e:MouseEvent<HTMLButtonElement>, value: string) => {
        e.preventDefault();

        setSelectedHeader(value);
        modifyItems(searchInput, value, setHeaders, filter);
    };

    const applyFilter = (e:MouseEvent<HTMLButtonElement>, filterObj: FilterType) => {
        e.preventDefault();

        setFilter && setFilter(filterObj);
        modifyItems(searchInput, selectedHeader, undefined, filterObj);
    };

    const applySearch = (e:MouseEvent<HTMLButtonElement>, input: string) => {
        e.preventDefault();

        modifyItems(input, selectedHeader, undefined, filter);
    }

    return {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch
    }

}

export default useModifyTableItems;