// assets
import Warning from '../../assets/warning.png';

// components
import Overlay from "./Overlay";

// types
import { MouseEvent } from "react";

export default function WarningPopup({togglePopup, handleWarning, items}: {togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, items: string[], handleWarning: (e:any, proceed: boolean) => void}) {


    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='bg-background rounded p-10 flex flex-col gap-8 justify-center items-stretch'>
                    <div className="flex flex-col justify-center items-center">
                        <img src={Warning} className='w-[150px]'  alt='warning-icon'/>
                        <p className='font-semibold text-[2.5rem] w-full text-center'>Warning</p>
                    </div>
                    <p className='max-w-[600px] text-center text-[20px] leading-10 font-medium text-primary'>The following cas numbers could not be converted:</p>
                    <div className='w-full border-2 border-secondary rounded bg-white p-4'>
                        <div className='max-h-[200px] overflow-y-auto'>
                            <div className='bg-white flex flex-col justify-start items-start gap-2'>
                            {items.map(item => (
                                <p>{item}</p>
                            ))}
                            </div>
                        </div>
                    </div>
                    <p className='max-w-[600px] text-center text-[20px] leading-10 font-medium text-primary'>Would you still like to proceed?</p>
                    <div className='flex justify-center items-center gap-4'>
                        <button onClick={(e:any) => {handleWarning(e, false)}} className="grow py-3 bg-white border-2 border-quaternary rounded text-quaternary font-medium text-[20px] hover:text-white hover:bg-quaternary transition-all">No</button>
                        <button onClick={(e:any) => {handleWarning(e, true)}} className="grow py-3 bg-tertiary rounded text-white font-medium text-[20px] hover:bg-secondary transition-all">Yes</button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}