import { Routes, Route } from "react-router-dom"
import JobQueue from "./pages/JobQueue"
import SingleJobPage from "./pages/SingleJobPage"
import NotFoundError from "../../components/Alerts/NotFoundError"

export default function JobsRoutes() {
    return (
        <Routes>
            <Route index element={<JobQueue />} />
            <Route path=':id' element={<SingleJobPage />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}