import PlotlyGraphs from "../../../components/Data/PlotlyGraphs";
import { SingleClassificationModel } from "../../../types/models.types";
import TruthTable from "../../../components/Data/TruthTable";
import { classificationLimits } from "../../../data/models";
import NumberLine from "../../../components/Data/NumberLine";
import EmptyGraph from "../../../components/Data/EmptyGraph";

const hist_data = (method: SingleClassificationModel) => {
  if (!method.y_true || !method.y_pred || !method.y_prob) {

    return null;

  } else {

    let pred_vals = [];
    let true_vals = [];
    let i = 0;
    for (let val of method.y_true) {
      if (val === 0) {
        pred_vals.push(method.y_prob[i])
      }
      i++;
    }
    let j = 0;
    for (let val of method.y_true) {
      if (val === 1) {
        true_vals.push(method.y_prob[j])
      }
      j++;
    }

    var trace1 = {
      x: pred_vals,
      name: 'Predicted Values',
      autobinx: false, 
      histnorm: "count", 
      marker: {
        color: "rgb(219, 30, 58, .7)", 
        line: {
          color:  "rgb(219, 30, 58, 1)", 
          width: 1
        }
      },  
      opacity: 0.5, 
      type: "histogram", 
      xbins: {
        end: 1, 
        size: 0.025, 
        start: 0
        }
      };

    var trace2 = {
      x: true_vals,
      name: "True Values", 
      histnorm: "count",
      autobinx: false, 
      marker: {
              color: "rgb(94, 146, 219, .7)",
              line: {
                color:  "rgb(94, 146, 219, 1)", 
                width: 1
        } 
          }, 
      opacity: 0.5, 
      type: "histogram", 
      xbins: { 
        end: 1, 
        size: 0.025, 
        start: 0
        }
      };

    return [trace1, trace2]

  }
    
  }


export default function ClassificationGraphs({ obj, item, }: {
    obj?: {
        name: string;
        graph: number;
    },
    item: SingleClassificationModel,

}) {
    return (
        <>
        {obj && obj.graph === 1 && <PlotlyGraphs type='roc' data={item.roc as any}/>}
            {obj && obj.graph === 0 &&  (
              <>
              {!hist_data(item) ? (
                <EmptyGraph type="Histogram"/>
              ) : (
                <PlotlyGraphs type='histogram' data={hist_data(item) as any} />
              )}
              </>
            )}
            {obj && obj.graph === 2 && (
              <>
              {item.tp !== undefined && item.tn !== undefined && item.fn !== undefined && item.fp !== undefined ? (
                <TruthTable data={[
                    {name: 'True Positive', value: item.tp}, 
                    {name: 'False Positive', value: item.fp}, 
                    {name: 'False Negative', value: item.fn},
                    {name: 'True Negative', value: item.tn},
                ]} />
              ) : (
                <EmptyGraph type="Truth"/>
              )}
              </>
            )
            }
            <div className='max-h-[180px] overflow-y-scroll'>
                <div className='flex flex-col gap-1 justify-center items-stretch'>
                {Object.keys(item).filter(key => ['acc', 'auc', 'cohens_kappa', 'f1score', 'mcc', 'specificity', 'precision', 'recall'].includes(key)).map(key => {
                    const limitsObj = classificationLimits.find(limit => limit.value === key);
                    if (limitsObj) {
                        return (
                            <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(item[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                        )
                    } else {
                        return null;
                    }
                })}
                </div>
            </div>   
        </>
    )
}