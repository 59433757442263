import usePopup from "../../hooks/usePopup";
import { useState, useEffect, MouseEvent } from "react";
import { DashboardData } from "../../types/all.types";
import ActivityChart from "./components/ActivityChart";
import DataRepresentationBox from "../../components/Data/DataRepresentationBox";
import JobQueueCard from "./components/JobQueueCard";
import RecentPredictions from "./components/RecentPredictions";
import RecentModels from "./components/RecentModels";
import QuickActions from "./components/QuickActions";
import UploadFile from "../Files/components/UploadFile";
import Select from 'react-select';
import useAllModels from "../../hooks/pageHooks/useAllModels";
import useResults from "../../hooks/pageHooks/useResults";
import { useSelector } from "react-redux";
import { selectApplication, selectAuth, selectTheme } from "../../store/selectors/auth.selector";
import PageHeader from "../../components/Page/PageHeader";
import Overlay from "../../components/Popups/Overlay";
import SelectFileTypePopup from "../Files/components/SelectFileTypePopup";
import ExistingFilePopup from "../../components/Popups/ExistingFilePopup";
import CreateDatasetPopup from "../Datasets/components/CreateDatasetPopup";

const options = [
    {value: 'week', label: 'This week'},
    {value: 'month', label: 'This month'},
    {value: 'year', label: 'This year'},
]

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
    'Nov', 'Dec',
];

const getWeek = (current: Date) => {
    var week: Date[] = new Array(); 
    // Starting Monday not Sunday
    current.setDate((current.getDate() - current.getDay() +1));
    for (var i = 0; i < 7; i++) {
        week.push(
            new Date(current)
        ); 
        current.setDate(current.getDate() +1);
    }
    return week; 
}

export default function Dashboard(){
    const [ chartData, setChartData ] = useState<DashboardData[]>([]);
    const [ timeline, setTimeline ] = useState<{value: string, label: string}>({ value: 'month', label: 'This month' });

    // const [ selectedType, setSelectedType ] = useState('');
    const [ isCreateDataset, setIsCreateDataset ] = useState(false);
    const { allModels } = useAllModels({ singleModel: undefined });
    const { allResults } = useResults({ searchInput: ''});
    const authUser = useSelector(selectAuth);

    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);

    // useEffect(() => {
    //     if (!authUser) {
    //         navigate('/401');
    //     }
    // }, [authUser]);

    const {
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup
    } = usePopup();


    useEffect(() => {
        let data: {name: string, models: number, predictions: number}[] = [];
        if (authUser) {
            console.log(allResults)


            if (timeline.value === 'week') {
                data = getWeek(new Date()).map(week => {
                    let models = [];
                    let results = [];
                    allModels.forEach(m => {
                        const dateString = new Date(m.creation_time);
                        if (dateString.getMonth() === week.getMonth() && dateString.getDate() === week.getDate() && m.created_by === authUser.user.full_name) {
                            models.push(m);
                        }
                    });
    
                    allResults.forEach(p => {
                        const dateString = new Date(p.date_created);
                        if (dateString.getMonth() === week.getMonth() && dateString.getDate() === week.getDate() && p.created_by === authUser.user.username) {
                            results.push(p)
                        }
                    })
    
                    return ({
                        name: `${week.getMonth()+1}/${week.getDate()}`,
                        models: models.length,
                        predictions: results.length,
                    })
                })
            } else if (timeline.value === 'year') {
                const monthArr = Array.from(Array(12).keys());
                data = monthArr.map(month => {
                    let models = [];
                    let results = [];
                    allModels.forEach(m => {
                        const dateString = new Date(m.creation_time);
                        if (dateString.getMonth() === month && m.created_by === authUser.user.full_name) {
                            models.push(m);
                            
                        }
                    });
    
                    allResults.forEach(p => {
                        const dateString = new Date(p.date_created);
                        if (dateString.getMonth() === month && p.created_by === authUser.user.username) {
                            results.push(p)
                        }
                    })
    
                    return ({
                        name: months[month],
                        models: models.length,
                        predictions: results.length,
                    })
    
                })
    
            } else {
                const weekArr = [1, 8, 15, 21];
                data = weekArr.map(week => {
                    const today = new Date();
                    const currentWeek = new Date(today.getFullYear(), today.getMonth(), week);
                    const w = getWeek(currentWeek);
    
                    const first = w[0];
                    const last = w[w.length-1];
    
                    let models = [];
                    let results = [];
                    allModels.forEach(m => {
                        const dateString = new Date(m.creation_time);
                        if (dateString.getMonth() >= first.getMonth() && dateString.getMonth() <= last.getMonth() 
                        && dateString.getDate() >= first.getDate() && dateString.getDate() <= last.getDate() && m.created_by === authUser.user.full_name) {
                            models.push(m);
                        }
                    });
    
                    allResults.forEach(p => {
                        const dateString = new Date(p.date_created);
                        if (dateString.getMonth() >= first.getMonth() && dateString.getMonth() <= last.getMonth() 
                            && dateString.getDate() >= first.getDate() && dateString.getDate() <= last.getDate() && p.created_by === authUser.user.username) {
                                results.push(p)
                            }
                    })
    
                    return ({
                        name: `${first.getMonth()+1}/${first.getDate()}-${last.getMonth()+1}/${last.getDate()}`,
                        models: models.length,
                        predictions: results.length,
                    })
    
    
    
                })
            }

        }
        
        setChartData(data);
    }, [timeline.value, allModels, allResults, authUser]);

    const [ selectedType, setSelectedType ] = useState('');

    const next = (e:MouseEvent<HTMLButtonElement>) => {
        if (selectedType === 'new-file') {
            setPopupType('upload-file')
        } else if (selectedType === 'existing-file') {
            setPopupType('existing-file')
        } else if (selectedType === 'compound-list') {
            setPopupType('upload-file')
        }
    };

    const goBack = (e:MouseEvent<HTMLButtonElement>) => {
        setPopupType('select-file-type');
    };

    const goBackDataset = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (selectedType !== "new-file") {
            setPopupType('existing-file');
        } else {
           setPopupType('upload-file')
        }
    };

    const nextDataset = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
       setPopupType('create-dataset')
    };




    return (
        <>

            <PageHeader label='Dashboard'></PageHeader>

            <div className='flex gap-6 mb-6 items-stretch'>
                <div id='activity-chart' className="w-2/3">
                    <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default flex flex-col gap-4 h-full p-8`}>
                        <div className={`w-full flex justify-between items-center`}>
                            <p className='text-[1.5rem] font-semibold text-nowrap'>User Activity</p>
                            <Select onChange={(e:any) => setTimeline(e)} className="dashboard" options={options} value={timeline}/>
                        </div>
                        <ActivityChart data={chartData}/>
                    </div>
                </div>
                <div id='job-queue-card' className="w-1/3">
                    <DataRepresentationBox label='Job Queue' path="/jobs">
                        <JobQueueCard />
                    </DataRepresentationBox>
                </div>
            </div>

            <div className="flex gap-6 justify-between items-stretch">
                <div id='quick-actions' className="w-1/3">
                <DataRepresentationBox label='Quick Actions'>
                    <QuickActions setIsCreateDataset={setIsCreateDataset} setPopupType={setPopupType} toggleUploadFile={togglePopup} application={application}/>
                </DataRepresentationBox>
                </div>

                <div id='recent-models-and-predictions' className="flex flex-col gap-6 w-2/3">
                {application && (application).includes('assay-central') && (
                    <div className="grow">
                        <DataRepresentationBox label='Recent Models' path='/models' isDashboard={true}>
                            <RecentModels />
                        </DataRepresentationBox>
                    </div>
                )}
                
                <div className="grow">
                    <DataRepresentationBox label='Recent Predictions' path='/predictions' isDashboard={true}>
                        <RecentPredictions application={application}/>
                    </DataRepresentationBox>
                </div>

                </div>
            </div>
            
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'select-file-type' ? (
                        <SelectFileTypePopup togglePopup={togglePopup} setSelectedType={setSelectedType} selectedType={selectedType} next={next}/>
                    ) : popupType === 'existing-file' ? (
                        <ExistingFilePopup togglePopup={togglePopup} next={nextDataset} goBack={goBack}/>
                    ) : popupType === 'upload-file' ? (
                        <UploadFile togglePopup={togglePopup} isDashboard={true} isFlow={isCreateDataset ? true : false} next={isCreateDataset ? nextDataset : undefined} goBack={isCreateDataset ? goBack : undefined}/>
                    ) : (
                        <CreateDatasetPopup isFlow={false} togglePopup={togglePopup} goBack={goBackDataset} redirect={true}/>
                    )}
                </Overlay>
            )}
 
        
        </>
    )
}