import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { DatasetProtocol, ParsedProtocol } from "../../types/protocol.types";

import API from '../../api';
import { Header } from "../../types/all.types";
import { download } from "../../data/functions";
import { useNavigate } from "react-router-dom";

const protocolHeaders: Header[] = [
    {
        label: 'Record',
        value: 'record',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: true,
    },
    {
        label: 'Original Structure',
        value: 'original_structure',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'File Fields',
        value: 'file_fields',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Issues',
        value: 'issues',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Dataset Fields',
        value: 'dataset_fields',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    },
    {
        label: 'Standardized Structure',
        value: 'standardized_structure',
        isRequired: true,
        isSelected: true,
        isAscending: false,
        isSortable: false,
    }
]

const useProtocol = ({ id, dsID, searchInput, setPopupMessage }: {
    id?: string,
    dsID?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
    searchInput: string,
}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ protocol, setProtocol ] = useState<DatasetProtocol | null>(null);
    
    const [ records, setRecords ] = useState<ParsedProtocol[]>([]);
    const [ sortedRecords, setSortedRecords ] = useState<ParsedProtocol[]>([]);
    const [ filteredRecords, setFilteredRecords ] = useState<ParsedProtocol[]>([]);
    const navigate = useNavigate();

    const setupProtocol = (protocol: DatasetProtocol) => {
        if (protocol) {

            

            setRoutes([
                {
                    label: 'Datasets',
                    path: 'datasets',
                },
                {
                    label: protocol.name.replace('Protocol', ''),
                    path: dsID ? `datasets/${dsID}` : 'datasets',
                },
                {
                    label: protocol.name ? protocol.name : 'Protocol',
                    path: protocol._id.$oid ? `protocols/${protocol._id.$oid}` : '',
                }
            ]);

            if (protocol.records.length > 0) {
                const parsedProtocol: ParsedProtocol[] = protocol.records.map(record => ({
                    record: record.ord,
                    original_structure: record.mol_org.$oid,
                    file_fields: record.file_fields,
                    issues: record.issues,
                    dataset_fields: Object.keys(record.ds_fields).length > 0 ? record.ds_fields : record.file_fields,
                    standardized_structure: record.mol_std ? record.mol_std.$oid : '',
                }))

                const ordered: ParsedProtocol[] = parsedProtocol.sort((a,b) => {
                    if ((a.record) < (b.record)) {
                        return -1
                    } else if ((a.record) > (b.record)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

                setRecords(ordered);
                setSortedRecords(ordered);
                setFilteredRecords(ordered);
            }
            

        }
    }


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            if (id) {

                const fetchProtocol = async () => {
                    setIsLoading(true);
    
                    try {
    
                        const response = await API.get(`protocols/${id}`, { headers: { 'X-Auth': authKey }});
                        const data: DatasetProtocol = await response.data;
                        setProtocol(data);
                        setupProtocol(data);
    
                    } catch (err:any) {
    
                        console.log(err);
                        
    
                    }
    
                    setIsLoading(false);
                    
                }
        
                fetchProtocol();
    
            }
        } else {
            navigate('/401');
        }
        
        
    }, [id, dsID]);

    useEffect(() => {
        if (sortedRecords.length > 0 && protocol) {
            const newRecords = sortedRecords.filter(record => (record.record).toString().includes(searchInput));
            setFilteredRecords(newRecords);
        }
    }, [searchInput, sortedRecords, protocol]);

    const downloadProtocol = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`protocols/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error downloading this protocol', false)
            }
        }
    }


    return {
        isLoading, 
        records,
        routes,
        protocolHeaders,
        protocol,
        downloadProtocol,
        filteredRecords,
        sortedRecords,
        setSortedRecords,
        setFilteredRecords,
    }
}

export default useProtocol;