// assets
import Minus from '../../assets/minus.svg';
import Plus from '../../assets/plus.svg';

// types
import { MouseEvent } from 'react';
import { SideInfo } from '../../types/all.types';


export default function FlowCardInfo({ toggleShow, isShown, info }: {
    toggleShow: (e:MouseEvent<HTMLButtonElement>) => void,
    isShown: boolean, 
    info: SideInfo[],
}) {
    return (
        <>
            <div className='w-full flex justify-end'>
                <button onClick={toggleShow} className='flex gap-1 justify-center items-center text-[14px] font-medium'>
                    {isShown ? (
                    <>
                        <img src={Minus} className='w-[12px]' alt='minus-icon' />
                        Hide Information
                    </>
                    ) : (
                    <>
                        <img src={Plus} className='w-[12px]' alt='plus-icon' />
                        Show Information
                    </>
                    )}
                    
                </button>
            </div>
            {isShown && (
                
                    <div className={` flex flex-col gap-8 justify-start items-stretch`}>
                    {info && info.map((n,i) => (
                        <div key={i} className='flex flex-col justify-start items-start gap-[2px] w-full font-medium'>
                            <p className="text-[12px] text-tertiary">{n.label}</p>
                            <p className="text-primary">{n.value}</p>
                        </div>
                    ))}
                    </div>
               
            )}
        </>
    )
}