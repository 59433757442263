import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthState } from "../slices/auth.slice";

const selectAuthReducer = (state:RootState): AuthState => state.auth;



export const selectAuth = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.auth,
)

export const selectMetadata = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.metadata,
)

export const selectApplication = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.application,
);

export const selectTheme = createSelector(
    [selectAuthReducer],
    (authSlice) => authSlice.theme,
)