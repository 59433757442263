import { MouseEvent, useState } from 'react';

import ChevronDown from '../../../assets/chevron-down.svg';
import ChevronUp from '../../../assets/chevron-up.svg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}

export default function OptionsDropdown({ toggleDelete }: { toggleDelete: (e:MouseEvent<HTMLButtonElement>) => void}) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <div>
            <button onClick={handleClick}>
                <div className={`text-white bg-tertiary hover:bg-secondary transition-all whiten flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                    <p className='font-medium'>Options</p>
                    {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{marginTop: '8px'}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={sx} onClick={(e:any) => {toggleDelete(e); handleClose()}}>Delete</MenuItem>
            </Menu>
        </div>
 
    )
}