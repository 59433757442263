import { Header } from "../types/all.types";

export const readacrossHeaders: Header[] = [
    {
        label: 'Structure',
        value: 'structure',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: false,
    },
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Dataset',
        value: 'dataset',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Access',
        value: 'access',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    }
]

export const defaultReadacrossFilter = {
    dataset: [],
    created_by: [],
    access: [],
  }
  
  export const defaultReadacrossFilterFields = {
    dataset:[],
    created_by: [],
    access: [],
  }