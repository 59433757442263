import { Dataset } from "../types/datasets.types";

export const datasetsTableHeaders = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Project',
        value: 'project',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Measurement Type',
        value: 'measurement_type',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Target',
        value: 'target',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Organism',
        value: 'organism',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Records',
        value: 'records',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Access Type',
        value: 'access_type',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
]


export const defaultDatasetFilter = {
    project: [],
    measurement_type: [],
    target: [],
    organism: [],
    created_by: [],
    records: {
        op: '',
        quantity: '',
    },
    access_type: [],
}

export const defaultDatasetFilterFields = {
    project: [],
    measurement_type: [],
    target: [],
    organism: [],
    created_by: [],
    records: [],
    access_type: [],
};


export const defaultDataset: Dataset = {
    _id: {
        $oid: '12345',
      },
      acl: {
        access: 'private',
        owner: '',
        read: [],
        write: [],
      },
      fields_mapping: [],
      files: [
        {
          $oid: '',
        }
      ],
      metadata: {
        description: null,
        measurement_type: null,
        name: null,
        organism: null,
        project: null,
        target: null,
      },
      name: 'dataset',
      protocol: {
        $oid: '',
      },
      records: [],
      records_number: 0,
      stats: {
        actives: 0,
        inactives: 0
      },
      user: {
        active: true,
        company: '',
        email: '',
        full_name: '',
        groups: [],
        privileges: [],
        roles: [],
        username: '',
        _id: {
          $oid: '',
        }
      }
}