import { Header } from "../types/all.types";
import { ParsedPrediction, Prediction } from "../types/predictions.types";
import { getDateFromObjectId, orderByDate } from "./functions";

export const predictionsHeader: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Records',
        value: 'records',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Conformal Predictor',
        value: 'conformal_predictor',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Access',
        value: 'access',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    }
]

export const defaultResultsFilter = {
    records: {
        op: '',
        quantity: '',
    },
    created_by: [],
    access: [],
    
}

export const defaultResultsFilterFields = {
    records: [],
    created_by: [],
    access: [],
};

export const setupResults = (results: Prediction[]) => {
    if (results.length > 0) {
        const arr: ParsedPrediction[] = results.map(m => {

            return ({
            id: m._id.$oid,
            name: m.name,
            records: m.records_number,
            conformal_predictor: m.conf_pred,
            access: m.acl ? m.acl.access : '',
            created_by: m.user ? m.user.username : '',
            date_created: getDateFromObjectId(m._id.$oid).toLocaleString('en-US', {timeZone: 'EST'})
        })});

        const ordered: ParsedPrediction[] = orderByDate(arr);

        return ordered;

        
    } else {
        return [];
    }
}

