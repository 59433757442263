import { Dispatch, SetStateAction, useEffect, useState, MouseEvent } from 'react';

import DoubleLeft from '../../assets/chevron-left-double-black.svg';
import DoubleRight from '../../assets/chevron-right-double-black.svg';
import Left from '../../assets/chevron-left-black.svg';
import Right from '../../assets/chevron-right-black.svg';
import Select from 'react-select';

export interface Options {
    value: string,
    label: string,
}

export default function Pagination({ total, setItemsPerPage, paginate, firstIndex, lastIndex, currentPage, count }: { total: number, setItemsPerPage: Dispatch<SetStateAction<number>>, paginate: (pageNumber: number) => void, firstIndex: number, lastIndex: number, currentPage: number, count: number }) {
    const [ options, setOptions ] = useState<Options[] | null>(null);
    const [ selectedOption, setSelectedOption ] = useState<{value: string, label: string}>({value: count.toString(), label: count.toString()});
    const [ pageNumbers, setPageNumbers ] = useState<number[]>([]);


    useEffect(() => {
        setOptions([
            {
                value: '5',
                label: '5',
            },
            {
                value: '10',
                label: '10',
            },
            {
                value: '20',
                label: '20',
            },
            {
                value: '50',
                label: '50',
            },
            {
                value: total.toString(),
                label: 'All',
            },
        ])
    }, [total]);

    useEffect(() => {
        let arr: number[] = [];
        for (let i = 1; i <= Math.ceil(total / Number(selectedOption.value)); i++) {
            arr.push(i);
        } 
        setPageNumbers(arr);

    }, [total, selectedOption.value]);



    const handleSelect = (e:any) => {
        setSelectedOption({value: e.value, label: e.value});
        setItemsPerPage(Number(e.value));
        paginate(1)
    }

    const firstPage = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginate(pageNumbers[0]);
    }

    const lastPage = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginate(pageNumbers[pageNumbers.length - 1]);
    }

    const nextPage = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginate(pageNumbers[currentPage]);
    }

    const previousPage = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        paginate(pageNumbers[currentPage - 2]);
    }



    return (
        <div className='flex justify-end'>
            <div className='flex justify-end items-center py-3 px-4 rounded gap-10'>
                <div className='flex justify-center items-center gap-3'>
                    <p>Results per page</p>
                    <Select aria-label='pagination-selection' onChange={handleSelect} options={options ? options : []} value={selectedOption}/>
                </div>
                <p>{firstIndex + 1}-{lastIndex >= total ? total : lastIndex} of {total}</p>
                <div className='flex justify-center items-center gap-3'>
                    <button onClick={firstPage} className='bg-background hover:bg-grad-one transition-all rounded p-2 flex justify-center items-center'><img src={DoubleLeft} className='h-[16px] w-[16px]' alt='all-previous-icon'/></button>
                    <button onClick={previousPage} disabled={currentPage === 1} className='bg-background hover:bg-grad-one disabled:hover:bg-background transition-all rounded p-2 flex justify-center items-center'><img src={Left} className='h-[16px] w-[16px]' alt='previous-icon'/></button>
                    <button onClick={nextPage} disabled={currentPage === pageNumbers.length} className='bg-background hover:bg-grad-one disabled:hover:bg-background transition-all rounded p-2 flex justify-center items-center'><img src={Right} className='h-[16px] w-[16px]' alt='next-icon'/></button>
                    <button onClick={lastPage} className='bg-background hover:bg-grad-one disabled:hover:bg-background transition-all rounded p-2 flex justify-center items-center'><img src={DoubleRight} className='h-[16px] w-[16px]' alt='all-next-icon'/></button>
                </div>
            </div>
        </div>
    )
}