import { Header, Limit } from "../../types/all.types";
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, MouseEvent } from "react";
import Green from '../../assets/greenStar.svg';
import Red from '../../assets/redStar.svg';

import ImageCell from "../Data/ImageCell";
import { useLocation, useNavigate } from "react-router-dom";
import { getStringValue } from "../../data/functions";
import BaseRow from "./BaseRow";
import ColorRows from "./ColorRow";
import ConformalRow from "./ConformalRow";
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/selectors/auth.selector";
import { PopupType } from "../../hooks/usePopup";
// import ColorRow from "./ColorRow";


export default function TableRowBase({
    headers, 
    item,
    isChecked,
    activeField,
    editSelectedRows,
    setSelection,
    selectedRows,
    children,
    action,
    rowType,
    limits,
    threshold,
} : {
    children?: ReactNode,
    headers: Header[],
    item: any,
    isChecked: boolean,
    rowType: 'base' | 'color' | 'conformal',
    activeField?: {[key:string]: string, name: string, type: string} | null,
    selectedRows?: string[],
    editSelectedRows?: (e:ChangeEvent<HTMLInputElement>) => void,
    setSelection?: Dispatch<SetStateAction<string>>,
    action?: {
        togglePopup?: (e:any) => void,
        path?: string,
        setMoleculeID?: Dispatch<SetStateAction<string>>,
        findSelectedMethod?: (e:any, name: string) => void,
        setPopupType?: Dispatch<SetStateAction<PopupType>>,
    },
    limits?: Limit[],
    threshold?: number,
}) {


    const { pathname } = useLocation();
    const navigate = useNavigate();
    const theme = useSelector(selectTheme);

    const performAction = (e: MouseEvent<HTMLButtonElement>, item: any) => {
        e.preventDefault();
        if (action) {
            const { togglePopup, setPopupType, path, setMoleculeID, findSelectedMethod } = action;

            if (togglePopup !== undefined) {
                togglePopup(e);
            };

            if (setPopupType !== undefined) {
                setPopupType('chemical');
            }
    
            if (path !== undefined) {
                navigate(`/${path}/${item.id ? item.id : item.name}`)
            }
    
            if (setMoleculeID !== undefined && item.id) {
                setMoleculeID(item.id);
            };

            if (findSelectedMethod !== undefined && item.method_name !== undefined) {
                findSelectedMethod(e, item.method_name)
            }
        }
        
    };

    const checkIfRowIsSelected = (item:any) => {
        if (selectedRows !== undefined) {
            if (selectedRows.includes(item.id ? item.id : item.name)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };


    return (
        <>
        <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px]`}>
        {headers.map(header => {
            const key = Object.keys(item).find(k => k.toLocaleLowerCase() === header.value.toLocaleLowerCase());
            if (key) {

                const value = pathname.includes('users') ? item[key] : getStringValue(item[key]);
                
                if (key.toLocaleLowerCase() === 'structure') {
                    return (
                        <td className={`cell text-left min-w-[200px] gap-4 relative`}>
                            {checkIfRowIsSelected(item) && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            <div className="py-2 px-3 flex justify-start items-start gap-2">
                            {activeField && activeField.type !== 'continuous-value' && (
                                <>
                                {item.isActive ? (
                                    <img className="w-[20px]" src={Green} alt='active-icon'/>
                                ) : (
                                    <img className="w-[20px]" src={Red} alt='inactive-icon'/>
                                )}
                                </>
                            )}
                            {isChecked ? (
                                <label className='flex justify-start items-center gap-4 py-4 px-4'>
                                    <input checked={checkIfRowIsSelected(item)} onChange={editSelectedRows} name={item.id ? item.id : item.name} type="checkbox" className="accent-secondary relative z-20 hover:cursor-pointer"/>
                                    <div className="py-2 px-3 rounded relative z-20">
                                        <ImageCell type="table" id={item.Structure ? item.Structure : item.id} hasMoleculeID={item.hasMoleculeID}/>
                                    </div>
                                </label>
                            ) : (
                                <button disabled={action === undefined ? true : isChecked ? true : false} className="py-2 px-3 hover:bg-secondary hover:bg-opacity-10 disabled:hover:bg-opacity-0 rounded transition-all" onClick={(e:any) => {performAction(e, item)}}>
                                    <ImageCell type="table" id={item.Structure ? item.Structure : item.id} hasMoleculeID={item.hasMoleculeID}/>
                                </button>
                            )}
                            </div>
                        </td>
                    )
                } else if (header.isRequired) {
                    const imgHeader = headers.find(h => h.label.toLocaleLowerCase() === 'structure');

                    return (
                        <td className={`relative name-cell`}>
                            {checkIfRowIsSelected(item) && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            <div className={`flex ${!imgHeader ? 'justify-start' : 'justify-center'} items-center`}>
                                {isChecked && !imgHeader ? (
                                    <label className='flex justify-start items-center gap-6 py-4 px-6'>
                                        <input id={item.id ? item.id : item.name} onChange={editSelectedRows} checked={checkIfRowIsSelected(item)}  name={item.id ? item.id : item.name} type="checkbox" className="accent-secondary relative z-20 hover:cursor-pointer"/>
                                        <label htmlFor={item.id ? item.id : item.name} style={{wordWrap: 'break-word'}} className="hover:text-tertiary transition-all max-w-[400px] relative z-20 hover:cursor-pointer">{value}</label>
                                    </label>
                                ) : setSelection ? (
                                    <label className='flex justify-start items-center gap-2 py-4 px-6'>
                                        <input id={item.id ? item.id : item.name} onChange={(e:ChangeEvent<HTMLInputElement>) => setSelection(e.target.value)} name='selection' type='radio' value={item.id ? item.id : item.name} className="mr-2 accent-tertiary hover:cursor-pointer"/>
                                        <label htmlFor={item.id ? item.id : item.name} style={{wordWrap: 'break-word'}} className="hover:text-tertiary transition-all max-w-[400px] hover:cursor-pointer">{value}</label>
                                    </label>
                                ) : (
                                    <button data-testid='redirectButton' style={{wordWrap: 'break-word'}}  disabled={action === undefined ? true : imgHeader ? true : isChecked ? true : false} onClick={(e:any) => performAction(e, item)} className={`text-highlight text-left ${theme === 'dark' ? 'disabled:text-white' : !value ? 'disabled:text-[#a9a9a9]' : 'disabled:text-primary'}  hover:text-tertiary transition-all max-w-[400px] py-4 px-6`}>
                                        {!value ? 'No value' : value}
                                    </button>
                                )}
                            </div>
                        </td>
                    )
                } else {
                    return (
                        <>
                            {rowType === 'base' ? (
                                <BaseRow 
                                headerObj={header}
                                item={item} 
                                isSelected={checkIfRowIsSelected(item)} 
                                itemKey={key}
                                />
                            ) : rowType === 'color' ? (
                                <ColorRows 
                                headerObj={header}
                                item={item} 
                                isSelected={checkIfRowIsSelected(item)} 
                                itemKey={key}
                                limits={limits}
                                />
                            ) : rowType === 'conformal' && threshold !== undefined ? (
                                <ConformalRow 
                                headerObj={header}
                                item={item} 
                                itemKey={key}
                                limits={limits}
                                threshold={threshold}
                                />
                            ) : (
                                <></>
                            )}
                        </>
                    )
                }

            } else {
                return null;
            }
        })}


            {children && <td className='text-right relative bg-none'>
                {checkIfRowIsSelected(item) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30 z-20"></div>}
                {children}
            </td>}
        </tr>
        </>
    )
}