import TrainingModelCover from '../../../../assets/tutorialAssets/Training Models Cover.png';
import ModelIcon from '../../../../assets/model.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';
import Redirect from '../../../../assets/link.svg';
import Trash from '../../../../assets/trash.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
export default function TrainingModelsTutorial() {

    const sections = [
        {id: 'where-do-i-build-models', label: 'Where do I build models?', type: 'main'},
        {id: 'train-model-new-dataset', label: 'Training a model from a new dataset', type: 'sub'},
        {id: 'train-model-existing-dataset', label: 'Training a model from an existing dataset', type: 'sub'},
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{label: 'Training Models', path: 'training-models'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Training Models</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={TrainingModelCover} alt='create-dataset-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id='where-do-i-build-models' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I build models?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={ModelIcon} alt='models-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Models</span> tab</li>
                        <li>Click on the <span className="font-semibold">Train New Model</span> <span className="inline-block darken-icons"><img src={ChevronDown} alt='dropdown-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> button located on the right-hand corner of the screen</li>
                        <li>
                            From the dropdown menu, select how you would like to train your model: 
                            <ol className="list-disc list-inside ml-12 my-2">
                                <li className="mt-4"><span className="font-semibold">From New Dataset</span> - Select this option if you are using a dataset that doesn't already exist in Assay Central</li>
                                <li className="mt-4"><span className="font-semibold">From Existing Dataset</span> - Select this option if you are using a previously created dataset</li>
                            </ol>
                        </li>
                    </ol>
                    <p>After making your selection, you will be redirected to the <span className="font-semibold">Train New Model</span> page. The view will differ depending on which selection you made. You can change views by toggling the switch located on the top right-hand corner of the screen.</p>
                </div>

                <div id='train-model-new-dataset' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Training a model from a new dataset</p>

                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/NewDataset.mp4'} type="video/mp4"/></video>
                    </div>

                    <p className="mb-4">This view will display three cards, with each card representing a step in the process of training a model from a new dataset. You cannot move forward to the next step until the previous step has been completed:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>
                            <span className="font-semibold">Selecting a file</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Click on the <span className="font-semibold text-secondary">Select file to import</span> button inside the Select File card. This will trigger a <span className="font-semibold">Select File Type</span> popup where you can choose between importing an existing file, uploading a new file or creating a new file from a list of CAS registry numbers. Selecting an option and clicking <span className="font-semibold text-secondary">Continue</span> will trigger another dialog corresponding to that selection, as seen when creating a new dataset from the <span className="font-semibold">Datasets</span> page.
                                </li>
                                <li>
                                    After your file has been selected/created, it will appear inside the <span className="font-semibold text-secondary">Select File card</span>. You can get more information about the file by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the file. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside the file, which will redirect you to that file’s Individual File View in a new tab on your browser
                                </li>
                                <li>
                                    If you would like to replace your selected file with a different file, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon. This will reset your file selection and will not delete the file itself. Note that changing your selected file after a new dataset has been generated will reset both the selected file and the dataset.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Generating a new dataset from your selected file</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Click on the <span className="font-semibold text-secondary">Create New Dataset</span> button inside the <span className="font-semibold text-secondary">Generate Dataset</span> card. This will trigger a <span className="font-semibold">Select Dataset Labels</span> popup, where the <span className="font-semibold text-secondary">Selected file</span> field will be populated by the file you selected in the previous step. Once all required fields have been populated, click <span className="font-semibold">Continue</span> to create the dataset.
                                </li>
                                <li>
                                    After your dataset has been generated successfully, it will appear inside the <span className="font-semibold text-secondary">Generate Dataset</span> card. You can get more information about the dataset by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the dataset. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside the datset, which will redirect you to that dataset's Individual Dataset View in a new tab on your browser.
                                </li>
                                <li>
                                    If you would like to replace your selected dataset with a different dataset, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon. This will reset your dataset selection and will not delete the dataset itself.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Setting model training parameters</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Once a file and dataset have been selected, you can set the parameters for the models to be built inside the <span className="font-semibold">Set Training Parameters</span> card. The model type (classification or regression) will be determined by the dataset you created. Complete all required fields in this card before continuing:
                                    <ul className="list-circle list-inside ml-12 my-4 flex flex-col gap-4">
                                        <li><span className="font-semibold">Model Name</span> - A descriptive name for your model. Checking <span className="font-semibold text-secondary">Autogenerate</span> will match the name to the dataset you created.</li>
                                        <li><span className="font-semibold">Descriptors</span> - Available selections include ECFP or FCFP. You can select one descriptor or both.</li>
                                        <li><span className="font-semibold">ECFP/FCFP Bits</span> - For each descriptor, you can select a Bits value from the corresponding dropdown menu. These options include 512, 1024, and 2048.</li>
                                        <li><span className="font-semibold">ECFP/FCFP Radius</span> - For each descriptor, you can select a Radius  value from the corresponding dropdown menu. These options include 2, 3 and 4.</li>
                                        <li><span className="font-semibold">Methods</span> - This field is automatically populated by all machine learning algorithms corresponding to the model type. You can choose to include some or all methods for training.</li>
                                        <li><span className="font-semibold">Conformal Predictors <span className="text-secondary">(optional)</span></span> - If this option is selected, all methods chosen in the Methods field will be built as a conformal predictor model and labeled with the suffix “-CP”. Details on conformal predictors output can be found in the Resultsets View section.</li>
                                    </ul>
                                </li>
                                <li>
                                    Note that resetting your selected dataset will also reset the fields inside the <span className="font-semibold">Set Training Parameters</span> card.
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>

                <div id='train-model-existing-dataset' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Training a model from an existing dataset</p>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/ExistingDataset.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">This view will display two cards, with each card representing a step in the process of training a model from an existing dataset. You cannot move forward to the next step until the previous step has been completed:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>
                            <span className="font-semibold">Selecting a dataset</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Click on the <span className="font-semibold text-secondary">Select dataset to import</span> button inside the Select Dataset card. This will trigger a <span className="font-semibold">Choose Existing Dataset</span> popup where you can select a dataset from a list of datasets that you have access to. Once you make a selection, click <span className="font-semibold text-secondary">Continue</span>.
                                </li>
                                <li>
                                   The dataset you selected will appear inside the <span className="font-semibold text-secondary">Select Dataset</span> card. You can get more information about the dataset by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the dataset. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside the dataset, which will redirect you to that dataset Individual Dataset View in a new tab on your browser
                                </li>
                                <li>
                                    If you would like to replace your selected file with a different dataset, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon. This will reset your dataset selection and will not delete the dataset itself.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Setting model training parameters</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Once a dataset has been selected, you can set the parameters for the models to be built inside the <span className="font-semibold">Set Training Parameters</span> card. The information required will be identical to training a model from a new dataset. Similarly, the model type (classification or regression) will be determined by your selected dataset.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <p className="mb-4">With either view selected, once all of the required steps have been completed, select <span className="font-semibold">Run</span>. If no issues were found in the information you provided, a <span className="font-semibold">Success dialog</span> will be shown stating that your model has begun training. Within this dialog, you can choose <span className="font-semibold">Train another model</span> to restart the process or <span className="font-semibold">Finish</span>, which will redirect you back to the Models page. </p>
                    <p className="mb-4">If there is an issue with initiating model training, an <span className="font-semibold">Error dialog</span> will be shown. You can choose <span className="font-semibold">Try Again</span> to re-initiate model training or <span className="font-semibold">Start Over</span> to restart the process from scratch. </p> 
                    <p>As your model is being trained, it will appear as a task in your <span className="font-semibold">Job Queue</span>. Once training has completed, it will appear in the Models table.</p>
                </div>

                <TutorialPagination nextPage={{page: 'creating-resultsets', label: 'Creating resultsets'}} prevPage={{page: 'creating-datasets', label: 'Creating datasets'}} />

            </div>
        </div>
        </>
    )
}