import { Header, Limit } from "../types/all.types"

export const classificationHeaders: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'N',
        value: 'n',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'AUC',
        value: 'auc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'F1',
        value: 'f1score',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Precision',
        value: 'precision',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Recall',
        value: 'recall',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Accuracy',
        value: 'acc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Specificity',
        value: 'specificity',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: "Cohen's Kappa",
        value: 'cohens_kappa',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'MCC',
        value: 'mcc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Access',
        value: 'access',
        isRequired: false,
        isAscending: true,
        isSelected: false,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: false,
        isSortable: false,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
  ]

  export const regressionHeaders: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'N',
        value: 'n',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'MAE',
        value: 'mae',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'RMSE',
        value: 'rmse',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'R2',
        value: 'r2',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Access',
        value: 'access',
        isRequired: false,
        isAscending: true,
        isSelected: false,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: false,
        isSortable: false,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
  ];

  export const allModelHeaders: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'N',
        value: 'n',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Model Type',
        value: 'model_type',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: false,
    },
   
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: false,
    },
    {
        label: 'Date Created',
        value: 'creation_time',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
  ]

  export const singleClassificationModelHeaders: Header[] = [
    {
        label: 'Method Name',
        value: 'method_name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'AUC',
        value: 'auc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'F1',
        value: 'f1score',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Precision',
        value: 'precision',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Recall',
        value: 'recall',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Accuracy',
        value: 'acc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Specificity',
        value: 'specificity',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: "Cohen's Kappa",
        value: 'cohens_kappa',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'MCC',
        value: 'mcc',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'True Positives',
        value: 'tp',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'True Negatives',
        value: 'tn',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'False Positives',
        value: 'fp',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'False Negatives',
        value: 'fn',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    }
  ]

  export const classificationLimits: Limit[] = [
    {
        value: 'auc',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'f1score',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'precision',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'recall',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'acc',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'specificity',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'cohens_kappa',
        min: -1,
        max: 1,
        isReversed: false,
    },
    {
        value: 'mcc',
        min: -1,
        max: 1,
        isReversed: false,
    },
  ]


//   mae: (Math.round(Number(m.mae) * 100) / 100 as number).toFixed(2),
//                 rmse: (Math.round(Number(m.rmse) * 100) / 100 as number).toFixed(2),
//                 mpd: (Math.round(Number(m.mpd) * 100) / 100 as number).toFixed(2),
//                 mgd: (Math.round(Number(m.mgd) * 100) / 100 as number).toFixed(2),
//                 r2: (Math.round(Number(m.r2) * 100) / 100 as number).toFixed(2),

  export const regressionLimits: Limit[] = [
    {
        value: 'mae',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'rmse',
        min: 0,
        max: 1,
        isReversed: false,
    },
    {
        value: 'r2',
        min: 0,
        max: 1,
        isReversed: false,
    }
  ]


  export const singleRegressionModelHeaders: Header[] = [
    {
        label: 'Method Name',
        value: 'method_name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'MAE',
        value: 'mae',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'RMSE',
        value: 'rmse',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'R2',
        value: 'r2',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    }
  ]

export const defaultClassificationModelFilter = {
    n: {
        op: '',
        quantity: '',
    },
    auc: {
        op: '',
        quantity: '',
    },
    f1score: {
        op: '',
        quantity: '',
    },
    precision: {
        op: '',
        quantity: '',
    },
    recall: {
        op: '',
        quantity: '',
    },
    acc: {
        op: '',
        quantity: '',
    },
    specificity: {
        op: '',
        quantity: '',
    },
    cohens_kappa: {
        op: '',
        quantity: '',
    },
    mcc: {
        op: '',
        quantity: '',
    },
    access: [],
}

export const defaultClassificationModelFilterFields = {
    n: [],
    auc: [],
    f1score: [],
    precision: [],
    recall: [],
    acc: [],
    specificity: [],
    cohens_kappa: [],
    mcc: [],
    created_by: [],
    access: [],
}


export const defaultRegressionModelFilter = {
    n: {
        op: '',
        quantity: '',
    },
    mae: {
        op: '',
        quantity: '',
    },
    rmse: {
        op: '',
        quantity: '',
    },
    r2: {
        op: '',
        quantity: '',
    },
    access: [],
}

export const defaultRegressionModelFilterFields = {
    n: [],
    mae: [],
    rmse: [],
    r2: [],
    // mpd: [],
    // mgd: [],
    created_by: [],
    access: [],
}

export const metricsArr = [
    'mae',
    'rmse',
    'r2',
    'auc',
    'f1score',
    'mcc',
    'acc'
]

export const regressionMethodsArr = [
    'adar',
    'br',
    'en',
    'knr',
    'rfr',
    'svr',
    'xgbr',
]

export const classificationMethodsArr = [
    'DL',
    'ada',
    'bnb',
    'kn',
    'lr',
    'rfc',
    'svc',
    'xgb',
];

export const allMethods = regressionMethodsArr.concat(classificationMethodsArr);


export const defaultModel = {
    acc: '0.5',
    acl: {
      access: [],
      owner: [],
    },
    auc: '0.5',
    cohens_kappa: '0.5',
    created_by: '',
    creation_time: '',
    d2_tweedie_score: '0.5',
    explained_variance: '0.5',
    f1score: '0.5',
    mae:  '0.5',
    mcc:  '0.5',
    mgd:  '0.5',
    mpd:  '0.5',
    n: 8,
    name:  'model_name',
    precision:  '0.5',
    r2:  '0.5',
    recall:  '0.5',
    rmse:  '0.5',
    specificity:  '0.5',
}