import { ChangeEvent, useState, MouseEvent, Dispatch, SetStateAction } from "react";

const useEnableSelection = ({ allItems, modelNames, setModelNames }: { allItems?: string[], modelNames?: string[], setModelNames?: Dispatch<SetStateAction<string[]>> }) => {
    const [ isChecked, setIsChecked ] = useState(false);
    const [ selectedRows, setSelectedRows ] = useState<string[]>([]);
    const [ allAreSelected, setAllAreSelected ] = useState(false);

    

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
        if (e.target.checked === false) {
            setSelectedRows([]);
            setModelNames && setModelNames([]);
        }
    }

    const editSelectedRows = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true ) {
            setSelectedRows(selectedRows.concat(e.target.name))
            setModelNames && modelNames && setModelNames(modelNames.concat(e.target.name))
        } else {
            setSelectedRows(selectedRows.filter(row => row !== e.target.name));
            setModelNames && modelNames && setModelNames(modelNames.filter(row => row !== e.target.name))
        }
    }

    const selectAllRows = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true && allItems) {
            setSelectedRows(allItems);
            setModelNames && setModelNames(allItems)
            setAllAreSelected(true);
        } else {
            setSelectedRows([]);
            setModelNames && setModelNames([]);
            setAllAreSelected(false);
        }
    }

    const addSingleItem = (id: string) => {
        setSelectedRows([id]);
    }

    const deselectItems = () => {
        setSelectedRows([]);
        setModelNames && setModelNames([]);
    }

    const toggleChecked = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsChecked(!isChecked);
    }
    

    return {
        handleChange,
        isChecked,
        selectedRows,
        editSelectedRows,
        selectAllRows,
        allAreSelected,
        addSingleItem,
        deselectItems,
        toggleChecked,
        setSelectedRows,
    }
}

export default useEnableSelection;