import EmptyIcon from '../../assets/NoDocuments.svg';
import EmptyQueue from '../../assets/EmptyState.svg';
import NoResults from '../../assets/NoResults.svg';

export default function EmptyTable({isDashboard, isPopup, searchInput, subject, message, isQueue, isGrid}: {
    isDashboard?: boolean, 
    isPopup?: boolean, 
    searchInput: string,
    subject: string,
    message?: string,
    isQueue?: boolean,
    isGrid?: boolean
}) {
    return (
        <div className="w-full rounded overflow-hidden">
            <table className="w-full">
                    {!isGrid && <tr className='rounded-tl rounded-tr bg-gradient-to-r from-grad-one to-grad-two text-quaternary w-full h-[52px]'><div></div></tr>}
                    <tr className="w-full bg-white">
                        <div className={`w-full ${isDashboard ? 'min-h-[200px]' : isPopup ? 'min-h-[300px]' : 'min-h-[400px]'} flex flex-col justify-center items-center`}>
                        {searchInput.length > 0 ? (
                            <>
                                <img src={NoResults} className="w-[200px]" alt='no-results-icon'/>
                                <p className="text-[18px] font-medium text-primary mb-1">No results found</p>
                                <p className="text-quaternary font-medium mb-10 max-w-[300px] text-center">Try adjusting your search to find what you are looking for</p>
                            </>
                        ) : (
                            <>
                                <img src={isQueue ? EmptyQueue : EmptyIcon} className="w-[200px]" alt='empty-table-icon'/>
                                <p className="text-[18px] font-medium text-primary mb-1">{subject}</p>
                                {message && <p className="text-quaternary font-medium mb-10">{message} and it will show up here</p>}
                            </>
                        )}  
                        </div>
                    </tr>
            </table>
        </div>
                    
    )
}