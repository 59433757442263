export default function TutorialNav({ tutorials }: {
    tutorials: {id: string, label: string, type: string}[];
}) {
    const handleClick = (e: any, targetId: string, offset: number) => {
        e.preventDefault();
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            document.body.scrollTo({
            top: targetElement.offsetTop - offset,
            behavior: 'smooth'
            });
        }
    };

    return (
        <div className="flex flex-col gap-2">
            <p className="text-quaternary text-[12px] mb-2">IN THIS TUTORIAL</p>
            {tutorials.length > 0 && tutorials.map(tutorial => (
                <a href={`#${tutorial.id}`} onClick={(e:any) => handleClick(e, tutorial.id, 40)} className={`text-[16px] hover:underline hover:text-tertiary ${tutorial.type === 'sub' && 'ml-6'}`}>{tutorial.label}</a>
            ))}
        </div>
    )
}