import { Routes, Route } from "react-router-dom"
import Predictions from "./pages/Predictions"
import SingleResultsetPage from "./pages/SingleResultsetPage"
import MakeNewPrediction from "./pages/MakeNewPrediction"
import NotFoundError from "../../components/Alerts/NotFoundError"

export default function PredictionsRoutes() {

    return (
        <Routes>
            <Route index element={<Predictions />} />
            <Route path=':id' element={<SingleResultsetPage />} />
            <Route path='/make-new-prediction' element={<MakeNewPrediction />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}