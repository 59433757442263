import { createSlice } from "@reduxjs/toolkit";
import { Job } from "../../types/jobs.types";

export interface JobState {
    jobs: Job[];
}

const initialState: JobState = {
    jobs: [],
}

export const jobSlice = createSlice({
    name: 'jobs',
    initialState,
    reducers: {
        getJobs: (state, action) => {
            state.jobs = action.payload;
        },
        deleteExistingJobs: (state, action) => {
            state.jobs = state.jobs.filter(job => !action.payload.includes(job._id.$oid));
        }
    }
})

export const { getJobs, deleteExistingJobs } = jobSlice.actions;

export const jobsReducer = jobSlice.reducer;
