import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ModelState } from "../slices/models.slice";

const selectModelReducer = (state:RootState): ModelState => state.models;

export const selectModels = createSelector(
    [selectModelReducer],
    (modelSlice) => modelSlice.models,
)

export const selectClassificationModels = createSelector(
    [selectModelReducer],
    (modelSlice) => modelSlice.classificationModels,
)

export const selectRegressionModels = createSelector(
    [selectModelReducer],
    (modelSlice) => modelSlice.regressionModels,
)

export const selectSingleModel = createSelector(
    [selectModelReducer],
    (modelSlice) => modelSlice.selectedModel,
)

export const selectCompleteModels = createSelector(
    [selectModelReducer],
    (modelSlice) => modelSlice.completeModels,
)