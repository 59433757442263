import Warning from '../../assets/alert-triangle.svg';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
export default function DeletePopup({ label, modelName, togglePopup, path, items, deleteFunc, deselectItems, toggleChecked, groups, isChecked }: { 
    label: string, 
    togglePopup: (e:any) => void, 
    path?: string, 
    items?: string[], 
    groups?: {groupName: string, groupID: string, items: string[]}[],
    deleteFunc?: (ids: string[], name?: string) => Promise<void>,
    deselectItems?: () => void,
    toggleChecked: (e: MouseEvent<HTMLButtonElement>) => void,
    isChecked: boolean,
    modelName?: string,
}) {

    const navigate = useNavigate();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);

    const cancelDelete = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (deselectItems) {
            deselectItems()
        }
        togglePopup(e);
        if (isChecked) {
            toggleChecked(e);
        }
    }


    const deleteAll = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        if (deleteFunc && items) {
            try {
                await deleteFunc(items, modelName);
    
                if (path) {
                    navigate(path);
                }

            } catch (err:any) {
                console.log(err);
            }
            
            setTimeout(function() {togglePopup(e)}, 100);

            if (isChecked) {
                toggleChecked(e);
            }

            if (deselectItems) {
                deselectItems()
            }
            
        }

        setIsLoading(false);
        setIsDisabled(false);
    }

    const deleteItemsInGroup = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        if (groups && deleteFunc) {
            const promises = groups.map(async (group) => {
                let items = group.items;
                return deleteFunc(items)
            })

            try {

                await Promise.all(promises);
                togglePopup(e);

                if (isChecked) {
                    toggleChecked(e);
                }

                if (path) {
                    navigate(path);
                }

                if (deselectItems) {
                    deselectItems();
                }

            } catch (err:any) {
                console.log(err);
            }
        }

        setIsDisabled(false);
        setIsLoading(false);
        
    }


    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-10 flex flex-col gap-8 justify-center items-stretch'>
                    <div className='w-full flex justify-start items-center gap-4'>
                        <img src={Warning} className='w-[32px]' alt='warning-icon'/>
                        <p className='text-[1.5rem] font-semibold'>Delete Confirmation</p>
                    </div>
                    <p className='text-[16px] max-w-[450px]'>Are you sure you want to delete {label}? This action cannot be undone.</p>
                    {/* <div className="flex justify-center">
                        <img src={Warning} className='w-[150px]'  alt='delete-item-popup-icon'/>
                    </div>
                    <p className='max-w-[450px] text-center text-[2rem] leading-10 font-semibold text-primary'>Do you really want to delete {label}?</p> */}
                    <div className='flex justify-end items-center gap-2'>
                        <button onClick={cancelDelete} className='py-2 px-4 rounded border border-quaternary bg-white hover:bg-quaternary hover:text-white transition-all text-quaternary text-[16px] mr-2'>Cancel</button>
                        <button disabled={isDisabled} onClick={(e:MouseEvent<HTMLButtonElement>) => {groups ? deleteItemsInGroup(e) : deleteAll(e)}} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[16px] border border-tertiary bg-tertiary hover:border-secondary hover:bg-secondary transition-all disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                        {isLoading ? (
                            <>
                                <CircularProgress size={16} color='inherit'/>
                                <p>Deleting</p>
                            </>
                        ) : (
                            <p>Delete</p>
                        )}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}