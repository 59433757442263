import { useDispatch, useSelector } from "react-redux";
import API from '../../api';
import { deleteExistingReadacross, getReadacross, updateExistingReadacross } from "../../store/slices/readacross.slice";
import { selectReadacross } from "../../store/selectors/readacross.selector";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { ParsedReadacross, ReadAcrossType } from "../../types/readacross.types";
import { filterItems, getDateFromObjectId, orderByDate, searchItems, sortByColumn } from "../../data/functions";
import { FilterType, Header } from "../../types/all.types";
import { FilterFieldsType } from "../../types/all.types";
import { defaultReadacrossFilter, defaultReadacrossFilterFields, readacrossHeaders } from "../../data/readacross";
import { download } from "../../data/functions";
// import { selectUsers } from "../../store/selectors/users.selector";
import { useNavigate } from "react-router-dom";

const hasMoleculeID = (obj: any): obj is {$oid: string} => {
    if (obj.$oid) {
        return true;
    } else {
        return false;
    }
} 

const useReadacross = ({ setPopupMessage }: {
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const dispatch = useDispatch();
    // const users = useSelector(selectUsers);
    const navigate = useNavigate();
    const readacross = useSelector(selectReadacross);
    const [ allReadacross, setAllReadacross ] = useState<ParsedReadacross[]>([]);
    const [ modifiedReadacross, setModifiedReadacross ] = useState<ParsedReadacross[]>([]);
    const [ allReadacrossIDs, setAllReadacrossIDs ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>(readacrossHeaders);

    const [ filter, setFilter ] = useState<FilterType>(defaultReadacrossFilter);
    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultReadacrossFilterFields);

    // Gets all readacross items and stores in redux
    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            const fetchReadacross = async () => {
                setIsLoading(true);

                try {

                    const response = await API.get('readacross', { headers: { 'X-Auth': authKey } });
                    const data: ReadAcrossType[] = await response.data;
                    dispatch(getReadacross(data));


                } catch (err:any) {

                    console.log(err);
                    navigate('/500');

                }

                setIsLoading(false);
                
            }

            fetchReadacross();
        } else {
            navigate('/401');
        }

        //eslint-disable-next-line
    }, [dispatch]);

    // Extracts info from readacross object and identifies if structure info is given as a smiles string or object id
    useEffect(() => {
        if (readacross && readacross.length > 0) {
            const parsedReadacross: ParsedReadacross[] = readacross.map(across => {

                return ({
                id: across._id.$oid,
                Structure: hasMoleculeID(across.molecule) ? across.molecule.$oid : across.molecule,
                hasMoleculeID:  hasMoleculeID(across.molecule) ? true : false,
                name: across.name,
                dataset: across.datasets_name[0],
                access: across.acl.access,
                created_by: across.user ? across.user.full_name : '',
                date_created: getDateFromObjectId(across._id.$oid).toLocaleString('en-US', {timeZone: 'EST'}),
            })});

            const ordered = orderByDate(parsedReadacross);

            setAllReadacross(ordered);
            setModifiedReadacross(ordered);
        }
    }, [readacross]);


    // Extracts readacross ids for table selection
    useEffect(() => {
        if (modifiedReadacross.length > 0) {
            setAllReadacrossIDs(modifiedReadacross.map(a => a.id));
        }
    }, [modifiedReadacross]);

    useEffect(() => {
        if (allReadacross.length > 0) {
            const datasets = allReadacross.map(file => file.dataset);
            const created_by = allReadacross.map(file => file.created_by);
            const access = allReadacross.map(file => file.access);
            setFilterFields({
                dataset: Array.from(new Set(datasets)).filter(a => a).map(p => p),
                created_by: Array.from(new Set(created_by)).filter(a => a).map(p => p),
                access: Array.from(new Set(access)).filter(a => a),
            })
        }
        
    }, [allReadacross]);

    const modifyReadacross = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, allReadacross);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedReadacross(filteredArr);
    }


    const updateReadacrossPermissions = async (ids: string[], owner: string, access: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = ids.map(async (id) => {
            const aclObj = {owner, access, read: [], write: []};
            return API.put(`readacross/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                .then(() => API.get(`readacross/${id}`,  { headers: { 'X-Auth': authKey } }))
                .then(res => dispatch(updateExistingReadacross(res.data)));
        });

        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'results you selected were' : 'result you selected was'} updated successfully!`, false);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating ${ids.length > 1 ? 'these results' : 'this result'}`, false);
            }
        }
    };
    

    const deleteReadacross = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`readacross/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingReadacross(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'results you selected were' : 'result you selected was'} deleted successfully`, false);
            } catch(err:any) {
                console.log(err);
                setPopupMessage('There was an issue deleting these readacross files', false);
            }
        }
    };
    

    const downloadReadacross = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`readacross/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
                setPopupMessage('The result you selected was successfully exported!', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('There was an issue downloading these readacross files', false);
            }
        }
    }



    return {
        readacross,
        allReadacross,
        modifiedReadacross,
        modifyReadacross,
        filter,
        setFilter,
        headers,
        setHeaders,
        allReadacrossIDs,
        isLoading,
        deleteReadacross,
        filterFields,
        downloadReadacross,
        updateReadacrossPermissions
    }

}

export default useReadacross;