import { Header, Limit } from "../../types/all.types";
import { useState, useEffect } from "react";
import { getThresholdValue } from "../../data/functions";

const methods = ['DL', 'ada', 'bnb', 'knn', 'lreg', 'rf', 'svc', 'xgb'];

const getModeColor = (modeLabel: string)  => {
    if (modeLabel === 'Out of Domain') {
        return { color: '#e0e0e0', label: 'Out of Domain' }
    } else if (modeLabel === 'Inconclusive') {
        return { color: '#FFC061', label: 'Inconclusive' }
    } else if (modeLabel === 'Inactive') {
        return { color: '#FF8484', label: 'Inactive' }
    } else {
        return { color: '#7BC960', label: 'Active' }
    }
}

export default function ConformalRow({ item, threshold, limits, headerObj, itemKey }: {
    item: any,
    itemKey: string,
    headerObj: Header,
    limits?: Limit[],
    threshold: number,
}) {
    const [ mode, setMode ] = useState('');


    const convertPercentage = (min: number, max: number, decimal: number) => {
        const difference = Math.abs(max - min);
        const ratio = difference * decimal;
        const mark = min + ratio;
        return mark;
    }

    useEffect(() => {
        if (threshold) {
            getThresholdMode(item, threshold);
        }
    }, [threshold, item]);

    const getThresholdMode = (item: any, threshold: number) => {
        let arr: string[] = [];
        let frequency: {[key:string]: number} = {};

        Object.keys(item).forEach(key => {
            if (methods.includes(key)) {
                const { label } = getThresholdValue(item[itemKey], threshold);
                arr.push(label);
            }
        });

        arr.forEach(field => {
            const label = Object.keys(frequency).find(key => key === field);
            if (!label) {
                frequency[field as keyof object] = 1;
            } else {
                frequency[label as keyof object] = frequency[label as keyof object]+1;
            }
        })

        let maxValue = 0;
        let maxKey = null;
        let duplicates: string[] = [];

        for (const [key, value] of Object.entries(frequency)) {
            if (value > maxValue) {
                maxValue = value;
                maxKey = key;
            }
        }

        for (const [key, value] of Object.entries(frequency)) {
            if (value === maxValue) {
                duplicates.push(key);
            }
        };

        

        if (maxKey && duplicates.length <= 1) {
            setMode(maxKey)
        } else if (maxKey && duplicates.length > 1) {
            if (duplicates.includes('Out of Domain')) {
                setMode('Out of Domain')
            } else if (duplicates.includes('Inconclusive')) {
                setMode('Inconclusive')
            } else if (duplicates.includes('Inactive')) {
                setMode('Inactive')
            } else {
                setMode('Active');
            }
        } else {
            setMode('Out of Domain');
        }
    }
    


    if (methods.includes(headerObj.label)) {
        const { color, label } = getThresholdValue(item[itemKey], threshold);
        return (
            <td style={{backgroundColor: `${color}`}} className={`text-primary py-4 px-6 text-center ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{label}</td>
        )
    } else if (headerObj.label === 'mode') {
        const { color, label } = getModeColor(mode);
        return (
            <td style={{backgroundColor: `${color}`}} className={`text-primary py-4 px-6 text-center ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{label}</td>
        )
    } else if (headerObj.label === 'duplicate') {
        return (
            <td className={`text-primary py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'} ${item[itemKey] === '1.00' ? 'bg-[#7BC960]' : 'bg-[#FF8484]'}`}>
                {item[itemKey] === '1.00' ? 'True' : 'False'}
            </td>
        )
    } else if (headerObj.label === 'ad-score') {
        const limitsObj = limits && limits.find(l => l.value === itemKey);
        if (!limitsObj) {
                return (
                    <td className={`text-primary py-4 px-6 text-center ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
                )
        } else if (item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.3)) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#FF8484] ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
            )
        } else if ((item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.3) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.49))) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#FFA461] ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
            )
        } else if ((item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.49) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.69))) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#FFF278] ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
            )
        } else if ((item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.69) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.89))) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#C7DB78] ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
            )
        } else if (item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.89)) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#7BC960] ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey]}</td>
            )
        } else {
            return null;
        }
    } else {
        return (
            <td className={`py-4 px-6 text-center text-nowrap break-words ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>{item[itemKey] === undefined ? 'N/A' : item[itemKey]}</td>
        )
    }
}