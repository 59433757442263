
import Overlay from './Overlay';
import Edit from '../../assets/edit.svg';
import Check from '../../assets/check.svg';
import { ReactNode, MouseEvent } from 'react';
import { SideInfo } from '../../types/all.types';
import EditInfo from '../Data/EditInfo';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';
import usePrivileges from '../../hooks/usePrivileges';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectApplication, selectTheme } from '../../store/selectors/auth.selector';


export default function AdditionalInfoSideMenu({ children, label, togglePopup, info, updateFunc, options, id }: {
    children?: ReactNode,
    label: string,
    togglePopup: (e: MouseEvent<HTMLButtonElement>) => void,
    info?: SideInfo[];
    updateFunc?: (obj: any) => Promise<void>,
    options?: any,
    id?: string,
 }) {

    const [ isEdit, setIsEdit ] = useState(false);
    const [ metadata, setMetadata ] = useState<any>();
    const [ isLoading, setIsLoading ] = useState(false);
    const [isDisabled, setIsDisabled ] = useState(false);
    const { canDelete } = usePrivileges();
    const theme = useSelector(selectTheme);

    const application = useSelector(selectApplication);
    const { pathname } = useLocation();

    const toggleEdit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsEdit(!isEdit);
    }

    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (updateFunc) {
            setIsLoading(true);
            setIsDisabled(true);
            await updateFunc(metadata);
            setIsLoading(false);
            setIsDisabled(false);
            toggleEdit(e);
        }
    }

    const showButtons = () => {
        if (pathname.includes('models') && !(application).includes('assay-central')) {
            return false;
        } else if (canDelete(id ? id : '') && updateFunc) {
            return true;
        } else {
            return false;
        }
    }



    return (
        <Overlay togglePopup={togglePopup}>
            <div className='w-full h-full flex justify-end items-center'>
                <div onClick={(e:any) => e.stopPropagation()} className={`side-menu h-full max-h-screen flex flex-col gap-8 py-4 px-6 ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} max-w-[600px] w-full`}>
                    <div className='w-full flex justify-between items-center'>
                        <p className='text-[2rem] font-semibold'>{label}</p>
                        {showButtons() && (
                        <div data-testid='canEditMetadata' className="flex gap-3 justify-end items-center">
                            <button disabled={isDisabled} onClick={(e:any) => {isEdit ? handleSubmit(e) : toggleEdit(e)}} className="flex justify-center items-center gap-3 py-2 px-4 rounded bg-tertiary border border-tertiary hover:bg-secondary hover:border-secondary disabled:bg-quaternary/[0.3] disabled:border-quaternary/[0.3] transition-all text-white whiten">
                                {isLoading ? (
                                    <CircularProgress size={18} color="inherit"/>
                                ) : (
                                    <img className="w-[18px]" src={isEdit ? Check : Edit} alt="edit-icon" />
                                )}                     
                                <p className="font-medium">{isEdit ? 'Save Changes' : 'Edit Metadata'}</p>
                            </button>
                            {isEdit && (
                                <button className="py-2 px-4 border rounded border-quaternary text-quaternary font-medium hover:bg-quaternary hover:text-white transition-all" onClick={toggleEdit}>Cancel</button>
                            )}
                        </div>
                        
                        )}
                    </div>
                    <div className='grow overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-start items-stretch'>
                        {children ? children : <>{info && <EditInfo metadata={metadata} setMetadata={setMetadata} info={info} isEdit={isEdit} options={options}/>}</>}
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}