import { FilterType, Header } from "../types/all.types";
import { useState, MouseEvent, Dispatch, SetStateAction } from "react";
import { applyFilters } from "../data/functions";

const useSortByHeaders = ({ allItems, setSortedItems, setFilteredItems, filterObj, searchInput, dynamicKeys }: { 
    allItems: any[], 
    setSortedItems: Dispatch<SetStateAction<any[]>>, 
    setFilteredItems?: Dispatch<SetStateAction<any[]>> ,
    filterObj?: FilterType,
    searchInput?: string,
    dynamicKeys?: boolean,
}) => {
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ selectedHeader, setSelectedHeader ] = useState('');


    const selectHeaders = (e:MouseEvent<HTMLButtonElement>, label: string) => {
        e.preventDefault();
        const updatedHeaders = headers.map(h => {
            if (h.label === label) {
                return { ...h, isSelected: !h.isSelected }
            } else {
                return { ...h }
            }
        });


        setHeaders(updatedHeaders);
    };

    const setSortedColumn = (e:any, value: string) => {
        e.preventDefault();
        
        
        setHeaders(headers.map(h => {
            if (h.value === value) {
                return { ...h, isAscending: !h.isAscending }
            } else {
                return { ...h }
            }
        }))

        setSelectedHeader(value);


        const headerObj = headers.find(h => h.value === value);

        if (headerObj) {
            changeItemOrder(headerObj.isAscending, value);
        }
    };


    const changeItemOrder = (isAscending: boolean, label: string) => {
        let key = '';

        if (dynamicKeys) {
            key = label;
        } else {
            key = label.toLocaleLowerCase().split(' ').join('_');
        }

        
        const ascending = [...allItems].sort((a,b) => {
            let aVal: number | string | Date = 0;
            let bVal: number | string | Date = 0;

            if (key === 'date_created') {
                aVal = new Date(a[key as keyof object] as string);
                bVal = new Date(b[key as keyof object] as string);
            } else {
                let numA = parseFloat(a[key]);
                let numB = parseFloat(b[key]);

                if (isNaN(numA) || isNaN(numB)) {
                    console.log(a[key])

                    aVal = a[key].toLocaleString().toLocaleLowerCase();
                    bVal = b[key].toLocaleString().toLocaleLowerCase();
                } else {
                    aVal = numA;
                    bVal = numB;
                }
            }

            if (aVal > bVal) {
                return -1;
            } else if (aVal < bVal) {
                return 1;
            } else {
                return 0;
            }

    
            
        })
    
        const descending = [...allItems].sort((a,b) => {
            let aVal: number | string | Date = 0;
            let bVal: number | string | Date = 0;

            if (key === 'date_created') {
                aVal = new Date(a[key as keyof object] as string);
                bVal = new Date(b[key as keyof object] as string);
            } else {
                let numA = parseFloat(a[key]);
                let numB = parseFloat(b[key]);

                if (isNaN(numA) || isNaN(numB)) {
                    aVal = a[key].toLocaleString().toLocaleLowerCase();
                    bVal = b[key].toLocaleString().toLocaleLowerCase();
                } else {
                    aVal = numA;
                    bVal = numB;
                }
            }

            if (aVal < bVal) {
                return -1;
            } else if (aVal > bVal) {
                return 1;
            } else {
                return 0;
            }
            
            
        });
        
    
        if (isAscending) {
            setSortedItems(ascending);
            if (setFilteredItems) {
                if (filterObj && searchInput) {
                    applyFilters(filterObj, ascending, setFilteredItems, searchInput)
                } else {
                    setFilteredItems(ascending);
                }
            }
            
           
        } else {
            setSortedItems(descending);
            if (setFilteredItems) {
                if (filterObj && searchInput) {
                    applyFilters(filterObj, descending, setFilteredItems, searchInput)
                } else {
                    setFilteredItems(descending);
                }
            }
            
        }
    }

    return {
        selectHeaders,
        headers,
        setHeaders,
        selectedHeader,
        setSortedColumn,
    }


}

export default useSortByHeaders;