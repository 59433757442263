import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';

import usePrivileges from '../../../hooks/usePrivileges';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPredictions } from '../../../store/selectors/predictions.selector';
import { PopupType } from '../../../hooks/usePopup';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}



export default function IndividualResultsetOptions({ setPopupType ,toggleDeletePopup, id, downloadFunc, threshold, handleClose, addSingleItem }: { 
    toggleDeletePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    id?: string,
    downloadFunc: (filename: string, id: string, ext: string, threshold: number) => Promise<void>,  
    threshold: number | null,
    handleClose: () => void,
    addSingleItem?: (id: string) => void,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
}) {
    const { canDelete } = usePrivileges();
    const predictions = useSelector(selectPredictions);
    const [ isDownloadingXLSX, setIsDownloadingXLSX ] = useState(false);
    const [ isDownloadingSDF, setIsDownloadingSDF ] = useState(false);

   

    const downloadItem = async (e:MouseEvent<HTMLButtonElement>, ext: string) => {
        e.preventDefault();
        if (id) {
            const prediction = predictions.find(p => p._id.$oid === id);
            if (prediction) {

                if (ext === 'xlsx') {
                    setIsDownloadingXLSX(true)
                } else {
                    setIsDownloadingSDF(true);
                }
    
                const filename = `${prediction.name ? prediction.name : 'prediction'}.${ext}`
                await downloadFunc(filename, id, ext, threshold ? threshold : 0.8);
    
                if (ext === 'xlsx') {
                    setIsDownloadingXLSX(false)
                } else {
                    setIsDownloadingSDF(false);
                }

            }
           
        }

        handleClose();
    }

    

    return (
        <>
            <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e, 'xlsx'); handleClose()}}>
                <div className='flex justify-start items-center gap-2'>
                    {isDownloadingXLSX && <CircularProgress size={12} color='inherit' />}
                    <p>Export (.xlsx)</p>
                </div>
            </MenuItem>
            <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e, 'sdf'); handleClose()}}>
                <div className='flex justify-start items-center gap-2'>
                    {isDownloadingSDF && <CircularProgress size={12} color='inherit' />}
                    <p>Export (.sdf)</p>
                </div>
            </MenuItem>
            {canDelete(id!) && <MenuItem sx={sx} onClick={(e:any) => {toggleDeletePopup(e); setPopupType && setPopupType('delete') ;addSingleItem && addSingleItem(id ? id : ''); handleClose()}}>Delete</MenuItem>}
        </>
  
    )
}