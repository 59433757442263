import { ReactNode, useEffect, useState } from "react";

export default function Zoomist({ children }: { children: ReactNode }) {
    const [ zoomInstance, setZoomInstance ] = useState<any | null>(null);

    const handleLoad = async () => {
        const win:any = window;
        if (!win.Zoomist) {
            window.setTimeout(handleLoad, 100)
        } else {
            setZoomInstance(new win.Zoomist('.zoomist-container', {
                maxScale: 4,
                bounds: true,
                slider: true,
                zoomer: true,
                minScale: 1,
            }));
        }
    }

    
    

    return (
        <div onLoad={handleLoad} className="zoomist-container">
            <div className="zoomist-wrapper">
                <div className="zoomist-image">
                    {children}
                </div>
            </div>
        </div>
    )
}