import { CompleteModel } from "../../../../types/models.types";
import ModelImg from '../../../../assets/model-colored.png';
import View from '../../../../assets/link.svg';
import Trash from '../../../../assets/trash.svg';
// import { useState } from "react";
import { MouseEvent } from "react";
// import FlowCardInfo from "../../../../components/Data/FlowCardInfo";
// import useAllModels from "../../../../hooks/pageHooks/useAllModels";

export default function MultipleModelSelections({ singleModel, index, deleteModel }: { 
    singleModel: CompleteModel,
    index: number,
    deleteModel?: (e: MouseEvent<HTMLButtonElement>, index: number, modelName: string) => void,
}) {


    return (
        <div className='flex flex-col justify-start items-stretch gap-2'>
            <div className='flex justify-between items-stretch gap-4 p-6 border-2 border-quaternary bg-white rounded w-full'>
                <div className='grow flex justify-start items-center gap-4'>
                    <img src={ModelImg} alt='any-model-icon' className="w-[80px]"/>
                    <div>
                        <p className="font-semibold text-primary mb-[2px] break-all">{singleModel.name}</p>
                        <p className="font-medium text-quaternary text-[12px]">{singleModel.auc ? 'Classification' : 'Regression'}</p>
                    </div>
                </div>
                <div className="h-full flex justify-center items-start gap-2">
                    {/* <button className="p-1 rounded-full border-2 border-quaternary"><img src={Download} alt='download-icon' className="w-[20px]"/></button> */}
                    <a href={`${process.env.REACT_APP_HOST_URL}/models/${singleModel.name}`} target='_blank' rel='noreferrer' className="lighten p-1 rounded-full border-2 border-quaternary hover:bg-quaternary transition-all"><img src={View} alt='view-icon' className="w-[20px]"/></a>
                    <button onClick={(e:any) => {deleteModel && deleteModel(e, index, singleModel.name)}} className="lighten p-1 rounded-full border-2 border-quaternary hover:bg-quaternary transition-all"><img src={Trash} alt='trash-icon' className="w-[20px]"/></button>
                </div>
            </div>
            {/* <FlowCardInfo toggleShow={toggleShow} isShown={isShown} info={info} /> */}
        </div>
    )
}