import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { LocationState } from "../slices/location.slice";

const selectLocationReducer = (state:RootState): LocationState => state.location;

export const selectPrevLocation = createSelector(
    [selectLocationReducer],
    (locationSlice) => locationSlice.prevPath,
)

