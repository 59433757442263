import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/selectors/auth.selector";


export default function LoadingRow() {
    const theme = useSelector(selectTheme);


    return (
        <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px] w-full`}>
            <SkeletonTheme baseColor={`${theme === 'dark' ? '#454E69' : '#ebebeb'}`} highlightColor={`${theme === 'dark' ? '#646A7B' : '#f5f5f5'}`}>
            <td className="py-4 px-6"><Skeleton /></td>
            </SkeletonTheme>
        </tr>
    )
}