import { useState, MouseEvent, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useLayout = () => {
    const [ isGrid, setIsGrid ] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes('models')) {
            setIsGrid(true);
        } else {
            setIsGrid(false);
        }
    }, [pathname])
    
    const toggleView = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsGrid(!isGrid);
    }

    return {
        toggleView,
        isGrid,
    }
}

export default useLayout;