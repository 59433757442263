import { getTimezoneOffset } from "../../data/functions";
import { Header, Limit } from "../../types/all.types";

export default function ColorRows({ item, itemKey, limits, isSelected, headerObj}: {
    item: any,
    itemKey: string,
    isSelected: boolean,
    headerObj: Header,
    limits?: Limit[],
}) {


    const convertPercentage = (min: number, max: number, decimal: number) => {
        const difference = Math.abs(max - min);
        const ratio = difference * decimal;
        const mark = min + ratio;
        return mark;
    }




    if (!isNaN(Number(item[itemKey]))) {
        const limitsObj = limits && limits.find(l => l.value === itemKey);
        if (!limitsObj) {
            return (
                <td className={`py-4 px-6 text-center bg-none relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (headerObj.label === 'duplicate') {                            
            return (
                <td className={`py-4 px-6 text-center relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'} ${item[itemKey] === '1.00' ? 'bg-[#7BC960]' : 'bg-[#FF8484]'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey] === '1.00' ? 'True' : 'False'}
                </td>
            )

        } else if (['mae', 'rmse'].includes(itemKey)) {
            return (
                <td className={`text-primary py-4 px-6 text-center bg-[#e0e0e0] relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.3)) {
            return (
                <td className={`text-primary py-4 px-6 text-center relative ${limitsObj.isReversed ? 'bg-[#7BC960]' : 'bg-[#FF8484]'} ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.3) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.49)) {
            return (
                <td className={`text-primary py-4 px-6 text-center relative ${limitsObj.isReversed ? 'bg-[#C7DB78]' : 'bg-[#FFA461]'} ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.49) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.69)) {
            return (
                <td  className={`text-primary py-4 px-6 text-center bg-[#FFF278] relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.69) && item[itemKey] <= convertPercentage(limitsObj.min, limitsObj.max, 0.89)) {
            return (
                <td className={`text-primary py-4 px-6 text-center relative ${limitsObj.isReversed ? 'bg-[#FFA461]' : 'bg-[#C7DB78]'} ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                     {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else if (item[itemKey] > convertPercentage(limitsObj.min, limitsObj.max, 0.89)) {
            return (
                <td className={`text-primary py-4 px-6 text-center relative ${limitsObj.isReversed ? 'bg-[#FF8484]' : 'bg-[#7BC960]'} ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                    {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                    {item[itemKey]}
                </td>
            )
        } else {
            return null;
        }
    } else {
        return (
            <td className={`py-4 px-6 text-center text-nowrap break-words relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                {item[itemKey]} {headerObj.value === 'date_created' ? getTimezoneOffset(item[itemKey]) : ''}
            </td>
        )
    }
}