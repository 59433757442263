import Plot from 'react-plotly.js';
import { Config } from 'plotly.js';


export default function PlotlyGraphs({data, type}: {data: Plotly.Data[], type: 'roc' | 'scatter' | 'histogram'}) {


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    const layout_reg: () => Partial<Plotly.Layout> = function() {
        const combinedData = ((data[0] as Plotly.ScatterData).x as number[]).concat((data[0] as Plotly.ScatterData).y as number[]);
        const sortedData = combinedData.sort((a,b) => a - b);

        let low_value = Math.floor(Number(sortedData[0]));
        let high_value = Math.round(Number(sortedData[sortedData.length - 1]));
    
        return {
          title: {
            text: "Cross-Validation Predictions",
            yref: 'paper',
            xref: 'paper',
            yanchor: 'bottom',
            y: 1,
            pad: {
              b:10,
              l:0,
              r:0,
              t:10
            },
            xanchor: 'auto',
            font: {
                family: 'Montserrat',
                size: 16,
            }
          },
          xaxis: {range: [low_value, high_value]},
          yaxis:  {range: [low_value, high_value]},
          responsive: true,
          autosize: true,
          showlegend:false,
          margin: {
            l: 30,
            r: 10,
            b: 30,
            t: 60,
            pad: 0
          },
          modebar: {
            color: '#555555',
            activecolor: '#315FB5',
          },
          shapes: [
            {
              type: 'line',
              x0: low_value,
              y0: low_value,
              x1: high_value,
              y1: high_value,
              line: {
                color: '#999999',
                width: 2,
                dash: 'dot'
              }
            }
          ]
        }
    } 

    const layout_roc: () => Partial<Plotly.Layout> = function() {

        return {

            title: {
              text: "5-fold Cross Validation ROC",
              yref: 'paper',
              xref: 'paper',
              yanchor: 'bottom',
              y: 1,
              pad: {
                b:10,
                l:0,
                r:0,
                t:10
              },
              xanchor: 'auto',
              font: {
                family: 'Montserrat',
                size: 16,
              }
            },
            responsive: true,
            autosize: true,  
            showlegend:false,
            margin: {
              l: 30,
              r: 10,
              b: 30,
              t: 60,
              pad: 0
            },
            modebar: {
              color: '#555555',
              activecolor: '#315FB5',
            },
            shapes: [
              {
                type: 'line',
                x0: 0,
                y0: 0,
                x1: 1,
                y1: 1,
                line: {
                  color: '#999999',
                  width: 2,
                  dash: 'dot'
                }
              }
            ]

        }
    }

    const layout_hist: () => Partial<Plotly.Layout> = function() {

        return {
            barmode: "overlay", 
            title: {
              text: "Model Evaluation Overlay",
              yref: 'paper',
              xref: 'paper',
              yanchor: 'bottom',
              y: 1,
              pad: {
                b:10,
                l:0,
                r:0,
                t:10
              },
              xanchor: 'auto',
              font: {
                family: 'Montserrat',
                size: 16,
              }
            },
            responsive: true,
            autosize: true, 
            showlegend:false,
            margin: {
              l: 38,
              r: 10,
              b: 38,
              t: 60,
              pad: 0
            },
            modebar: {
              color: '#555555',
              activecolor: '#315FB5',
            },
            xaxis: {
                title: {
                    text: 'Value',
                    font: {
                        family: 'Montserrat',
                        size: 14,
                    },
                }
            }, 
            yaxis: {
                title: {
                    text: 'Count',
                    font: {
                        family: 'Montserrat',
                        size: 14,
                    },
                }
            }, 
          }
        
    }




    return (
        <Plot data={data} layout={type === 'scatter' ? layout_reg() : type === 'roc' ? layout_roc() : layout_hist()} config={config} className='w-full aspect-square'/>
    )
}