import { useEffect, useState } from "react";
import useSortByHeaders from "../../../hooks/useSortByHeaders";
import { predictionsHeader, setupResults } from "../../../data/predictions";
// import useResults from "../../../hooks/pageHooks/useResults";
import EmptyTable from "../../../components/Table/EmptyTable";
import { ParsedPrediction, Prediction } from "../../../types/predictions.types";

import API from '../../../api';
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";

export default function RecentPredictions({ application }: { application: string }) {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ allResults, setAllResults] = useState<ParsedPrediction[]>([]);
    const [ sortedResults, setSortedResults ] = useState<ParsedPrediction[]>([]);
    const [ quantity, setQuantity ] = useState(3);



    useEffect(() => {

        const fetchResults = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                setIsLoading(true);


                try {

                    const response = await API.get(`resultsets`, { headers: { 'X-Auth': authKey } });
                    const data: Prediction[] = await response.data;

                    const ordered = setupResults(data);

                    setAllResults(ordered!);
                    setSortedResults(ordered!);

                } catch (err: any) {

                    console.log(err);
                    
                }

                setIsLoading(false);

                
            }
            
        }

        fetchResults();

    }, []);
    
    useEffect(() => {
        
        setHeaders(predictionsHeader)

    }, []);

    useEffect(() => {
        if (application) {
            if (!(application).includes('assay-central')) {
                setQuantity(5)
            } else {
                setQuantity(3);
            }
        } else {
            setQuantity(3);
        }
    }, [application]);


    const { 
        headers, 
        setHeaders, 
        selectedHeader, 
        setSortedColumn 
    } = useSortByHeaders({ allItems: allResults, setSortedItems: setSortedResults, setFilteredItems: setSortedResults });


    return (
        <>
        {isLoading || sortedResults.length > 0 ? (
            <TableBase
                isLoading={isLoading}
                isChecked={false}
                isPopupOpen={false}
                hasOptions={false} 
                headers={headers} 
                selectedHeader={selectedHeader} 
                setSortedColumn={setSortedColumn}
                quantity={quantity}
            >
                {sortedResults.length > 0 && sortedResults.slice(0,quantity).map(item => (
                    <TableRowBase 
                        item={item} 
                        headers={headers} 
                        isChecked={false}
                        action={{path: 'predictions'}}
                        rowType="base"
                    />
                ))}
            </TableBase>
        ) : (
            <EmptyTable isPopup={true} message="Make a new prediction" subject="No resultsets found" searchInput='' />
        )}
        </>
    )
}