
// hooks
import { useState, useEffect, MouseEvent } from "react";
import usePagination from "../../../hooks/usePagination";
import useEnableSelection from "../../../hooks/useEnableSelection";


// data
import { datasetsTableHeaders, defaultDatasetFilter } from "../../../data/datasets";


// components
import Pagination from "../../../components/Page/Pagination";
import EnableSelectionButton from '../../../components/Buttons/EnableSelectionButton';
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import FilterPopup from '../../../components/Popups/FilterPopup';
import DeletePopup from "../../../components/Popups/DeletePopup";
import EmptyTable from "../../../components/Table/EmptyTable";
import SuccessPopup from '../../../components/Popups/SuccessPopup';

// types
import TableControls from '../../../components/Table/TableControls';
import FilterButton from '../../../components/Buttons/FilterButton';
import useDataset from "../../../hooks/pageHooks/useDataset";
import SnackBar from '../../../components/Alerts/SnackBar';
import useMessage from "../../../hooks/useMessage";
import { Dataset } from "../../../types/datasets.types";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import { useSelector } from "react-redux";
import { selectApplication, selectTheme } from "../../../store/selectors/auth.selector";
import IndividualDatasetOptions from "../components/IndividualDatasetOptions";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import Overlay from "../../../components/Popups/Overlay";
import ExistingFilePopup from "../../../components/Popups/ExistingFilePopup";
import UploadFile from "../../Files/components/UploadFile";
import CreateDatasetPopup from "../components/CreateDatasetPopup";
import ModifyPermissionPopup from "../../../components/Popups/ModifyPermissionsPopup";
import useModifyTableItems from "../../../hooks/useModifyTableItems";




export default function SeparateDatasets({ preloadedDatasets, isLoading, selection, toggleObj } : { 
    preloadedDatasets: Dataset[], 
    isLoading: boolean, 
    selection: "new" | "existing", 
    toggleObj: any,
}) {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const theme = useSelector(selectTheme);
    const application = useSelector(selectApplication);

    const {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    } = useMessage();

    const {
        allDatasets,
        filterFields,
        allDatasetIDs,
        deleteDatasets,
        downloadDataset,
        updateDatasetPermissions,
        headers,
        setHeaders,
        filter,
        setFilter,
        modifiedDatasets,
        modifyDatasets,
    } = useDataset({ setPopupMessage, preloadedDatasets });

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyDatasets, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })

    
    

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedDatasets, count: 50});

    const {
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup,
    } = toggleObj;

    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        allAreSelected,
        selectAllRows,
        addSingleItem,
        deselectItems,
        toggleChecked
    } = useEnableSelection({allItems: allDatasetIDs});

    useEffect(() => {
        setHeaders(datasetsTableHeaders);
        //eslint-disable-next-line
    }, []);

    const goBack = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (selection === "existing") {
            setPopupType('existing-file')
        } else {
            setPopupType('upload-file')
        }
    };

    const next = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setPopupType('create-dataset');
    };


    return (
        <>

            <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default`}>
            {/* Search Bar & Buttons Component */}
            <TableControls applySearch={applySearch} identifier="Name" toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows}>
                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType} />   
                <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />               
            </TableControls>
            
            {isLoading || paginatedItems.length > 0 ? (
                <>
                    <TableBase 
                        isChecked={isChecked} 
                        selectAllRows={selectAllRows} 
                        allAreSelected={allAreSelected} 
                        hasOptions={isLoading ? false : true} 
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn}
                        isPopupOpen={false}
                        isLoading={isLoading}
                    >
                        {paginatedItems.length > 0 && paginatedItems.map(item => (
                            <TableRowBase 
                                selectedRows={selectedRows} 
                                editSelectedRows={editSelectedRows} 
                                isChecked={isChecked} 
                                item={item} 
                                headers={headers} 
                                action={{path: 'datasets'}}
                                rowType="base"
                            >
                                <IndividualOptionsDropdownBase>
                                    <IndividualDatasetOptions application={application} downloadFunc={downloadDataset} id={item.id} addSingleItem={addSingleItem} toggleDeletePopup={togglePopup} setPopupType={setPopupType} handleClose={() => {}}/>
                                </IndividualOptionsDropdownBase>
                            </TableRowBase>
                        ))}
                    </TableBase>
                    {paginatedItems.length > 0 && (
                        <Pagination count={50} total={allDatasets.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                    )}
                </>
            ) : (
                <EmptyTable message="Create a new dataset" subject="No datasets found" searchInput={searchInput} />
            )}

            </div>
            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

        {isPopupOpen && (
            <Overlay togglePopup={togglePopup}>
                {popupType === 'delete' ? (
                    <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deselectItems={deselectItems} deleteFunc={deleteDatasets} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these datasets' : 'this dataset'}`}/>
                ) : popupType === 'filter' ? (
                    <FilterPopup itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={defaultDatasetFilter} fieldsObj={filterFields} />
                ) : popupType === 'success' ? (
                    <SuccessPopup type='datasets' togglePopup={togglePopup}/>
                ) : popupType === 'existing-file' ? (
                    <ExistingFilePopup togglePopup={togglePopup} next={next}/>
                ) : popupType === 'upload-file' ? (
                    <UploadFile togglePopup={togglePopup} next={next} isFlow={true} />
                ) : popupType === 'create-dataset' ? (
                    <CreateDatasetPopup isFlow={false} togglePopup={togglePopup} goBack={goBack} setPopupMessage={setPopupMessage}/>
                ) : (
                    <ModifyPermissionPopup toggleChecked={toggleChecked} deselectItems={deselectItems} updateFunc={updateDatasetPermissions} items={selectedRows} togglePopup={togglePopup} />
                )}
            </Overlay>
        )}

        </>
    )
}