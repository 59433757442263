import { useEffect, useState, Dispatch, SetStateAction } from "react";
import API from '../../api';
import { ClassificationRecords, RegressionRecords, SingleClassificationModel, SingleRegressionModel } from "../../types/models.types";
import { useNavigate } from "react-router-dom";
import { Header, SideInfo } from "../../types/all.types";
import { convertDecimal, download, dsExists, getDatedFilename, isAdmin, isOwner, searchItems, sortByColumn } from "../../data/functions";
import { useSelector } from "react-redux";
import { selectDatasets } from "../../store/selectors/datasets.selector";
import { selectAuth } from "../../store/selectors/auth.selector";
import { deleteExistingModels } from "../../store/slices/models.slice";
import { useDispatch } from "react-redux";
import { singleClassificationModelHeaders, singleRegressionModelHeaders } from "../../data/models";
import { selectUsers } from "../../store/selectors/users.selector";


export const isClassificationType = (obj:any): obj is SingleClassificationModel => {
    if (obj.auc !== undefined && obj.auc !== '') {
        return true;
    } else {
        return false;
    }
}

const useSingleModel = ({ name, setPopupMessage }: {
    name?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,

}) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ singleModel, setSingleModel ] = useState<SingleClassificationModel[] | SingleRegressionModel[]>([]);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ allMethodIDs, setAllMethodIDs ] = useState<string[]>([]);
    const [ isClassification, setIsClassification ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>([]);

    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const datasets = useSelector(selectDatasets);
    const auth = useSelector(selectAuth);
    const users = useSelector(selectUsers);

    const findUserById = (id: string) => {
        const user = users.length > 0 && users.find(u => u._id.$oid === id);
        if (user) {
            return user.full_name
        } else {
            return ''
        }
    }


    useEffect(() => {
        if (name) {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchModel = async () => {

                    setIsLoading(true);

                    try {

                        const response = await API.get(`models/${name}`, { headers: { 'X-Auth': authKey } });
                        const data = await response.data;
                        
                        if (!data.length) {
                            throw Error();
                        } else {
                            setSingleModel(data);
                        }    

                    } catch (err:any) {

                        console.log(err);
                        navigate('/models/404')

                    }
                    
                    setIsLoading(false);
                    
                };
        
                fetchModel();

            } else {
                navigate('/401');
            }
            
        }
    }, [name, navigate]);


    useEffect(() => {
        if (singleModel && singleModel.length > 0) {

            let records: ClassificationRecords[] | RegressionRecords[] = [];
            let isClassificationModel = false;

            if (isClassificationType(singleModel[0])) {
                
                isClassificationModel = true;
                setIsClassification(true);

                records = (singleModel as SingleClassificationModel[]).map((m: SingleClassificationModel) => ({
                    id: m._id.$oid,
                    method_name: m.method_name,
                    auc: convertDecimal(m.auc),
                    f1score: convertDecimal(m.f1score),
                    precision: convertDecimal(m.precision),
                    recall: convertDecimal(m.recall),
                    acc: convertDecimal(m.acc),
                    specificity: convertDecimal(m.specificity),
                    cohens_kappa: convertDecimal(m.cohens_kappa),
                    mcc: convertDecimal(m.mcc),
                    tp: m.tp,
                    tn: m.tn,
                    fp: m.fp,
                    fn: m.fn,
                }))

            } else {

                isClassificationModel = false;
                setIsClassification(false);

                records = (singleModel as SingleRegressionModel[]).map((m:SingleRegressionModel) => ({
                    id: m._id.$oid,
                    method_name: m.method_name,
                    mae: convertDecimal(m.mae),
                    rmse: convertDecimal(m.rmse),
                    mpd: convertDecimal(m.mpd),
                    mgd: convertDecimal(m.mgd),
                    r2: convertDecimal(m.r2),
                }))

            }

            setRoutes([
                {
                    label: 'Models',
                    path: 'models',
                },
                {
                    label: name ? name : 'Single Model',
                    path: name ? `models/${name}` : '',
                }
            ])

            setOrderedRecords(records);
            setModifiedRecords(records);


            setInfo([
                {
                    label: 'Dataset Name',
                    value: singleModel[0].dataset ? singleModel[0].dataset.name : '',
                    path: singleModel[0].dataset ? dsExists(singleModel[0].dataset._id.$oid, datasets) : undefined,
                },
                {
                    label: 'Model Type',
                    value: isClassificationModel ? 'Classification' : 'Regression'
                },
                {
                    label: 'Model Label',
                    value: singleModel[0].label
                },
                {
                    label: 'Descriptor',
                    value: singleModel[0].descriptors[0].name,
                },
                {
                    label: 'Bits',
                    value: singleModel[0].descriptors[0].params.Bits,
                },
                {
                    label: 'Radius',
                    value: singleModel[0].descriptors[0].params.Radius,
                },
                {
                    label: 'Created By',
                    value: singleModel[0].created_by,
                },
                {
                    label: 'Owner',
                    value: singleModel[0].acl ? findUserById(singleModel[0].acl.owner) : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleModel[0].acl.access,
                    key: auth && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
                
            ]);

        }
         
        
    }, [singleModel, name, auth, datasets]);


    useEffect(() => {
        setHeaders(isClassification ? singleClassificationModelHeaders : singleRegressionModelHeaders);
    }, [isClassification]);


    useEffect(() => {
        if (modifiedRecords.length > 0) {
            setAllMethodIDs(modifiedRecords.map(r => r.id))
        }
    }, [modifiedRecords])



    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        const searchArr = searchItems(searchInput, orderedRecords, 'method_name');
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }

    const updateModel = async (obj: any) => {
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                if (auth && singleModel && (isAdmin(auth) || isOwner(auth, singleModel[0].acl.owner))) {
                    await API.put(`models/${name}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`models/${name}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;

                setPopupMessage && setPopupMessage('This model was updated successfully!', true);
                setSingleModel && setSingleModel(data);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error updating this model', false);
    
            }
        }
    }

    const downloadModels = async (names: string[], name?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post(`models/download`, { names: names, ids: []} ,{ headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                let filename = '';
                if (name !== undefined) {
                    filename = `${name}.xlsx`;
                } else {
                    filename = getDatedFilename('models', 'xlsx');
                } 

                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading ${names.length > 1 ? 'these models' : 'this model'}`, false);
            }
        }
    };

    const deleteModels = async (names: string[], modelName?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = names.map(async (name) => {
            const encoded = encodeURI(name);
            if (!modelName) {
                return API.delete(`models/${encoded}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingModels(name)));      
            } else {
                return API.delete(`models/${modelName}/${name}`, { headers: { 'X-Auth': authKey } })
            }
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                setPopupMessage && setPopupMessage(`There was an error deleting ${modelName ? names.length > 1 ? 'these models' : 'this model' : names.length > 1 ? 'these models' : 'this model'}`, false);
                throw new Error('Error');
            }

            if (modelName) {
                const response = await API.get(`models/${modelName}`, { headers: { 'X-Auth': authKey } });
                const data: SingleClassificationModel[] | SingleRegressionModel[] = response.data;
                setSingleModel && setSingleModel(data);
            }
        }
    };
    


    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        headers,
        setHeaders,
        info,
        routes,
        downloadModels,
        allMethodIDs, 
        updateModel,
        deleteModels,
        isLoading,
        isClassification,
        singleModel,
    }

}

export default useSingleModel;