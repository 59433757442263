import { createSlice } from "@reduxjs/toolkit";
import { Prediction } from "../../types/predictions.types";

export interface PredicationState {
    predictions: Prediction[];
}

const initialState: PredicationState = {
    predictions: [],
}

export const predictionSlice = createSlice({
    name: 'predictions',
    initialState,
    reducers: {
        getPredictions: (state, action) => {
            state.predictions = action.payload;
        },
        deleteExistingResults: (state, action) => {
            state.predictions = state.predictions.filter(p => !action.payload.includes(p._id.$oid));
        },
        updateExistingResult: (state, action) => {
            state.predictions = state.predictions.map(pred => {
                if (pred._id.$oid === (action.payload as Prediction)._id.$oid) {
                    return {...action.payload}
                } else {
                    return {...pred}
                }
            })
        },
    }
})

export const { getPredictions, deleteExistingResults, updateExistingResult } = predictionSlice.actions;

export const predictionsReducer = predictionSlice.reducer;
