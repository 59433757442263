import UsersCover from '../../../../assets/tutorialAssets/Managing Users Cover.png';
import UsersIcon from '../../../../assets/users.svg';
import Plus from '../../../../assets/plus.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";

export default function ManagingUsersTutorial() {

    const sections = [
        {id: 'how-do-i-add-a-new-user', label: 'How do I add a new user?', type: 'main'},
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'managing-users', label: 'Managing Users'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Managing Users</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={UsersCover} alt='managing-users-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                {/* <div className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I manage other users?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={UsersIcon} alt='users-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Users</span> tab</li>
                        <li>Click on the <span className="inline-block darken-icons"><img src={Plus} alt='plus-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span><span className="font-semibold">Add New User</span> button located on the right-hand corner of the screen. This will open a popup form titled <span className="font-semibold">Add User</span>.</li>
                    </ol>
                </div> */}

                <div id='how-do-i-add-a-new-user' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">How do I add a new user?</p>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/AddNewUser.mp4'} type="video/mp4"/></video>
                    </div>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={UsersIcon} alt='users-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Users</span> tab</li>
                        <li>Click on the <span className="inline-block darken-icons"><img src={Plus} alt='plus-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span><span className="font-semibold">Add New User</span> button located on the right-hand corner of the screen. This will open a popup form titled <span className="font-semibold">Add User</span>. Complete all required input fields inside the popup form before continuing:</li>
                    </ol>
                    <ul className="list-disc list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>Creating a new user requires their full name, username, email address, password, company, privileges, and active status. The password field is not required when editing a user.</li>
                        <li>Privilege types include creating files and datasets, training models and making predictions. A user given the administrator privilege will have all three privilege types, including the ability to access all files, datasets, models, predictions, and jobs, edit metadata, change access settings, and create/edit users. </li>
                    </ul>
                </div>

                <TutorialPagination prevPage={{page: 'generating-readacross', label: 'Generating readacross values'}} />
            </div>
        </div>
        </>
    )
}