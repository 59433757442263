import { useState, useEffect, Dispatch, SetStateAction } from "react";

import { SingleDatasetType } from "../../types/datasets.types";
import { SideInfo, Header } from "../../types/all.types";

import API from '../../api';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { filesExists, getIdentifier, download, isAdmin, isOwner, convertDecimal, searchItems, sortByColumn } from "../../data/functions";
import { useSelector } from "react-redux";
import { selectFiles } from "../../store/selectors/files.selectors";
import { selectAuth } from "../../store/selectors/auth.selector";
import { updateExistingDataset, deleteExistingDatasets } from "../../store/slices/datasets.slice";
import { selectDatasets } from "../../store/selectors/datasets.selector";
import { ModelStats } from "../../types/models.types";

const useSingleDataset = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {

    const [ singleDataset, setSingleDataset ] = useState<SingleDatasetType | null>(null);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ valueField, setValueField ] = useState<{name: string, type: string, [key:string]: string} | null>(null);
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ options, setOptions ] = useState<any>();
    const [ stats, setStats ] = useState<ModelStats | null>(null);
    const [ itemIdentifier, setItemIdentifier ] = useState('');


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const files = useSelector(selectFiles);
    const datasets = useSelector(selectDatasets);
    const auth = useSelector(selectAuth);

    const setupSingleDataset = (singleDataset: SingleDatasetType) => {
        if (singleDataset) {
            setRoutes([
                {
                    label: 'Datasets',
                    path: 'datasets',
                },
                {
                    label: singleDataset ? singleDataset.name : 'Single File',
                    path: singleDataset ? `datasets/${singleDataset._id.$oid}` : '',
                }
            ]);

            setStats({
                records_number: singleDataset.records_number,
                stats: singleDataset.stats,
            })

            let activeField: {name: string, type: string} | null = null;
            const valueField = singleDataset.fields_mapping.find(f => ['single-class-label', 'multi-class-label', 'continuous-value'].includes(f.type));
            if (valueField) {
                setValueField(valueField);
                activeField = valueField;
            }

            setInfo([
                {
                    label: 'Raw File Name',
                    value: singleDataset.files[0].name,
                    path: filesExists(singleDataset.files[0].$oid, files),
                },
                {
                    label: 'Project',
                    value: !singleDataset.metadata.project ? 'No project' : singleDataset.metadata.project,
                    key: 'project',
                    type: 'creatable-select',
                }, 
                {
                    label: 'Measurement Type',
                    value: !singleDataset.metadata.measurement_type ? 'No measurement type' : singleDataset.metadata.measurement_type,
                    key: 'measurement_type',
                    type: 'text',
                },
                {
                    label: 'Value Type',
                    value: activeField ? activeField.type : '',
                },
                {
                    label: 'Activity Column',
                    value: activeField ? activeField.name : '',
                },
                {
                    label: singleDataset.stats.actives !== undefined ? 'Active Compounds' : singleDataset.stats.high_value !== undefined ? 'High Value' : 'Active Compounds',
                    value: singleDataset.stats.actives !== undefined ? singleDataset.stats.actives : singleDataset.stats.high_value !== undefined ? (Math.round(Number(singleDataset.stats.high_value) * 100) / 100 as number).toFixed(2) : '' ,
                },
                {
                    label: singleDataset.stats.inactives !== undefined ? 'Inactive Compounds' : singleDataset.stats.low_value !== undefined ? 'Low Value' : 'Inactive Compounds',
                    value: singleDataset.stats.inactives !== undefined ? singleDataset.stats.inactives : singleDataset.stats.low_value !== undefined ? (Math.round(Number(singleDataset.stats.low_value) * 100) / 100 as number).toFixed(2) : '' ,
                },
                {
                    label: 'Organism',
                    value: !singleDataset.metadata.organism ? 'No organism' : singleDataset.metadata.organism,
                    key: 'organism',
                    type: 'text',
                },
                {
                    label: 'Target',
                    value: !singleDataset.metadata.target ? 'No target' : singleDataset.metadata.target,
                    key: 'target',
                    type: 'text',
                },
                {
                    label: 'Created By',
                    value: singleDataset.acl ? singleDataset.acl.owner : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleDataset.acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleDataset.acl ? singleDataset.acl.access : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleDataset.acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
            ])

            if (singleDataset.records.length > 0) {

                const all = singleDataset.records.map(r => ({fields: r.fields, molecule: r.molecule.$oid }));
                const nonEmptyKeys = Object.keys(singleDataset.records[0].fields).filter(key => singleDataset.records[0].fields[key]);
                const fieldKeys = Object.keys(singleDataset.records[0].fields);
                const identifier = getIdentifier(nonEmptyKeys, singleDataset.fields_mapping);

                let orderedFields: string[];

                if (identifier) {
                    setItemIdentifier(identifier);
                    orderedFields = [identifier].concat(fieldKeys.filter(f => f !== identifier));
                } else {
                    orderedFields = fieldKeys;
                }

                
                const datasetHeaders = [{
                    label: 'Structure',
                    value: 'structure',
                    isRequired: true,
                    isAscending: true,
                    isSelected: true,
                    isSortable: false,
                }].concat(orderedFields.map((field,i) => ({
                    label: field,
                    value: field,
                    isRequired: identifier && identifier === field ? true : false,
                    isAscending: true,
                    isSelected: true,
                    isSortable: true,
                })));


                setHeaders(datasetHeaders);
                
                const valueFieldName = datasetHeaders.find(h => valueField && valueField.name.includes(h.label));
                
                
                let records: any[] = [];
                all.forEach(r => {
                    let obj = {};
                    let isActive: boolean;
                    datasetHeaders.forEach(h => {
                        if (h.label !== 'Structure') {
                            obj = {...obj, [h.label]: r.fields[h.label] === undefined ? '' : h.label === (valueFieldName && valueFieldName.label) || ['single-class-label', 'continuous-value'].includes(h.label) ? convertDecimal(Number(r.fields[h.label])) : r.fields[h.label]}
                        } else {
                            obj = {...obj, [h.label]: ''}
                        }
                    })
                    if (activeField) {
                        const activity = r.fields[activeField.name];
                        if (activity === 1) {
                            isActive = true;
                        } else {
                            isActive = false;
                        }
                    } else {
                        isActive = false;
                    }
                    records.push({...obj, id: r.molecule, isActive: isActive});
                })

                setOrderedRecords(records);
                setModifiedRecords(records);

            }
            

        }
    }

    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            if (id) {

                const fetchDataset = async () => {
                    setIsLoading(true);
    
                    try {
    
                        const response = await API.get(`datasets/${id}`, { headers: { 'X-Auth': authKey }});
                        const data: SingleDatasetType = await response.data;
                        setSingleDataset(data);
    
                    } catch (err:any) {
    
                        console.log(err);
                        navigate('/datasets/404')
    
                    }
    
                    setIsLoading(false);
                    
                }
        
                fetchDataset();
    
            }
        } else {
            navigate('/401');
        }
        
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (singleDataset) {
            setupSingleDataset(singleDataset);
        }
        //eslint-disable-next-line
    }, [singleDataset]);


    useEffect(() => {
        if (datasets && datasets.length > 0 && Array.isArray(datasets)) {

            const projects = datasets.map(file => {
                if (file.metadata && file.metadata.project) {
                    return file.metadata.project
                } else {
                    return ''
                }
            });

            setOptions({
                project: Array.from(new Set(projects)).filter(a => a).map(p => ({value: p, label: p})),
            })   
        } 
    }, [datasets]);

    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        let searchArr: any[] = [];
        if (itemIdentifier) {
            searchArr = searchItems(searchInput, orderedRecords, itemIdentifier);
        } else {
            searchArr = orderedRecords;
        }
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }


    const updateDataset = async (obj: any) => {
        const { project, measurement_type, organism, target } = obj;
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const metadata = { project, measurement_type, organism, target };
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                await API.put(`datasets/${id}`, metadata, { headers: { 'X-Auth': authKey } });
                if (auth && singleDataset && (isAdmin(auth) || isOwner(auth, singleDataset.acl.owner))) {
                    await API.put(`datasets/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`datasets/${id}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;
                setSingleDataset(data);
                dispatch(updateExistingDataset(data));
                setPopupMessage('This dataset was updated successfully!', true);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage('There was an error updating this dataset', false)
    
            }
        }
    }

    const deleteDatasets = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`datasets/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingDatasets(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
                setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these datasets' : 'this dataset'}`, false);
                throw new Error('Error');
            }
        }
    };

    const downloadDataset = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`datasets/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage('There was an error downloading this dataset', false)
            }
        }
    }

    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        singleDataset,
        headers,
        setHeaders,
        valueField,
        updateDataset,
        downloadDataset,
        deleteDatasets,
        isLoading,
        options,
        stats,
        itemIdentifier,
    }

}

export default useSingleDataset;