import { useState, useEffect } from "react";

const usePagination = ({items, count}: { items: any[], count: number }) => {
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ itemsPerPage, setItemsPerPage ] = useState(count);
    const [ paginatedItems, setPaginatedItems ] = useState<any[]>([]);

    useEffect(() => {
        const lastIndex = currentPage * itemsPerPage;
        const firstIndex = lastIndex - itemsPerPage;
        const currentFiles = items.slice(firstIndex, lastIndex);
        setPaginatedItems(currentFiles);

    }, [currentPage, itemsPerPage, items]);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    }

    return {
        currentPage,
        setItemsPerPage,
        itemsPerPage,
        paginatedItems,
        paginate
    }
}

export default usePagination;