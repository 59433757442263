import UploadFileCover from '../../../../assets/tutorialAssets/Upload Files Cover.png';
import FileIcon from '../../../../assets/file.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";

export default function UploadFileTutorial() {

    const sections = [
        {id: 'where-do-i-upload-files', label: 'Where do I upload files?', type: 'main'},
        {id: 'creating-a-new-file', label: 'Creating a new file', type: 'main'}
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'uploading-files', label: 'Uploading Files'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Uploading Files</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={UploadFileCover} alt='upload-file-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections}/>

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id="where-do-i-upload-files" className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I upload files?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={FileIcon} alt='files-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Files</span> tab</li>
                        <li>Click on the  <span className="font-semibold">Upload</span> <span className="inline-block darken-icons"><img src={ChevronDown} alt='files-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> button located on the right-hand corner of the screen</li>
                        <li>
                            From the dropdown menu, select how you would like to create your new file: 
                            <ol className="list-disc list-inside ml-12 my-2">
                                <li className="mt-4"><span className="font-semibold">New File</span> - Select this option if you are uploading a file from your computer</li>
                                <li className="mt-4"><span className="font-semibold">Compound List</span> - Select this option if you are creating a file from a list of CAS registry numbers</li>
                            </ol>
                        </li>
                    </ol>
                    <p>After making your selection, you will see a popup form titled <span className="font-semibold">Upload New File</span> or <span className="font-semibold">Paste CAS List</span> depending on your selection. Both forms will require the same information in order to process your file.</p>
                </div>

                <div id='creating-a-new-file' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Creating a new file</p>
                    
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/UploadFilesEdited.mp4'} type="video/mp4"/></video>
                    </div>
                    
                    <p>Complete all required input fields inside the popup form before continuing:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li><span className="font-semibold">Uploaded File / CAS List</span> - Browse or drag and drop a file from your computer into the dropzone.  Alternatively, you can type out a list of CAS registry numbers in the textarea field.</li>
                        <li><span className="font-semibold">File Name</span> - A descriptive name for your uploaded file. Checking <span className="font-semibold">Autogenerate</span> will match the name to its raw file name. </li>
                        <li><span className="font-semibold">Project</span> - A descriptive name for the project associated with your file. Choose from a list of pre-existing projects or create a new one by typing it and pressing <span className="font-semibold">Enter</span>.</li>
                        <li><span className="font-semibold">Description <span className="text-tertiary">(optional)</span></span> - Contains any notes about your data (e.g. how they were obtained or curated).</li> 
                        <li><span className="font-semibold">Measurement Type</span> - The type of data endpoint for which you will be modeling or predicting (e.g., IC50 , Ki , EC50).</li> 
                        <li><span className="font-semibold">Target</span> - Contains the name of your protein of interest. (e.g., BACE1).</li> 
                        <li><span className="font-semibold">Organism </span> - Contains the details of your species (e.g. Human).</li> 
                        <li><span className="font-semibold">Access Type</span> - Determines who can access this file once it’s been created. “Private”  indicates that only you have access to this file. “Authenticated” indicates that anyone with an authenticated account within your company may access this file.
                         <span className="font-semibold"> NOTE:</span> Be careful when selecting this access, as any “authenticated” files will populate all workspaces–this can cause other users’ workspaces to become cluttered.</li> 
                        <li><span className="font-semibold">Structure Field</span> - If your uploaded file is CSV or XLSX format, you will need to select the name of the column corresponding to the chemical structure. Currently only CAS numbers and SMILES can be accepted as the structure field.</li> 
                    </ol>
                    <p className="mb-4">Click <span className="font-semibold">Continue</span> to upload your file. Your data is now available for us ine AC.</p>
                    <p><span className="font-semibold">NOTE: </span>In order to avoid redundant file uploads, Assay Central prevents duplicate file uploads. If another user on your license has already uploaded a file with the same name, even if you can’t see it, you will need to rename the file.</p>
                </div>

                <TutorialPagination nextPage={{label: 'Creating datasets', page: 'creating-datasets'}} prevPage={{label: 'Getting started', page: 'quick-start'}} />

            </div>
        </div>
        </>
    )
}