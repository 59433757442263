// hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import usePopup from "../../../hooks/usePopup";
import useLayout from "../../../hooks/useLayout";
import useSelection from "../../../hooks/useEnableSelection";

// functions + data
import { classificationLimits } from "../../../data/models";

// types
import { ModelStats, SingleClassificationModel, SingleRegressionModel } from "../../../types/models.types"
import { MouseEvent } from "react";

// assets
import Info from '../../../assets/info-circle.svg';

// components
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import Button from "../../../components/Buttons/Button";
import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import ToggleView from "../../../components/Page/ToggleView";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import Grid from "../../../components/Grid/Grid";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import DeletePopup from "../../../components/Popups/DeletePopup";
import TableControls from "../../../components/Table/TableControls";
import BarGraph from "../../../components/Data/BarGraph";
import RadarChart from "../../../components/Data/RadarChart";
import GridItemBase from "../../../components/Grid/GridItemBase";
import ChangePage from "../../../components/Page/ChangePage";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleModel, { isClassificationType } from "../../../hooks/pageHooks/useSingleModel";
import { useSelector } from "react-redux";
import { selectApplication } from "../../../store/selectors/auth.selector";
import { Limit } from "../../../types/all.types";
import ClassificationGraphs from "../components/ClassificationGraphs";
import RegressionGraphs from "../components/RegressionGraphs";
import PlotPopup from "../../../components/Popups/PlotPopup";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualModelOptions from "../components/IndividualModelOptions";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import Overlay from "../../../components/Popups/Overlay";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import ToggleArrows from "../../../components/Page/ToggleArrows";


const rgbColors = {
    bnb: 'rgba(44, 114, 142, 0.1)',
    DL: 'rgba(33, 144, 140, 0.1)',
    ada: '#rgba(59, 82, 139, 0.1)',
    knn: 'rgba(39, 173, 129, 0.1)',
    lreg: 'rgba(93, 200, 99, 0.1)',
    rf: 'rgba(170, 220, 50, 0.1)',
    svc: 'rgba(253, 231, 37, 0.1)',
    xgb: 'rgba(255, 197, 111, 0.1)',
}

const trColors = {
    bnb: '#2C728E',
    DL: '#21908C',
    ada: '#3B528B',
    knn: '#27AD81',
    lreg: '#5DC863',
    rf: '#AADC32',
    svc: '#FDE725',
    xgb: '#FFC56F'
}

const regTrColors = {
    r2: '#DB5C68',
    mae: '#B8328A',
    rmse: '#5802A3',
}




export default function SingleModelTemplate() {
    const { name } = useParams();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const application = useSelector(selectApplication);

    const {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    } = useMessage();

    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        downloadModels,
        allMethodIDs, 
        updateModel,
        deleteModels,
        singleModel,
        isClassification,
        isLoading,
        headers,
        setHeaders,
    } = useSingleModel({setPopupMessage, name});
    
    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, setSelectedHeader, selectedHeader })

    const { 
        isSideMenuOpen, 
        toggleSideMenu, 
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked, 
        handleChange,
        toggleChecked,
        selectAllRows,
        selectedRows,
        allAreSelected,
        editSelectedRows,
        deselectItems,
        addSingleItem
    } = useSelection({allItems: allMethodIDs});

    const [ currentGraphs, setCurrentGraphs ] = useState<{name: string, graph: number}[]>([])
    const [ graphIndices, setGraphIndices ] = useState([0,1,2]);
    const [ selectedMethod, setSelectedMethod ] = useState<SingleClassificationModel | SingleRegressionModel | null>(null);

    const [barData, setBarData] = useState<{x: string[], y: number[], name: string, type: string}[]>([]);

    const [ radarData, setRadarData ] = useState<{r: any[], theta: any[], name: string, type: string, fill: string}[]>([]);
    const [ isFirst, setIsFirst ] = useState(true);
    const [ stats, setStats ] = useState<ModelStats | null>(null);
    const [ limits, setLimits ] = useState<Limit[]>([]);
    const [ maxValue, setMaxValue ] = useState(1);



    const findSelectedMethod = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const obj = singleModel.find(m => m.method_name === name);
        if (obj) {
            setSelectedMethod(obj);
            togglePopup(e);
            setPopupType('chemical');
        }
    }

    const next = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const obj = currentGraphs.find(g => g.name === name );
        if (obj) {
            if (obj.graph === graphIndices[graphIndices.length - 1]) {
                return;
            } else {
                setCurrentGraphs(currentGraphs.map(g => {
                    if (g.name === name) {
                        return {...g, graph: g.graph + 1}
                    } else {
                        return {...g}
                    }
                }));
            }
        } 

    }

    const back = (e:MouseEvent<HTMLButtonElement>, name: string) => {
        e.preventDefault();
        const obj = currentGraphs.find(g => g.name === name );
        if (obj) {
            if (obj.graph === 0) {
                return;
            } else {
                setCurrentGraphs(currentGraphs.map(g => {
                    if (g.name === name) {
                        return {...g, graph: g.graph - 1}
                    } else {
                        return {...g}
                    }
                }));
            }
        } 

    }


    useEffect(() => {

        setGraphIndices([0,1,2]);
        //eslint-disable-next-line
    }, [isClassification]);


    useEffect(() => {
        if (modifiedRecords.length > 0 && !isClassification) {
            let mae: number[] = [];
            let rmse: number[] = [];

            modifiedRecords.forEach(record => {
                Object.keys(record).forEach(key => {
                    if (key === 'mae') {
                        mae.push(Number(record[key]))
                    } else if (key === 'rmse') {
                        rmse.push(Number(record[key]))
                    }
                })
            })


            const maxMae = Math.max(...mae);
            const maxRmse = Math.max(...rmse);

            if (maxMae > maxRmse) {
                setMaxValue(maxMae)
            } else {
                setMaxValue(maxRmse)
            }

            const newLimits: Limit[] = [
                {
                    value: 'r2',
                    min: 0,
                    max: 1,
                    isReversed: false,
                },
                {
                    value: 'mae',
                    min: 0,
                    max: maxMae,
                    isReversed: false,
                },
                {
                    value: 'rmse',
                    min: 0,
                    max: maxRmse,
                    isReversed: false,
                }
            ]

            setLimits(newLimits)

            
        } else {
            setLimits(classificationLimits);

        }
    }, [modifiedRecords, isClassification]);


    useEffect(() => {

        if (singleModel.length > 0) {

            const sorted = singleModel.sort((a,b) => {
                if (a.method_name.toLowerCase() < b.method_name.toLowerCase()) {
                    return -1
                } else if (a.method_name.toLowerCase() > b.method_name.toLowerCase()) {
                    return 1
                } else {
                    return 0
                }
            })

            let methodArr: string[] = [];
            // let otherMethodArr: string[] = [];


            if (isClassification) {

                methodArr = ['auc', 'acc', 'cohens_kappa', 'f1score', 'mcc', 'precision', 'recall', 'specificity'];

                const arr = sorted.map(m => ({
                    x: methodArr,
                    y: methodArr.map(method => m[method as keyof object]),
                    name: m.method_name,
                    type: 'bar',
                    marker: {
                        color: trColors[m.method_name as keyof object],
                    }
                }));
    
                const radarArr = sorted.map(m => ({
                    type: 'scatterpolar',
                    r: methodArr.map(method => m[method as keyof object]),
                    theta: methodArr,
                    fill: 'toself',
                    name: m.method_name,
                    marker: {
                        color: trColors[m.method_name as keyof object],
                    },
                    fillcolor: rgbColors[m.method_name as keyof object],
                }));

                setRadarData(radarArr);
                setBarData(arr);
                
            } else {
                methodArr = ['r2', 'mae', 'rmse'];
                // otherMethodArr = ['mae', 'rmse'];

                const traceArr = methodArr.map(method => ({
                    x: singleModel.map(m => m.method_name),
                    y: singleModel.map(m => m[method as keyof object]),
                    name: method,
                    type: 'bar',
                    marker: {
                        color: regTrColors[method as keyof object],
                    }
                }));
    

                setBarData(traceArr);
                // setOtherBarData(otherTraceArr)
            }

            if (singleModel[0].dataset) {
                const ds = singleModel[0].dataset;
                setStats({
                    records_number: ds.records_number,
                    stats: ds.stats,
                })
            }
    
            setCurrentGraphs(singleModel.map(m => ({name: m.method_name, graph: 0})));
        }

    }, [singleModel, isClassification]);


    return (
        <>
                <SinglePageHeader label={name ? name : 'Single Model'} isLoading={isLoading} routes={routes ? routes : []} stats={stats}>
                    <OptionsDropdownBase isLoading={isLoading} >
                        <IndividualModelOptions application={application} singleModel={singleModel} name={name ? name : ''} downloadFunc={downloadModels} toggleDelete={togglePopup} setPopupType={setPopupType} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                

                <div className='mb-6'>
                    <div className={`w-full bg-white rounded drop-shadow-default flex flex-col gap-4 h-full p-8`}>
                        <div className={`w-full flex justify-between items-center`}>
                            <p className='text-[1.5rem] font-semibold text-nowrap'>Method Comparison</p>
                            <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
                                {isClassification && <ToggleArrows firstCondition={!isFirst} secondCondition={isFirst} firstAction={(e) => setIsFirst(true)} secondAction={(e) => setIsFirst(false)}/>}
                            </div>
                        </div>
                        {isLoading !== undefined && isLoading ? (
                            <Skeleton/>
                        ) : (
                            <div className='w-full flex justify-center items-center gap-10'>
                            
                            {isFirst ? (
                                <BarGraph data={barData as any} maxValue={isClassification ? undefined : maxValue}/>
                            ) : (
                                <>
                                {isClassification ? (
                                    <RadarChart data={radarData as any} />  
                                ) : (
                                    <></>
                                )}
                                </>
                            )}
                            
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-full rounded bg-white drop-shadow-default">
                {/* Search Bar & Buttons Component */}
                <TableControls applySearch={applySearch} identifier="Method Name" name={name} deselectItems={deselectItems} toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows} downloadFunc={downloadModels} toggleCheck={toggleChecked}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    {application && (application).includes('assay-central') && <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />}
                    <ToggleView toggleView={toggleView} isGrid={isGrid} />
                </TableControls>
                
                {/* Table Component */}
                <div className='mb-6'>
                {isGrid ? (
                    <Grid noResults={!modifiedRecords.length ? true : false} isLoading={isLoading}>
                        {modifiedRecords.length > 0 ? (
                            <>
                            {modifiedRecords.length > 0 && modifiedRecords.map(record => {
                                const item = singleModel.find(m => m.method_name === record.method_name);
                                if (item) {
                                    const obj = currentGraphs.find(g => g.name === item.method_name);
                                    return (
                                    <GridItemBase 
                                        title={item.method_name} 
                                        optionButton={isClassification ? <ChangePage name={item.method_name} controls={{next, back}}  currentGraph={currentGraphs} graphIndices={graphIndices}/> : undefined}
                                        action={{findSelectedMethod}}
                                        item={item} 
                                        editSelectedRows={editSelectedRows} 
                                        selectedRows={selectedRows} 
                                        isChecked={isChecked}
                                        gridType="structure"
                                    >
                                        {isClassification ? (
                                            <ClassificationGraphs obj={obj} item={item as SingleClassificationModel} />
                                        ) : (
                                            <RegressionGraphs limits={limits} item={item as SingleRegressionModel} />
                                        )} 
                                    </GridItemBase>
                                    )
                                } else {
                                    return null;
                                }
                            })}
                            </>
                        ) : (
                            <EmptyTable isGrid={true} searchInput={searchInput} subject="This page contains no methods" />
                        )}
                        
                    </Grid>
                ) : (
                    <>
                    {modifiedRecords.length > 0 || isLoading ? (
                        <TableBase
                            isLoading={isLoading}
                            isPopupOpen={false}
                            isChecked={isChecked} 
                            selectAllRows={selectAllRows} 
                            allAreSelected={allAreSelected} 
                            hasOptions={isLoading ? false : true} 
                            headers={headers} 
                            selectedHeader={selectedHeader} 
                            setSortedColumn={setSortedColumn}
                        >
                            {modifiedRecords.length > 0 && modifiedRecords.map((record,i) => (
                                <TableRowBase 
                                    rowType="color"
                                    selectedRows={selectedRows} 
                                    editSelectedRows={editSelectedRows} 
                                    isChecked={isChecked} 
                                    action={{findSelectedMethod}}
                                    item={record} 
                                    headers={headers} 
                                    limits={limits}
                                >
                                    {application && (application).includes('assay-central') ? 
                                        <IndividualOptionsDropdownBase>
                                            <IndividualModelOptions application={application} handleClose={() => {}} name={record.id} addSingleItem={addSingleItem} toggleDelete={togglePopup} setPopupType={setPopupType}/> 
                                        </IndividualOptionsDropdownBase>
                                        : 
                                        <div></div>
                                    }
                                </TableRowBase>
                            ))}
                        </TableBase>
                        ) : (
                            <EmptyTable searchInput={searchInput} subject="This page contains no methods" />
                        )}
                    </>
                    )}
                </div>
                </div>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

           
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={name} info={info} label='Model Details' togglePopup={toggleSideMenu} updateFunc={updateModel}/>
            )}
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup deselectItems={deselectItems} modelName={name} toggleChecked={toggleChecked} isChecked={isChecked} togglePopup={togglePopup} deleteFunc={deleteModels} items={selectedRows.length ? selectedRows : name ? [name] : []} label={selectedRows.length > 1 ? 'these methods' : 'this model'} path={!selectedRows.length ? '/models' : undefined}/>
                    ) : (
                        <PlotPopup togglePopup={togglePopup} allData={selectedMethod && selectedMethod} plotData={selectedMethod && isClassificationType(selectedMethod) ? selectedMethod.roc : selectedMethod ? selectedMethod.cross_val_predict : []} info={info} metrics={orderedRecords} limits={limits} title={selectedMethod ? selectedMethod.method_name : '' } type={isClassification ? 'classification' : 'regression'} />
                    )}
                </Overlay>
            )}
            

        </>
    )
}