import { CircularProgress } from "@mui/material"
import { MouseEvent } from "react"

export default function FormButtons({ backFunction, cancelFunction, continueFunction, isDisabled, isLoading }: {
    backFunction?: (e:MouseEvent<HTMLButtonElement>) => void,
    cancelFunction: (e:MouseEvent<HTMLButtonElement>) => void,
    continueFunction: (e:MouseEvent<HTMLButtonElement>) => void,
    isDisabled: boolean,
    isLoading: boolean,
}) {
    return (
        <div className={`${backFunction ? 'justify-between' : 'justify-end'} flex items-center gap-2`}>
            {backFunction ? 
               <button onClick={cancelFunction} className='py-2 px-4 rounded border border-quaternary bg-white hover:bg-quaternary hover:text-white transition-all text-quaternary text-[14px] mr-2'>Cancel</button>
            : 
                null
            }
            <div className='flex justify-end'>
                <div className='flex gap-2'>
                    {backFunction ? (
                      <button onClick={backFunction} className='py-2 px-4 rounded border border-quaternary bg-quaternary hover:bg-[#4B4B4B] hover:border-[#4B4B4B] transition-all text-white text-[14px] mr-2'>Back</button>
                    ) : (
                        <button onClick={cancelFunction} className='py-2 px-4 rounded border border-quaternary bg-white hover:bg-quaternary hover:text-white transition-all text-quaternary text-[14px] mr-2'>Cancel</button>
                    )}
                    
                    <button disabled={isDisabled} onClick={continueFunction} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[14px] border border-tertiary bg-tertiary hover:border-secondary hover:bg-secondary transition-all disabled:border-quaternary/[0.1] disabled:bg-quaternary disabled:bg-opacity-30'>
                        {isLoading ? (
                            <>
                            <CircularProgress size={16} color='inherit'/>
                            <p>Submitting</p>
                            </>
                        ) : (
                            <p>Continue</p>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}