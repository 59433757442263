import { Link } from "react-router-dom"
import ArrowLeft from '../../../assets/arrow-narrow-left.svg';
import ArrowRight from '../../../assets/arrow-narrow-right.svg';
export default function TutorialPagination({ nextPage, prevPage }: { nextPage?: {page: string, label: string}, prevPage?: {page: string, label: string} }) {
    return (
        <div className="w-full flex justify-between items-center gap-6 pt-8 border-t border-[#b4b4b4]">
            {prevPage && (
                <Link to={`/help/${prevPage.page}`} className="flex justify-start items-center gap-2">
                    <img src={ArrowLeft} alt='back'/>
                    <p className="font-semibold text-tertiary text-[16px] hover:text-secondary">{prevPage.label}</p>
                </Link>
            )}
            
            {nextPage && (
                <Link to={`/help/${nextPage.page}`} className="flex justify-start items-center gap-3">
                    <p className="font-semibold text-tertiary text-[16px] hover:text-secondary">{nextPage.label}</p>
                    <img src={ArrowRight} alt='next'/>
                </Link>
            )}    
        </div>
    )
}