

// hooks
import { useState, useEffect } from "react";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useEnableSelection from "../../../hooks/useEnableSelection";
import useResults from '../../../hooks/pageHooks/useResults';


// types
import { ParsedPrediction, Prediction } from "../../../types/predictions.types";

// data
import { predictionsHeader, setupResults } from "../../../data/predictions";
import { defaultResultsFilter } from '../../../data/predictions';

// components
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import DeletePopup from "../../../components/Popups/DeletePopup";
import FilterPopup from "../../../components/Popups/FilterPopup";
import EnableSelectionButton from '../../../components/Buttons/EnableSelectionButton';
import Pagination from "../../../components/Page/Pagination";
import FilterButton from "../../../components/Buttons/FilterButton";
import TableControls from "../../../components/Table/TableControls";
import EmptyTable from "../../../components/Table/EmptyTable";


// assets
import SnackBar from "../../../components/Alerts/SnackBar";
import useMessage from "../../../hooks/useMessage";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import IndividualResultsetOptions from "../components/IndividualResultsetOptions";
import Overlay from "../../../components/Popups/Overlay";
import ModifyPermissionPopup from "../../../components/Popups/ModifyPermissionsPopup";
import useModifyTableItems from "../../../hooks/useModifyTableItems";

export default function SeparatePredictions({ predictions, isLoading, isRegression }: { 
    predictions: Prediction[], 
    isLoading: boolean,
    isRegression: boolean,
}) {
    const [ parsedPredictions, setParsedPredictions ] = useState<ParsedPrediction[]>([]);
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const theme = useSelector(selectTheme);

    const {
        open,
        setOpen,
        message,
        setPopupMessage,
        isSuccess,
    } = useMessage();

    useEffect(() => {
        if (predictions && predictions.length > 0) {
            const results = setupResults(predictions);
            if (results) {
                setParsedPredictions(results);
            }
        }
    }, [predictions])

    const {
        modifiedResults,
        modifyResults,
        filterFields,
        filter,
        setFilter,
        headers,
        setHeaders,
        deleteResults,
        downloadResultset,
        updateResultPermissions,
        allResultsIDs,
    } = useResults({searchInput: searchInput, parsedPredictions, setPopupMessage});
    

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyResults, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedResults, count: 50});

    const { 
        isPopupOpen,
        togglePopup,
        setPopupType,
        popupType
    } = usePopup();

   

    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        allAreSelected,
        selectAllRows,
        addSingleItem,
        deselectItems,
        toggleChecked
    } = useEnableSelection({allItems: allResultsIDs});




    useEffect(() => {
        if (isRegression) {
            setHeaders(predictionsHeader.filter(h => h.value !== 'conf_pred'))
        } else {
            setHeaders(predictionsHeader);
        }
        //eslint-disable-next-line
    }, [isRegression]);



    return (
        <>

        <div className={`${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'}`}>   
            {/* Search Bar & Buttons Component */}
            
            <TableControls applySearch={applySearch} identifier="Name" toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows}>
                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType} />
                <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
            </TableControls>

            {isLoading || paginatedItems.length > 0 ? (
                <>
                    <TableBase
                        isLoading={isLoading}
                        isPopupOpen={false}
                        isChecked={isChecked} 
                        selectAllRows={selectAllRows} 
                        allAreSelected={allAreSelected} 
                        hasOptions={isLoading ? false : true} 
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn}
                    >
                        {paginatedItems.length > 0 && paginatedItems.map(item => (
                            <TableRowBase
                                selectedRows={selectedRows} 
                                editSelectedRows={editSelectedRows} 
                                item={item} 
                                headers={headers}
                                isChecked={isChecked}
                                action={{path: 'predictions'}}
                                rowType="base"
                            >
                                <IndividualOptionsDropdownBase>
                                    <IndividualResultsetOptions setPopupType={setPopupType} threshold={null} handleClose={() => {}} downloadFunc={downloadResultset} id={item.id} addSingleItem={addSingleItem} toggleDeletePopup={togglePopup}/>
                                </IndividualOptionsDropdownBase>
                            </TableRowBase>
                        ))}
                    </TableBase>
                    {paginatedItems.length > 0 && (
                        <Pagination count={50} total={modifyResults.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                    )}
                </>
            ) : (
                <EmptyTable message="Make a new prediction" subject="No resultsets found" searchInput={searchInput} />
            )}
                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  
            </div>
            
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deselectItems={deselectItems} deleteFunc={deleteResults} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these results' : 'this result'}`}/>
                    ) : popupType === 'filter' ? (
                        <FilterPopup itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={defaultResultsFilter} fieldsObj={filterFields} />
                    ) : (
                        <ModifyPermissionPopup toggleChecked={toggleChecked} deselectItems={deselectItems} updateFunc={updateResultPermissions} items={selectedRows} togglePopup={togglePopup} />
                    )}
                </Overlay>
            )}


        </>
    )
}