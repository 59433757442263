import { SideInfo } from "../../types/all.types";
import { AllModels, CompleteModel, Model, ModelWithMethods, SingleClassificationModel, SingleRegressionModel } from "../../types/models.types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import API from '../../api';
import { deleteExistingModels, getCompleteModels } from "../../store/slices/models.slice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useAllModels = ({ singleModel, models, searchInput, setSingleModel, setMessage, setOpen }: { 
    singleModel?: SingleClassificationModel[] | SingleRegressionModel[], 
    models?: CompleteModel[],
    searchInput?: string,
    setSingleModel?: Dispatch<SetStateAction<SingleClassificationModel[] | SingleRegressionModel[]>>,
    setOpen?: Dispatch<SetStateAction<boolean>>,
    setMessage?: Dispatch<SetStateAction<string>>,
}) => {
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const dispatch = useDispatch();
    const [ originalModels, setOriginalModels ] = useState<Model[]>([])
    const [ allModels, setAllModels ] = useState<AllModels[]>([]);
    const [ sortedModels, setSortedModels ] = useState<AllModels[]>([]);
    const [ filteredModels, setFilteredModels ] = useState<AllModels[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ allModelNames, setAllModelNames ] = useState<string[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        const fetchModels = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                try {

                    const response = await API.get(`models?aggr=max`, { headers: { 'X-Auth': authKey } });
                    const methodResponse = await  API.get(`models`, { headers: { 'X-Auth': authKey } });

                    const data: Model[] = await response.data;
                    const methodData: ModelWithMethods = await methodResponse.data;

                    let modelArr: CompleteModel[] = [];
                    
                    data.forEach(m => {
                        const match  = methodData[m.name as keyof ModelWithMethods];
                        const modifiedModel: CompleteModel = { ...m, method_ids: match.method_ids};
                        modelArr.push(modifiedModel);
                    })


                    dispatch(getCompleteModels(modelArr));
                    setOriginalModels(modelArr);
  

                } catch (err: any) {

                    console.log(err);
                    
                }
                
            } else {
                navigate('/401');
            }

            
        }


        if (models === undefined) {
            fetchModels();
        } else {
            setOriginalModels(models);
        }

        setIsLoading(false);

    }, [models, dispatch]);

    useEffect(() => {
        if (originalModels && originalModels.length > 0) {
            const arr: AllModels[] = originalModels.map(m => ({
                name: m.name,
                n: m.n,
                model_type: m.auc ? 'Classification' : 'Regression',
                created_by: m.created_by,
                creation_time: new Date(m.creation_time).toLocaleString('en-US', {timeZone: 'EST'}),
            }))

            const ordered = arr.sort((a,b) => {
                if ((new Date(a.creation_time as string)) > (new Date(b.creation_time as string))) {
                    return -1
                } else if ((new Date(a.creation_time as string)) < (new Date(b.creation_time as string))) {
                    return 1;
                } else {
                    return 0;
                }
            })
    
            setAllModels(ordered);
            setSortedModels(ordered);
            setFilteredModels(ordered);
            
        }
    }, [originalModels])

    useEffect(() => {
        if (sortedModels.length > 0 && searchInput !== undefined) {
            setFilteredModels(sortedModels.filter(f => (f.name as string).toLocaleLowerCase().includes(searchInput)))
        } else {
            setFilteredModels(sortedModels)
        }
    }, [searchInput, sortedModels]);

    useEffect(() => {
        if (filteredModels.length > 0) {
            setAllModelNames(filteredModels.map(m => m.name))
        }
    }, [filteredModels])


    useEffect(() => {
        if (singleModel && singleModel.length > 0) {

            setInfo([
                {
                    label: 'Dataset Name',
                    value: singleModel[0].dataset ? singleModel[0].dataset.name : ''
                },
                // {
                //     label: 'Model Type',
                //     value: 'Classification'
                // },
                {
                    label: 'Model Label',
                    value: singleModel[0].label
                },
                {
                    label: 'Descriptor',
                    value: singleModel[0].descriptors[0].name,
                },
                {
                    label: 'Bits',
                    value: singleModel[0].descriptors[0].params.Bits,
                },
                {
                    label: 'Radius',
                    value: singleModel[0].descriptors[0].params.Radius,
                },
                {
                    label: 'Access Type',
                    value: singleModel[0].acl.access,
                }
                
            ]);

        }
         
        
    }, [singleModel]);

    const deleteModels = async (names: string[], modelName?: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = names.map(async (name) => {
            const encoded = encodeURI(name);
            if (!modelName) {
                return API.delete(`models/${encoded}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingModels(name)));      
            } else {
                return API.delete(`models/${modelName}/${name}`, { headers: { 'X-Auth': authKey } })
            }
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
                setMessage && setMessage(`There was an error deleting ${modelName ? names.length > 1 ? 'these methods' : 'this method' : names.length > 1 ? 'these models' : 'this model'}`);
                setOpen && setOpen(true);
            }

            if (modelName) {
                const response = await API.get(`models/${modelName}`, { headers: { 'X-Auth': authKey } });
                const data: SingleClassificationModel[] | SingleRegressionModel[] = response.data;
                setSingleModel && setSingleModel(data);
            }
        }
    };

    return {
        info,
        deleteModels,
        isLoading,

        allModels,
        sortedModels,
        setSortedModels,
        filteredModels,
        setFilteredModels,
        allModelNames,

    }
}

export default useAllModels;