import { MouseEvent, useState, useEffect } from "react"
import Overlay from "./Overlay";
import PlotlyGraphs from "../Data/PlotlyGraphs";
import TruthTable from "../Data/TruthTable";

import { ClassificationRecords, RegressionRecords, SingleClassificationModel } from "../../types/models.types";
import NumberLine from "../Data/NumberLine";
import { Limit } from "../../types/all.types";
import EmptyGraph from "../Data/EmptyGraph";
import ToggleArrows from "../Page/ToggleArrows";

const hist_data = (method: SingleClassificationModel) => {
    
    if (!method.y_true || !method.y_pred || !method.y_prob) {

        return null;

    } else {

        let pred_vals = [];
        let true_vals = [];
        let i = 0;
        for (let val of method.y_true) {
          if (val === 0) {
            pred_vals.push(method.y_prob[i])
          }
          i++;
        }
        let j = 0;
        for (let val of method.y_true) {
          if (val === 1) {
            true_vals.push(method.y_prob[j])
          }
          j++;
        }
    
        var trace1 = {
          x: pred_vals,
          name: 'Predicted Values',
          autobinx: false, 
          histnorm: "count", 
          marker: {
            color: "rgb(219, 30, 58, .7)", 
            line: {
              color:  "rgb(219, 30, 58, 1)", 
              width: 1
            }
          },  
          opacity: 0.5, 
          type: "histogram", 
          xbins: {
            end: 1, 
            size: 0.025, 
            start: 0
            }
          };
    
        var trace2 = {
          x: true_vals,
          name: "True Values", 
          histnorm: "count",
          autobinx: false, 
          marker: {
                  color: "rgb(94, 146, 219, .7)",
                  line: {
                    color:  "rgb(94, 146, 219, 1)", 
                    width: 1
            } 
              }, 
          opacity: 0.5, 
          type: "histogram", 
          xbins: { 
            end: 1, 
            size: 0.025, 
            start: 0
            }
          };
    
        return [trace1, trace2]

    }
    
  }

export default function PlotPopup({ togglePopup, plotData, info, metrics, limits, title, type, allData }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void,
    plotData: any,
    info: any,
    metrics: any,
    title: string,
    type: 'classification' | 'regression',
    allData: any,
    limits?: Limit[],
}) {

    const [ isBackDisabled, setIsBackDisabled ] = useState(true);
    const [ isNextDisabled, setIsNextDisabled ] = useState(false);
    const [ currentGraph, setCurrentGraph ] = useState(0);
    const [ indices, setIndices ] = useState([0,1,2]);
    const [ fields, setFields ] = useState<ClassificationRecords | RegressionRecords | null>(null);

    const next = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (currentGraph === indices[indices.length-1]) {
            return;
        } else {
            setCurrentGraph(currentGraph + 1);
        }
    }

    const back = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (currentGraph === indices[0]) {
            return;
        } else {
            setCurrentGraph(currentGraph - 1);
        }
    }

    useEffect(() => {
        setIndices([0,1,2])
    }, []);

    useEffect(() => {
        if (currentGraph === 0) {
            setIsBackDisabled(true);
            setIsNextDisabled(false);
        } else if (currentGraph === indices[indices.length-1]) {
            setIsBackDisabled(false);
            setIsNextDisabled(true);
        } else {
            setIsBackDisabled(false);
            setIsNextDisabled(false);
        }
    }, [currentGraph, indices]);

    useEffect(() => {
        if (metrics) {
            const field = metrics.find((m:any) => m.method_name === title);
            if (field) {
                setFields(field);
            }
        }
    }, [metrics, title])
    


    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-5  max-w-[1200px] w-full'>
                    <div className='popup-max-height '>
                        <div className='flex gap-6 justify-center items-stretch'>
                                
                        <div className='rounded flex flex-col gap-6 p-6 bg-white w-1/2'>
                            <div className="w-full flex justify-end">
                            {type === 'classification' && (
                                <div className="flex justify-end">
                                    <ToggleArrows firstCondition={!isBackDisabled} secondCondition={!isNextDisabled} firstAction={back} secondAction={next}/>  
                                </div>
                            )}
                            </div>
                             {type === 'regression' ? (
                                    <PlotlyGraphs type='scatter' data={plotData}/>
                                ) : (
                                    <>
                                        {currentGraph === 1 && <PlotlyGraphs type='roc' data={plotData as any}/>}
                                        {currentGraph === 0 && (
                                            <>
                                            {!hist_data(allData) ? (
                                                <EmptyGraph type="Histogram" />
                                            ) : (
                                                <PlotlyGraphs type='histogram' data={hist_data(allData as SingleClassificationModel) as any} />
                                            )}
                                            </>
                                        )}
                                        {currentGraph === 2 && (
                                            <>
                                            {allData.tp !== undefined && allData.tn !== undefined && allData.fn !== undefined && allData.fp !== undefined ? (
                                                <TruthTable data={[
                                                {name: 'True Positive', value: (allData as SingleClassificationModel).tp as number}, 
                                                {name: 'False Positive', value: (allData as SingleClassificationModel).fp as number}, 
                                                {name: 'False Negative', value: (allData as SingleClassificationModel).fn as number},
                                                {name: 'True Negative', value: (allData as SingleClassificationModel).tn as number},
                                            ]} />
                                            ) : (
                                                <EmptyGraph type="Truth" />
                                            )}
                                            </>
                                        ) }
                                    </>
                                )}
                            </div>

                            <div className="w-1/2 flex flex-col justify-between items-stretch">
                                <div className="field-popup-max-height h-full overflow-y-auto">
                                    <div className="flex flex-col gap-4 justify-start items-stretch">
                                        <p className="font-semibold text-[18px] text-primary">{title}</p>
                                        <div className='flex flex-col gap-4 justify-center items-stretch'>
                                        {fields !== null && Object.keys(fields).map(key => {
                                            const limitsObj = limits && limits.find(limit => limit.value === key);
                                            if (limitsObj) {
                                                return (
                                                    <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(fields[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex justify-end items-center">
                                    <div className="flex gap-2">
                                        <button onClick={togglePopup} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[14px] text-medium border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all font-medium disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
       
    )
}