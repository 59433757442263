import { useState, useEffect, RefObject } from 'react';

interface IntersectionObserverArgs {
    threshold?: number | number[];
    root?: Element | null;
    rootMargin?: string;
}

const useLazyLoad = (elementRef: RefObject<HTMLElement>, { threshold = 0.1, root = null, rootMargin = '0%' }: IntersectionObserverArgs) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                threshold,
                root,
                rootMargin,
            }
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [elementRef, threshold, root, rootMargin]);

    return isVisible;
};

export default useLazyLoad;