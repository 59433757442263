import View from '../../../assets/link.svg';
import Empty from '../../../assets/EmptyState.svg';
import ColoredPredictions from '../../../assets/predictive-analysis.png'
import ColoredModels from '../../../assets/model-colored.png';
import ColoredDataset from '../../../assets/dataset-colored.png';
import { useEffect, useState } from 'react';
import { getDateFromObjectId, orderByDate } from '../../../data/functions';
import { useNavigate } from 'react-router-dom';

import API from '../../../api';

import { Job } from '../../../types/jobs.types';

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../store/selectors/auth.selector';

interface JobArrType {
    id: string;
    name: string;
    type: string;
    message: string;
    date_created:string;
}


const getMessage = (status: string) => {
    if (status === 'Failed') {
        return 'has failed to process'
    } else if (status === 'Done') {
        return 'has completed processing'
    } else if (status === 'Pending') {
        return 'is pending processing'
    } else if (status === 'Running') {
        return 'is running'
    } else {
        return ''
    }
}


export default function JobQueueCard() {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ recentJobs, setRecentJobs ] = useState<JobArrType[] | null>(null);
    const navigate = useNavigate();
    const theme = useSelector(selectTheme);

    useEffect(() => {
        const fetchJobs = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                setIsLoading(true);

                try {

                    const response = await API.get('jobs', { headers: { 'X-Auth': authKey } });
                    const data: Job[] = await response.data;

                    let arr: JobArrType[]  = [];

                    if (data.length > 0) {
                        data.forEach(job => {
                            const name = job.job_type === 'ad_domain' ? job.params.ds_name : job.job_type === 'train' ? job.params.model_name!  : job.params.rs_name!;
                            const combinedName = job.params.method !== undefined ? `${name}-${job.params.method}` : name;
                            arr.push({
                                id: job._id.$oid,
                                name: combinedName,
                                type: job.job_type,
                                message: getMessage(job.status),
                                date_created: getDateFromObjectId(job._id.$oid).toLocaleString('en-US', {timeZone: 'EST'})
                            })
                        });
    
                        const ordered = orderByDate(arr);
    
                        const recent = ordered.slice(0,5);
                        setRecentJobs(recent);
                    } else {
                        setRecentJobs(null);
                    }
                    
                    

                } catch (err: any) {

                    console.log(err);
                    navigate('/500');

                }
                
                setIsLoading(false);
            }
        }

        fetchJobs();
        
        
    }, []);


    return (
        <div className='w-full max-h-[350px] grow overflow-y-auto mt-6'>
            {isLoading ? (
                <div className='flex flex-col h-full justify-start items-center'>
                {Array.from(Array(5).keys()).map(k => (
                    <div key={k} className='w-full'><Skeleton height={100} baseColor={`${theme === 'dark' ? '#454E69' : '#ebebeb'}`} highlightColor={`${theme === 'dark' ? '#646A7B' : '#f5f5f5'}`}/></div>
                ))}
                </div>
            ) : recentJobs === null ? (
                <div className='flex h-full justify-center items-center flex-col rounded'>
                    <img src={Empty} alt='empty-queue' className='w-[200px]'/>
                    <p className="text-[16px] font-medium max-w-[250px] text-center mb-10">Your job queue is currently empty</p>
                </div>
            ) : (
                <div className='flex flex-col items-stretch rounded '>
                    {recentJobs.length > 0 && recentJobs.map((q,i) => (
                        <div key={i} className={`flex justify-between items-stretch gap-2 p-6 rounded w-full ${theme === 'dark' ? 'dark-card-body' : 'card-body'}`}>
                            <div className='grow flex justify-start items-center gap-4'>
                                <img src={q.type === 'train' ? ColoredModels : q.type === 'predict' ? ColoredPredictions : ColoredDataset} alt='queue-icon' className="w-[40px]"/>
                                <div>
                                    <p className="font-semibold mb-[2px] break-all">
                                        {q.name}
                                        <span className='font-medium'> {q.message}</span>
                                    </p>
                                    <p className="font-medium text-quaternary text-[12px]">{q.date_created}</p>
                                </div>
                            </div>
                            <div className="h-full flex justify-center items-start gap-2">
                                <button onClick={(e:any) => navigate(`/jobs/${q.id}`)} className="transition-all p-1 rounded-full border-2 border-quaternary hover:bg-quaternary lighten"><img src={View} alt='view-icon' className="w-[20px]"/></button>
                                {/* <button disabled className="p-1 rounded-full border-2 border-quaternary hover:bg-secondary/[0.2]"><img src={Trash} alt='trash-icon' className="w-[20px]"/></button> */}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            
            
        </div>
    )
}