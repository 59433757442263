import { Header } from "../../types/all.types";
import { Link } from "react-router-dom";
import { ChangeEvent, Dispatch, MouseEvent, ReactNode, SetStateAction } from "react";
import ChevronUp from '../../assets/chevron-up.svg';
import ChevronDown from '../../assets/chevron-down.svg';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { selectDatasets } from "../../store/selectors/datasets.selector";


import { useState } from "react";
import { useSelector } from "react-redux";
import { selectJobs } from "../../store/selectors/jobs.selector";
import { useNavigate } from "react-router-dom";

import { ParsedJobs, GroupData } from "../../types/jobs.types";
import JobDropdown from "../../routes/Job Queue/components/JobDropdown";
import { selectPredictions } from "../../store/selectors/predictions.selector";
import { selectModels } from "../../store/selectors/models.selector";
import { selectTheme } from "../../store/selectors/auth.selector";
import { PopupType } from "../../hooks/usePopup";




export default function JobRows({ groupData, associatedItems, headers, path, setSelection, togglePopup, valueField, isChecked, selectAllItemsInGroup, selectSingleItemInGroup, selectedGroup, isLoading, setGroupForDeletion, setPopupType }: {
    groupData?: GroupData,
    associatedItems?: ParsedJobs[];
    headers: Header[],
    path?: string,
    setSelection?: Dispatch<SetStateAction<string>>,
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void,
    valueField?: {name: string, type: string, [key:string]: string} | null,
    children?: ReactNode,
    isChecked: boolean,
    selectedGroup?: {groupName: string, items: string[]},
    selectAllItemsInGroup: (e:ChangeEvent<HTMLInputElement>, id: string) => void,
    setGroupForDeletion: (e:any, groupID: string) => void,
    selectSingleItemInGroup: (e:ChangeEvent<HTMLInputElement>, groupID: string, id: string) => void,
    isLoading?: boolean,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
    
}) {
    const jobs = useSelector(selectJobs);
    const datasets = useSelector(selectDatasets);
    const results = useSelector(selectPredictions);
    const models = useSelector(selectModels);
    const navigate = useNavigate();
    const theme = useSelector(selectTheme);

    const [ isOpen, setIsOpen ] = useState(false);

    const toggleOpen = (e:any) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    }

    const getStatusColor = (status: string) => {
        switch(status) {
            case "Pending": 
                return '#FFF278';
            
            case "Rescheduled":
                return '#FFF278';
                
            case "Running":
                return '#FFC061';
    
            case "Done":
                return '#C7DB78';
    
            case "Failed":
                return '#FF8484';
            
            case "train":
                return '#B3D5F5';

            case 'predict': 
                return '#D7EEBF';

            case 'ad_domain': 
                return '#E8BFF3';
    
            default:
                return '#EEEEEE';
        }
    };
    
    const getGroupStatusColor = () => {
        if (associatedItems) {
            let statusCount: any = {};

            associatedItems.forEach(item => {
                const status = item.status;
                statusCount[status] = statusCount[status] ? statusCount[status]+1 : 1;
            });

            let groupStatus = "";

            const statusKeys = Object.keys(statusCount);

            if (statusKeys.includes('Running')) {
                groupStatus = 'Running';
            } else if (statusKeys.includes('Pending')) {
                groupStatus = 'Pending';
            } else if (statusKeys.includes('Failed')) {
                groupStatus = 'Failed';
            } else {
                groupStatus = 'Done';
            };

            return {
                color: getStatusColor(groupStatus),
                status: groupStatus,
            }
        } else {
            return {
                color: '#FFF278',
                status: 'Pending'
            }
        }
    }

    const fileExists = (value: string, id: string) => {
        const job = jobs.find(j => j._id.$oid === id);
        if (job) {
            if (value === 'dataset') {
                const dsID = job.params.ds_id;
                const ds = datasets.find(d => d._id.$oid === dsID);
                if (ds) {
                    return true;
                } else {
                    return false;
                }
            } else if (value === 'result') {
                if (job.params.model_name) {
                    const resID = job.params.model_name;
                    const mod = models.find(m => m.name === resID);
                    if (mod) {
                        return true
                    } else {
                        return false
                    }
                } else if (job.params.rs_ids) {
                    const resID = job.params.rs_ids[0];
                    const res = results.find(r => r._id.$oid === resID);
                    if (res) {
                        return true;
                    } else {
                        return false
                    }
                }                 
            }
        }
        
    }

    const goToDataset = (id: string) => {
        const job = jobs.find(j => j._id.$oid === id);
        if (job) {
            navigate(`/datasets/${job.params.ds_id}`);
        }
    }

    const gotToResult = (id:string) => {
        const job = jobs.find(j => j._id.$oid === id);
        if (job) {
            if (job.params.model_name) {
                navigate(`/models/${job.params.model_name}`)
            } else if (job.params.rs_ids) {
                navigate(`/predictions/${job.params.rs_ids[0]}`)
            } else {
                navigate(`/datasets/${job.params.ds_id}`)
            }
        }
    }



    return (
        <>
        {isLoading ? (
            <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px]`}>
                <td className="py-4 px-6"><Skeleton /></td>
            </tr>
        ) : (
        <>
        {groupData && (
            <tr className={`${theme === 'dark' ? 'dark-table-body' : 'table-body'} text-[14px]`}>
            <>
            {headers.map(header => {
                if (header.isRequired) {
                    return (
                        <td className={` text-left min-w-[200px] break-words hover:cursor-pointer relative`}>
                            {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            <div className="flex justify-start items-center cell">
                            {isChecked && (<input onChange={(e:any) => {selectAllItemsInGroup(e, groupData.id)}} checked={selectedGroup === undefined ? false : true} name={groupData.id} type="checkbox" className="accent-secondary ml-6 relative z-20 hover:cursor-pointer"/>)}
                            <button onClick={toggleOpen} className="flex gap-3 justify-start items-center py-4 px-4">
                                {isOpen ? (
                                    <img src={ChevronUp} className="w-[18px]" alt='close-menu-icon'/>
                                ) : (
                                    <img src={ChevronDown} className="w-[18px]" alt='open-menu-icon'/>
                                )}
                                <p style={{wordWrap: 'break-word'}} className="max-w-[400px] text-left">{groupData[header.value as keyof object]}</p>

                            </button>
                            
                            </div>
                        </td>
                    )
                } else {
                    return (
                        <td className={`py-4 px-6 text-center min-w-[200px] break-words relative ${header.isSelected ? 'table-cell' : 'hidden'}`}>
                            {selectedGroup && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                            {header.value === 'status' ? (
                                <div style={{backgroundColor: `${getGroupStatusColor().color}`}} className="rounded-full py-2 px-4 text-center text-primary">{getGroupStatusColor().status}</div>
                            ) : (header.value === 'dataset' || header.value === 'result') && getGroupStatusColor().status === 'Done' ? (
                                <button style={{wordWrap: 'break-word'}} disabled={!fileExists(header.value, associatedItems ? associatedItems[0].id : groupData.id)} onClick={(e:any) => {header.value === 'dataset' ? goToDataset(associatedItems ? associatedItems[0].id : groupData.id) : gotToResult(associatedItems ? associatedItems[0].id : groupData.id)}} className={`text-highlight hover:text-tertiary transition-all disabled:text-primary max-w-[400px]`}>{groupData[header.value as keyof object]}</button>
                            ) : (
                                <>{groupData[header.value as keyof object]}</>
                            )}
                            
                        </td>
                    )
                }
            })}
            </>
                <td className='text-right'><JobDropdown setGroupForDeletion={setGroupForDeletion} groupID={groupData.id} toggleDelete={togglePopup} setPopupType={setPopupType}/></td>
            </tr>
            )}
            {isOpen && associatedItems && associatedItems.length && (
                <>
                {associatedItems.map((item, i) => (
                    <tr className={`text-[14px] ${theme === 'dark' ? 'dark-table-inner-body' : 'table-inner-body'}`}>
                    <>
                    {headers.map(header => {
                        if (header.isRequired) {
                            return (
                                <td className={` text-left min-w-[200px] break-words z-20 relative ${i === 0 && 'border-t'} ${i === associatedItems.length-1 && 'border-b'}`}>
                                    
                                    {selectedGroup && selectedGroup.items.includes(item.id) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                                    <div className="flex gap-3 justify-start items-center py-4 px-6 inner-cell">
                                    {isChecked && <input onChange={(e:ChangeEvent<HTMLInputElement>) => selectSingleItemInGroup(e, groupData ? groupData.id : '', item.id)} checked={selectedGroup ? ( selectedGroup.items.includes(item.id) ? true : false ) : false}  type="checkbox" className="accent-secondary mr-2 relative z-20 hover:cursor-pointer"/>}
                                    <Link to={`/jobs/${item.id}`} style={{wordWrap: 'break-word'}} className="text-highlight hover:text-tertiary transition-all max-w-[600px]">{item[header.value as keyof object]}</Link>
                                    </div>
                                </td>
                            )
                        } else {
                            return (
                                <td className={`py-4 px-6 text-center min-w-[200px] break-words text-nowrap relative z-20 ${header.isSelected ? 'table-cell' : 'hidden'} ${i === 0 && 'border-t'} ${i === associatedItems.length-1 && 'border-b'}`}>
                                    {/* <div className="inner-shadow absolute top-0 left-0 right-0 bottom-0 z-10"></div> */}
                                    {selectedGroup && selectedGroup.items.includes(item.id) && <div className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                                    <>
                                    {header.value === 'status' ? (
                                        <div style={{backgroundColor: `${getStatusColor(item[header.value as keyof object])}`}} className="rounded-full py-2 px-4 text-center text-primary">{item[header.value as keyof object]}</div>
                                    ) : (header.value === 'dataset' || header.value === 'result') && item.status === 'Done' ? (
                                        <button disabled={!fileExists(header.value, item.id)} onClick={(e:any) => {header.value === 'dataset' ? goToDataset(item.id) : gotToResult(item.id)}} className={`text-highlight hover:text-tertiary transition-all ${theme === 'dark' ? 'disabled:text-white' : 'disabled:text-primary'} `}>{item[header.value as keyof object]}</button>
                                    ) : (
                                        <>{item[header.value as keyof object]}</>
                                    )}
                                    </>
                                    
                                    
                                </td>
                            )
                        }
                    })}
                    </>
                    <td className={`text-right relative ${i === 0 && 'border-t'} ${i === associatedItems.length-1 && 'border-b'}`}>
                        <div className="w-[24px]"></div>
                    </td>
                        {/* <td className='text-right'><JobDropdown groupID={groupData ? groupData.id : ''}  toggleDelete={togglePopup}/></td> */}
                    </tr>
                ))}
                </>
            )}
            </>
            )}
        </>
    )
}