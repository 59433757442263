import Plot from 'react-plotly.js';
import * as Plotly from 'plotly.js';


export default function BarGraph({data, maxValue}: {data: Plotly.Data[], maxValue?: number}) {



    const config: Partial<Plotly.Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }


    const layout_bar: () => Partial<Plotly.Layout> = function() {
        return {
            barmode: 'group',
            yaxis: {range: [-1,maxValue ? maxValue : 1], autorange: false, dtick: 0.2},
            showlegend: true,
            legend: {
                font: {
                    size: 16,
                }
            },
            shapes: [
                {
                    type: 'line',
                    x0: 0,
                    y0: maxValue ? maxValue : 1,
                    x1: 1,
                    y1: maxValue ? maxValue : 1,
                    xref: 'paper',
                    yref: 'y',
                    line: {
                        color: 'blue',
                        width: 3,
                        dash: 'dot',
                    }
                }
            ]
        }
    }
    


    return (
        <Plot data={data} layout={layout_bar()} config={config}  className='w-full h-[600px]'/>
    )
}