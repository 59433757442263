import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ChevronDown from '../../../assets/chevron-down.svg';
import ChevronUp from '../../../assets/chevron-up.svg'
import usePrivileges from '../../../hooks/usePrivileges';



const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}

export default function CreateModelDropdown() {
    const navigate = useNavigate();
    const { canCreate } = usePrivileges();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (

        <div>
            {canCreate ? (
                <>
                <button onClick={handleClick}>
                <div className={`text-white bg-tertiary hover:bg-secondary transition-all whiten flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                        <p className='font-medium'>Train New Model</p>
                        {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
                </button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    sx={{
                        marginTop: '8px',
                        '& .MuiMenu-list': {
                            padding: 0,
                        }
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem sx={sx} onClick={(e:any) => {navigate('/models/create-new-model?dataset=new'); handleClose()}}>From New Dataset</MenuItem>
                    <MenuItem sx={sx} onClick={(e:any) => {navigate('/models/create-new-model?dataset=existing'); handleClose()}}>From Existing Dataset</MenuItem>
                </Menu>
                </>
            ) : (
                <button onClick={(e:any) => navigate('/models/create-new-model?dataset=existing')} className={`text-white bg-tertiary hover:bg-secondary transition-all whiten flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                        <p className='font-medium'>Create New Model</p>
                </button>
            )}
            
        </div>
  
    )
}