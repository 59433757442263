import { Dispatch, SetStateAction } from "react";
import { FieldMappingType, FilterType, Header } from "../types/all.types";
import { FileType } from "../types/files.types";
import { Dataset } from "../types/datasets.types";
import { AuthUser, User } from "../types/users.types";
import { Model } from "../types/models.types";




interface SizeType {
    op: string,
    size: string,
}

interface RecordType {
    op: string,
    quantity: string,
}

interface AccessType {
    authenticated: boolean,
    private: boolean,
}

export const isSizeType = (obj:any): obj is SizeType => {
    if (obj.size !== undefined) {
        return true;
    } else {
        return false;
    }
}

export const isRecordType = (obj:any): obj is RecordType => {
    if (obj.quantity !== undefined) {
        return true;
    } else {
        return false;
    }
}

export const isAccessType = (obj:any): obj is AccessType => {
    if (obj.authenticated !== undefined || obj.private !== undefined) {
        return true;
    } else {
        return false;
    }
}


export const getDateFromObjectId = (id: string) => {
    return new Date(parseInt(id.substring(0 , 8), 16) * 1000);
}

export const getTimezoneOffset = (date: string) => {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    const offsetHours = -offset / 60;
    const sign = offsetHours >= 0 ? '+' : '-';
    const formattedOffset = `UTC${sign}${Math.abs(offsetHours)}`;
    return formattedOffset;
}




const makeComparison = (op: string, a: number, b: number) => {
    if (op === '<') {
        return a < b;
    } else if (op === '<=') {
        return a <= b;
    } else if (op === '>') {
        return a > b;
    } else if (op === '>=') {
        return a >= b;
    }
}


export const applyFilters = (filterObj: FilterType, items: any[], setFilteredItems: Dispatch<SetStateAction<any[]>>, searchInput?: string) => {
    const arr = items.filter((item) => {
        let doesNotSatisfy: string[] = [];
        Object.keys(filterObj).forEach((key) => {   
            const objKey: keyof object = key as keyof object;
            if (Array.isArray(filterObj[objKey])) {
                if (!(filterObj[objKey] as string[]).length) {
                    return;
                } else if ((filterObj[objKey] as string[]).includes(item[objKey])) {
                    return;
                } else {
                    doesNotSatisfy.push(objKey);
                } 
            } else if (isAccessType(filterObj[objKey])) {
                
                const trueValues = Object.keys(filterObj[objKey]).filter(k => ((filterObj[objKey] as AccessType)[k as keyof AccessType]) === true);
                if (trueValues.length) {
                    if (trueValues.includes(item[objKey])) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isRecordType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as RecordType).op || !(filterObj[objKey] as RecordType).quantity) {
                    return;
                } else {
                    const { op, quantity } = (filterObj[objKey] as RecordType);
                    const result = makeComparison(op, Number(item[objKey]), Number(quantity));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isSizeType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as SizeType).op || !(filterObj[objKey] as SizeType).size) {
                    return;
                } else {
                    const { op, size } = (filterObj[objKey] as SizeType);
                    const result = makeComparison(op, item[objKey], Number(size));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            }
        })
        if (doesNotSatisfy.length) {
            return false;
        } else {
            return true;
        }
    });



    if (searchInput !== undefined) {
        const searchedArr = arr.filter(f => getLabel(f.name).includes(searchInput));
        setFilteredItems(searchedArr);
    } else {
        setFilteredItems(arr);
    }

    
}


export const filterItems = (filterObj: FilterType, items: any[]) => {
    const arr = items.filter((item) => {
        let doesNotSatisfy: string[] = [];
        Object.keys(filterObj).forEach((key) => {   
            const objKey: keyof object = key as keyof object;
            if (Array.isArray(filterObj[objKey])) {
                if (!(filterObj[objKey] as string[]).length) {
                    return;
                } else if ((filterObj[objKey] as string[]).includes(item[objKey])) {
                    return;
                } else {
                    doesNotSatisfy.push(objKey);
                } 
            } else if (isAccessType(filterObj[objKey])) {
                
                const trueValues = Object.keys(filterObj[objKey]).filter(k => ((filterObj[objKey] as AccessType)[k as keyof AccessType]) === true);
                if (trueValues.length) {
                    if (trueValues.includes(item[objKey])) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isRecordType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as RecordType).op || !(filterObj[objKey] as RecordType).quantity) {
                    return;
                } else {
                    const { op, quantity } = (filterObj[objKey] as RecordType);
                    const result = makeComparison(op, Number(item[objKey]), Number(quantity));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            } else if (isSizeType(filterObj[objKey])) {
                if (!filterObj[objKey]) {
                    return;
                } else if (!(filterObj[objKey] as SizeType).op || !(filterObj[objKey] as SizeType).size) {
                    return;
                } else {
                    const { op, size } = (filterObj[objKey] as SizeType);
                    const result = makeComparison(op, item[objKey], Number(size));
                    if (result) {
                        return;
                    } else {
                        doesNotSatisfy.push(objKey);
                    }
                }
            }
        })
        if (doesNotSatisfy.length) {
            return false;
        } else {
            return true;
        }
    });



    return arr;
    
};


export const sortByColumn = (value: string, headers: Header[], dynamicKeys: boolean, items: any[], setHeaders?: Dispatch<SetStateAction<Header[]>>) => {
    
    const updatedHeaders = headers.map(h => {
        if (h.value === value) {
            return { ...h, isAscending: !h.isAscending }
        } else {
            return { ...h }
        }
    });

    setHeaders && setHeaders(updatedHeaders);
  

    const headerObj = setHeaders ? updatedHeaders.find(h => h.value === value) : headers.find(h => h.value === value);


    if (headerObj) {
        const orderedItems = changeItemOrder(headerObj.isAscending, value, dynamicKeys, items);

        return orderedItems;
    } else {
        return items;
    }
};


const changeItemOrder = (isAscending: boolean, label: string, dynamicKeys: boolean, allItems: any[]) => {
    let key = '';

    if (dynamicKeys) {
        key = label;
    } else {
        key = label.toLocaleLowerCase().split(' ').join('_');
    }

    
    const ascending = [...allItems].sort((a,b) => {
        let aVal: number | string | Date = 0;
        let bVal: number | string | Date = 0;

        if (key === 'date_created') {
            aVal = new Date(a[key as keyof object] as string);
            bVal = new Date(b[key as keyof object] as string);
        } else {
            let numA = parseFloat(a[key]);
            let numB = parseFloat(b[key]);

            if (isNaN(numA) || isNaN(numB)) {

                aVal = a[key].toLocaleString().toLocaleLowerCase();
                bVal = b[key].toLocaleString().toLocaleLowerCase();
            } else {
                aVal = numA;
                bVal = numB;
            }
        }

        if (aVal < bVal) {
            return -1;
        } else if (aVal > bVal) {
            return 1;
        } else {
            return 0;
        }


        
    })

    const descending = [...allItems].sort((a,b) => {
        let aVal: number | string | Date = 0;
        let bVal: number | string | Date = 0;

        if (key === 'date_created') {
            aVal = new Date(a[key as keyof object] as string);
            bVal = new Date(b[key as keyof object] as string);
        } else {
            let numA = parseFloat(a[key]);
            let numB = parseFloat(b[key]);

            if (isNaN(numA) || isNaN(numB)) {
                aVal = a[key].toLocaleString().toLocaleLowerCase();
                bVal = b[key].toLocaleString().toLocaleLowerCase();
            } else {
                aVal = numA;
                bVal = numB;
            }
        }

        if (aVal > bVal) {
            return -1;
        } else if (aVal < bVal) {
            return 1;
        } else {
            return 0;
        }
        
        
    });
    

    if (isAscending) {
        return ascending;
        
       
    } else {
        return descending;
        
    }
};


export const searchItems = (searchInput: string, items: any[], identifier?: string) => {
    let searchedArr: any[] = [];

    if (identifier) {
        searchedArr = items.filter(f => (f[identifier]).toLocaleString().toLocaleLowerCase().includes(searchInput.toString().toLocaleLowerCase()));
    } else {
        searchedArr = items.filter(f => (f.name).toLocaleString().toLocaleLowerCase().includes(searchInput.toString().toLocaleLowerCase()));
    }

    return searchedArr;
};



export const cleanFileName = (name: string) => {
    return name.replaceAll('?', '_').replaceAll('/','_').replaceAll('%', '_percent_').replaceAll('#', '_').trim()
}

export const getDatedFilename = (prefix: string, ext: string) => {
    const now = new Date();
    return prefix + '-' + now.toISOString().substring(0, 19) + '.' + ext;
}

export const download = (filename: string, data: any) => {
    let opt;
    const format = filename.split('.')[1].toLowerCase();
    if (format === 'xlsx') opt = { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' };
    else if (format === 'csv') opt = { type: 'text/csv' };
    else if (format === 'sdf') opt = { type: 'chemical/x-mdl-sdfile' };
    else if (format === 'zip') opt = { type: 'application/zip' };
    else opt = { type: 'text/plain' };
  
    let blob = new Blob([data], opt); // pass a useful mime type here
    let url = URL.createObjectURL(blob);
  
    let element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);
  }

  export const filesExists = (id: string, files: FileType[]) => {
    const file = files.find(f => f._id.$oid === id);
    if (file) {
        return `/files/${id}`
    } else {
        return undefined;
    }
};

export const modelExists = (name: string, models: Model[]) => {
    const model = models.find(m => m.name === name);
    if (model) {
        return `/models/${name}`
    } else {
        return undefined;
    }
}

export const getFileName = (id: string, files: FileType[]) => {
    const file = files.find(f => f._id.$oid === id);
    if (file) {
        return file.name;
    } else {
        return 'No file name'
    }
};

export const dsExists = (id: string, datasets: Dataset[]) => {
    const ds = datasets.find(f => f._id.$oid === id);
    if (ds) {
        return `/datasets/${id}`
    } else {
        return undefined;
    }
};

export const isAdmin = (auth: AuthUser) => {
    const adminField = auth.privileges.find(p => p.value === 'admin');
    if (adminField) {
        return true
    } else {
        return false
    }
}

export const isOwner = (auth: AuthUser, ownerID: string) => {
    if (auth.user._id.$oid === ownerID) {
        return true;
    } else {
        return false;
    }
}

export const getOwnerName = (ownerID: string, users: User[]) => {
    const user = users.find(u => u._id.$oid === ownerID);
    if (user) {
        return user.username;
    } else {
        return 'No owner'
    }
}

export const getThresholdValue = (numArr: number[], threshold: number) => {
    if (numArr === undefined) {
        return { color: '#ffffff', label: 'N/A' }
    }
    const inactive = numArr[0];
    const active = numArr[1];

    if (inactive < threshold && active < threshold) {
        return { color: '#e0e0e0', label: 'Out of Domain' }
    } else if (inactive > threshold && active > threshold) {
        return { color: '#FFC061', label: 'Inconclusive' }
    } else if (inactive > threshold && active < threshold) {
        return { color: '#FF8484', label: 'Inactive' }
    } else {
        return { color: '#7BC960', label: 'Active' }
    }
}

const getLabel = (label: any) => {
    if (label && typeof label === 'string') {
        return label.toLocaleLowerCase()
    } else if (label) {
        return label.toLocaleString();
    } else {
        return '';
    }
}

export const searchByIdentifier = (records: any[], headers: Header[], fields_mapping: FieldMappingType[], searchInput: string) => {
    const identifierObj = headers.find(header => header.isRequired && header.label !== "Structure");
    if (identifierObj && fields_mapping.length) {

        return records.filter(f => getLabel(f[identifierObj.label]).includes(searchInput))
        
    } else {
        return records;
    }
}

export const getIdentifier = (nonEmptyKeys: string[], fields_mapping: FieldMappingType[]) => {

    let identifier = '';

    const nameField = fields_mapping.find(f => f.type === 'chem-name');
    const idField = fields_mapping.find(f => f.type === 'chem-id');

  
    if (!fields_mapping.length || (!nameField && !idField)) {
        identifier = ''

    } else if (nameField && nameField.name) {
        identifier = nameField.name;
    } else if (idField && idField.name) {
        identifier = idField.name
    } else {
        identifier = ''
    }
    // } else if (!nonEmptyKeys.includes(nameField!.name) || !nameField) {
    //     if (nonEmptyKeys.includes(idField!.name)) {
    //       identifier = idField!.name;
    //     } else {
    //       identifier = ''
    //     }
    // } else if (!nonEmptyKeys.includes(idField!.name) || !idField) {
    //     if (nonEmptyKeys.includes(nameField.name)) {
    //       identifier = nameField.name;
    //     } else {
    //       identifier = ''
    //     }
    // } else if (nameField && idField) {
    //     if (nonEmptyKeys.includes(nameField.name)) {
    //         identifier = nameField.name
    //     } else if (nonEmptyKeys.includes(idField.name)) {
    //         identifier = idField.name
    //     } else {
    //         identifier = ''
    //     }
    // }

    return identifier;
} 

export const getStringValue = (value: any) => {
    if (typeof value === 'string') {
        return value.split(' ').join('_'); 
    } else {
        return value;
    }
}

export const orderByDate = (items: any[]) => {
    const ordered = items.sort((a,b) => {
        if ((new Date(a.date_created as string)) > (new Date(b.date_created as string))) {
            return -1
        } else if ((new Date(a.date_created as string)) < (new Date(b.date_created as string))) {
            return 1;
        } else {
            return 0;
        }
    });

    return ordered;
};

export const convertDecimal = (value: number | string) => {
    return (Math.round(Number(value) * 100) / 100 as number).toFixed(2);
}

