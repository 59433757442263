import { useState } from "react";

const useMessage = () => {
    const [ open, setOpen ] = useState(false);
    const [ message, setMessage ] = useState('');
    const [ isSuccess, setIsSuccess ] = useState(false);

    const setPopupMessage = (popupMessage: string, isSuccessMessage: boolean) => {
        setOpen(true);
        setMessage(popupMessage);
        setIsSuccess(isSuccessMessage)

    }

    return {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    }
}

export default useMessage;