import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FlowCard from "../../../../components/Data/FlowCard";
import Field from "../../../../components/Form/Field";
import useCreateDatasetHandlers from "../../../../hooks/handlers/useCreateDatasetHandlers";
import { FieldMappingForm, FieldMappingType } from "../../../../types/all.types";
import { FileType } from "../../../../types/files.types";
import Select from 'react-select';
import { CreateDatasetForm } from "../../../../types/datasets.types";


const modelOptions = [
    {value: 'classification', label: 'Classification'},
    {value: 'regression', label: 'Regression'},
]

const classificationOptions = [
    {value: 'single-class-label', label: 'Single class'},
    {value: 'split-on-value', label: 'Split-on'}
]

const quantifierOptions = [
    {value: 'lt', label: '<' },
    {value: 'le', label: '<='},
    {value: 'gt', label: '>'},
    {value: 'ge', label: '>='}
];

export default function CreateDataset({number, selectedFile, form, setForm, fieldsMapping, setFieldsMapping, activityColumn, setActivityColumn }: {
    number: number, 
    selectedFile: FileType | null,
    form: CreateDatasetForm,
    setForm: Dispatch<SetStateAction<CreateDatasetForm>>,
    fieldsMapping: FieldMappingForm,
    setFieldsMapping: Dispatch<SetStateAction<FieldMappingForm>>,
    activityColumn: string,
    setActivityColumn: Dispatch<SetStateAction<string>>,
    
}) {

    const {
        handleCheck,
        handleInput,
        handleMultiSelect,
        handleSelect,
        setThreshold,
        selectValueType,
        setQuantifier,
        selectModelType,

        fieldOptions,

        isAuto,
        modelType,
        valueType,
    } = useCreateDatasetHandlers({ form, setForm, fieldsMapping, setFieldsMapping, activityColumn, setActivityColumn });

    const [ options, setOptions ] = useState<{value: string, label: string}[]>([]);

    useEffect(() => {
        if (fieldOptions.length > 0) {
            setOptions(fieldOptions);
        }
    }, [fieldOptions]);


    return (
        <FlowCard label="Generate Dataset" number={number} isLocked={selectedFile ? false : true}>
            <div className="flex flex-col gap-6 justify-start items-stretch">
                <Field label='Dataset Name' isRequired={true}>
                    <div className=' w-full flex justify-between items-stretch rounded'>
                        <input onChange={handleInput} name='dataset_name' value={form.name} className='border-l border-t border-b border-primary  rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='Dataset Name'/>
                        <label className={`px-3 border ${isAuto ? 'border-secondary' : 'border-primary'} cursor-pointer flex items-center gap-2 rounded-tr rounded-br relative`}>
                            {isAuto && <div className="absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10" />}
                            <input onChange={handleCheck} type='checkbox' checked={isAuto} className='accent-secondary'/>
                            <p className={`text-[12px] ${isAuto ? 'font-medium text-secondary' : 'font-regular text-primary'}`}>Auto-generate</p>
                        </label>
                    </div>
                </Field>

                <div className='flex justify-between items-center gap-8'>
                    <div className='w-1/2'><Field label='Chemical Name' isRequired={false}>
                        <Select isClearable={true} maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'chem-name')} options={options} value={fieldsMapping["chem-name"] && fieldsMapping["chem-name"].name && {value: fieldsMapping["chem-name"].name, label: fieldsMapping["chem-name"].name}} className='text-left text-[14px] rounded w-full' placeholder='Chemical Name'/>
                    </Field></div>
                    <div className='w-1/2'><Field label='Record ID' isRequired={false}>
                        <Select isClearable={true} maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'chem-id')} value={fieldsMapping["chem-id"] && fieldsMapping["chem-id"].name && {value: fieldsMapping["chem-id"] && fieldsMapping["chem-id"].name, label: fieldsMapping["chem-id"] && fieldsMapping["chem-id"].name}} options={options} className='text-left text-[14px] rounded w-full' placeholder='Record ID'/>
                    </Field></div>
                </div>

                <div className='flex justify-between items-start gap-8'>
                    <div className='w-1/2'><Field label='Relevant Data (Non-Activity)' isRequired={false}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} isMulti onChange={(e:any) => handleMultiSelect(e)} value={fieldsMapping['non-activity'] && (fieldsMapping['non-activity'] as FieldMappingType[]).map((act: FieldMappingType) => ({value: act.name, label: act.name}))} options={options} className='text-left text-[14px] rounded w-full' placeholder='Relevant Data'/>
                    </Field></div>
                    <div className='w-1/2'><Field label='Activity Column' isRequired={true}> 
                        <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => setActivityColumn(e.value)} value={activityColumn && {value: activityColumn, label: activityColumn}} options={options} className='text-left text-[14px] rounded w-full' placeholder='Activity Column'/>
                    </Field></div>
                </div>

                <div className='flex justify-between items-center gap-8'>
                    <div className='w-1/2'><Field label='Model Type' isRequired={true}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} options={modelOptions} value={modelType && {value: modelType, label: modelType}} onChange={(e:any) => selectModelType(e)} className='text-left text-[14px] rounded w-full' placeholder='Model Type'/>
                    </Field></div>
                    <div className='w-1/2'><Field label='Value Type' isRequired={true}>
                        {!modelType ? (
                            <input disabled placeholder="Value Type" value='' className='text-left text-[14px] p-2 grow rounded border border-quaternary/[0.3] w-full bg-white'/>
                        ) : modelType === 'regression' ? (
                            <input disabled value="Continuous" className='text-left text-[14px] p-2 grow rounded border border-quaternary/[0.3] w-full bg-white text-quaternary/[0.3]'/>
                        ) : (
                            <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => selectValueType(e)} value={{value: valueType, label: valueType}} options={classificationOptions} className='text-left text-[14px] rounded w-full' placeholder='Value Type'/>
                        )}
                    </Field></div>
                </div>

                {modelType === 'regression' && (
                    <Field label='Quantifier' isRequired={false}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => setQuantifier(e)} options={[{value: 'log', label: 'Log'}, {value: '', label: 'None'}]} className='text-left text-[14px] rounded w-full' placeholder='None'/>
                    </Field>
                )}

                {modelType === 'classification' && valueType === 'split-on-value' && (
                    <div className='flex justify-between items-center gap-8'>
                        <div className="w-1/4">
                            <Field label="Quantifier" isRequired={true}>
                                <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} options={quantifierOptions} onChange={(e:any) => setQuantifier(e)} className='text-left text-[14px] rounded w-full' placeholder='Quantifier' />
                            </Field>
                        </div>
                        <div className="w-3/4">
                            <Field label="Threshold" isRequired={true}>
                                <input onChange={setThreshold} value={fieldsMapping['split-on-value'] ? (fieldsMapping['split-on-value'] as FieldMappingType).value! : ''}  name='threshold' placeholder="Threshold" className='text-left text-[14px] p-2 grow rounded border border-primary w-full bg-white text-primary'/>
                            </Field>
                        </div>
                    </div>
                )}
            </div>
        </FlowCard>
    )
}