import { AuthUser, AddEditUserForm } from "../../../types/users.types";

import Field from "../../../components/Form/Field";
import Eye from '../../../assets/eye.svg';
import EyeOff from '../../../assets/eye-off.svg';
import Active from '../../../assets/circle-check.svg';
import Inactive from '../../../assets/circle-x.svg';

import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import Toggle from "../../../components/Form/Toggle";
import Select from 'react-select';
import API from '../../../api';
import md5 from "md5";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";

const privilegeOptions = [
    {value: 'admin', label: 'Administrator'},
    {value: 'train', label: 'Train Models'},
    {value: 'create', label: 'Create Files and Datasets'},
    {value: 'predict', label: 'Make Predictions'}
]

const defaultForm = {
    full_name: '',
    username: '',
    password: '',
    password2: '',
    email: '',
    company: '',
    active: true,
    privileges: [],
}

const permissions = {
    admin: {
        color: '#47AD24',
        label: 'Administrator',
    },
    train: {
        color: '#2429AD',
        label: 'Train Models'
    },
    predict: {
        color: '#AD248E',
        label: 'Make Predictions'
    },
    create: {
        color: '#FFA461',
        label: 'Create Files & Datasets'
    }
    
}

const getLabel = (value: string) => {
    const opt = privilegeOptions.find(p => p.value === value);
    if (opt) {
        return opt.label
    }
}

export default function ProfileDetails({ authUser }: { authUser: AuthUser | null }) {
    const [ isAdd, setIsAdd ] = useState(false);
    const [ form, setForm ] = useState<AddEditUserForm>(defaultForm);
    const [ show, setShow ] = useState(false);
    const [ show2, setShow2 ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);

    const theme = useSelector(selectTheme);

    const toggleAdd = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsAdd(!isAdd);
    }

    useEffect(() => {
        if (authUser) {
            const { full_name, username, email, company, privileges, active } = authUser.user;
            setForm({
                full_name,
                username,
                email,
                company,
                privileges,
                active,
                password: '',
                password2: '',
            })
        } else {
            setForm(defaultForm);
        }
    }, [authUser])
    

    const togglePassword = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShow(!show);
    }

    const togglePassword2 = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShow2(!show2);
    }

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.checked})
    }

    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }



    const handleSelect = (e:any, name: string) => {
        const values: string[] = e.map((val:any) => val.value);
        const currentPrivileges = form.privileges;
        if (currentPrivileges.includes('admin')) {
            setForm({...form, [name]: values.filter(v => v !== 'admin')});
        } else if (values.includes('admin') && !currentPrivileges.includes('admin')) {
            setForm({...form, [name]: ['admin']});
        } else {
            setForm({...form, [name]: values});
        }
    }

    const editUser = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);
        if (form.password2 !== form.password) {
            setErrorMessage('Passwords do not match');
            return;
        } else {

            const body = {
                username: form.username,
                password_hash: md5(form.password),
                full_name: form.full_name,
                email: form.email,
                company: form.company,
                active: form.active,
                privileges: form.privileges,
            }

            const authKey = localStorage.getItem('X-Auth');

            if (authKey) {
                    try {
                        const response = await API.put(`users/${authUser!.user._id.$oid}`, body, { headers: { 'X-Auth': authKey } });
                        const data = await response.data;
                        console.log(data);
                        setErrorMessage('');
                    } catch (err:any) {
                        setErrorMessage(err.data.detail)
                    }
             
            }

            setIsLoading(false);
            setIsDisabled(false);
            toggleAdd(e);
        }
        
    }

    return (
        <>
        {authUser && (
            <div className="mb-16">
                <p className='text-[1.5rem] font-semibold text-nowrap mb-6'>Profile Details</p>
                <div className="w-full flex gap-6 justify-between items-stretch">
                    <div className={`${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default rounded grow flex flex-col justify-start items-start max-w-[900px]`}>
                        {/* <div className="p-6 w-full">
                            <p className='text-[1.5rem] font-semibold text-nowrap text-primary'>Profile Details</p>
                        </div> */}

                        <div className="w-full flex justify-between items-start px-6 py-8">
                            <div className="flex flex-col gap-6 justify-start items-stretch w-full">

                            <Field label='Full Name' isRequired={isAdd ? true : false}>
                                    <div className="w-full">
                                        {isAdd ? (
                                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                                <input onChange={handleInput} name='full_name' value={form.full_name}  className={`rounded text-left text-[14px] p-2 grow`} placeholder='Full Name'/>
                                            </div>
                                        ) : (
                                            <p className="text-[16px] font-medium">{authUser.user.full_name}</p>
                                        )}
                                        

                                    </div>
                                </Field>

                                <Field label='Username' isRequired={isAdd ? true : false}>
                                    <div className="w-full flex justify-between items-stretch gap-2">
                                        {isAdd ? (
                                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                                <input onChange={handleInput} name='username' value={form.username}  className={`text-left text-[14px] p-2 grow rounded`} placeholder='Username'/> 
                                            </div>
                                        ) : (
                                            <p className="text-[16px] font-medium">{authUser.user.username}</p>
                                        )}
                                    </div>
                                </Field>

                                <Field label='Email' isRequired={isAdd ? true : false}>
                                    <div className="w-full flex justify-between items-stretch gap-2">
                                        {isAdd ? (
                                        <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                            <input onChange={handleInput} name='email' value={form.email} type='email' className={`text-left text-[14px] p-2 grow rounded`} placeholder='Email'/>
                                        </div>
                                        ) : (
                                            <p className="text-[16px] font-medium">{authUser.user.email}</p>
                                        )}
                                    </div>
                                </Field>
                                
                                {isAdd && (
                                    <Field label='Password' isRequired={true}>
                                    <div className="w-full flex justify-between items-start gap-2">
                                        <div className="flex flex-col gap-4 justify-start items-start w-full">
                                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                                <input onChange={handleInput} name='password' value={form.password}  type={show ? 'text' : 'password'} className={`rounded-tl rounded-bl text-left text-[14px] p-2 grow ${isAdd && 'rounded-tr rounded-br'}`} placeholder='New Password'/>
                                                <div>
                                                <button onClick={togglePassword} className="flex items-center px-3 h-full bg-white rounded-tr rounded-br">
                                                    {show ? <img src={EyeOff} alt='hide-password-icon'/> : <img src={Eye} alt='show-password-icon'/>}  
                                                </button>
                                                </div>
                                            </div>

                                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                                <input onChange={handleInput} name='password2' value={form.password2}  type={show2 ? 'text' : 'password'} className={`rounded-tl rounded-bl text-left text-[14px] p-2 grow ${isAdd && 'rounded-tr rounded-br'}`} placeholder='Confirm Password'/>
                                                <div>
                                                <button onClick={togglePassword2} className="flex items-center px-3 h-full bg-white rounded-tr rounded-br">
                                                    {show2 ? <img src={EyeOff} alt='hide-password-icon'/> : <img src={Eye} alt='show-password-icon'/>}  
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                    
                                    </div>
                                    </Field>
                                )}

                                <Field label='Company' isRequired={isAdd ? true : false}>
                                <div className="w-full flex justify-between items-stretch gap-2">
                                    {isAdd ? (
                                        <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                            <input onChange={handleInput} name="company" value={form.company}  className={`rounded text-left text-[14px] p-2 grow`} placeholder='Company'/>
                                        </div>
                                    ) : (
                                        <p className="text-[16px] font-medium">{authUser.user.company}</p>
                                    )}
                                    
                                </div>
                                </Field>

                                <Field label='Privileges' isRequired={isAdd ? true : false}>
                                <div className="w-full flex justify-between items-stretch gap-2">
                                    {isAdd ? (
                                        <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                            <Select onChange={(e:any) => {handleSelect(e, 'privileges')}} isMulti options={privilegeOptions} value={form.privileges.map(p => ({value: p, label: getLabel(p)}))} className={`no-border text-left text-[14px] grow rounded`} placeholder='Privileges'/>
                                        </div>
                                    ) : (
                                        <div className="flex flex-wrap justify-start items-center gap-3">
                                        {authUser.user.privileges.map(p => {
                                            const { color, label } = permissions[p as keyof object];
                                            return (
                                            <div style={{color: `${color}`, borderColor: `${color}`}} className='rounded border px-4 py-2'>
                                                <p className='font-medium text-[14px] text-nowrap'>{label}</p>
                                            </div>
                                            )
                                        })}
                                        </div>
                                    )}
                                    
                                </div>
                                </Field>

                                {isAdd && (
                                <Field label='Active Status' isRequired={true}>
                                <div className="w-full flex justify-start items-stretch gap-2">
                                    <div className='rounded border border-primary flex justify-between items-stretch'>
                                        <div className='flex justify-start items-center gap-2 py-3 px-3 bg-white grow rounded'>
                                            <p className="text-[14px]">{form.active ? 'Active' : 'Inactive'}</p>
                                            <Toggle handleCheck={handleCheck} name="active" isChecked={form.active}/>
                                        </div>
                                        
                                    </div>

                                </div>
                                </Field>
                                )}

                                {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                            </div>
                            
                        </div>
                        {/* <div className="flex gap-3 justify-end items-center w-full p-4 border-t border-quaternary/[0.3]">
                            <button disabled={isDisabled} onClick={(e:any) => {isAdd ? editUser(e) : toggleAdd(e)}} className="flex justify-center items-center gap-3 py-2 px-4 rounded bg-tertiary hover:bg-secondary disabled:bg-quaternary/[0.3] transition-all text-white whiten">
                                {isLoading ? (
                                    <CircularProgress size={18} color="inherit"/>
                                ) : (
                                    <img className="w-[18px]" src={isAdd ? SaveIcon : EditIcon} alt="edit-icon" />
                                )}                     
                                <p className="font-medium">{isAdd ? 'Save Changes' : 'Edit Profile'}</p>
                            </button>
                            {isAdd && (
                                <button className="py-2 px-4 border rounded border-quaternary text-quaternary font-medium hover:bg-quaternary hover:text-white transition-all" onClick={toggleAdd}>Cancel</button>
                            )}
                        </div> */}
                    </div>
                    <div className="drop-shadow-default grow max-h-[500px]">
                        <div className="w-full h-full relative bg-[url('https://st4.depositphotos.com/9998432/27431/v/450/depositphotos_274313380-stock-illustration-person-gray-photo-placeholder-woman.jpg')] bg-cover bg-center">
                            <img src={authUser.user.active ? Active : Inactive} alt='user-status' className="w-[64px] absolute bottom-[12px] right-[12px]" />
                        </div>
                            {/* <div className="w-[300px] relative">
                                <img src={Placeholder} className="w-full object-cover" alt='profile-img-placeholder' />
                                <img src={authUser.user.active ? Active : Inactive} alt='user-status' className="w-[64px] absolute bottom-[12px] right-[24px]" />
                            </div> */}
                    </div>
                </div>
            </div>
        )}
        </>
    )
}