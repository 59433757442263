import { createSlice } from "@reduxjs/toolkit";
import { CompleteModel, Model } from "../../types/models.types";

export interface ModelState {
    models: Model[];
    classificationModels: Model[];
    regressionModels: Model[];
    completeModels: CompleteModel[];
    selectedModel: CompleteModel | null,
}

const initialState: ModelState = {
    models: [],
    classificationModels: [],
    regressionModels: [],
    completeModels: [],
    selectedModel: null,
}

export const modelSlice = createSlice({
    name: 'models',
    initialState,
    reducers: {
        getModels: (state, action) => {
            state.models = action.payload;
        },
        getCompleteModels: (state, action) => {
            state.completeModels = action.payload;
        },
        setClassificationModels: (state, action) => {
            state.classificationModels = action.payload;
        },
        setRegressionModels: (state, action) => {
            state.regressionModels = action.payload;
        },
        setSelectedModel: (state, action) => {
            state.selectedModel = action.payload;
        },
        updateExistingModels: (state, action) => {
            state.models = state.models.map(model => {
                if (model.name === (action.payload as Model).name) {
                    return {...action.payload}
                } else {
                    return {...model}
                }
            })
        },
        updateModelsByType: (state, action) => {
            const { isClassification, model } = action.payload as { isClassification: boolean, model: Model };
            if (isClassification) {
                state.classificationModels = state.classificationModels.map(m => {
                    if (m.name === model.name) {
                        return {...model}
                    } else {
                        return {...m}
                    }
                })
            } else {
                state.regressionModels = state.regressionModels.map(m => {
                    if (m.name === model.name) {
                        return {...model}
                    } else {
                        return {...m}
                    }
                })
            }
        },
        deleteExistingModels: (state, action) => {
            state.models = state.models.filter(file => !action.payload.includes(file.name));
        },
        deleteModelsByType: (state, action) => {
            const { isClassification, name } = action.payload as { isClassification: boolean, name: string };
            if (isClassification) {
                state.classificationModels = state.classificationModels.filter(m => m.name !== name);
            } else {
                state.regressionModels = state.regressionModels.filter(m => m.name !== name);
            }
        }
    }
})

export const { 
    getModels, 
    deleteExistingModels, 
    setSelectedModel, 
    updateExistingModels, 
    getCompleteModels,
    setClassificationModels,
    setRegressionModels,
    updateModelsByType,
    deleteModelsByType,
} = modelSlice.actions;

export const modelsReducer = modelSlice.reducer;
