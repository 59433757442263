// hooks
import { useDropzone } from "react-dropzone";

// assets
import Empty from '../../assets/empty-upload.svg';
import CSV from '../../assets/csv.svg';
import SDF from '../../assets/sdf.svg';
import XLSX from '../../assets/xlsx-file.png';


export default function FileDropzone({onDrop, uploadedFile}: {onDrop: (acceptedFiles: File[]) => void, uploadedFile: File | null}) {


      const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/csv': ['.csv'],
            'chemical/x-mdl-sdf': ['.sdf'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        maxFiles: 1,
        onDrop,
      });
      
    
      return (
        <div className='hover:cursor-pointer hover:border-secondary transition-all w-full bg-white rounded flex justify-center items-center p-10 border border-dashed border-quaternary text-[12px]' {...getRootProps()}>
          <input {...getInputProps()} />
              <div className='flex flex-col justify-center items-center gap-4'>
                {!uploadedFile ? (
                    <>
                        <img src={Empty} alt='empty-file-icon' className="w-[80px]"/>
                        <p>Drag and drop or Browse your files</p>
                    </>
                ) : uploadedFile.type === 'text/csv' ? (
                    <>
                        <img src={CSV} alt='csv-file-icon' className="w-[80px]"/>
                        <p className="max-w-[400px] text-center break-words">{uploadedFile.name}</p>
                    </>
                ) : uploadedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                    <>
                        <img src={XLSX} alt='xlsx-file-icon' className="w-[80px]"/>
                        <p className="max-w-[400px] text-center break-words">{uploadedFile.name}</p>
                    </>
                ) : (
                    <>
                        <img src={SDF} alt='sdf-file-icon' className="w-[80px]"/>
                        <p className="max-w-[400px] text-center break-words">{uploadedFile.name}</p>
                    </>
                )}
              </div>
        </div>
      )
}