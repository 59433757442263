import FlowCard from "../../../components/Data/FlowCard"
import Field from "../../../components/Form/Field"
import Select from 'react-select';
import { ReadAcrossForm } from "../../../types/readacross.types";
import { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectDatasets } from "../../../store/selectors/datasets.selector";
import { Dataset } from "../../../types/datasets.types";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";

const neighborsOptions = [
    {value: 'ECFP', label: 'ECFP'},
    {value: 'MACCS', label: 'MACCS'}
];

const modelTypeOptions = [
    {value: 'Classification', label: 'Classification'},
    {value: 'Regression', label: 'Regression'}
]

const datasetOptions = [
    {value: 'ToxRef', label: 'ToxRef'},
    {value: 'AssayCentral', label: 'AssayCentral'}
]

export default function ReadAcrossSettings({ form, setForm, selectedDatasets, setSelectedDatasets, errorMessage }: {
    form: ReadAcrossForm, 
    setForm: Dispatch<SetStateAction<ReadAcrossForm>>,
    selectedDatasets: {value: string, label: string}[],
    setSelectedDatasets: Dispatch<SetStateAction<{value: string, label: string}[]>>,
    errorMessage: string | null,
}) {
    const datasets = useSelector(selectDatasets);
    const [datasetNameOptions, setDatasetNameOptions] = useState<{value: string, label: string}[]>([]);
    

    const handleSelect = (e:any, name: string) => {
        setForm({
            ...form,
            [name]: e.value,
        })
    };

    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }

    const handleDataset = (e:any) => {
        if (e.value === 'ToxRef') {
            setForm({
                ...form,
                toxref: true,
            })
            setSelectedDatasets([]);
        } else {
            setForm({
                ...form,
                toxref: false,
            })
        }
    };

    const handleMulti = (e:any) => {
        setSelectedDatasets(e);
    }

    useEffect(() => {
        if (!form.toxref && form.filter) {
            let classificationSets: Dataset[] = [];
            let regressionSets: Dataset[] = [];
    
            datasets.forEach(dataset => {
                const field = dataset.fields_mapping.find(f => ['continuous-value', 'single-class-label'].includes(f.type));
                if (field) {
                    if (field.type === 'continuous-value') {
                        regressionSets.push(dataset);
                    } else {
                        classificationSets.push(dataset)
                    }
                }
            })

            if (form.filter === 'Classification') {
                setDatasetNameOptions(classificationSets.map(set => ({value: set._id.$oid, label: set.name})))
            } else {
                setDatasetNameOptions(regressionSets.map(set => ({value: set._id.$oid, label: set.name})))
            }


        } else {
            setDatasetNameOptions([]);
        }
        
    }, [form, datasets]);

    return (
            <FlowCard label="Set Result Parameters" number={2} isLocked={false} isKetcher={false}>
                <form id='readacross-form' className="flex flex-col gap-6 justify-start items-stretch">
                    <Field label='Result Name' isRequired={true}>
                        <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                            <input required name='result_name' onChange={handleInput} value={form.result_name} className='rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='Result Name'/>
                        </div> 
                    </Field>

                    <Field label='Neighbors' isRequired={true}>
                        <Select aria-label="neighbors" name="neighbors" required onChange={(e:any) => handleSelect(e, 'desc')} value={form.desc ? {value: form.desc, label: form.desc} : null} options={neighborsOptions} className='text-left text-[14px] rounded w-full' placeholder='Neighbors'/>
                    </Field>

                    <Field label='Model Type' isRequired={true}>
                        <Select aria-label="model_type" name='model_type' required onChange={(e:any) => handleSelect(e, 'filter')} value={form.filter ? {value: form.filter, label: form.filter} : null} options={modelTypeOptions} className='text-left text-[14px] rounded w-full' placeholder='Model Type'/>
                    </Field>

                    <Field label='Analog Number' isRequired={true}>
                        <input required onChange={handleInput} value={form.num_neighbors} name='num_neighbors' className="rounded text-left p-2 w-full border border-primary" placeholder="Analog Number"/>
                    </Field>

                    <Field label='Dataset Type' isRequired={true}>
                        <Select aria-label="dataset_type" name='dataset_type' required onChange={handleDataset} value={form.toxref ? datasetOptions[0] : datasetOptions[1]} options={datasetOptions} className='text-left text-[14px] rounded w-full' placeholder='Dataset Type'/>
                    </Field>

                    {form.filter && !form.toxref && (
                        <div className="mb-6">
                        <Field label='Dataset' isRequired={true}>
                            <Select aria-label="dataset_selection" name='dataset' isMulti required value={selectedDatasets.length ? selectedDatasets : null} onChange={handleMulti} options={datasetNameOptions} className='text-left text-[14px] rounded w-full' placeholder='Selected datasets'/>
                        </Field>
                        </div>
                    )}

                        {errorMessage && (
                            <ErrorMessage errorMessage={errorMessage} />
                        )}
                    
                </form>
            </FlowCard>
    )
}