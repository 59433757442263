import { Routes, Route, useNavigate } from "react-router-dom"
import Files from "./pages/Files"
import SingleFile from "./pages/SingleFile"
import NotFoundError from "../../components/Alerts/NotFoundError"
import { useSelector } from "react-redux";
import { selectAuth } from "../../store/selectors/auth.selector";
import { useEffect } from "react";

export default function FilesRoutes() {
    const authUser = useSelector(selectAuth);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!authUser) {
    //         navigate('/401');
    //     }
    // }, [authUser]);


    return (
        <Routes>
            <Route index element={<Files />} />
            <Route path=':id' element={<SingleFile />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}