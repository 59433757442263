import { SingleFileType } from "../types/files.types"


export const filesTableHeaders = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Project',
        value: 'project',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Measurement Type',
        value:'measurement_type',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Target',
        value: 'target',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Organism',
        value: 'organism',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Size',
        value: 'size',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Records',
        value: 'records',
        isRequired: false,
        isSelected: true,
        isAscending: true,
        isSortable: true,
    },
    {
        label: 'Access Type',
        value: 'access_type',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
        label: "Created By",
        value: 'created_by',
        isRequired: false,
        isSelected: false,
        isAscending: true,
        isSortable: true,
    },
    {
      label: 'Date Created',
      value: 'date_created',
      isRequired: false,
      isSelected: true,
      isAscending: true,
      isSortable: true,
    },
]

export const defaultFilesFilter = {
  project: [],
  measurement_type: [],
  target: [],
  organism: [],
  created_by: [],
  size: {
      op: '',
      size: '',
  },
  records: {
      op: '',
      quantity: '',
  },
  access_type: [],
}

export const defaultFilesFilterFields = {
  project: [],
  measurement_type: [],
  target: [],
  organism: [],
  created_by: [],
  size: [],
  records: [],
  access_type: [],
}

export const getOrderedFiles = (singleFile: SingleFileType) => {

    const nonEmptyKeys = Object.keys(singleFile.records[0].fields).filter(key => singleFile.records[0].fields[key]);

    let identifier = '';

    const nameField = singleFile.fields_mapping.find(f => f.type === 'chem-name');
    const idField = singleFile.fields_mapping.find(f => f.type === 'chem-id');

  
    if (!singleFile.fields_mapping.length || (!nameField && !idField)) {
        identifier = ''
    } else if (!nonEmptyKeys.includes(nameField.name) || !nameField) {
        if (nonEmptyKeys.includes(idField.name)) {
          identifier = idField.name;
        } else {
          identifier = ''
        }
    } else if (!nonEmptyKeys.includes(idField.name) || !idField) {
        if (nonEmptyKeys.includes(nameField.name)) {
          identifier = nameField.name;
        } else {
          identifier = ''
        }
    }

    let orderedFields: string[];

    if (identifier) {
        orderedFields = [identifier].concat(nonEmptyKeys.filter(f => f !== identifier));
    } else {
        orderedFields = nonEmptyKeys;
    }

    return { orderedFields, identifier };
} ;


export const defaultFile = {
  _id: {
     $oid: '12345'
  },
  acl: {
      access: 'private',
      owner: '',
      read: [],
      write: [],
  },
  done: false,
  fields: [],
  fields_mapping: [],
  file: {
      $oid: '',
  },
  metadata: {
      description: null,
      measurement_type:  null,
      name:  null,
      organism:  null,
      project:  null,
      target:  null,
  },
  name: 'file-name.csv',
  protocol: [],
  records: [],
  records_number: 0,
  size: 0,
}

export const mockSingleFile = {
  fields_mapping: [
    {
      "name": "dataset",
      "op": null,
      "type": "chem-name",
      "value": null
    }, 
    {
      "name": "curated_smiles",
      "op": null,
      "type": "chem-id",
      "value": null
    },
    {
      "name": "value",
      "op": "log",
      "type": "continuous-value",
      "value": null
    },
  ],
  records: [
    {
      fields: {
          curated_smiles: "Cc1ccccc1C(=O)Nc1ccc(C(=O)N2CCCNc3ccccc32)cc1",
          dataset: "chembl",
          kw_eq: "no_keywords",
          kw_eq_exclude: "lt gt",
          sample: 2,
          structure_group: 0,
          value: 6.6020599913279625,
          value_relation: "eq",
          value_units: "neglog(M)"
      },
      molecule: {
          $oid: "66439e310a76a9a00735e22c"
      },
      ord: 0
    }
  ],
  fields: [
    "dataset",
    "sample",
    "curated_smiles",
    "structure_group",
    "value_relation",
    "value",
    "value_units",
    "kw_eq",
    "kw_eq_exclude"
  ]
};
