// hooks
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';

// functions
import { setSelectedFile } from '../../../store/slices/files.slice';

// components
import Field from '../../../components/Form/Field';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FileDropzone from '../../../components/Data/FileDropzone';
import ErrorMessage from '../../../components/Alerts/ErrorMessage';
import WarningPopup from '../../../components/Popups/WarningPopup';
import useUploadFileHandlers from '../../../hooks/handlers/useUploadFileHandlers';
import FormButtons from '../../../components/Buttons/FormButtons';


const accessOptions = [
    {value: 'private', label: 'private'},
    {value: 'authenticated', label: 'authenticated'}
]

export default function UploadFile({
    togglePopup,
    isFlow,
    isDashboard,
    next,
    goBack,
    setPopupMessage,
} : {
    togglePopup: (e:any) => void,
    isFlow?: boolean,
    isDashboard?: boolean,
    next?: (e:MouseEvent<HTMLButtonElement>) => void,
    goBack?: (e:MouseEvent<HTMLButtonElement>) => void,
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,
}) {

    const dispatch = useDispatch();

    const {
        handleFileSubmit,
        handleWarning,
        handleCasList,
        handleCheck,
        handleInput,
        handleSelect,
        onDrop,

        changeFileType,
        toggleWarning,
        errorMessage,
        
        isAuto,
        isLoading,
        isDisabled,
        isWarningOpen,
        
        projects,
        headers,
        notReturned,
        casList,
        form,
        type,
        uploadedFile,

    } = useUploadFileHandlers({togglePopup, isFlow, isDashboard, next, setPopupMessage});


    const backFunction = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        goBack && goBack(e);
    };

    const cancelFunction = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        togglePopup(e); 
        dispatch(setSelectedFile(null))
    };

    const continueFunction = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        handleFileSubmit(e);
    }

    return (
        <>
           <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className={`bg-background rounded p-8 max-w-[600px] w-full popup-menu`}>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-center items-stretch'>        
                        <div className='w-full flex justify-between items-center'>
                            <p className='text-primary font-semibold text-[1.5rem]'>{type === "new-file" ? "Upload New File" : "Upload CAS List"}</p>
                            <div id='upload-file-toggle' className='flex justify-center items-center bg-white rounded'>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => { changeFileType(e, 'new-file') }} disabled={type === 'new-file' ? true : false} className={`${type === 'new-file' ? 'border-secondary text-secondary ' : 'border-quaternary text-quaternary'} border-t border-l border-b flex justify-center items-center px-5 py-3 rounded-tl rounded-bl relative`}>
                                    {type === 'new-file' && <div className='absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10' />}
                                    <p className={`${type === 'new-file' ? 'font-semibold' : 'font-medium'}`}>New File</p>
                                </button>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => { changeFileType(e, 'cas-list') }} disabled={type === 'cas-list' ? true : false} className={`${type === 'cas-list' ? 'border-secondary text-secondary ' : 'border-quaternary text-quaternary'} border flex justify-center items-center px-5 py-3 rounded-tr rounded-br relative`}>
                                    {type === 'cas-list' && <div className='absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10' />}
                                    <p className={`${type === 'cas-list' ? 'font-semibold' : 'font-medium'}`}>CAS List</p>
                                </button>
                            </div>
                        </div>

                        <Field label={type === 'new-file' ? 'Uploaded File' : 'CAS List'} isRequired={true} message={type === 'new-file' ? 'All files must have column headers to upload properly' : undefined}>
                            {type === "new-file" ? (
                                <FileDropzone onDrop={onDrop} uploadedFile={uploadedFile}/>
                            ) : (
                                <textarea aria-label='cas-list' onChange={handleCasList} value={casList} name='cas-list' className='text-left text-[14px] rounded border border-primary p-2 w-full min-h-[180px]' placeholder="Copy and paste your list of compounds here (CAS Numbers only)"/>
                            )}
                        </Field>

                        <Field label='File Name' isRequired={true}>
                            <div className='rounded w-full flex justify-between items-stretch'>
                                <input onChange={handleInput} name='file_name' value={form.file_name} className='border-l border-t border-b border-primary  rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='File Name'/>
                                <label className={`px-3 border ${isAuto ? 'border-secondary' : 'border-primary'} cursor-pointer flex items-center gap-2 rounded-tr rounded-br relative`}>
                                    {isAuto && <div className="absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10" />}
                                    <input onChange={handleCheck} type='checkbox' checked={isAuto} className='accent-secondary'/>
                                    <p className={`text-[12px] ${isAuto ? 'font-medium text-secondary' : 'font-regular text-primary'}`}>Auto-generate</p>
                                </label>
                            </div>
                            
                        </Field>

                        <Field label='Project' isRequired={true} message='Type to create a new project'>
                            <CreatableSelect aria-label='project' maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'project')} value={{value: form.project, label: form.project}} options={projects} className='text-left text-[14px] rounded w-full' placeholder='Project'/>
                        </Field>

                        <Field label='Description' isRequired={false}>
                            <textarea aria-label='description' onChange={handleInput} name='description' value={form.description}  className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='File Description'/>
                        </Field>

                        <div className='flex justify-between items-center gap-8'>
                            <div className='grow'><Field label='Organism' isRequired={true}>
                                <input onChange={handleInput} name='organism' value={form.organism} className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='Organism'/>
                            </Field></div>
                            <div className='grow'><Field label='Measurement Type' isRequired={true}>
                                <input onChange={handleInput} name='measurement_type' value={form.measurement_type} className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='Measurement Type'/>
                            </Field></div>
                        </div>

                        <div className='flex justify-between items-center gap-8'>
                            <div className='w-1/2'><Field label='Target' isRequired={true}>
                                <input onChange={handleInput} name='target' value={form.target} className='text-left text-[14px] rounded border border-primary p-2 w-full' placeholder='Target'/>
                            </Field></div>
                            <div className='w-1/2'><Field label='Access Type' isRequired={true}>
                                <Select aria-label='access_type' maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'access_type')} options={accessOptions} value={{value: form.access_type, label: form.access_type}} className='text-left text-[14px] rounded w-full' placeholder='Access'/>
                            </Field></div>
                        </div>

                        {type === 'new-file' && uploadedFile && headers.length !== 0 && (
                        <Field label='Structure Field' isRequired={true}>
                            <Select aria-label='smiles' maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'smiles')} options={headers} value={{value: form.smiles, label: form.smiles}} className='text-left text-[14px] rounded w-full' placeholder='Structure Field'/>
                        </Field>
                        )}

                        {errorMessage && (
                            <ErrorMessage errorMessage={errorMessage} />
                        )}

                        <FormButtons backFunction={goBack ? backFunction : undefined} continueFunction={continueFunction} cancelFunction={cancelFunction} isDisabled={isDisabled} isLoading={isLoading}/>
                        {isWarningOpen && <WarningPopup items={notReturned} togglePopup={toggleWarning} handleWarning={handleWarning}/>}
                        </div> 
                        </div>
                    </div>
                </div>
        </>
    )
}