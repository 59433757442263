import { createSlice } from "@reduxjs/toolkit";
import { ReadAcrossType } from "../../types/readacross.types";

export interface ReadAcrossState {
    readacross: ReadAcrossType[],
}

const initialState: ReadAcrossState = {
    readacross: [],
}

export const readacrossSlice = createSlice({
    name: 'readacross',
    initialState,
    reducers: {
        getReadacross: (state, action) => {
            state.readacross = action.payload;
        },
        addReadacross: (state, action) => {
            state.readacross = state.readacross.concat(action.payload)
        },
        updateExistingReadacross: (state, action) => {
            state.readacross = state.readacross.map(file => {
                if (file._id.$oid === (action.payload as ReadAcrossType)._id.$oid) {
                    return {...action.payload}
                } else {
                    return {...file}
                }
            })
        },
        deleteExistingReadacross: (state, action) => {
            state.readacross = state.readacross.filter(file => !action.payload.includes(file._id.$oid));
        }
    }
})

export const { getReadacross, addReadacross, deleteExistingReadacross, updateExistingReadacross } = readacrossSlice.actions;

export const readacrossReducer = readacrossSlice.reducer;
