import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { MenuItem } from '@mui/material';

import { PopupType } from '../../hooks/usePopup';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}
export default function SingleCompoundOptions({ togglePopup, moleculeID, addSingleItem, setPopupType, handleClose }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    moleculeID: string,
    addSingleItem: (id: string) => void,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
    handleClose: () => void,
}) {
   
    
    

    return (
        <>
            <MenuItem sx={sx} onClick={(e:any) => {togglePopup(e); setPopupType && setPopupType('readacross'); addSingleItem(moleculeID); handleClose()}}>Create Read-Across</MenuItem>
          
        </>
    )
}