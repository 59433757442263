import { useSelector } from 'react-redux';
import Logo from '../../assets/logo.jpg';
import LoginCard from './components/LoginCard';
import { selectApplication } from '../../store/selectors/auth.selector';


export default function Login() {
    const application = useSelector(selectApplication);

    return (
        <>
            <img src={Logo} className='w-[200px] absolute top-[8px] left-[8px]' alt='collaborations-pharma-logo'/>
            <div className='w-full h-screen bg-hero bg-cover flex justify-center items-center p-10'>
                <div className='p-[1px] bg-gradient-to-r from-tertiary to-primary rounded max-w-[500px] w-full'>
                    <LoginCard application={application}/>
                </div>
            </div>
        </>
    )
}