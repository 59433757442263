import { ReactElement, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import React from 'react';

import ChevronDown from '../../assets/chevron-down.svg';
import ChevronUp from '../../assets/chevron-up.svg';



export default function OptionsDropdownBase({ children, isLoading }: { 
    children: ReactNode,
    isLoading: boolean,
}) {


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    


    return (
        <div>
            <button disabled={isLoading} onClick={handleClick}>
                <div className={`text-white bg-tertiary ${isLoading ? 'hover:bg-tertiary' : 'hover:bg-secondary'}  transition-all whiten flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                    <p className='font-medium'>Options</p>
                    {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                    marginTop: '8px',
                    '& .MuiMenu-list': {
                        padding: 0,
                    }
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
                
            >
                { children = React.Children.map(children, el => {
                    return React.cloneElement(el as ReactElement, { handleClose })
                })}
            </Menu>
        </div>
           
    )
}