import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { DatasetState } from "../slices/datasets.slice";

const selectDatasetReducer = (state:RootState): DatasetState => state.datasets;

export const selectDatasets = createSelector(
    [selectDatasetReducer],
    (datasetSlice) => datasetSlice.datasets,
)

export const selectSingleDataset = createSelector(
    [selectDatasetReducer],
    (datasetSlice) => datasetSlice.selectedDataset,
)