import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../types/users.types";

export interface UserState {
    users: User[];
}

const initialState: UserState = {
    users: [],
}

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers: (state, action) => {
            state.users = action.payload;
        },
        addUser: (state, action) => {
            state.users = state.users.concat(action.payload);
        },
        deleteExistingUser: (state, action) => {
            state.users = state.users.filter(user => !action.payload.includes(user._id.$oid));
        },
        updateUser: (state, action) => {
            state.users = state.users.map(user => {
                if (user._id.$oid === (action.payload as User)._id.$oid) {
                    return {...action.payload}
                } else {
                    return {...user}
                }
            })
        }
    }
})

export const { getUsers, addUser, deleteExistingUser, updateUser } = userSlice.actions;

export const userReducer = userSlice.reducer;
