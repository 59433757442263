

import QuickSupport from "../components/QuickSupport";
import Tutorials from "../components/Tutorials";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import SearchIcon from '../../../assets/search.svg';
import { InputAdornment } from "@mui/material";

export default function TutorialsAndDocumentation() {
    const navigate = useNavigate();

    const options = [
        {title: 'Uploading files', page: 'uploading-files'},
        {title: 'Creating datasets', page: 'creating-datasets'},
        {title: 'Training models', page: 'training-models'},
        {title: 'Creating resultsets', page: 'creating-resultsets'},
        {title: 'Making single predictions', page: 'single-prediction'},
        {title: 'Generating readacross values', page: 'generating-readacross'},
        {title: 'Managing users', page: 'managing-users'}
    ]

    const goToPage = (e:any, option:string) => {
        const optionObj = options.find(opt => opt.title === option);
        if (optionObj) {
            navigate(`/help/${optionObj.page}`)
        }
    }
 

      


    return (
        <>
            
                <div className="w-full px-10 py-[100px] flex justify-center items-center bg-hero bg-top bg-cover">
                    <div className="flex flex-col max-w-[1200px] w-full px-10 justify-center items-center">
                        <p className="font-semibold text-[3rem] mb-6">Help Center</p>
                        <p className="max-w-[600px] text-[16px] text-center mb-8 font-medium">Maximize your productivity with our help. Explore our resources to get the most out of {process.env.REACT_APP_META_TITLE}.</p>
                        {/* <input className="w-full max-w-[600px] p-4 rounded-lg border border-tertiary" placeholder="Search"/> */}
                        <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            disableClearable
                            sx={{
                                width: '100%',
                                maxWidth: '600px',
                                backgroundColor: 'white',
                                border: '1px solid #006D77',
                                borderRadius: '5px',
                                fontFamily: 'Montserrat',
                                
                            }}
                            componentsProps={{
                                paper: {
                                  sx: {
                                    '& .MuiAutocomplete-listbox': {
                                      fontFamily: 'Montserrat, sans-serif',
                                    },
                                  },
                                },
                              }}
                            onChange={(e, option: string) => goToPage(e, option)}
                            options={options.map(option => option.title)}
                            renderInput={(params) => <TextField sx={{fontFamily: 'Montserrat, sans-serif'}} {...params} label="" placeholder="Search tutorials" InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <img src={SearchIcon} alt='search-icon' className="ml-2"/>
                                    </InputAdornment>
                                ),
                                type: 'search',
                                style: {fontFamily: 'Montserrat, sans-serif'}
                              }}/>}
                        />
                        
                    </div>
                </div>
                <QuickSupport />
                <Tutorials />
          
        </>
    )
}