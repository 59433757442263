import Overlay from "../../Popups/Overlay";
import ACLogo from '../../../assets/assay-central.svg';
import { MouseEvent } from "react";

import Shepherd, { PopperPlacement } from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';

export interface TourSteps {
    [key: string]: {
        target: string,
        content: string,
        placement: string | undefined,
        canClickTarget?: boolean,
        type?: 'button' | 'input',
    }[],
}

const steps: TourSteps = {
    dashboard: [
        {
            target: 'body',
            content: `
                    <div>
                        <h2>Task 1</h2>
                        <h1>Explore Your Dashboard</h1>
                    </div>
                    <p>Learn the essential features of your dashboard to help maximize your productivity.</p>
                `,
            placement: undefined,
        },
        {
            target: '#activity-chart',
            content: `<p>This is your <span>Activity Chart</span>, which keeps track of all the models and predictions you created over the past week, month or year.</p>`,
            placement: 'right',
        },
        {
            target: '#job-queue-card',
            content: `<p>The<span>Job Queue Card</span> displays updates from recent tasks that are running in the job queue. Clicking <span>View All</span> will redirect you to the job queue page.</p>`,
            placement: 'left',
        },
        {
            target: '#quick-actions',
            content: `<p>Access your most frequently used features with a single click. This section provides shortcuts to essential functions, helping you perform tasks efficiently and save time</p>`,
            placement: 'right',
        },
        {
            target: '#recent-models-and-predictions',
            content: `<p>These tables provide a quick glance at the most recent models and predictions you've created so you can pick up right where you left off.</p>`,
            placement: 'left',
        }
    ],

    files: [
        {
            target: 'body',
            content: `
                    <div>
                        <h2>Task 2</h2>
                        <h1>Upload A File</h1>
                    </div>
                    <p>This task will guide you through the process of uploading files into Assay Central for later use in training models and generating predictions.</p>
                `,
            placement: undefined,
        },
        {
            target: '#files-nav',
            content: `<p>Navigate to the Files page by clicking on this icon</p>`,
            placement: 'right',
            canClickTarget: true,
        },
        {
            target: 'body',
            content: `<p>The Files page contains all of the files that you have access to on Assay Central. This includes the files that you've previously created and files with the "authenticated" access type. Private files are only accessible by the user who created them.</p>`,
            placement: undefined,
        },
        {
            target: '#files-table',
            content: `<p>Files are the basis for training models and making predictions. The table allows you to search, sort and filter through your files based on their metadata. You can also select multiple files to delete or export, or perform certain actions on a specific file by clicking on the three dots at the end of its respective row.</p>`,
            placement: 'top',
        },
        {
            target: '#upload-file-button',
            content: `<p>Click on the Upload File button</p>`,
            placement: 'left',
            canClickTarget: true,
        },
        {
            target: '#upload-file-popup-window',
            content: `<p>Uploading files into Assay Central is a simple process that involves importing an SDF, CSV or XLSX file and specifying its metadata.</p>`,
            placement: 'left',
        },
        {
            target: '#upload-file-toggle',
            content: `<p>You can also choose to import a list of CAS numbers by clicking on the toggle located on the top-right corner of the popup window.</p>`,
            placement: 'left',
        },
        {
            target: '#cancel-file-upload',
            content: `<p>Once your file has been successfully uploaded, you can use it to create any number of models and predictions.</p>
                    <p>Click <span>Cancel</span> to close this window.</p>`,
            placement: 'left',
            canClickTarget: true,
        },
        {
            target: '.name-cell',
            content: `<p>Click on the name of this file to see more details.</p>`,
            placement: 'right',
            canClickTarget: true,
        },
        {
            target: 'body',
            content: `<p>Each file that is uploaded into Assay Central will have its own page displaying details about that file, including the molecular structure that was generated for each compound in that file.</p>
            <p>Similar to the files page, you can search, sort or filter through the list of compounds in a file and perform certain actions with them.</p>`,
            placement: undefined,
        },
        {
            target: '#single-file-header',
            content: `<p>You can also perform certain actions on the entire file or edit the metadata if you're the owner of the file.</p>`,
            placement: 'left',
        }
        
        
    ]
}



export default function BeginTour({ togglePopup }: {
    togglePopup: (e:any) => void,
}) {



    const initializeTour = (e: MouseEvent<HTMLButtonElement>, type: string) => {
        e.preventDefault();

         const newTour: Shepherd.Tour = new Shepherd.Tour({
            useModalOverlay: true,
            defaultStepOptions: {
              classes: 'custom-class-name', // Add custom classes
              scrollTo: true,
            },
        });

        startTour(e, newTour, type)
    }
    
   

    const startTour = (e: any, tour: Shepherd.Tour, type: string) => {

        if (tour) {        
            
            

            Array.from(steps[type as keyof object]).forEach(step => {
                const buttons = step.canClickTarget ? [] : [
                    {
                        text: 'Next',
                        action: tour.next,
                        classes: 'tour-next',
                    },
                ];

                tour.addStep({
                    text: step.content,
                    attachTo: {
                        element: step.target,
                        on: step.placement as PopperPlacement,
                    },
                    buttons,
                    classes: step.target === 'body' ? 'tour-box' : 'tutorial-box',
                    modalOverlayOpeningPadding: 20,
                    modalOverlayOpeningRadius: 5,
                    canClickTarget: step.canClickTarget ? true : false,
                    advanceOn: step.canClickTarget ? { selector: step.target, event: 'click' } : undefined,
                })
            });
    
            tour.start();
            tour.on('complete', ()  => initializeTour(e, 'files'));
        }

    }


   

    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className={`bg-white drop-shadow-modal rounded p-8 max-w-[400px] w-max flex flex-col gap-6 justify-center items-center`}>
                    <div className="flex justify-center items-center gap-2">
                        <img src={ACLogo} className="w-[44px]" alt='assay-central-logo' />
                        <p className="text-[2rem] font-semibold text-nowrap">Assay Central</p>
                    </div>
                    <p className="text-center font-medium">See how you can maximize Assay Central for building machine learning models.</p>
                    <div className="flex flex-col gap-4">
                        <button onClick={(e:any) => {togglePopup(e); initializeTour(e, 'dashboard') }} className="py-2 px-4 rounded bg-secondary text-white font-medium hover:bg-darken transition-all">Begin tour</button>
                        <button onClick={togglePopup} className="text-[12px] text-highlight font-semibold hover:text-tertiary transition-all">Skip the tour</button>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}