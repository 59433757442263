import { useEffect, useState, SetStateAction, Dispatch } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { selectAuth } from "../../store/selectors/auth.selector";
import { convertDecimal, isAdmin, isOwner, download, getDateFromObjectId, sortByColumn } from "../../data/functions";
import { updateExistingReadacross, deleteExistingReadacross } from "../../store/slices/readacross.slice";

import { ReadAcrossType } from "../../types/readacross.types";
import { Header, SideInfo, Limit } from "../../types/all.types";

import API from '../../api';

const hasMoleculeID = (obj: any): obj is {$oid: string} => {
    if (obj.$oid) {
        return true;
    } else {
        return false;
    }
} 

const useSingleReadacross = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {

    const [ singleReadacross, setSingleReadacross ] = useState<ReadAcrossType | null>(null);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ headers, setHeaders ] = useState<Header[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ limits, setLimits ] = useState<Limit[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);

    const auth = useSelector(selectAuth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setupSingleReadacross = (singleReadacross: ReadAcrossType) => {

        if (singleReadacross) {

            setRoutes([
                {
                    label: 'Read-Across',
                    path: 'readacross',
                },
                {
                    label: singleReadacross ? singleReadacross.name : 'Single Read-Across',
                    path: singleReadacross ? `readacross/${singleReadacross._id.$oid}` : '',
                }
            ]);
            
            const fieldNames = Object.keys(singleReadacross.nn_molecules[0].fields!);

            setLimits(fieldNames.map(n => ({
                value: n,
                max: 1,
                min: 0,
                isReversed: true,
            })));

            const readHeaders = [{
                label: 'Structure',
                value: 'structure',
                isRequired: true,
                isAscending: true,
                isSelected: true,
                isSortable: false,
            }].concat(fieldNames.map((field, i) => ({
                label: field,
                value: field,
                isRequired: false,
                isAscending: true,
                isSelected: true,
                isSortable: true,
            })));

            setHeaders(readHeaders)

            const all = singleReadacross.nn_molecules.map(molecule => ({fields: molecule.fields, molecule: hasMoleculeID(molecule.mol) ? molecule.mol.$oid : molecule.mol, hasMoleculeID: hasMoleculeID(molecule.mol) ? true : false}));

            // let molInfo: {id: string}[] = [];

            const authKey = localStorage.getItem('X-Auth');
            // const promises = singleReadacross.nn_molecules.map(async (molecule) => {
            //     return API.get(`molecule/${molecule.mol!.$oid}`, {headers: {'X-Auth': authKey}}).then(res => res.data).then(data => molInfo.push({id: molecule.mol!.$oid}));
            // })

            if (authKey) {
                const setRecords = () => {
                    let records: any[] = [];
                        all.forEach(r => {
                            let obj = {}
                            readHeaders.forEach(h => {
                                if (h.label !== 'Structure') {
                                    obj = {...obj, [h.label]: convertDecimal(r.fields![h.label])}
                                } else {
                                    obj = {...obj, [h.label]: ''}
                                }
                            })
                            records.push({...obj, id: r.molecule, hasMoleculeID: r.hasMoleculeID});
                        })
                        setOrderedRecords(records);
                        setModifiedRecords(records);
                }

                setRecords();
            }
            

            let additionalInfo: SideInfo[] = [];


            if (Object.keys(singleReadacross.mol_metadata).length) {
                additionalInfo = [
                    {
                        label: 'Description',
                        value: singleReadacross.mol_metadata.description ? singleReadacross.mol_metadata.description : 'No description',
                    },
                    {
                        label: 'Measurement Type',
                        value: singleReadacross.mol_metadata.measurement_type ? singleReadacross.mol_metadata.measurement_type : 'No measurement type'
                    },
                    {
                        label: 'Organism',
                        value: singleReadacross.mol_metadata.organism ? singleReadacross.mol_metadata.organism : 'No organism',
                    },
                    {
                        label: 'Project',
                        value: singleReadacross.mol_metadata.project ? singleReadacross.mol_metadata.project : 'No project',
                    },
                    {
                        label: 'Target',
                        value: singleReadacross.mol_metadata.target ? singleReadacross.mol_metadata.target : 'No target',
                    }
                ]
            }

            

            const newInfo: SideInfo[] = [
                {
                    label: 'Result Name',
                    value: singleReadacross.name,
                },
                {
                    label: 'Dataset',
                    value: singleReadacross.datasets_name[0],
                },
                {
                    label: 'Created By',
                    value: singleReadacross.user ? singleReadacross.user.full_name : '',
                },
                {
                    label: 'Owned By',
                    value: singleReadacross.acl ? singleReadacross.acl.owner : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleReadacross.acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleReadacross.acl ? singleReadacross.acl.access : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleReadacross.acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
                {
                    label: 'Date Created',
                    value: getDateFromObjectId(singleReadacross._id.$oid).toLocaleString('en-US', {timeZone: 'EST'}),
                },
            ]

            if (additionalInfo.length > 0) {
                setInfo(newInfo.concat(additionalInfo));
            } else {
                setInfo(newInfo);
            }
            
        };
    }


    useEffect(() => {
        if (id) {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchSingleReadacross = async () => {

                    setIsLoading(true);

                    try {

                        const response = await API.get(`readacross/${id}`, { headers: { 'X-Auth': authKey } });
                        const data: ReadAcrossType = response.data;
                        
                        setSingleReadacross(data);

                    } catch (err:any) {

                        console.log(err);
                        navigate('/readacross/404')

                    }
                    
                    setIsLoading(false);
                    
                };
        
                fetchSingleReadacross();

            } else {
                navigate('/401');
            }
            
        }
         //eslint-disable-next-line
    }, [id]);


    useEffect(() => {
        if (singleReadacross) {
            setupSingleReadacross(singleReadacross);
        }   
        //eslint-disable-next-line
    }, [singleReadacross]);

    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        const sortedArr = sortByColumn(selectedHeader, headers, true, orderedRecords, updateHeaders);
        setModifiedRecords(sortedArr);
    }

    const updateReadacross = async (obj: any) => {
        const { description, project, measurement_type, organism, target } = obj;
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const metadata = { description, project, measurement_type, organism, target };
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {
                if (metadata) {
                    await API.put(`readacross/${id}`, metadata, { headers: { 'X-Auth': authKey } });
                }
            
                if (auth && singleReadacross && (isAdmin(auth) || isOwner(auth, singleReadacross.acl.owner))) {
                    await API.put(`readacross/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`readacross/${id}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;

                

                setSingleReadacross(data);
                dispatch(updateExistingReadacross(data));
                setPopupMessage && setPopupMessage('This readacross was updated successfully!', true);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error updating this readacross', false)
    
            }
        }
    }

    const deleteReadacross = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`readacross/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingReadacross(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error deleting this read-across', false);
                throw new Error('Error');
            }
        }
    };
    

    const downloadReadacross = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`readacross/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error downloading this read-across', false)
            }
        }
    }


    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        setHeaders,
        headers,
        routes,
        singleReadacross,
        info,
        limits,
        deleteReadacross,
        downloadReadacross,
        updateReadacross,
        isLoading,
    }

}

export default useSingleReadacross;