import { Routes, Route, useLocation, useNavigate } from "react-router-dom"
import TutorialsAndDocumentation from "./pages/TutorialsAndDocumentation"
import TutorialPage from "./pages/TutorialPage"
import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { selectAuth } from "../../store/selectors/auth.selector";
// import NotFoundError from "../../components/NotFoundError"

export default function HelpCenter() {
    // const authUser = useSelector(selectAuth);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (!authUser) {
    //         navigate('/401');
    //     }
    // }, [authUser])




    return (
        <Routes>
            <Route index element={<TutorialsAndDocumentation />} />
            <Route path=':title' element={<TutorialPage />} />
        </Routes>
    )
}