import { MouseEvent, useState } from "react";
import usePagination from "../../hooks/usePagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useEnableSelection from "../../hooks/useEnableSelection";
import useDataset from "../../hooks/pageHooks/useDataset";

import { selectDatasets } from "../../store/selectors/datasets.selector";
import { setSelectedDataset } from "../../store/slices/datasets.slice";

import { defaultDatasetFilter } from "../../data/datasets";

import HeadersDropdown from "../Dropdowns/HeadersDropdown";
import TableControls from "../Table/TableControls";
import Overlay from "./Overlay";
import Pagination from "../Page/Pagination";
import FilterPopup from "./FilterPopup";
import FilterButton from "../Buttons/FilterButton";
import EmptyTable from "../Table/EmptyTable";

import Redirect from '../../assets/link.svg';
import TableBase from "../Table/TableBase";
import TableRowBase from "../Table/TableRowBase";
import useModifyTableItems from "../../hooks/useModifyTableItems";
import useMessage from "../../hooks/useMessage";
import SnackBar from "../Alerts/SnackBar";
import FormButtons from "../Buttons/FormButtons";



export default function ExistingDatasetPopup({ togglePopup, next, goBack }: { 
    togglePopup:  (e:MouseEvent<HTMLButtonElement>) => void, 
    next?: (e:MouseEvent<HTMLButtonElement>) => void, 
    goBack?: (e:MouseEvent<HTMLButtonElement>) => void, 
}) {
    const dispatch = useDispatch();
    const datasets = useSelector(selectDatasets);
    const [ selection, setSelection ] = useState('');
    const { isChecked } = useEnableSelection({allItems: undefined});
    const [ isFilterOpen, setIsFilterOpen ] = useState(false);

    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');

    const {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    } = useMessage();
    
    const {
        allDatasets,
        filterFields,
        headers,
        setHeaders,
        filter,
        setFilter,
        modifiedDatasets,
        modifyDatasets,
        isLoading,
    } = useDataset({ setPopupMessage });

    
    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyDatasets, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedDatasets, count: 10});


    const toggleFilterPopup = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsFilterOpen(!isFilterOpen);
   }


    const getSelectedDataset = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (selection) {
            const datasetObj = datasets.find(d => d._id.$oid === selection);
            if (datasetObj) {
                dispatch(setSelectedDataset(datasetObj));
                togglePopup(e);
                if (next) {
                    next(e)
                }
            }
        }
        
    }

    const backFunction = (e:MouseEvent<HTMLButtonElement>) => {
        goBack && goBack(e);
    }

    const cancelFunction = (e:MouseEvent<HTMLButtonElement>) => {
        togglePopup(e); 
        dispatch(setSelectedDataset(null))
    }

    const continueFunction = (e:MouseEvent<HTMLButtonElement>) => {
        getSelectedDataset(e);
    }
    

    return (
        <>
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-5  max-w-[1400px] w-full'>
                    <div className='flex flex-col justify-center items-stretch'>
                    
                        <div className='w-full text-center mb-6'>
                            <p className='text-primary font-semibold text-[1.5rem]'>Choose Existing Dataset</p>
                        </div>

                        <div className="w-full rounded bg-white">
                            <TableControls applySearch={applySearch} toggleDeletePopup={toggleFilterPopup} setInput={setSearchInput} searchInput={searchInput}>
                                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                                {paginatedItems.length > 0 && <FilterButton toggleFilterPopup={toggleFilterPopup} />}
                            </TableControls>

                            <div className='max-h-[420px] overflow-y-auto rounded'>
                                {isLoading || paginatedItems.length > 0 ? (
                                    <>
                                        <TableBase
                                            hasOptions={isLoading ? false : true} 
                                            headers={headers} 
                                            selectedHeader={selectedHeader} 
                                            setSortedColumn={setSortedColumn} 
                                            isPopupOpen={true}
                                            isChecked={false}
                                            isLoading={isLoading}
                                        >
                                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                                <TableRowBase
                                                    rowType="base"
                                                    isChecked={isChecked} 
                                                    item={item} 
                                                    headers={headers} 
                                                    setSelection={setSelection}
                                                >
                                                    <label className="w-full h-full flex justify-end items-center">
                                                        <a href={`${process.env.REACT_APP_HOST_URL}/datasets/${item.id}`} target="_blank" rel='noreferrer' className="p-1 rounded hover:bg-secondary/[0.2] mr-2">
                                                            <img src={Redirect} alt='redirect-icon' className="w-[20px]" />
                                                        </a>
                                                    </label>
                                                </TableRowBase>
                                            ))}
                                        </TableBase>
                                        
                                    </>
                                ) : (
                                    <EmptyTable isPopup={true} message="Create a new dataset" subject="No datasets found" searchInput={searchInput}/>
                                )}
                                
                            </div>

                            {paginatedItems.length > 0 && (
                                <Pagination count={10} total={allDatasets.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                            )}
                            
                        </div>   

                        <div className="mt-6">
                            <FormButtons backFunction={goBack ? backFunction : undefined} cancelFunction={cancelFunction} continueFunction={continueFunction} isDisabled={!selection} isLoading={false} />
                        </div>
                            
                      
                    </div>
                    
                </div>
            </div>
            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  
        </Overlay>

        {isFilterOpen &&  
            <Overlay togglePopup={toggleFilterPopup}>
                <FilterPopup applyFilter={applyFilter} itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={toggleFilterPopup} defaultFilter={defaultDatasetFilter} fieldsObj={filterFields} />
            </Overlay>
        }
        </>
    )
}