import { useNavigate, useLocation } from "react-router-dom";
import { MouseEvent } from "react";

export default function TogglePage({ query, param, pathOne, pathTwo, labelOne, labelTwo }: { query: string, param: string, pathOne: string, pathTwo: string, labelOne: string, labelTwo: string }) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <div className='flex justify-center items-center bg-white rounded'>
            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate(pathname + param + pathOne)} disabled={query === pathOne ? true : false} className={`${query === pathOne ? 'border-secondary text-secondary' : 'border-quaternary text-quaternary hover:text-secondary'} border-t border-l border-b flex justify-center items-center px-5 py-3 rounded-tl rounded-bl relative`}>
                {query === pathOne && <div className='absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10' />}
                <p className={`${query === pathOne ? 'font-semibold' : 'font-medium'}`}>{labelOne}</p>
            </button>
            <button onClick={(e:MouseEvent<HTMLButtonElement>) => navigate(pathname + param + pathTwo)} disabled={query === pathTwo ? true : false} className={`${query === pathTwo ? 'border-secondary text-secondary' : 'border-quaternary text-quaternary hover:text-secondary'} border flex justify-center items-center px-5 py-3 rounded-tr rounded-br relative`}>
                {query === pathTwo && <div className='absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10' />}
                <p className={`${query === pathTwo ? 'font-semibold' : 'font-medium'}`}>{labelTwo}</p>
            </button>
        </div>
    )
}