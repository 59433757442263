import { Routes, Route } from "react-router-dom"
import Protocol from "./pages/Protocol"
import NotFoundError from "../../components/Alerts/NotFoundError"

export default function ProtocolRoutes() {
    return (
        <Routes>
            <Route path=':id' element={<Protocol />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}