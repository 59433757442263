// assets
import Error from '../../assets/cancel.png';

// components
import Overlay from "./Overlay";

// hooks
import { useNavigate } from 'react-router-dom';

// types
import { MouseEvent } from "react";

export default function ErrorPopup({togglePopup, type, resetValues}: {togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, type: 'models' | 'predictions' | 'datasets', resetValues?: () => void}) {
    const navigate = useNavigate();


    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-5  max-w-[450px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col justify-center items-stretch text-primary'>
                            <div className='w-full flex justify-center mb-2'><img src={Error} alt='error-icon' className='w-[100px]' /></div>
                            <p className='text-[2.5rem] font-semibold w-full text-center mb-2'>Oops!</p>
                            {type === 'models' ? (
                                <>
                                    <div className='w-full flex justify-center mb-6'><p className='max-w-[400px] text-center font-medium'>Something went wrong. Would you like to try again or train a new model?</p></div>
                                    <div className='flex gap-2 justify-center'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e)}} className='w-1/2 py-2 px-4 border-2 border-secondary text-secondary font-medium rounded bg-white'>Try Again</button>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); resetValues && resetValues()}} className='w-1/2 py-2 px-4 border-2 border-secondary text-white font-medium rounded bg-secondary'>Start Over</button>
                                    </div>
                                </>
                            ) : type === 'predictions' ? (
                                <>
                                    <div className='w-full flex justify-center mb-6'><p className='max-w-[400px] text-center font-medium'>Something went wrong. Would you like to try again or make a new prediction?</p></div>
                                    <div className='flex gap-2 justify-center'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e);}} className='w-1/2 py-2 px-4 border-2 border-secondary text-secondary font-medium rounded bg-white'>Try Again</button>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); resetValues && resetValues()}} className='w-1/2 py-2 px-4 border-2 border-secondary text-white font-medium rounded bg-secondary'>Start Over</button>
                                    </div>
                                </>
                            ) : (

                                <>
                                    <div className='w-full flex justify-center mb-6'><p className='max-w-[400px] text-center font-medium'>Something went wrong. Would you like to try again or create a new readacross?</p></div>
                                    <div className='flex gap-2 justify-center'>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e);}} className='w-1/2 py-2 px-4 border-2 border-secondary text-secondary font-medium rounded bg-white'>Try Again</button>
                                        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup(e); resetValues && resetValues()}} className='w-1/2 py-2 px-4 border-2 border-secondary text-white font-medium rounded bg-secondary'>Start Over</button>                                    
                                    </div>
                                </>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    )
}