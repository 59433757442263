import ReadacrossCover from '../../../../assets/tutorialAssets/Readacross Cover.png';
import ReadacrossIcon from '../../../../assets/beaker.svg';
import Plus from '../../../../assets/plus.svg';
import Options from '../../../../assets/dots-vertical.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
export default function GeneratingReadacrossTutorial() {

    const sections = [
        {id: 'what-is-readacross', label: 'What is read-across?', type: 'main'},
        {id: 'readacross-tab', label: 'Creating a read-across set from the Read-Across tab', type: 'sub'},
        {id: 'readacross-dialog', label: 'Creating a read-across set using molecules from files and datasets', type: 'sub'}
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'generating-readacross', label: 'Generating Readacross Values'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Generating Readacross Values</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={ReadacrossCover} alt='readacross-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id='what-is-readacross' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">What is read-across?</p>
                    <p className="mb-4"><span className="font-semibold">Read-across</span> is a method that predicts properties of a molecule by comparing it to similar molecules in a provided dataset. It looks at information obtained from testing these similar molecules to make predictions about the unknown molecule.</p>
                    <p>In this tutorial, we will explore the two main ways of generating read-across values using Assay Central.</p>
                </div>

                <div id='readacross-tab' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[1.6rem] font-semibold mb-6">Creating a read-across set from the Read-Across tab</p>
                    <ol className="list-decimal list-inside mb-8 ml-12 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={ReadacrossIcon} alt='readacross-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Readacross</span> tab</li>
                        <li>Click on the <span className="inline-block darken-icons"><img src={Plus} alt='plus-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span><span className="font-semibold">Create Read Across</span> button located on the right-hand corner of the screen. This will redirect you to the <span className="font-semibold">Create Read Across</span> page.</li>
                    </ol>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/Readacross.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">This view will display two cards, with each card representing a step in the process of creating a readacross from a single molecule:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>
                            <span className="font-semibold">Selecting a molecule</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    There are several ways to select a read-across compound:
                                    <ol className="list-decimal list-inside my-4 ml-12 flex flex-col gap-4">
                                        <li>Search a specific compound by name, CAS registry number, or SMILES string by selecting the appropriate option and typing into the search bar. The structure of your selected compound will be automatically drawn inside the chemical editor below the search bar.</li>
                                        <li>Draw your molecule inside the chemical editor.</li>
                                    </ol>
                                </li>
                                <li>
                                    Choosing either option will generate a SMILES string below the chemical editor corresponding to the compound you selected.                                 
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Setting result parameters</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Once a compound has been selected, you can set the parameters for the read-across to be generated inside the <span className="font-semibold">Set Result Parameters</span> card.
                                    <ol className="list-decimal list-inside my-4 ml-12 flex flex-col gap-4">
                                        <li><span className="font-semibold">Result Name</span> - A descriptive name for your read-across results</li>
                                        <li><span className="font-semibold">Neighbors</span> - Choose between ECFP or MACCS</li>
                                        <li><span className="font-semibold">Model Type</span> - Choose between Classification or Regression</li>
                                        <li><span className="font-semibold">Dataset</span> - ToxRef is selected as the default dataset. You may also select one of your own datasets for the read-across prediction.</li>
                                        <li><span className="font-semibold">Analog Number</span> - The number of nearest neighbors to be chosen for your selected molecule. The maximum number of analogs you can select is 15.</li>
                                    </ol>
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p className="mb-4">Once all of the required steps have been completed, select <span className="font-semibold">Run</span>. If no issues were found in the information you provided, you will be redirected to the <span className="font-semibold">Individual Readacross View</span> for the read-across set you just created.</p>
                </div>

                <div id='readacross-dialog' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[1.6rem] font-semibold mb-6">Creating a read-across set using molecules from files and datasets</p>
                    <ol className="list-decimal list-inside mb-8 ml-12 flex flex-col gap-4">
                        <li>Navigate to the file or dataset containing your desired molecule</li>
                        <li>Scroll through the file/dataset records to find your desired molecule and click on the <span className="inline-block darken-icons"><img src={Options} alt='options-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span><span className="font-semibold">icon</span> located at the rightmost end of the respective table row or top-right corner of the respective grid item, depending on the view.</li>
                        <li>Select <span className="font-semibold">Create resultset</span> from the dropdown options. This will trigger a popup form containing fields for creating a new read-across from the molecule you selected. These fields are identical to those shown in the <span>Set Result Parameters</span> card in the previous section.</li>
                    </ol>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/ReadAcrossDialog.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">Once all of the required fields have been completed, click <span className="font-semibold">Continue</span>. If no issues were found in the information you provided, you will be redirected to the <span className="font-semibold">Individual Readacross View</span> for the read-across set you just created.</p>
                </div>
                
                <TutorialPagination nextPage={{page: 'managing-users', label: "Managing users"}} prevPage={{page: 'single-prediction', label: 'Making single predictions'}}/>

            </div>
        </div>
        </>
    )
}