import { ChangeEvent } from "react";

export default function Toggle({ handleCheck, isChecked, name, isDisabled }: { handleCheck: (e: ChangeEvent<HTMLInputElement>) => void, isChecked: boolean, name?: string, isDisabled?: boolean }) {

    console.log(handleCheck, isChecked, name)

    return (

        <label htmlFor="toggle-checkbox" className="inline-flex items-center cursor-pointer">
            <input id='toggle-checkbox' disabled={isDisabled !== undefined ? isDisabled : false} type="checkbox" checked={isChecked} onChange={handleCheck} name={name && name} className="sr-only peer" />
            <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiary"></div>
        </label>

    )
}