import { useState, MouseEvent, useEffect} from "react";
import { useParams, useLocation } from "react-router-dom"

import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import { useSelector } from "react-redux";
import TableControls from "../../../components/Table/TableControls";
import useProtocol from "../../../hooks/pageHooks/useProtocol";
import { selectNav } from "../../../store/selectors/nav.selector";
import { Header } from "../../../types/all.types";
import ImageCell from "../../../components/Data/ImageCell";
import Arrow from '../../../assets/long-arrow.svg';
import DownloadIcon from '../../../assets/download.svg';
import Ascending from '../../../assets/sort-ascend.svg';
import Descending from '../../../assets/sort-descend.svg';

import SnackBar from "../../../components/Alerts/SnackBar";
import useSortByHeaders from "../../../hooks/useSortByHeaders";
import usePagination from "../../../hooks/usePagination";
import Pagination from "../../../components/Page/Pagination";
import { CircularProgress } from "@mui/material";
import useMessage from "../../../hooks/useMessage";


export default function Protocol() {
    const { id } = useParams();
    const { search } = useLocation();
    const [ searchInput, setSearchInput ] = useState('');
    const [ isExportLoading, setIsExportLoading ] = useState(false);

    const isNavOpen = useSelector(selectNav);

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage,
    } = useMessage();


    const {
        isLoading, 
        records,
        routes,
        protocolHeaders,
        protocol,
        downloadProtocol,
        setSortedRecords,
        filteredRecords,
        setFilteredRecords
    } = useProtocol({id, dsID: search.split('=')[1], setPopupMessage, searchInput});

    const { 
        setHeaders, 
        selectedHeader, 
        setSortedColumn,
        headers,
    } = useSortByHeaders({ allItems: records, setSortedItems: setSortedRecords, setFilteredItems: setFilteredRecords });

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: filteredRecords, count: 50});

    const downloadItem = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (protocol) {
            setIsExportLoading(true);
            await downloadProtocol(`${protocol.name}.xlsx`, protocol._id.$oid, 'xlsx');
            setIsExportLoading(false);
        }
    }

    useEffect(() => {
        setHeaders(protocolHeaders)
    }, [protocolHeaders])

    return (
        <>
           

                <SinglePageHeader label={protocol ? protocol.name : 'Protocol'} isLoading={isLoading} routes={routes}>
                    {/* <OptionsDropdown downloadFunc={downloadDataset} name={singleDataset ? singleDataset.name : ''} toggleDeletePopup={toggleDeletePopup} id={id}/>
                    <Button togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button> */}

                    <button onClick={(e:any) => downloadItem(e)}>
                        <div className={`text-white bg-tertiary hover:bg-secondary transition-all whiten flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                            {isExportLoading ? (
                                <CircularProgress color="inherit" size={20}/>
                            ) : (
                                <img src={DownloadIcon} alt='download-icon' className="w-[20px]" />
                            )}         
                            <p className='font-medium text-nowrap'>Export Protocol</p>
                        </div>
                    </button>
                </SinglePageHeader>

                <div className="w-full rounded bg-white drop-shadow-default">
                <TableControls searchInput={searchInput} setInput={setSearchInput}>
                    <></>
                    {/* <div className="h-[42px]"></div>
                    <ToggleView toggleView={toggleView} isGrid={isGrid} /> */}
                </TableControls>


                <div className={`w-full ${isNavOpen ? 'table-container-expanded' : 'table-container'} overflow-x-auto bg-white rounded`}>
                    <table className="w-full bg-darker-background">

                        <tr  className='bg-gradient-to-r from-grad-one to-grad-two text-quaternary'>
                        {isLoading !== undefined && isLoading ? (
                            <th className='w-full py-4 px-6'><div className='h-[14px]'/></th>
                        ) : (
                        <>
                        {headers.map((header:Header, i:number)=> {
                            if (header.isSelected) {
                                return (
                                    <th key={i} className={`text-left text-nowrap text-[14px] min-w-[150px]`}>
                                        <div className={`flex ${header.isRequired ? 'justify-start' : 'justify-center'} items-center py-4 px-6`}>
                                        <button onClick={(e:any) => setSortedColumn(e, header.label)} disabled={header.value === 'record' ? false : true}  className={`${header.value === 'record' ? 'justify-start' : 'justify-center'} hover:text-secondary disabled:text-quaternary flex items-center gap-3 w-full`}>
                                            {header.label}
                                            {selectedHeader === header.label && ( 
                                                <>
                                                {header.isAscending ? (
                                                <img src={Ascending} alt='sort-ascending-icon' className='w-[18px]'/>
                                                ) : (
                                                <img src={Descending} alt='sort-descending-icon' className='w-[18px]'/>
                                                )}
                                                </>
                                            )}  
                                            
                                        </button> 
                                        </div>
                                    </th>
                                )
                            } else {
                                return null;
                            }
                        })}
                        </>
                        )}
                        </tr>
                        {paginatedItems.length > 0 && paginatedItems.map((record,i) => (
                            <tr className="table-body">
                                <td className="px-6 h-full">
                                    <div className="">
                                    <p className="font-medium pl-4">{record.record}</p>
                                    </div>
                                </td>
                                <td className="px-6 py-4"><ImageCell type="table" id={record.original_structure} /></td>
                                <td className="px-6 text-center">
                               
                                    <div className="flex flex-col justify-center items-start">
                                        {Object.keys(record.file_fields).map(k => {
                                            const value = record.file_fields[k as keyof object];
                                            return (
                                                <p className="text-[12px] font-medium w-full text-center">{k}: {value}</p>
                                            )
                                        })}

                                    </div>
                                </td>
                                <td className="px-6">
                                    <div className="flex flex-col gap-1 justify-center items-center max-w-[300px] mx-auto">
                                        <p className="text-[12px] font-medium text-center break-all">{record.issues[0].severity}: {record.issues[0].message}</p>
                                        <img src={Arrow} alt='issues-arrow' className="w-full min-w-[200px]"/>
                                    </div>
                                </td>
                                <td className="px-6">
                                    <div className="flex flex-col justify-center items-start">
                                        {Object.keys(record.dataset_fields).map(k => {
                                            const value = record.file_fields[k as keyof object];
                                            return (
                                                <p className="text-[12px] font-medium w-full text-center">{k}: {value}</p>
                                            )
                                        })}
                                    </div>
                                </td>
                                <td className="px-6"><ImageCell type="table" id={record.standardized_structure} /></td>
                            </tr>
                        ))}


                    </table>
                </div>

                <Pagination count={50} total={records.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>

                </div>

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>
          
           

        </>
    )
}