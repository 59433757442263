import CreateDatasetCover from '../../../../assets/tutorialAssets/Create Dataset Cover.png';
import DatasetIcon from '../../../../assets/dataset.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';
import Redirect from '../../../../assets/link.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
export default function CreateDatasetTutorial() {

    const sections = [
        {id: 'where-do-i-create-datasets', label: 'Where do I Create Datasets?', type: 'main'},
        {id: 'creating-a-new-datasets', label: 'Creating a new dataset', type: 'main'}
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'creating-datasets', label: 'Creating Datasets'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Creating Datasets</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={CreateDatasetCover} alt='create-dataset-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id='where-do-i-create-datasets' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I create datasets?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={DatasetIcon} alt='datasets-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Datasets</span> tab</li>
                        <li>Click on the  <span className="font-semibold">Create New Dataset</span> <span className="inline-block darken-icons"><img src={ChevronDown} alt='dropdown-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> button located on the right-hand corner of the screen</li>
                        <li>
                            From the dropdown menu, select how you would like to create your dataset: 
                            <ol className="list-disc list-inside ml-12 my-2">
                                <li className="mt-4"><span className="font-semibold">From Existing File</span> - Select this option if you are using a file that's been previously uploaded</li>
                                <li className="mt-4"><span className="font-semibold">From New File</span> - Select this option if you are using a new file that will be uploaded from your computer</li>
                                <li className="mt-4"><span className="font-semibold">From Compound List</span> - Select this option if you are using a new file with will be created from a list of CAS registry numbers</li>
                            </ol>
                        </li>
                    </ol>
                    <p>After making your selection, you will see a popup form titled <span className="font-semibold">Select Dataset Labels</span>.</p>
                </div>

                <div id='creating-a-new-datasets' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Creating a new dataset</p>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/CreatingDatasets.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">The file you selected will appear under the <span className="font-semibold">Selected file</span> field at the top of the form. To view more details about the file, click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon to view that file in a new tab on your browser.</p>
                    <p>Complete all required input fields inside the popup form before continuing:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li><span className="font-semibold">Dataset Name</span> - A descriptive name for your new dataset. Checking <span className="font-semibold">Autogenerate</span> will match the name to the file you selected, removing the file format suffix.</li>
                        <li><span className="font-semibold">Chemical Name</span> - The column in your selected file containing the name for each compound in that file.</li>
                        <li><span className="font-semibold">Record ID</span> - The column in your selected file containing the unique identifier for each compound in that file. This can be the same as the Chemical Name field you chose if no such column exists.</li>
                        <li><span className="font-semibold">Relevant Data (Non-Activity) <span className="text-secondary">(optional)</span><span className="text-tertiary">(optional)</span></span> - The column(s) in your selected file containing additional information that you would like to include in your dataset.</li> 
                        <li><span className="font-semibold">Activity Column</span> - The column in your selected file containing the values for which subsequent models will be trained on.</li> 
                        <li><span className="font-semibold">Model Type</span> - Identifies the activity column as classification or regression values.</li> 
                        <li><span className="font-semibold">Value Type </span> - Options for this column will differ depending on which model type you chose:
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li><span className="font-semibold">Single-class Label</span> (classification model type)
                                    <ul className="list-inside list-circle ml-12 flex flex-col gap-2 my-4">
                                        <li>Designates activity baesd on a binary value in your file</li>
                                        <li>Use this if the data in your activity column is already binarized</li>
                                    </ul>
                                </li>
                                <li><span className="font-semibold">Split-on Value</span> (classification model type)
                                    <ul className="list-inside list-circle ml-12 flex flex-col gap-2 my-4">
                                        <li>Designates activity based on non-binary values and binarizes it based on a specific threshold</li>
                                        <li>If this option is selected, you will prompted to choose a quantifier and threshold to be classified</li>
                                    </ul>
                                </li>
                                <li><span className="font-semibold">Continuous Value</span> (regression model type)
                                    <ul className="list-inside list-circle ml-12 flex flex-col gap-2 my-4">
                                        <li>Designates activity based on a continuous value in your file</li>
                                        <li>It is standard practice for regression models’ final units to be logarithmic. If the values in the activity column have standard units (such as nM, µg/ml, etc.) select <span className="font-semibold">Log</span> from the Quantifier field.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li> 
                    </ol>
                    <p className="mb-4">Click <span className="font-semibold">Continue</span> to create your new dataset. If you would like to select a different file to create your dataset, click <span className="font-semibold">Back</span>. Note that doing so will clear the selections you made in the Create New Dataset dialog.</p>
                    <p>After your dataset has been successfully created, you will be redirected to its Individual Dataset View page.</p>
                </div>

                <TutorialPagination nextPage={{page: 'training-models', label: 'Training models'}} prevPage={{page: 'uploading-files', label: 'Uploading files'}} />

            </div>
        </div>
        </>
    )
}