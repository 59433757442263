// import { Link } from 'react-router-dom';
import ErrorImage from '../../assets/no-results.png';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface RedirectType {
    path: string;
    name: string;
    title: string;
}

export default function NotFoundError() {
    const [ redirect, setRedirect ] = useState<RedirectType>({path: '', name: '', title: ''});
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes('files')) {
            setRedirect({ path: '/files', name: 'file', title: 'Files' })
        } else if (pathname.includes('datasets')) {
            setRedirect({ path: '/datasets', name: 'dataset', title: 'Datasets' })
        } else if (pathname.includes('models')) {
            setRedirect({ path: '/models', name: 'model', title: 'Models' })
        } else if (pathname.includes('predictions')) {
            setRedirect({ path: '/predictions', name: 'resultset', title: 'Predictions' })
        } else if (pathname.includes('jobs')) {
            setRedirect({ path: '/jobs', name: 'job', title: 'Job Queue' })
        } else if (pathname.includes('readacross')) {
            setRedirect({ path: '/readacross', name: 'readacross result', title: 'Read Across' })
        }
    }, [pathname]);

    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <div className='max-w-[300px] w-full mb-6'>
                    <img src={ErrorImage} className='object-cover w-full' alt='404-error-icon'/>
                </div>
                
                <p className='text-[20px] mb-3 font-medium text-secondary'>404 - PAGE NOT FOUND</p>
                <p className='mb-6'>The {redirect.name} you are looking does not exist.</p>
                {/* <Link to={redirect.path} className='rounded-full py-3 px-10 bg-tertiary hover:bg-secondary font-medium text-white transition-all'>Return to {redirect.title}</Link> */}
            </div>
        </div>
    )
}