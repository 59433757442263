import { combineReducers } from "@reduxjs/toolkit";
import { FileState, filesReducer } from "./slices/files.slice";
import { DatasetState, datasetsReducer } from "./slices/datasets.slice";
import { ModelState, modelsReducer } from "./slices/models.slice";
import { PredicationState, predictionsReducer } from "./slices/predictions.slice";
import { UserState, userReducer } from "./slices/users.slice";
import { JobState, jobsReducer } from "./slices/jobs.slice";
import { NavState, navReducer } from "./slices/nav.slice";
import { LocationState, locationReducer } from "./slices/location.slice";
import { AuthState, authReducer } from "./slices/auth.slice";
import { ReadAcrossState, readacrossReducer } from "./slices/readacross.slice";

export interface AppState {
    files: FileState,
    datasets: DatasetState,
    models: ModelState,
    predictions: PredicationState,
    users: UserState,
    jobs: JobState,
    nav: NavState,
    location: LocationState,
    auth: AuthState,
    readacross: ReadAcrossState,
}

export const rootReducer = combineReducers({
    files: filesReducer,
    datasets: datasetsReducer,
    models: modelsReducer,
    predictions: predictionsReducer,
    users: userReducer,
    jobs: jobsReducer,
    nav: navReducer,
    location: locationReducer,
    auth: authReducer,
    readacross: readacrossReducer,

})