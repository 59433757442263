// import { Link } from 'react-router-dom';
import ErrorImage from '../../assets/server.png';

export default function ServerError() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <div className='max-w-[300px] w-full mb-6'>
                    <img src={ErrorImage} className='object-cover w-full' alt='500-error-icon'/>
                </div>
                
                <p className='text-[20px] mb-3 font-medium text-secondary'>500 - INTERNAL SERVER ERROR</p>
                <p className='mb-6 max-w-[400px] text-center'>Oops, something went wrong. Please try again or feel free to contact us if the problem persists.</p>
                {/* <Link to='/dashboard' className='rounded-full py-3 px-10 bg-tertiary hover:bg-secondary font-medium text-white transition-all'>Return to Dashboard</Link> */}
            </div>
        </div>
    )
}