import UploadFile from '../../../assets/file(1).png';
import CreateDataset from '../../../assets/dataset-colored.png';
import TrainModels from '../../../assets/model-colored.png';
import MakePredictions from '../../../assets/predictive-analysis.png';
import SinglePrediction from '../../../assets/chemical.png';
import GenerateReadacross from '../../../assets/chemistry.png';
import { Dispatch, MouseEvent, SetStateAction } from 'react';

import { useNavigate } from 'react-router-dom';
import usePrivileges from '../../../hooks/usePrivileges';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../store/selectors/auth.selector';
import { PopupType } from '../../../hooks/usePopup';


export default function QuickActions({ setPopupType, toggleUploadFile, setIsCreateDataset, application}: {
    toggleUploadFile: (e:MouseEvent<HTMLButtonElement>) => void,
    setIsCreateDataset: Dispatch<SetStateAction<boolean>>,
    application: string,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {
    const navigate = useNavigate();
    const { canCreate, canTrain, canPredict } = usePrivileges();
    const theme = useSelector(selectTheme);

    return (
        <div className="grid grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] auto-rows-auto gap-4 h-full mt-4 overflow-y-auto">
            {canCreate && (
                <>
                    <button onClick={(e:any) => {toggleUploadFile(e); setPopupType('upload-file'); setIsCreateDataset(false)}} className={`transition-all ${theme === 'dark' ? 'bg-dark-background' : 'bg-background'} rounded-md gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary`}>
                        <p className='text-[16px] font-medium text-left'>Upload Files</p>
                        <img src={UploadFile} className='w-[64px]' alt='upload-file-icon'/>
                    </button>
                    {application && (application).includes('assay-central') && (
                        <button onClick={(e:any) => {toggleUploadFile(e); setPopupType('select-file-type') ;setIsCreateDataset(true)}}  className={`transition-all ${theme === 'dark' ? 'bg-dark-background' : 'bg-background'} rounded-md gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary`}>
                            <p className='text-[16px] font-medium text-left'>Create Datasets</p>
                            <img src={CreateDataset} className='w-[64px]' alt='create-dataset-icon'/>
                        </button>
                    )}
                </>
            )}
            
            {canTrain && application && (application).includes('assay-central') && <button onClick={(e:any) => navigate('/models/create-new-model?dataset=new')} className="transition-all bg-background rounded gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary">
                <p className='text-[16px] font-medium text-left'>Train Models</p>
                <img src={TrainModels} className='w-[64px]' alt='train-models-icon'/>
            </button>}
            {canPredict && (
                <>
                <button onClick={(e:any) => navigate('/predictions/make-new-prediction?type=set')} className={`transition-all ${theme === 'dark' ? 'bg-dark-background' : 'bg-background'} rounded-md gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary`}>
                    <p className='text-[16px] font-medium text-left'>Make Predictions</p>
                    <img src={MakePredictions} className='w-[64px]' alt='make-predictions-icon'/>
                </button>
                <button onClick={(e:any) => navigate('/predictions/make-new-prediction?type=single')} className={`transition-all ${theme === 'dark' ? 'bg-dark-background' : 'bg-background'} rounded-md gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary`}>
                    <p className='text-[16px] font-medium text-left'>Single Prediction</p>
                    <img src={SinglePrediction} className='w-[64px]' alt='single-prediction-icon'/>
                </button>
                </>
            )}
            
            <button onClick={(e:any) => navigate('/readacross/create-readacross')} className={`transition-all ${theme === 'dark' ? 'bg-dark-background' : 'bg-background'} rounded-md gap-4 p-8 flex flex-col justify-between items-start border-2 border-background hover:border-secondary hover:bg-secondary/[0.1] hover:cursor-pointer hover:text-secondary`}>
                <p className='text-[16px] font-medium text-left'>Generate Readacross</p>
                <img src={GenerateReadacross} className='w-[64px]' alt='generate-readacross-icon'/>
            </button>
        </div>
    )
}