// components
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import SuccessPopup from "../../../components/Popups/SuccessPopup";
import TogglePage from "../../../components/Page/TogglePage";
import UploadFileCard from "../../Models/components/Cards/UploadFileCard";
import { CircularProgress } from "@mui/material";

// functions
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// hooks
import { useState } from "react";
import usePopup from "../../../hooks/usePopup";

// types
import { MouseEvent } from "react";
import UploadModelCard from "../components/Cards/UploadModelCard";
import SetPredictionValues from "../components/Cards/SetPredictionValues";
import ExistingModelPopup from "../../../components/Popups/ExistingModelPopup";
import KetcherCard from "../components/Cards/KetcherCard";
import ResultTable from "../components/ResultsTable";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import SnackBar from "../../../components/Alerts/SnackBar";
import useMessage from "../../../hooks/useMessage";
import Overlay from "../../../components/Popups/Overlay";
import SelectFileTypePopup from "../../Files/components/SelectFileTypePopup";
import UploadFile from "../../Files/components/UploadFile";
import ExistingFilePopup from "../../../components/Popups/ExistingFilePopup";
import useMakeNewPredictionHandlers from "../../../hooks/handlers/useMakeNewPredictionHandlers";



export default function MakeNewPrediction() {
    const [ selectedType, setSelectedType ] = useState('');

    const {
        togglePopup,
        setPopupType,
        isPopupOpen,
        popupType,
    } = usePopup();

    const {
        setPopupMessage,
        open,
        isSuccess,
        setOpen,
        message
    } = useMessage();

   const {
    next,
    goBack,
    handleMethodSelection,
    handleSingleSubmit,
    handleSubmit,

    resetFile, 
    resetMethods,
    resetValues,

    routes,
    isDisabled,
    isLoading,
    setFieldsMapping,
    setSingleClassField,
    classificationResults,
    regressionResults,
    addMultipleModels,
    deleteModel,

    selectedFile,
    selectedModels,
    query,
    form,
    setForm,
    smiles,
    setSmiles,
    predictionResults,
    individualMethods,
    fieldsMapping,
    singleClassField
 } = useMakeNewPredictionHandlers({ togglePopup, setPopupMessage, setPopupType, selectedType })
    

    return (
        <>


        <SinglePageHeader label='Make New Prediction' routes={routes}>
            <TogglePage query={query} param='?type=' pathOne="set" pathTwo="single" labelOne="Set" labelTwo="Single"/>
        </SinglePageHeader>

        <div className='w-full table-container overflow-x-auto py-2'>
            <div  className='w-full flex justify-start items-stretch gap-4 h-full min-h-[60vh]'>
            {query === 'set' ? (
                <>
                    <UploadFileCard number={1} selectedFile={selectedFile} toggleSelectFile={togglePopup} setPopupType={setPopupType} reset={selectedFile ? resetFile : undefined}/>
                    <UploadModelCard isSet={true} handleMethodSelection={handleMethodSelection} individualMethods={individualMethods} number={2} deleteModel={deleteModel} toggleSelectModel={togglePopup} setPopupType={setPopupType} form={form} setForm={setForm} resetMethods={resetMethods} selectedModels={selectedModels}/>
                    <SetPredictionValues numberOne={3} numberTwo={4} selectedModels={selectedModels} form={form} setForm={setForm} fieldsMapping={fieldsMapping} setFieldsMapping={setFieldsMapping} singleClassField={singleClassField} setSingleClassField={setSingleClassField}/>
                </>
            ) : (
                <>
                    <KetcherCard number={1} setSmiles={setSmiles} smiles={smiles}/>
                    <UploadModelCard isSet={false} handleMethodSelection={handleMethodSelection} individualMethods={individualMethods} number={2} deleteModel={deleteModel} selectedModels={selectedModels} toggleSelectModel={togglePopup} setPopupType={setPopupType} isKetcher={false} resetMethods={resetMethods}/>
                </>
            )}
            </div>
        </div>

        <div className='mt-4 w-full flex justify-end items-center'>
            <button onClick={(e: MouseEvent<HTMLButtonElement>) => {query === 'set' ? handleSubmit(e) : handleSingleSubmit(e)}} disabled={isDisabled} className='flex justify-center items-center gap-2 max-w-[150px] w-full py-2 px-4 rounded text-white text-[14px] border border-tertiary bg-tertiary hover:border-secondary hover:bg-secondary transition-all disabled:border-quaternary/[0.1] disabled:bg-quaternary disabled:bg-opacity-30'>
            {isLoading ? 
                <>
                <CircularProgress size={16} color='inherit'/>
                <p>Running</p>
                </>
                : 
                <p>Run</p>
            }
            </button>
        </div>

        <div id='results-table'>
        {selectedModels && predictionResults.length > 0 && query === 'single' && (
        <Tabs>
            <TabList>
                {classificationResults.length >0 && <Tab onSelect={(e:any) => console.log('selected')}>Classification</Tab>}
                {regressionResults.length > 0 && <Tab>Regression</Tab>}
            </TabList>

            {classificationResults.length > 0 && <TabPanel>
                <ResultTable results={classificationResults} selectedModels={selectedModels} />            
            </TabPanel>}
            {regressionResults.length > 0 && <TabPanel>
                <ResultTable results={regressionResults} selectedModels={selectedModels} />
            </TabPanel>}
        </Tabs>
        )}
        </div>
        

        {isPopupOpen && (
            <Overlay togglePopup={togglePopup}>
                {popupType === 'select-file-type' ? (
                    <SelectFileTypePopup togglePopup={togglePopup} setSelectedType={setSelectedType} selectedType={selectedType} next={next}/>
                ) : popupType === 'existing-model' ? (
                    <ExistingModelPopup togglePopup={togglePopup} addMultipleModels={addMultipleModels} selectedModels={selectedModels}/>
                ) : popupType === 'success' ? (
                    <SuccessPopup togglePopup={togglePopup} type='predictions' resetValues={resetValues}/>
                ) : popupType === 'error' ? (
                    <ErrorPopup togglePopup={togglePopup} type='predictions' resetValues={resetValues}/>
                ) : popupType === 'upload-file' ? (
                    <UploadFile togglePopup={togglePopup} goBack={goBack} isFlow={true}/>
                ) : (
                    <ExistingFilePopup togglePopup={togglePopup} goBack={goBack}/>
                )}
            </Overlay>
        )}

    <SnackBar setOpen={setOpen} open={open} message={message} isSuccess={isSuccess} />
    </>
    )
}