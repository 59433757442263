import { Alert } from '@mui/material';

export default function ErrorMessage({ errorMessage }: { errorMessage: string }) {
    return (
        // <div className='w-full border rounded border-error bg-error/[0.2] text-error p-3 flex justify-start items-center gap-3'>
        //     <img src={Warning} alt='invalid-credentials-alert-icon' />
        //     <p className='font-medium'>{errorMessage}</p>
        // </div>
        <Alert severity='error' sx={{
            fontFamily: 'Montserrat',
            fontSize: '14px',
            fontWeight: 500,
        }}>{errorMessage}</Alert>
    )
}