import { MouseEvent, useState } from "react";
import { useSelector } from "react-redux";
import Select from 'react-select';

import AnyFile from '../../../assets/any-file.png';
import View from '../../../assets/link.svg';
import useSizeFormat from "../../../hooks/useSizeFormat";

import { selectSingleFile } from "../../../store/selectors/files.selectors";

import Field from "../../../components/Form/Field";
import ErrorMessage from "../../../components/Alerts/ErrorMessage";
import FormButtons from "../../../components/Buttons/FormButtons";
import useCreateDatasetHandlers from "../../../hooks/handlers/useCreateDatasetHandlers";
import { FieldMappingType } from "../../../hooks/handlers/useCreateDatasetHandlers";
import { CreateDatasetForm } from "../../../types/datasets.types";
import { FieldMappingForm } from "../../../types/all.types";


const defaultForm: CreateDatasetForm = {
    file_ids: [],
    name: '',
    mode: null,
    average_mode: 'name',
    model_names: null,
    stdizer: 'Simple',
    model_ids: [],
    fields_mapping: [],
};

const defaultFieldMapping = {
    'chem-name': {
        name: '',
        op: null,
        type: 'chem-name',
        value: null,
    },
    'chem-id': {
        name: '',
        op: null,
        type: 'chem-id',
        value: null,
    },
    'continuous-value': {
        name: '',
        op: 'log',
        type: 'continuous-value',
        value: null,
    },
    'single-class-label': {
        name: '',
        op: null,
        type: 'single-class-label',
        value: null,
    },
    'split-on-value': {
        name: '',
        op: '',
        type: 'split-on-value',
        value: null,
    },
    'non-activity': [],
}


const modelOptions = [
    {value: 'classification', label: 'Classification'},
    {value: 'regression', label: 'Regression'},
]

const classificationOptions = [
    {value: 'single-class-label', label: 'Single class'},
    {value: 'split-on-value', label: 'Split-on'}
]

const quantifierOptions = [
    {value: 'lt', label: '<' },
    {value: 'le', label: '<='},
    {value: 'gt', label: '>'},
    {value: 'ge', label: '>='}
];






export default function CreateDatasetPopup({ togglePopup, goBack, isFlow, redirect, setPopupMessage } : { 
    togglePopup: (e: MouseEvent<HTMLButtonElement>) => void, 
    goBack?: (e: MouseEvent<HTMLButtonElement>) => void, 
    isFlow: boolean,
    redirect?: boolean,
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,

}) {

    const [ form, setForm ] = useState<CreateDatasetForm>(defaultForm);
    const [ fieldsMapping, setFieldsMapping ] = useState<FieldMappingForm>(defaultFieldMapping);
    const [ activityColumn, setActivityColumn ] = useState('');

    const {
        handleCheck,
        handleInput,
        handleMultiSelect,
        handleSelect,
        setThreshold,
        selectValueType,
        setQuantifier,
        selectModelType,

        backFunction,
        cancelFunction,
        continueFunction,

        fieldOptions,
        isDisabled,
        isLoading,
        errorMessage,
        isAuto,
        modelType,
        valueType,
    } = useCreateDatasetHandlers({ isFlow, redirect, togglePopup, goBack, setPopupMessage, form, setForm, fieldsMapping, setFieldsMapping, activityColumn, setActivityColumn })


    const selectedFile = useSelector(selectSingleFile);

    const { formatBytes } = useSizeFormat();
    
    
    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className={`bg-background rounded p-8 max-w-[600px] w-full popup-menu`}>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-center items-stretch'>  
                            <div className='w-full text-center'>
                                <p className='text-primary font-semibold text-[1.5rem]'>Select Dataset Labels</p>
                            </div>

                            {selectedFile && (
                                <Field label="Selected file" isRequired={true}>
                                    <div className='flex justify-between items-stretch p-6 border-2 border-quaternary bg-white rounded w-full gap-4'>
                                        <div className='grow flex justify-start items-center gap-4'>
                                            <img src={AnyFile} alt='any-file-icon' className="w-[80px]"/>
                                            <div>
                                                <p className="font-semibold text-primary mb-[2px] break-all">{selectedFile.name}</p>
                                                <p className="font-medium text-quaternary text-[12px]">{formatBytes(selectedFile.size)}</p>
                                            </div>
                                        </div>
                                        <div className="h-full flex justify-center items-start gap-2">
                                            <a href={`${process.env.REACT_APP_HOST_URL}/files/${selectedFile._id.$oid}`} target='_blank' rel='noreferrer' className="p-1 rounded-full border-2 border-quaternary hover:bg-secondary/[0.2]"><img src={View} alt='view-icon' className="w-[20px]"/></a>
                                        </div>
                                    </div>
                                </Field>
                            )}

                            <Field label='Dataset Name' isRequired={true}>
                                <div className=' w-full flex justify-between items-stretch rounded '>
                                    <input onChange={handleInput} name='dataset_name' value={form.name} className='border-l border-t border-b border-primary  rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='Dataset Name'/>
                                    <label className={`px-3 border ${isAuto ? 'border-secondary' : 'border-primary'} cursor-pointer flex items-center gap-2 rounded-tr rounded-br relative`}>
                                        {isAuto && <div className="absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10" />}
                                        <input onChange={handleCheck} type='checkbox' checked={isAuto} className='accent-secondary'/>
                                        <p className={`text-[12px] ${isAuto ? 'font-medium text-secondary' : 'font-regular text-primary'}`}>Auto-generate</p>
                                    </label>
                                </div>
                            </Field>

                            <div className='flex justify-between items-center gap-8'>
                                <div className='w-1/2'><Field label='Chemical Name' isRequired={false}>
                                    <Select isClearable={true} maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'chem-name')} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Chemical Name'/>
                                </Field></div>
                                <div className='w-1/2'><Field label='Record ID' isRequired={false}>
                                    <Select isClearable={true} maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'chem-id')} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Record ID'/>
                                </Field></div>
                            </div>

                            <div className='flex justify-between items-center gap-8'>
                                <div className='w-1/2'><Field label='Relevant Data (Non-Activity)' isRequired={false}>
                                    <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} isMulti onChange={(e:any) => handleMultiSelect(e)} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Relevant Data'/>
                                </Field></div>
                                <div className='w-1/2'><Field label='Activity Column' isRequired={true}> 
                                    <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => setActivityColumn(e.value)} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Activity Column'/>
                                </Field></div>
                            </div>

                            <div className='flex justify-between items-center gap-8'>
                                <div className='w-1/2'><Field label='Model Type' isRequired={true}>
                                    <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} options={modelOptions} onChange={(e:any) => selectModelType(e)} className='text-left text-[14px] rounded w-full' placeholder='Model Type'/>
                                </Field></div>
                                <div className='w-1/2'><Field label='Value Type' isRequired={true}>
                                    {!modelType ? (
                                        <input disabled placeholder="Value Type" className='text-left text-[14px] p-2 grow rounded border border-quaternary/[0.3] w-full bg-white'/>
                                    ) : modelType === 'regression' ? (
                                        <input disabled value="Continuous" className='text-left text-[14px] p-2 grow rounded border border-quaternary/[0.3] w-full bg-white text-quaternary/[0.3]'/>
                                    ) : (
                                        <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => selectValueType(e)} options={classificationOptions} className='text-left text-[14px] rounded w-full' placeholder='Value Type'/>
                                    )}
                                </Field></div>
                            </div>

                            {modelType === 'regression' && (
                                <Field label='Quantifier' isRequired={false}>
                                    <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => setQuantifier(e)} options={[{value: 'log', label: 'Log'}, {value: '', label: 'None'}]} className='text-left text-[14px] rounded w-full' placeholder='None'/>
                                </Field>
                            )}

                            {modelType === 'classification' && valueType === 'split-on-value' && (
                                <div className='flex justify-between items-center gap-8'>
                                    <div className="w-1/4">
                                        <Field label="Quantifier" isRequired={true}>
                                            <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} options={quantifierOptions} onChange={(e:any) => setQuantifier(e)} className='text-left text-[14px] rounded w-full' placeholder='Quantifier' />
                                        </Field>
                                    </div>
                                    <div className="w-3/4">
                                        <Field label="Threshold" isRequired={true}>
                                            <input onChange={setThreshold} value={fieldsMapping['split-on-value'] ? (fieldsMapping['split-on-value'] as FieldMappingType).value! : ''}  name='threshold' placeholder="Threshold" className='text-left text-[14px] p-2 grow rounded border border-primary w-full bg-white text-primary'/>
                                        </Field>
                                    </div>
                                </div>
                            )}

                            {errorMessage && (
                                <ErrorMessage errorMessage={errorMessage} />
                            )}

                            <FormButtons backFunction={goBack ? backFunction : undefined} continueFunction={continueFunction} cancelFunction={cancelFunction} isDisabled={isDisabled} isLoading={isLoading}/>
        
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}