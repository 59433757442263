import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FileState } from "../slices/files.slice";

const selectFileReducer = (state:RootState): FileState => state.files;

export const selectFiles = createSelector(
    [selectFileReducer],
    (filesSlice) => filesSlice.files,

);

export const selectSingleFile = createSelector(
    [selectFileReducer],
    (fileSlice) => fileSlice.selectedFile,
);

