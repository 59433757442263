import QuickStartCover from '../../../../assets/tutorialAssets/Quick Start Cover.png'

import FileIcon from '../../../../assets/file.svg';
import DatasetIcon from '../../../../assets/dataset.svg';
import ModelsIcon from '../../../../assets/model.svg';
import PredictionsIcon from '../../../../assets/predictions.svg';
import DashboardIcon from '../../../../assets/dashboard.svg';
import JobsIcon from '../../../../assets/jobs.svg';
import UserIcon from '../../../../assets/users.svg';
import ReadacrossIcon from '../../../../assets/beaker.svg';
import ProfileIcon from '../../../../assets/user1.svg';
import HelpIcon from '../../../../assets/info-circle.svg';
import LogoutIcon from '../../../../assets/logout.svg';

import FilterIcon from '../../../../assets/filter.svg';
import Column from '../../../../assets/columns.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';


import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
import ChevronRight from '../../../../assets/chevron-right-double-black.svg';
import Toggle from '../../../../components/Form/Toggle';


export default function QuickStart() {

    const sections = [
        {id: 'what-is-assay-central', label: 'What is Assay Central?', type: 'main'},
        {id: 'key-features-at-a-glance', label: 'Key Features at a Glance', type: 'main'},
        {id: 'navigation', label: 'Navigation', type: 'sub'},
        {id: 'tables', label: 'Tables', type: 'sub'}
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'getting-started', label: 'Getting Started'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Getting Started</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={QuickStartCover} alt='getting-started-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections}/>

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id="what-is-assay-central" className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">What is Assay Central?</p>
                    <p className='mb-4'>
                        Assay Central ® (AC) is a web-based software program that uses multiple algorithms to facilitate data modeling and prediction.
                    </p>
                    <p className='mb-4'>
                        AC contains both classification and regression modeling. The classification models
                        include adaboost classifier, BernoulliNB, K-Nearest neighbors (kNN) classifier, logistic
                        regression, random forest classifier, and SVC and XGB classifier. 6 The regression
                        models consist of multiple regression algorithms including adaboost regression,
                        Bayesian regression, elastic net regression, kNN regression, random forest regression,
                        support vector machine regression, and XGboost regression.
                    </p>
                    <p>
                        Many computational models have already been built from a large collection of openly
                        available data that represents many thousands of different biological targets. They
                        include both targets that represent diseases and off-targets that fall under the
                        ADME/tox categories.
                    </p>
                </div>

                <div id='key-features-at-a-glance' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Key Features at a Glance</p>
                    <div id='navigation' className='my-10'>
                        <p className='text-[1.5rem] font-semibold mb-6'>Navigation</p>
                        <p className='mb-4'>
                            The left sidebar serves as your primary navigation tool, designed for quick and easy access to all major sections and features of Assay Central.
                            You can choose to collapse or expand the sidebar items by clicking on the <span className="inline-block darken-icons pb-[2px]"><img src={ChevronRight} alt='open-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> icon. 
                            Hovering over an icon in the collapsed state will display a tooltip providing a brief description of that item. 
                        </p>
                        <p>
                            The top section of the sidebar is organized in a hierarchical structure to help guide you through the general workflow of Assay Central: 
                        </p>
                        <ol className='list-decimal list-inside my-8 ml-12 flex flex-col gap-6'>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={DashboardIcon} alt='dashboard-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Dashboard</span></li>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={FileIcon} alt='files-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Files</span></li>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={DatasetIcon} alt='dataset-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Datasets</span></li>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={ModelsIcon} alt='models-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Models</span></li>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={PredictionsIcon} alt='predicitions-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Predictions</span></li>
                            <li><span className="inline-block darken-icons pb-[2px] ml-1"><img src={JobsIcon} alt='jobs-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> <span className='font-semibold'>Job Queue</span></li>
                        </ol>

                        <p className='mb-4'>The <span className="inline-block darken-icons pb-[2px] ml-1"><img src={UserIcon} alt='users-icon' className="w-[16px] relative bottom-[-4px]" /></span> <span className='font-semibold'>User Directory</span> item will appear for admin users only. If your organization has access to <span className="inline-block darken-icons pb-[2px] ml-1"><img src={ReadacrossIcon} alt='readacross-icon' className="w-[16px] relative bottom-[-4px]" /></span> <span className='font-semibold'>Read-Across</span> it will appear here as well.</p>
                        <p>The bottom section of the sidebar contains general resources, including the <span className="inline-block darken-icons pb-[2px] ml-1"><img src={HelpIcon} alt='help-icon' className="w-[16px] relative bottom-[-4px]" /></span> <span className='font-semibold'>Help Center</span> and <span className="inline-block darken-icons pb-[2px] ml-1"><img src={ProfileIcon} alt='profile-icon' className="w-[16px] relative bottom-[-4px]" /></span> <span className='font-semibold'>Profile</span> pages. The <span className="inline-block darken-icons pb-[2px] ml-1"><img src={LogoutIcon} alt='logout-icon' className="w-[16px] relative bottom-[-4px]" /></span> <span className='font-semibold'>Logout</span> button is also located in this section.</p>
                    </div>

                    <div id='tables' className='my-10'>
                        <p className='text-[1.5rem] font-semibold mb-6'>Tables</p>
                        <p className='mb-4'>
                            Tables are a central part of Assay Central, allowing you to organize, manage and analyze all of your data efficiently with the following features:
                        </p>
                        <ol className='list-decimal list-inside my-8 ml-12 flex flex-col gap-6'>
                            <li>
                                <div className={` hover:bg-grad-one bg-grad-one/[0.7] transition-all text-primary inline-flex justify-center items-center gap-2 py-2 px-5 rounded text-nowrap`}>
                                    <img src={FilterIcon} alt='filter-icon' className='w-[20px]'/>
                                    <p className='font-medium hidden lg:block'>Filter</p>
                                </div>
                            </li>
                            <li>
                                <div  className={`transition-all bg-grad-one/[0.7] hover:bg-grad-one text-primary inline-flex justify-center items-center gap-3 py-3 px-5 rounded`}>
                                    <img src={Column} alt='columns-dropdown-icon' className='w-[20px]'/>
                                    <p className='font-medium hidden lg:block'>Columns</p>
                                    <img src={ChevronDown} alt='open-dropdown-icon' />
                                </div>
                            </li>
                            <li>
                                <label className={`hover:cursor-pointer shadow-inner transition-all text-primary bg-darker-background hover:bg-grad-one/[0.3] inline-flex justify-center items-center gap-2 py-3 px-5 rounded text-nowrap`}>
                                    <p className="font-medium transition-all hidden lg:block">Enable Selection</p>
                                    <Toggle isChecked={false} handleCheck={(e:any) => {}}/>
                                </label>
                            </li>
                            <li>
                                <span>Sort by column</span> - Clicking on any column header will sort your data based on the contents of that specific column in ascending or descending order.</li>
                            <li><span>Search by name</span> - Browse or search through your data by typing into the Search Bar located at the top-left corner of each table. If no "Name" column exists, the search will be based on the contents of the first column.</li>
                            <li><span>Filter</span> - Clicking on the Filter button will open a popup window </li>
                        </ol>
                    </div>
                </div>

                <TutorialPagination nextPage={{label: 'Creating datasets', page: 'creating-datasets'}} prevPage={{label: 'Getting started', page: 'quick-start'}} />

            </div>
        </div>
        </>
    )
}