import { useState, useEffect } from "react"

const colors = [
    '#FF8484',
    '#FFA461',
    '#FFF278',
    '#C7DB78',
    '#7BC960',
    '#47AD24',
]

const noLimits = [ 'rmse', 'mpd', 'mae', 'mgd' ];

export default function NumberLine({ label, value, min, max }: { label: string, value: number, min: number, max: number }) {
    const [ percentage, setPercentage ] = useState('0%');
    const [ color, setColor ] = useState('#E7EBEE');

    useEffect(() => {
        const convertWidth = (value: number, min: number, max: number) => {
            const difference = Math.abs(max - min);
            const distanceFromMin = value - min;
            const decimal = distanceFromMin / difference;
            const percent = decimal * 100;
            setPercentage((percent > 100 ? 100 : percent) + '%');
            if (noLimits.includes(label)) {
                setColor('#B4BDC4');
            } else if (decimal <= 0.3) {
                setColor(colors[0]);
            } else if (decimal > 0.3 && decimal <= 0.49) {
                setColor(colors[1]);
            } else if (decimal > 0.49 && decimal <= 0.69) {
                setColor(colors[2]);
            } else if (decimal > 0.69 && decimal <= 0.79) {
                setColor(colors[3]);
            } else if (decimal > 0.79 && decimal <= 0.89) {
                setColor(colors[4]);
            } else if (decimal > 0.89) {
                setColor(colors[5]);
            }
        }

        convertWidth(value, min, max);
    }, [value, min, max, label]);

    return (
        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
            <div className="flex justify-start items-center gap-1 text-[14px]">
                <p className="font-semibold text-secondary uppercase">{label}</p>
                =
                <p className="font-medium">{value}</p>
            </div>
            <div className="w-full rounded-full h-[25px] bg-[#E7EBEE]">
                <div style={{width: `${percentage}`, backgroundColor: `${color}`, transitionDuration: '500ms'}} className={`h-[25px] rounded-full transition-all ease-in-out delay-150`}></div>
            </div>

        </div>
    )
}