import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { deleteExistingJobs } from "../../store/slices/jobs.slice";

import { SingleJobType } from "../../types/jobs.types";
import { SideInfo } from "../../types/all.types";

import API from '../../api';
import { useNavigate } from "react-router-dom";

const useSingleJob = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ singleJob, setSingleJob ] = useState<SingleJobType | null>(null);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);

    const getDatasetPath = (job: SingleJobType) => {
        return `/datasets/${job.params.ds_id}`
    }

    const getResultPath = (job: SingleJobType) => {
    
            if (job.params.model_name) {
                return `/models/${job.params.model_name}`
            } else if (job.params.rs_ids) {
                return `/predictions/${job.params.rs_ids[0]}`
            } else {
                return `/datasets/${job.params.ds_id}`
            }
    
    }

    const setupSingleJob = (singleJob: SingleJobType) => {
        if (singleJob) {
            setRoutes([
                {
                    label: 'Job Queue',
                    path: 'jobs'
                },
                {
                    label: singleJob.params.model_names ? singleJob.params.model_names[0] : singleJob.params.model_name ? singleJob.params.model_name : 'Single Job',
                    path: `jobs/${id}`,
                }
            ])


            setInfo([
                {
                    label: 'Model',
                    value: singleJob.params.model_names ? singleJob.params.model_names[0] : singleJob.params.model_name ? singleJob.params.model_name : 'N/A',
                },
                {
                    label: 'Job Type',
                    value: singleJob.params.job_type,
                },
                {
                    label: 'Status',
                    value: singleJob.status,
                },
                {
                    label: 'Dataset',
                    value: singleJob.params.ds_name,
                    path: getDatasetPath(singleJob),
                },
                {
                    label: 'Result',
                    value: singleJob.params.rs_name ? singleJob.params.rs_name : singleJob.params.model_name ? singleJob.params.model_name : singleJob.params.ds_name,
                    path: getResultPath(singleJob),
                },
                {
                    label: 'Container',
                    value: singleJob.container_name,
                },
                {
                    label: 'Execution Time',
                    value: singleJob.stats.execution_time + 's',
                }
            ])
        }
    }

    useEffect(() => {
        if (id) {
            const fetchJob = async () => {
                const authKey = localStorage.getItem('X-Auth');
                if (authKey) {
                    try {

                        const response = await API.get(`jobs/${id}`, { headers: { 'X-Auth': authKey } });
                        const data: SingleJobType = await response.data;
                        setSingleJob(data);

                    } catch (err:any) {

                        console.log(err);
                        navigate('/jobs/404')

                    }
                    
                } else {
                    navigate('/401');
                }
            
            }

            const refreshJobs = setInterval(() => {
                fetchJob();
            }, 5000);
    
            return () => clearInterval(refreshJobs);
            
        }

    }, [id]);

    useEffect(() => {
        if (singleJob) {
            setupSingleJob(singleJob)
        }
    }, [singleJob]);

    const deleteJobs = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`jobs/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingJobs(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these jobs' : 'this job'}`, false)
            }
        }
    }


    return {
        singleJob,
        routes,
        info,
        deleteJobs
    }

}

export default useSingleJob;