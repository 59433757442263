import { Link } from "react-router-dom";
import SinglePredictionCover from '../../../../assets/tutorialAssets/Making Single Prediction Cover.png';
import PredictionIcon from '../../../../assets/predictions.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';
import Redirect from '../../../../assets/link.svg';
import Trash from '../../../../assets/trash.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
export default function SinglePredictionTutorial() {

    const sections = [
        {id: 'where-do-i-make-single-predictions', label: 'Where do I make single predictions?', type: 'main'},
        {id: 'forming-single-predictions', label: 'Forming single predictions', type: 'main'},
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'single-prediction', label: 'Making Single Predictions'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Making Single Predictions</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={SinglePredictionCover} alt='single-prediction-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id='where-do-i-make-single-predictions' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I make single predictions?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={PredictionIcon} alt='predictions-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Predictions</span> tab</li>
                        <li>Click on the <span className="font-semibold">Make New Prediction</span> <span className="inline-block darken-icons"><img src={ChevronDown} alt='dropdown-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> button located on the right-hand corner of the screen</li>
                        <li>
                            From the dropdown menu, select <span className="font-semibold">From Single Molecule</span>
                        </li>
                    </ol>
                    <p>After making your selection, you will be redirected to the <span className="font-semibold">Make New Prediction</span> page. You also have the option to make sets of predictions on this page by toggling the switch located on the top right-hand corner of the screen. More details about this can be found in the <Link to='/help/creating-resultsets'>Creating resultsets</Link> tutorial.</p>
                </div>

                <div id='forming-single-predictions' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Forming single predictions</p>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/SinglePrediction.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">This view will display two cards, with each card representing a step in the process of creating new single predictions:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>
                            <span className="font-semibold">Selecting a molecule</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    There are several ways to select a molecule for your prediction:
                                    <ol className="list-decimal list-inside my-4 ml-12 flex flex-col gap-4">
                                        <li>Search a specific compound by name, CAS registry number, or SMILES string by selecting the appropriate option and typing into the search bar. The structure of your selected compound will be automatically drawn inside the chemical editor below the search bar.</li>
                                        <li>Draw your molecule inside the chemical editor.</li>
                                    </ol>
                                </li>
                                <li>
                                    Choosing either option will generate a SMILES string below the chemical editor corresponding to the compound you selected.                                 
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Selecting models</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Click on the <span className="font-semibold">Select model to import</span> button. This will trigger a <span className="font-semibold">Choose Existing Model</span> popup where you can select one or multiple models from a list of models that you have access to. Once you make your selection(s), click <span className="font-semibold">Continue</span>.
                                </li>
                                <li>
                                    After your models have been selected, they will appear inside the <span className="font-semibold text-secondary">Select Model</span> card. You can get more information about each model by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the model. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside each model, which will redirect you to that model's Individual Model View in a new tab on your browser.
                                </li>
                                <li>
                                    If you would like to replace some of the models that you've selected, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon next to the model you would like to remove or click on the <span className="font-semibold">Select model to import</span> button and change your selections.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p className="mb-4">Once all of the required steps have been completed, select <span className="font-semibold">Run</span>. If no issues were found in the information you provided, a table will appear at the bottom of the page displaying your results. Similar to the models table, your results will be separated into two tables depending on whether the models you selected were classification or regression. You can toggle between tables by clicking the tabs above each table. If any of your chosen models are conformal predictors, a threshold slider will appear at the top of the Classification table. </p>
                    <p>You can run another single prediction by making changes to your selected compound/models and clicking <span className="font-semibold">Run</span> again.</p>
                </div>

                <TutorialPagination nextPage={{page: 'generating-readacross', label: 'Generating readacross values'}} prevPage={{page: 'creating-resultsets', label: 'Creating resultsets'}} />

            </div>
        </div>
        </>
    )
}