import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import API from '../../api';

import { SingleResultset } from "../../types/predictions.types";
import { SideInfo, Limit, Header, FieldMappingType } from "../../types/all.types";

import { selectApplication, selectAuth } from "../../store/selectors/auth.selector";
import { selectFiles } from "../../store/selectors/files.selectors";
import { convertDecimal, getFileName, filesExists, isAdmin, isOwner, download, modelExists, searchItems, sortByColumn } from "../../data/functions";
import { updateExistingResult, deleteExistingResults } from "../../store/slices/predictions.slice";
import { selectModels } from "../../store/selectors/models.selector";
import { allMethods } from "../../data/models";

const useSingleResult = ({ id, setPopupMessage }: {
    id?: string,
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [ singleResult, setSingleResult ] = useState<SingleResultset | null>(null);
    const [ orderedRecords, setOrderedRecords ] = useState<any[]>([]);
    const [ modifiedRecords, setModifiedRecords ] = useState<any[]>([]);
    const [ info, setInfo ] = useState<SideInfo[]>([]);
    const [ activityColumn, setActivityColumn ] = useState('');
    const [ infoCells, setInfoCells ] = useState(0);
    const [ predictionCells, setPredictionCells ] = useState(0);
    const [ limits, setLimits ] = useState<Limit[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ routes, setRoutes ] = useState<{label: string, path: string}[]>([]);
    const [ headers, setHeaders ] = useState<Header[]>([]);

    const [ modelNames, setModelNames ] = useState<string[]>([]);
    const [ tab, setTab ] = useState('');
    const application = useSelector(selectApplication);
    const [ itemIdentifier, setItemIdentifier ] = useState('');

    const files = useSelector(selectFiles);
    const models = useSelector(selectModels);
    const auth = useSelector(selectAuth);

    const setupSingleResult = (singleResult: SingleResultset, selectedTab?: string) => {
        if (singleResult) {
            setRoutes([
                {
                    label: 'Predictions',
                    path: 'predictions',
                },
                {
                    label: singleResult ? singleResult.name : 'Single Resultset',
                    path: id ? `predictions/${id}` : '',
                }
            ]);

            let names: string[] = [];
            singleResult.fields_mapping.forEach(field => {
                if (field.type === 'predicted-value') {
                    names.push(field.name.split('/')[0]);
                }
            });

            setModelNames(Array.from(new Set(names)));

            let activityName: string = '';

            if (selectedTab) {
                activityName = selectedTab;
            } else {
                activityName = names[0];
            }

            setActivityColumn(activityName);

            // const activityField = singleResult.fields_mapping.find(field => field.type === 'predicted-value');
            // if (activityField) setActivityColumn(activityField.name.split('/')[0]);

            const emptyKeys = Object.keys(singleResult.records[0].fields).filter(key => singleResult.records[0].fields[key] === undefined);
            const all = singleResult.records.map(r => ({ fields: r.fields, molecule: r.molecule.$oid }));

            let identifier: FieldMappingType | null = {name: '', type: '', op: null, value: null};
            const nameField = singleResult.fields_mapping.find(f => f.type === 'chem-name')!;
            const idField = singleResult.fields_mapping.find(f => f.type === 'chem-id')!;

            let filteredArr: FieldMappingType[] = [];
            const filteredFields = Object.keys(singleResult.records[0].fields).filter(f => !emptyKeys.includes(f));
            filteredFields.forEach(f => {
                const mappingObj = singleResult.fields_mapping.find(map => map.name === f);
                if (mappingObj) {
                    filteredArr.push(mappingObj)
                }
            })

            if (!singleResult.fields_mapping.length || (!nameField && !idField)) {
                identifier = null;
            } else {
                if (!nameField) {
                    identifier = idField;
                } else {
                    identifier = nameField;
                }
            }

            let orderedFields: FieldMappingType[] = [];

            if (identifier) {
                setItemIdentifier(identifier.name);
                orderedFields = [identifier].concat(filteredArr.filter(f => f.name !== identifier!.name))
            } else {
                orderedFields = filteredArr;
            }


            let moleculeInfoHeaders: Header[] = [{
                label: 'Structure',
                value: 'Structure',
                isAscending: true,
                isRequired: true,
                isSelected: true,
                isSortable: false,
            }];

            let predictionHeaders: Header[] = [];
            let orderedHeaders: Header[] = [];
            orderedFields.forEach(field => {
                if (field.type !== 'predicted-value' && field.type !== 'ad-score') {
                    moleculeInfoHeaders.push({
                        label: field.name,
                        value: field.name,
                        isAscending: true,
                        isRequired: identifier && field.name === identifier.name ? true : false,
                        isSelected: true,
                        isSortable: true,
                    })
                } else {
                    const splitName = field.name.split('/');
                    const model = splitName[0];
                    if (model === activityName) {
                        predictionHeaders.push({
                            label: splitName[1],
                            value: splitName[1],
                            isAscending: true,
                            isRequired: false,
                            isSelected: true,
                            isSortable: singleResult.conf_pred && allMethods.includes(splitName[1]) ? false : true,
                        })
                    }
                }
            });

            if (singleResult.conf_pred) {
                predictionHeaders.push({
                    label: 'mode',
                    value: 'mode',
                    isAscending: true,
                    isRequired: false,
                    isSelected: true,
                    isSortable: false,
                })
            }

            setInfoCells(moleculeInfoHeaders.length);
            setPredictionCells(predictionHeaders.length);

            const ad = predictionHeaders.find(h => h.label === 'ad-score');
            const mode = predictionHeaders.find(h => h.label === 'mode');
            const duplicate = predictionHeaders.find(h => h.label === 'duplicate');
            if (ad) {
                if (mode) {
                    if (duplicate) {
                        orderedHeaders = [duplicate].concat(ad).concat(mode).concat(predictionHeaders.filter(h => h.label !== 'ad-score' && h.label !== 'mode' && h.label !== 'duplicate'));
                    } else {
                        orderedHeaders = [ad].concat(mode).concat(predictionHeaders.filter(h => h.label !== 'ad-score' && h.label !== 'mode'));
                    }
                } else if (duplicate) {
                    orderedHeaders = [duplicate].concat(ad).concat(predictionHeaders.filter(h => h.label !== 'ad-score' && h.label !== 'duplicate'));
                } else {
                    orderedHeaders = [ad].concat(predictionHeaders.filter(h => h.label !== 'ad-score'));
                }
            } else if (mode) {
                if (duplicate) {
                    orderedHeaders = [duplicate].concat(mode).concat(predictionHeaders.filter(h => h.label !== 'ad-score' && h.label !== 'duplicate'));
                } else {
                    orderedHeaders = [mode].concat(predictionHeaders.filter(h => h.label !== 'ad-score'));
                }
                
            } else if (duplicate) {
                orderedHeaders = [duplicate].concat(predictionHeaders.filter(h => h.label !== 'ad-score'));
            } else {
                orderedHeaders = predictionHeaders;
            }


            const allHeaders = moleculeInfoHeaders.concat(orderedHeaders);

            setHeaders(allHeaders);

            let records: any[] = [];
            let allLimits: Limit[] = [];
            all.forEach(r => {
                let obj = {};
                Object.keys(r.fields).forEach((field,i) => {
                    let key = '';
                    if (field.includes(activityName)) {
                        key = (field.split('/'))[1];
                        allLimits.push({
                            value: (field.split('/'))[1],
                            min: 0,
                            max: 1,
                            isReversed: application === 'megatox' ? true : false,
                        })
                    } else {
                        key = field;
                    }
                    obj = { ...obj, [key]: (!isNaN(Number(r.fields[field]))) ? convertDecimal(Number(r.fields[field])) : r.fields[field] }
                })
                records.push({...obj, id: r.molecule});
            });


            setLimits(allLimits)
            
            let sortedArr: any[] = [];
            records.forEach(r => {
                let obj = {};
                allHeaders.forEach(h => {
                    if (h.label !== 'Structure') {
                        obj = {...obj, [h.label]: r[h.label]}
                    } else {
                        obj = {...obj, [h.label]: ''}
                    }
                })
                sortedArr.push({...obj, id: r.id});
            })

            setOrderedRecords(sortedArr);
            setModifiedRecords(sortedArr);

            setInfo([
                {
                    label: 'Raw File Name',
                    value: getFileName(singleResult.files[0].$oid, files),
                    path: filesExists(singleResult.files[0].$oid, files),
                },
                {
                    label: 'Model Name',
                    value: names.length ? names[0] : '',
                    path: names.length ? modelExists(names[0], models) : undefined,
                },  
                {
                    label: 'Result Description',
                    value: !singleResult.metadata.description ? 'No description' : singleResult.metadata.description,
                    key: 'description',
                    type: 'text-area',
                },
                // {
                //     label: 'Project',
                //     value: !singleResult.metadata.project ? 'No project' :  singleResult.metadata.project,
                //     key: 'project',
                //     type: 'creatable-select',
                // }, 
                {
                    label: 'Measurement Type',
                    value: !singleResult.metadata.measurementType ? 'No measurement type' : singleResult.metadata.measurementType,
                    key: 'measurementType',
                    type: 'text',
                },
                {
                    label: 'Organism',
                    value: !singleResult.metadata.organism ? 'No organism' : singleResult.metadata.organism,
                    key: 'organism',
                    type: 'text',
                },
                {
                    label: 'Target',
                    value: !singleResult.metadata.target ? 'No target' : singleResult.metadata.target,
                    key: 'target',
                    type: 'text',
                },
                {
                    label: 'Created By',
                    value: singleResult.acl ? singleResult.acl.owner : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner)) ? 'owner' : undefined,
                    type: 'select',
                },
                {
                    label: 'Access Type',
                    value: singleResult.acl ? singleResult.acl.access : '',
                    key: auth && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner)) ? 'access' : undefined,
                    type: 'select',
                },
            ]);

        }
    }


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            if (id && authKey) {
                const fetchResults = async () => {
                    setIsLoading(true);
    
                    try {
    
                        const response = await API.get(`resultsets/${id}`, { headers: { 'X-Auth': authKey } });
                        const data: SingleResultset = await response.data;
                        
                        setSingleResult(data);
    
                    } catch (err:any) {
    
                        console.log(err);
                        navigate('/predictions/404');
    
                    }
                    
                    setIsLoading(false);
                };
        
                fetchResults();
            }
        } else {
            navigate('/401');
        }
        
        //eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        if (singleResult) {
            setupSingleResult(singleResult);
        }
        //eslint-disable-next-line
    }, [singleResult]);

    const changeTab = (index: number) => {
        if (modelNames.length) {
            const t = modelNames[index];
            setTab(t);
            if (singleResult) {
                setupSingleResult(singleResult, t)
            }
        }
    }


    const modifyRecords = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>) => {
        let searchArr: any[] = [];
        if (itemIdentifier) {
            searchArr = searchItems(searchInput, orderedRecords, itemIdentifier);
        } else {
            searchArr = orderedRecords;
        }
        const sortedArr = sortByColumn(selectedHeader, headers, true, searchArr, updateHeaders);
        setModifiedRecords(sortedArr);
    }

    const updateResultset = async (obj: any) => {
        const { project, measurement_type, organism, target } = obj;
        const aclObj = {owner: obj.owner, access: obj.access, read: [], write: []};
        const metadata = { project, measurement_type, organism, target };
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {
            try {

                await API.put(`resultsets/${id}`, metadata, { headers: { 'X-Auth': authKey } });
                if (auth && singleResult && (isAdmin(auth) || isOwner(auth, singleResult.acl.owner))) {
                    await API.put(`resultsets/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                }
                const getResponse = await API.get(`resultsets/${id}`, { headers: { 'X-Auth': authKey } });
                const data = getResponse.data;
                setSingleResult(data);
                dispatch(updateExistingResult(data));
                setPopupMessage && setPopupMessage('This resultset was updated successfully!', true);
    
            } catch(err:any) {
    
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating this resultset`, false)
    
            }
        }
    }

    const deleteResults = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`resultsets/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingResults(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);

            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these resultsets' : 'this resultset'}`, false);
                throw new Error('Error');
            }
        }
    }

    const downloadResultset = async (filename: string, id: string, ext:string, threshold: number) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`resultsets/${id}/download?format=${threshold}-allStats-${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error downloading this resultset`, false)
            }
        }
    }

    return {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        headers,
        setHeaders,
        info,
        routes,
        activityColumn,
        infoCells,
        predictionCells,
        limits,
        singleResult,
        deleteResults,
        downloadResultset,
        updateResultset,
        isLoading,
        modelNames,
        changeTab,
        itemIdentifier,
    }

}

export default useSingleResult;