import { Routes, Route, useNavigate } from "react-router-dom"
import ReadAcross from "./page/ReadAcross"
import CreateNewReadAcross from "./page/CreateNewReadAcross"
import SingleReadAcross from "./page/SingleReadAcross"
import NotFoundError from "../../components/Alerts/NotFoundError"
import { useSelector } from "react-redux"
import { selectAuth } from "../../store/selectors/auth.selector"
import { useEffect } from "react"

export default function ReadAcrossRoutes() {
    const authUser = useSelector(selectAuth);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!authUser) {
    //         navigate('/401');
    //     }
    // }, [authUser]);

    return (
        <Routes>
            <Route index element={<ReadAcross />} />
            <Route path=':id' element={<SingleReadAcross />} />
            <Route path='/create-readacross' element={<CreateNewReadAcross />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}