import Plot from 'react-plotly.js';
import { Config } from 'plotly.js';


export default function BoxPlot({data}: {data: Plotly.Data[]}) {


    const config: Partial<Config> = {
        scrollZoom: true,
        displaylogo: false,
        displayModeBar: true,
        responsive: true,
        modeBarButtonsToRemove: ['toggleSpikelines', 'resetScale2d', 'hoverCompareCartesian', 'hoverClosestCartesian'],
        toImageButtonOptions: {
            format: 'svg',
            scale: 1,
        }
    }

    

    const layout_pred: () => Partial<Plotly.Layout> = function()  {
        return {
            xaxis: {
                title: {
                    text: 'Probability-Like Scores',
                    standoff: 40
                },
                zeroline:false
            },
            yaxis: {
                title: {
                    text: 'Molecule',
                    standoff: 40
                },
                showgrid: true,
                gridwidth: 1,
                gridcolor: 'rgb(243,243,243)',
                zeroline: false
            },
            margin: {
                l: 130,
                r: 60,
                t: 60,
                b: 100,
                pad: 10
            },
            autosize: true,
            showlegend: false,
            font: {
                color: '#333333',
                family: 'Trebuchet MS, Tahoma, Arial'
            },
            dragmode: 'pan',
            modebar: {
                color: '#555555',
                activecolor: '#315FB5',
                orientation: 'h'
            },
            hovermode: "closest",
            hoverlabel: { bgcolor: "#FFF" },
        }

    }




    return (
        <Plot data={data} layout={layout_pred()} config={config} className='w-full h-[600px]'/>
    )
}