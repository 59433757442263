import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedModel } from '../../../store/slices/models.slice';
import MenuItem from '@mui/material/MenuItem';
import API from '../../../api';

import { SingleClassificationModel, SingleRegressionModel } from '../../../types/models.types';
import usePrivileges from '../../../hooks/usePrivileges';
import { CircularProgress } from '@mui/material';
import { PopupType } from '../../../hooks/usePopup';
import { useSelector } from 'react-redux';
import { selectCompleteModels, selectModels } from '../../../store/selectors/models.selector';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}

export default function IndividualModelOptions({ setPopupType, toggleDelete, name, downloadFunc, singleModel, application, handleClose, addSingleItem }: { 
    toggleDelete: (e:MouseEvent<HTMLButtonElement>) => void,
    name: string,
    downloadFunc?: (names: string[], name?: string) => Promise<void>,
    singleModel?: SingleClassificationModel[] | SingleRegressionModel[],
    application: string,
    handleClose: () => void,
    addSingleItem?: (id: string) => void,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
 }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);
    const models = useSelector(selectModels);
    const completeModels = useSelector(selectCompleteModels);

    const { canPredict, canDelete } = usePrivileges();

    const downloadItem = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (name && downloadFunc) {
            setIsDownloading(true);
            await downloadFunc([name], name);
            setIsDownloading(false);
        }

        handleClose();
    };

    const getModel = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const authKey = localStorage.getItem('X-Auth');
        const m = models.find(model => model.name === name);

        if (completeModels.length > 0) {
            const m = completeModels.find(model => model.name === name);
            if (m) {
                dispatch(setSelectedModel(m));
                navigate('/predictions/make-new-prediction?type=set')
            }
        } else if (authKey && m) {

            setIsLoading(true);

            try {
                
                const response = await API.get(`models`, { headers: { 'X-Auth': authKey } });
                const data = await response.data;
                const methodIds = data[name].method_ids;

                dispatch(setSelectedModel({...m, methodIds}));
                navigate('/predictions/make-new-prediction?type=set')

            } catch(err:any) {
                console.log(err);
            }

            setIsLoading(false); 

            handleClose();
        }
        
    }



    return (
        <>
                {canPredict && downloadFunc && <MenuItem sx={sx} onClick={(e:any) => {getModel(e);}}>
                    <div className='flex justify-start items-center gap-3 w-full'>
                        {isLoading && <CircularProgress size={12} color='inherit' />}
                        <p>Make New Prediction</p>
                    </div> 
                </MenuItem>}
                {downloadFunc && <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e); handleClose()}}>
                    <div className='flex justify-start items-center gap-2'>
                        {isDownloading && <CircularProgress size={12} color='inherit' />}
                        <p>Export</p>
                    </div>
                </MenuItem>}
                {canDelete(name) && application && (application).includes('assay-central') && 
                    <MenuItem sx={sx} onClick={(e:any) => {toggleDelete(e); setPopupType && setPopupType('delete') ;handleClose(); addSingleItem && addSingleItem(name)}}>Delete</MenuItem>
                }
        </>
     
    )
}