import { createSlice } from "@reduxjs/toolkit";
import { FileType } from "../../types/files.types";

export interface FileState {
    files: FileType[];
    selectedFile: FileType | null;
}

const initialState: FileState = {
    files: [],
    selectedFile: null,
}

export const fileSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        getFiles: (state, action) => {
            state.files = action.payload;
        },
        addFile : (state, action) => {
            state.files = state.files.concat(action.payload)
        },
        setSelectedFile: (state, action) => {
            state.selectedFile = action.payload;
        },
        updateExistingFile: (state, action) => {
            state.files = state.files.map(file => {
                if (file._id.$oid === (action.payload as FileType)._id.$oid) {
                    return {...action.payload}
                } else {
                    return {...file}
                }
            })
        },
        deleteExistingFiles: (state, action) => {
            state.files = state.files.filter(file => !action.payload.includes(file._id.$oid));
        }
    }
})

export const { getFiles, addFile, setSelectedFile, updateExistingFile, deleteExistingFiles } = fileSlice.actions;

export const filesReducer = fileSlice.reducer;
