// assets
import AnyFile from '../../../../assets/any-file.png';
import Upload from '../../../../assets/upload.svg';
import View from '../../../../assets/link.svg';
import Trash from '../../../../assets/trash.svg';

// components
import FlowCard from "../../../../components/Data/FlowCard";
import FlowCardInfo from '../../../../components/Data/FlowCardInfo';

// hooks
import useSizeFormat from '../../../../hooks/useSizeFormat';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// types
import { FileType } from '../../../../types/files.types';
import { MouseEvent } from 'react';
import { SideInfo } from '../../../../types/all.types';
import { PopupType } from '../../../../hooks/usePopup';


export default function UploadFileCard({number, setPopupType, selectedFile, toggleSelectFile, reset}: {
    number: number, 
    selectedFile: FileType | null, 
    toggleSelectFile: (e:MouseEvent<HTMLButtonElement>) => void, 
    reset?: (e:MouseEvent<HTMLButtonElement>) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {
    const { formatBytes } = useSizeFormat();
    const [ isShown, setIsShown ] = useState(false);
    const [ info, setInfo ] = useState<SideInfo[]>([]);

    const toggleShow = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsShown(!isShown);
    }


    useEffect(() => {
        if (selectedFile) {
            setInfo([
                {
                    label: 'File Description',
                    value: !selectedFile.metadata.description ? 'No description' : selectedFile.metadata.description,
                },
                {
                    label: 'Project',
                    value: !selectedFile.metadata.project ? 'No project' : selectedFile.metadata.project,
                }, 
                {
                    label: 'Measurement Type',
                    value: !selectedFile.metadata.measurement_type ? 'No measurement type' : selectedFile.metadata.measurement_type,
                },
                {
                    label: 'Organism',
                    value: !selectedFile.metadata.organism ? 'No organism' : selectedFile.metadata.organism,
                },
                {
                    label: 'Target',
                    value: !selectedFile.metadata.target ? 'No target' : selectedFile.metadata.target,
                },
                {
                    label: 'Access Type',
                    value: selectedFile.acl.access,
                }
            ])
        }
    }, [selectedFile]);


    return (
        <FlowCard label="Select File" number={number} isLocked={false}>
                {selectedFile ? (
                <div className='flex flex-col justify-start items-stretch gap-2'>
                    <div className='flex justify-between items-stretch gap-2 p-6 border-2 border-quaternary bg-white rounded w-full'>
                        <div className='grow flex justify-start items-center gap-4'>
                            <img src={AnyFile} alt='any-file-icon' className="w-[80px]"/>
                            <div>
                                <p className="font-semibold text-primary mb-[2px] break-all">{selectedFile.name}</p>
                                <p className="font-medium text-quaternary text-[12px]">{formatBytes(selectedFile.size)}</p>
                            </div>
                        </div>
                        <div className="h-full flex justify-center items-start gap-2">
                            <a href={`${process.env.REACT_APP_HOST_URL}/files/${selectedFile._id.$oid}`} target='_blank' rel='noreferrer' className="p-1 rounded-full border-2 border-quaternary hover:bg-quaternary lighten transition-all"><img src={View} alt='view-icon' className="w-[20px]"/></a>
                            {reset && <button onClick={reset} className="p-1 rounded-full border-2 border-quaternary hover:bg-quaternary lighten transition-all"><img src={Trash} alt='trash-icon' className="w-[20px]"/></button>}
                        </div>
                    </div>
                    <FlowCardInfo toggleShow={toggleShow} isShown={isShown} info={info} />
                </div>
                ) : (
                <div className='flex justify-center items-center p-6 border-2 border-quaternary/[0.5] border-dashed bg-white rounded'>
                    <button onClick={(e:any) => {toggleSelectFile(e); setPopupType('select-file-type')}} className='transition-all lighten rounded border-2 border-secondary text-secondary text-[14px] font-medium flex justify-center items-center gap-2 bg-white hover:bg-secondary hover:text-white px-4 py-2'>
                        <img src={Upload} className='w-[18px]' alt='upload-icon' />
                        <p>Select file to import</p>
                    </button>
                </div>
                )}
        </FlowCard>
    )
}