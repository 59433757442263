import { createSlice } from "@reduxjs/toolkit";
import { Dataset } from "../../types/datasets.types";

export interface DatasetState {
    datasets: Dataset[];
    selectedDataset: Dataset | null;
}

const initialState: DatasetState = {
    datasets: [],
    selectedDataset: null,
}

export const datasetSlice = createSlice({
    name: 'datasets',
    initialState,
    reducers: {
        getDatasets: (state, action) => {
            state.datasets = action.payload;
        },
        setSelectedDataset: (state, action) => {
            state.selectedDataset = action.payload;
        },
        addDataset: (state, action) => {
            state.datasets = state.datasets.concat(action.payload);
        },
        updateExistingDataset: (state, action) => {
            state.datasets = state.datasets.map(ds => {
                if (ds._id.$oid === (action.payload as Dataset)._id.$oid) {
                    return {...action.payload}
                } else {
                    return {...ds}
                }
            })
        },
        deleteExistingDatasets: (state, action) => {
            state.datasets = state.datasets.filter(dataset => !action.payload.includes(dataset._id.$oid));
        }
    }
})

export const { getDatasets, setSelectedDataset, addDataset, deleteExistingDatasets, updateExistingDataset } = datasetSlice.actions;

export const datasetsReducer = datasetSlice.reducer;
