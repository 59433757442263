import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';

import { selectDatasets } from '../../../store/selectors/datasets.selector';
import { setSelectedDataset } from '../../../store/slices/datasets.slice';

import usePrivileges from '../../../hooks/usePrivileges';
import { PopupType } from '../../../hooks/usePopup';
import { CircularProgress } from '@mui/material';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}



export default function IndividualDatasetOptions({ setPopupType, toggleDeletePopup, id, downloadFunc, application, addSingleItem, handleClose }: { 
    toggleDeletePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    id?: string,
    downloadFunc: (filename: string, id: string, ext: string) => Promise<void>, 
    application: string,
    addSingleItem?: (id: string) => void,
    handleClose: () => void,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const datasets = useSelector(selectDatasets);
    const { canTrain, canDelete } = usePrivileges();
    const [ isDownloadingSDF, setIsDownloadingSDF ] = useState(false);
    const [ isDownloadingXLSX, setIsDownloadingXLSX ] = useState(false);

    const selectSingleDataset = (id?: string) => {
        if (id) {
            const dataset = datasets.find(d => d._id.$oid === id);
            if (dataset) {
                dispatch(setSelectedDataset(dataset))
            }
        }
    }

    const downloadItem = async (e:MouseEvent<HTMLButtonElement>, ext: string) => {
        e.preventDefault();
        
        if (id) {
            const ds = datasets.find(d => d._id.$oid === id)
            if (ds) {
                if (ext === 'xlsx') {
                    setIsDownloadingXLSX(true);
                } else {
                    setIsDownloadingSDF(true);
                }

                const filename = `${ds.name ? ds.name : 'datset'}.${ext}`;
                await downloadFunc(filename, id, ext);

                if (ext === 'xlsx') {
                    setIsDownloadingXLSX(false);
                } else {
                    setIsDownloadingSDF(false);
                }
            }
        }

        handleClose();
    }

    return (
        <>  
                {canTrain && application && (application).includes('assay-central') && <MenuItem sx={sx} onClick={(e:any) => {navigate('/models/create-new-model?dataset=existing'); selectSingleDataset(id); handleClose()}}>Train New Model</MenuItem>}
                <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e, 'xlsx')}} disabled={isDownloadingXLSX}>
                    <div className='flex justify-start items-center gap-2'>
                        {isDownloadingXLSX && <CircularProgress size={12} color='inherit' />}
                        <p>Export (.xlsx)</p>
                    </div>
                </MenuItem>
                <MenuItem sx={sx} onClick={(e:any) => {downloadItem(e, 'sdf')}} disabled={isDownloadingSDF}>
                    <div className='flex justify-start items-center gap-2'>
                        {isDownloadingSDF && <CircularProgress size={12} color='inherit' />}
                        <p>Export (.sdf)</p>
                    </div>
                </MenuItem>
                {canDelete(id!) && application && (application).includes('assay-central') && <MenuItem sx={sx} onClick={(e:any) => {toggleDeletePopup(e); setPopupType && setPopupType('delete'); addSingleItem && addSingleItem(id ? id : ''); handleClose()}}>Delete</MenuItem>}
        </>
           
    )
}