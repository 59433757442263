import { Routes, Route } from "react-router-dom"
import CreateNewModel from "./pages/CreateNewModel"
import Models from "./pages/Models"
import NotFoundError from "../../components/Alerts/NotFoundError"
// import { useSelector } from "react-redux"
// import { selectAuth } from "../../store/selectors/auth.selector"
// import { useEffect } from "react"
import SingleModelTemplate from "./pages/SingleModelTemplate"


export default function ModelsRoutes() {
    // const authUser = useSelector(selectAuth);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (!authUser) {
    //         navigate('/401');
    //     }
    // }, [authUser]);

    return (
        <Routes>
            <Route index element={<Models />} />
            <Route path=':name' element={<SingleModelTemplate />} />
            <Route path='/create-new-model' element={<CreateNewModel />} />
            <Route path='404' element={<NotFoundError />} />
        </Routes>
    )
}