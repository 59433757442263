import { MouseEvent, useState, useEffect } from "react"
import API from '../../api';
import { CircularProgress } from "@mui/material";
import { Limit, SideInfo } from "../../types/all.types";
import EditInfo from "../Data/EditInfo";
import Camera from '../../assets/camera.svg';
import NumberLine from "../Data/NumberLine";
import { getThresholdValue } from "../../data/functions";
import ThresholdSlider from "../Data/ThresholdSlider";
import { useLocation } from "react-router-dom";
import NoImg from '../../assets/noImg.svg';
import CopyIcon from '../../assets/copy.svg';

export default function ChemicalPopup({ togglePopup, id, info, records, limits, isConfPred, setPopupMessage }: { 
    togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    id: string,
    info?: SideInfo[],
    records?: any,
    limits?: Limit[],
    isConfPred?: boolean,
    setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void,
}) {
    const [ identifier, setIdentifier ] = useState('');
    const [ fields, setFields ] = useState<any | null>(null);
    const [ threshold, setThreshold ] = useState(0.5);
    const location = useLocation();
    const [ src, setSrc ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notFound, setNotFound ] = useState(false);


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {


                const fetchMoleculeInfo = async (id: string) => {
                    const res = await API.get(`molecule/${id}`, { headers: { 'X-Auth': authKey } });
                    const molData = await res.data;
                    setIdentifier(molData.smiles);
                }

                const fetchImageBlob = async (id: string) => {
                    setIsLoading(true);
                    try {
                        const res = await API.get(`render/${id}?size=200`,  { headers: { 'X-Auth': authKey }, responseType: 'blob' })
                        const data = await res.data;
                        const blob = new Blob([data], { type: 'image/svg+xml' });
                        const blobUrl = URL.createObjectURL(blob);
                        setSrc(blobUrl);
                        setNotFound(false);
                    } catch (err:any) {
                        console.log(err);
                        setSrc('');
                        setNotFound(true);
                    }
                    setIsLoading(false);

                }

                fetchMoleculeInfo(id);
                fetchImageBlob(id);
            }
        
    }, [id]);


    const screenshot = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const div = document.getElementById('structure-img');
        if (div) {
            const img = div.firstElementChild as HTMLImageElement;
            if (img) {
                const imgSrc = img.src;

                const newImg = new Image();
                newImg.onload = () => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    canvas.width = 400;
                    canvas.height = 400;

                    if (context) {
                        context.drawImage(img, 0, 0, 400, 400);
                        const href = canvas.toDataURL();
                        const anchor = document.createElement('a');
                        anchor.download = `${identifier}.png`;
                        anchor.href = href;
                        anchor.click();
                        anchor.remove();
                        
                    }
                }
                newImg.src = imgSrc;
            }
        }
    }

    const copyToClipboard = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (identifier) {
            if (!navigator.clipboard) {
                // Clipboard API not available, fallback method
                fallbackCopyToClipboard(identifier);
                return;
            }
            navigator.clipboard.writeText(identifier).then(function() {
                console.log('Copied to clipboard successfully!');
                setPopupMessage('Copied to clipboard successfully!', true)
            }, function(err) {
                console.error('Could not copy text: ', err);
                setPopupMessage('There was an error copying to clipboard', false)
            });
        }
        
    }
    
    function fallbackCopyToClipboard(text: string) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
    
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
    
        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
            setPopupMessage('Copied to clipboard successfully!', true)
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
            setPopupMessage('There was an error copying to clipboard', false)
        }
    
        document.body.removeChild(textArea);
    }
    


    useEffect(() => {
        if (records && records.length > 0) {
            const obj = records.find((r:any) => r.id === id);
            if (obj) {
                let fieldObj: any = {};
                Object.keys(obj).forEach(key => {
                    if (['ad-score', 'ada', 'DL', 'bnb', 'knn', 'lreg', 'rf', 'svc', 'xgb'].includes(key)) {
                        fieldObj[key] = obj[key];
                    }
                })
                
                setFields(fieldObj);

            }
        } else {
            setFields(null)
        }
    }, [records, id]);

    

    return (
        <>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-5 max-w-[1200px] w-full'>
                    <div className='popup-max-height overflow-hidden'>
                        <div className='grid grid-cols-2 grid-rows-1 gap-6'>
                                
                            <div className='rounded flex flex-col items-end gap-6 p-6 bg-white'>
                                <div className="flex gap-3">
                                    <button onClick={copyToClipboard} className="lighten transition-all py-2 px-4 rounded border-2 border-secondary text-secondary hover:bg-secondary hover:text-white font-semibold flex justify-center items-center gap-2">
                                        <img src={CopyIcon} alt='copy-icon' className="w-[16px]" />
                                        <p>Copy SMILES</p>
                                    </button>
                                    <button onClick={(e:MouseEvent<HTMLButtonElement>) => screenshot(e)} className="lighten transition-all py-2 px-4 rounded border-2 border-secondary text-secondary hover:bg-secondary hover:text-white font-semibold flex justify-center items-center gap-2">
                                        <p>Screenshot</p>
                                        <img src={Camera} alt='screenshot-icon' className="w-[16px]"/>
                                    </button>
                                </div>
                                {isLoading ? (
                                    <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={100} color='inherit'/></div>
                                ) : src ? (
                                    <div id='structure-img' className="w-full max-h-[600px]"><img src={src} alt='chemical-structure-svg' className="object-cover w-full"/></div>
                                ) : notFound ? (
                                    <div className="w-full max-h-[600px]"><img src={NoImg} alt='no-img' className="object-cover w-full"/></div>
                                ) : (
                                    <div className="w-full aspect-square flex justify-center items-center text-[#D9D9D9]"><CircularProgress size={100} color='inherit'/></div>
                                )}
                            </div>

                            <div className="flex flex-col justify-between items-stretch gap-6">
                                <div className="flex flex-col justify-start items-stretch gap-6">
                                <p className="font-semibold text-[18px] text-primary">{identifier}</p>
                                <div className="max-h-[500px] overflow-y-auto">
                                    <div className="flex flex-col gap-6 justify-start items-stretch">
                                        {fields !== null && isConfPred && (
                                            <div className="">
                                                <ThresholdSlider threshold={threshold} setThreshold={setThreshold}/>
                                            </div>
                                        )}

                                        <div className='flex flex-col gap-1 justify-center items-stretch'>
                                            {fields !== null && Object.keys(fields).map(key => {
                                                const limitsObj = limits && limits.find(limit => limit.value === key);
                                                if (limitsObj && !Array.isArray(fields[key])) {
                                                    return (
                                                        <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(fields[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                    )
                                                } else {
                                                    const { color, label } = getThresholdValue(fields[key], threshold);

                                                    return (
                                                        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                            <p className="font-semibold text-secondary uppercase">{key}</p>
                                                            <div style={{backgroundColor: color}} className="py-1 px-3 w-full text-center text-[12px] rounded-full font-medium">
                                                                {label}
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                        
                                        {info && !location.pathname.includes('readacross') ? (
                                            <EditInfo info={info} isEdit={false} />
                                        ) : info && location.pathname.includes('readacross') && (
                                            <>
                                            {info.map(n => {
                                                const rounded = Math.round(Number(n.value));
                                                return (
                                                    <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                        <p className="font-semibold text-secondary uppercase">{n.label}</p>
                                                        <div className={`py-1 px-3 w-full text-center text-[12px] rounded-full font-medium ${rounded === 0 ? 'bg-[#7BC960]' : 'bg-[#FF8484]'}`}>
                                                            {n.value}
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                            </>
                                        )}
                                        
                                        
                                    </div>
                                </div>
                                </div>
                                <div className="w-full flex justify-end items-center">
                                    <div className="flex gap-2">
                                        <button onClick={togglePopup} className='flex items-center justify-center gap-2 py-2 px-4 rounded text-white text-[14px] text-medium border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all font-medium disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                                            Done
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}