

// components
import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import JobRows from '../../../components/Table/JobTableRows';
import PageHeader from "../../../components/Page/PageHeader";
import Pagination from '../../../components/Page/Pagination';
import FilterPopup from '../../../components/Popups/FilterPopup';
import DeletePopup from "../../../components/Popups/DeletePopup";
import EmptyTable from "../../../components/Table/EmptyTable";



// functions + data
import { defaultJobsFilter } from "../../../data/jobs";

// hooks
import { useState, ChangeEvent } from "react"
import useEnableSelection from "../../../hooks/useEnableSelection";
import usePagination from '../../../hooks/usePagination';
import usePopup from '../../../hooks/usePopup';

// types
import TableControls from '../../../components/Table/TableControls';
import FilterButton from '../../../components/Buttons/FilterButton';
import useJobs from "../../../hooks/pageHooks/useJobs";
import SnackBar from "../../../components/Alerts/SnackBar";
import useMessage from "../../../hooks/useMessage";
import TableBase from "../../../components/Table/TableBase";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";



export default function JobQueue() {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ selectedGroups, setSelectedGroups ] = useState<{groupName: string, groupID: string, items: string[]}[]>([]);
    const [ allGroupsAreSelected, setAllGroupsAreSelected ] = useState(false);
    const theme = useSelector(selectTheme);

    const {
        open,
        setOpen,
        message,
        setPopupMessage,
        isSuccess,
    } = useMessage();

    const {
        groupData,
        modifiedJobs,
        modifyJobs,
        filterFields,
        filter,
        setFilter,
        headers,
        setHeaders,
        associatedItems,
        isLoading,
        deleteJobs
    } = useJobs({setPopupMessage, searchInput, selectedHeader: !selectedHeader ? undefined : selectedHeader});

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyJobs, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })
    

    const { 
        handleChange, 
        isChecked, 
        toggleChecked, 
    } = useEnableSelection({allItems: undefined});


    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedJobs, count: 50});

    const { 
        setPopupType,
        popupType,
        togglePopup,
        isPopupOpen
    } = usePopup();




    const selectAllGroups = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked === true) {
            const selectedArr: {groupName: string, groupID: string, items: string[]}[] = [];
            modifiedJobs.forEach(job => {
                const associatedObj = associatedItems.find(item => item.groupID === job.id);
                if (associatedObj) {
                    selectedArr.push({
                        groupName: job.name,
                        groupID: job.id,
                        items: associatedObj.items.map(item => item.id)
                    })
                }
            })
            setSelectedGroups(selectedArr);
            setAllGroupsAreSelected(true);
        } else {
            setSelectedGroups([]);
            setAllGroupsAreSelected(false);
        }
    }


    const selectAllItemsInGroup = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked === true) {
            const associatedObj = associatedItems.find(item => item.groupID === id);
            if (associatedObj) {
                setSelectedGroups(selectedGroups.concat({
                    groupName: associatedObj.groupName,
                    groupID: associatedObj.groupID,
                    items: associatedObj.items.map(item => item.id)
                }))
            }
            
        } else {
            setSelectedGroups(selectedGroups.filter(group => group.groupID !== e.target.name));
        }
    }

    const selectGroupForDeletion = (e:any, groupID: string) => {
        const associatedObj = associatedItems.find(item => item.groupID === groupID);
            if (associatedObj) {
                setSelectedGroups([{
                    groupName: associatedObj.groupName,
                    groupID: associatedObj.groupID,
                    items: associatedObj.items.map(item => item.id)
                }])
            }
    };

    const selectSingleItemInGroup = (e: ChangeEvent<HTMLInputElement>, groupID: string, id: string) => {
        const associatedObj = associatedItems.find(item => item.groupID === groupID);
        const groupInSelectedArr = selectedGroups.find(group => group.groupID === groupID);
        let selectedArr: {groupName: string, groupID: string, items: string[]}[] = [];

        if (associatedObj && groupInSelectedArr) {
            selectedGroups.forEach(group => {
                if (group.groupID === groupID) {
                    selectedArr.push({
                        ...group,
                        items: e.target.checked ? group.items.concat(id) : group.items.filter(item => item !== id)
                    })
                } else {
                    selectedArr.push({...group})
                }
            })
        } else if (associatedObj && !groupInSelectedArr) {
            if (e.target.checked) {
                selectedArr = selectedGroups.concat({groupName: associatedObj.groupName, groupID: associatedObj.groupID, items: [id]})
            }
        }

        if (selectedArr.length && groupInSelectedArr) {
            const objInSelected = selectedArr.find(arr => arr.groupID === groupID);
            if (objInSelected && !objInSelected.items.length) {
                setSelectedGroups(selectedArr.filter(arr => arr.groupID !== groupID))
            } else {
                setSelectedGroups(selectedArr);
            }
        } else {
            setSelectedGroups(selectedArr);
        }
        
    }

    const deselectItems = () => {
        setSelectedGroups([]);
    }


    return (
        <>


            <PageHeader label='Job Queue'></PageHeader>

            <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default`}>
            {/* Search Bar & Buttons Component */}
            <TableControls applySearch={applySearch} identifier="Name" toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedGroups}>
                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType}/>
                <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
            </TableControls>
                {isLoading || paginatedItems.length > 0 ? (
                    <>
                    <TableBase
                        isLoading={isLoading}
                        isChecked={isChecked}
                        isPopupOpen={false}
                        selectAllRows={selectAllGroups} 
                        allAreSelected={allGroupsAreSelected} 
                        hasOptions={isLoading ? false : true} 
                        headers={headers} 
                        selectedHeader={selectedHeader} 
                        setSortedColumn={setSortedColumn}
                    >
                        {paginatedItems && paginatedItems.map(group => {
                            const obj = associatedItems.find(item => item.groupID === group.id);
                            const selectedGroup = selectedGroups.find(g => g.groupID === group.id);
                            if (obj) {    
                                return (
                                    <JobRows 
                                    setGroupForDeletion={selectGroupForDeletion} 
                                    togglePopup={togglePopup} 
                                    setPopupType={setPopupType}
                                    selectedGroup={selectedGroup} 
                                    selectAllItemsInGroup={selectAllItemsInGroup} 
                                    selectSingleItemInGroup={selectSingleItemInGroup} 
                                    groupData={group} 
                                    associatedItems={obj.items} 
                                    headers={headers} 
                                    path='jobs' 
                                    isChecked={isChecked} 
                                    />
                                )
                            } else return null;
                        })}
                    </TableBase>
                    {paginatedItems.length > 0 && (
                        <Pagination count={50} total={groupData.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                    )}
                    </>
                ) : (
                    <EmptyTable subject="Your job queue is currently empty" searchInput={searchInput} />
                )}
                
            </div>

            <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/> 
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} groups={selectedGroups} deselectItems={deselectItems} deleteFunc={deleteJobs} togglePopup={togglePopup} label={`${selectedGroups.length > 1 ? 'these jobs' : 'this job'}`}/>
                    ) : (
                        <FilterPopup itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={defaultJobsFilter} fieldsObj={filterFields} />
                    )}
                </Overlay>
            )}
    
        </>
    )
}