import { SideInfo } from "../../types/all.types";
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import CreatableSelect from "react-select/creatable";
import Select from 'react-select'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUsers } from "../../store/selectors/users.selector";
import useExists from "../../hooks/useExists";


const accessOptions = [
    {value: 'private', label: 'private'},
    {value: 'authenticated', label: 'authenticated'},
]

export default function EditInfo({ info, isEdit, options, metadata, setMetadata }: { 
    info: SideInfo[], 
    isEdit: boolean, 
    options?: any,
    updateFunc?: (id: string, obj: any) => void, 
    metadata?: any,
    setMetadata?: Dispatch<SetStateAction<any>>,
}) {
    const users = useSelector(selectUsers);
    const { fileExists } = useExists();
   
    const [ userOptions, setUserOptions ] = useState<{value: string, label: string}[]>([]);


    useEffect(() => {
        let form: any = {};
        info.forEach(n => {
            if (n.key) {
                const key = n.key as string;

                form[key] = n.value; 
            }
            
        });
        setMetadata && setMetadata(form);

        //eslint-disable-next-line
    }, [info]);


    useEffect(() => {
        if (users && users.length) {
            setUserOptions(users.map(u => ({value: u._id.$oid, label: u.full_name})))
        }
    }, [users]);


    const handleInput = (e: any) => {
        setMetadata && setMetadata({...metadata, [e.target.name]: e.target.value})
    }

    const handleSelect = (e:any, name: string) => {
        setMetadata && setMetadata({...metadata, [name]: e.value})
    }

    const getLabel = (key:string, value: string) => {
        if (key === 'access') {
            const opt = accessOptions.find(a => a.value === value);
            if (opt) return opt.label;
        } else {
            const opt = userOptions.find(u => u.value === value);
            const label = userOptions.find(u => u.label === value);
            if (opt) {
                return opt.label;
            } else if (label) {
                return label.label;
            }
        }
    };



   

    return (
        <>
        {info && info.map((n,i) => (
            <div key={i} className='flex flex-col justify-start items-start gap-[2px] w-full font-medium'>
                <p className="text-[14px] text-tertiary font-semibold">{n.label}:</p>
                {isEdit && n.key && n.type ? (
                    <>
                    {n.type === 'text' ? (
                        <input onChange={handleInput} name={n.key} value={metadata[n.key]} className="w-full rounded border border-primary focus:outline-tertiary p-3"/>
                    ) : n.type === 'text-area' ? (
                        <textarea onChange={handleInput} name={n.key} value={metadata[n.key]} className="w-full rounded border border-primary focus:outline-tertiary p-3 min-h-[100px]"/>
                    ) : n.type === 'creatable-select' ? (
                        <>
                        <CreatableSelect onChange={(e:any) => handleSelect(e, (n.key as string))} value={{value: metadata[n.key], label: metadata[n.key]}}  className="w-full" options={options ? options[n.key] : []}/>
                        </>
                    ) : (
                        <Select onChange={(e:any) => handleSelect(e, (n.key as string))} value={{value: metadata[n.key], label: getLabel(n.key, metadata[n.key])}} className="w-full" options={n.key === 'access' ? accessOptions : n.key === 'owner' ? userOptions : []} />
                    )} 
                    </>
                ) : !isEdit && n.path && fileExists(n.path) ? (
                    <Link to={n.path} className="text-highlight hover:text-tertiary transition-all">{n.value}</Link>
                ) : (
                    <p className={`font-medium ${!n.value ? 'text-[#a9a9a9]' : 'text-primary'}`}>{n.key && n.key === 'access' ? getLabel(n.key, n.value as string) : !n.value ? 'No value' : n.value}</p>
                )}
                
            </div>
        ))}
        </>
    )
}