import { MouseEvent, useState, useEffect, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { addUser, updateUser } from "../../../store/slices/users.slice";
import { selectUsers } from "../../../store/selectors/users.selector";
import { selectApplication } from "../../../store/selectors/auth.selector";

import EyeOff from '../../../assets/eye-off.svg';
import Eye from '../../../assets/eye.svg';
import Warning from '../../../assets/alert-circle.svg'
import Select from 'react-select';
import Overlay from "../../../components/Popups/Overlay";
import Field from "../../../components/Form/Field";
import { AddEditUserForm } from "../../../types/users.types";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import API from '../../../api';
import md5 from "md5";

const privilegeOptions = [
    {value: 'admin', label: 'Administrator'},
    {value: 'train', label: 'Train Models'},
    {value: 'create', label: 'Create Files and Datasets'},
    {value: 'predict', label: 'Make Predictions'}
]

const defaultForm = {
    full_name: '',
    username: '',
    password: '',
    password2: '',
    email: '',
    company: '',
    active: true,
    privileges: [],
}


export default function AddEditUserPopup({toggleUserPopup, isAdd, deselectItems, items, setPopupMessage}: {
    toggleUserPopup: (e:MouseEvent<HTMLButtonElement>) => void, 
    isAdd: boolean,
    deselectItems: () => void,
    items: string[],
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,
}) {
    const [ form, setForm ] = useState<AddEditUserForm>(defaultForm);
    const [ show, setShow ] = useState(false);
    const [ show2, setShow2 ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const dispatch = useDispatch();
    const users = useSelector(selectUsers);
    const application = useSelector(selectApplication);
    const [ options, setOptions ] = useState<{value: string, label: string}[]>(privilegeOptions);

    const getLabel = (value: string) => {
        const opt = options.find(p => p.value === value);
        if (opt) {
            return opt.label
        }
    }
    
    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const handleSelect = (e:any, name: string) => {
        const values: string[] = e.map((val:any) => val.value);
        const currentPrivileges = form.privileges;
        if (currentPrivileges.includes('admin')) {
            setForm({...form, [name]: values.filter(v => v !== 'admin')});
        } else if (values.includes('admin') && !currentPrivileges.includes('admin')) {
            setForm({...form, [name]: ['admin']});
        } else {
            setForm({...form, [name]: values});
        }
    }

    const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.checked})
    }

    const togglePassword = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShow(!show);
    }

    const togglePassword2 = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShow2(!show2);
    }

    useEffect(() => {
            if (!form.full_name || !form.username || !form.email || !form.company || !form.privileges.length) {
                if (isAdd && (!form.password || !form.password2)) {
                    setIsDisabled(true);
                } else {
                    setIsDisabled(false);
                }
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
    }, [form, isAdd]);


    useEffect(() => {
        if (isAdd) {
            setForm(defaultForm)
        } else {
            const id = items[0];
            const user = users.find(u => u._id.$oid === id);
            if (user) {
                setForm({
                    full_name: user.full_name,
                    username: user.username,
                    password: '',
                    password2: '',
                    email: user.email,
                    company: user.company,
                    active: user.active,
                    privileges: user.privileges,
                })
            }
        }
    }, [isAdd, items, users]);

    useEffect(() => {
        if (application && !(application).includes('assay-central')) {
            setOptions([
                {value: 'admin', label: 'Administrator'},
                {value: 'create', label: 'Create Files'},
                {value: 'predict', label: 'Make Predictions'}
            ])
        } else {
            setOptions(privilegeOptions);
        }
    }, [application]);

    

    const addOrEditUser = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);
        if (isAdd && form.password2 !== form.password) {
            setErrorMessage('Passwords do not match');
            setIsLoading(false);
            setIsDisabled(false);
            return;
        } else if (isAdd && form.password.length < 8) {
            setErrorMessage('Passwords must be at least 8 characters long');
            setIsLoading(false);
            setIsDisabled(false);
            return;
        } else {

            const body = {
                username: form.username,
                password_hash: form.password ? md5(form.password) : null,
                full_name: form.full_name,
                email: form.email,
                company: form.company,
                active: form.active,
                privileges: form.privileges,
            }

            const authKey = localStorage.getItem('X-Auth');

            if (authKey) {
                if (isAdd) {
                    try {
                        const response = await API.post(`users`, body, { headers: { 'X-Auth': authKey } });
                        const data = await response.data;
                        dispatch(addUser(data));
                        setErrorMessage('');
                        toggleUserPopup(e);
                        setPopupMessage && setPopupMessage('User added successfully!', true);
                    } catch (err:any) {
                        setErrorMessage(err.data.detail)
                    }
                } else {
                    try {
                        const response = await API.put(`users/${items[0]}`, body, { headers: { 'X-Auth': authKey } });
                        const data = await response.data;
                        dispatch(updateUser(data));
                        deselectItems();
                        setErrorMessage('');
                        toggleUserPopup(e);
                        setPopupMessage && setPopupMessage('User edited successfully', true);
                    } catch (err:any) {
                        console.log(err);
                        const details = err.response.data.detail;
                        if (details) {
                            setErrorMessage(details);
                        } else {
                            setErrorMessage(`There was an error ${isAdd ? 'adding' : 'editing'} this user`);
                        }
                    }
                }
             
            }

            setIsLoading(false);
            setIsDisabled(false);
        }
        
    }


    return (
        <Overlay togglePopup={toggleUserPopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-8  max-w-[600px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                    <div className='flex flex-col gap-8 justify-center items-stretch'>
                    
                        <div className="w-full flex justify-between items-stretch">
                                <p className='text-primary font-semibold text-[2rem]'>{isAdd ? 'Add' : 'Edit'} User</p>
                        </div>

                        <Field label='Full Name' isRequired={true}>
                            <div className="w-full flex justify-between items-stretch gap-2">
                                <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                    <input name='full_name' value={form.full_name} onChange={handleInput} className={`rounded text-left text-[14px] p-2 grow`} placeholder='Full Name'/>
                                </div>
                             
                            </div>
                        </Field>

                        <Field label='Username' isRequired={true}>
                            <div className="w-full flex justify-between items-stretch gap-2">
                                <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                    <input name='username' value={form.username} onChange={handleInput} className={`text-left text-[14px] p-2 grow rounded`} placeholder='Username'/>
                                    
                                </div>
                              
                            </div>
                        </Field>

                        <Field label='Email' isRequired={true}>
                            <div className="w-full flex justify-between items-stretch gap-2">
                                <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                    <input name='email' value={form.email} onChange={handleInput} type='email' className={`text-left text-[14px] p-2 grow rounded`} placeholder='Email'/>
                                </div>
                              
                            </div>
                        </Field>
                        
                        <Field label='Password' isRequired={isAdd ? true : false}>
                        <div className="w-full flex justify-between items-start gap-2">
                            <div className="flex flex-col gap-4 justify-start items-start w-full">
                                <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                    <input name='password' value={form.password} onChange={handleInput} type={show ? 'text' : 'password'} className={`rounded-tl rounded-bl text-left text-[14px] p-2 grow ${isAdd && 'rounded-tr rounded-br'}`} placeholder='New Password'/>
                                    <div>
                                    <button onClick={togglePassword} className="flex items-center px-3 h-full bg-white rounded-tr rounded-br">
                                        {show ? <img src={EyeOff} alt='hide-password-icon'/> : <img src={Eye} alt='show-password-icon'/>}  
                                    </button>
                                    </div>
                                </div>

                                <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                    <input name='password2' value={form.password2} onChange={handleInput} type={show2 ? 'text' : 'password'} className={`rounded-tl rounded-bl text-left text-[14px] p-2 grow ${isAdd && 'rounded-tr rounded-br'}`} placeholder='Confirm Password'/>
                                    <div>
                                    <button onClick={togglePassword2} className="flex items-center px-3 h-full bg-white rounded-tr rounded-br">
                                        {show2 ? <img src={EyeOff} alt='hide-password-icon'/> : <img src={Eye} alt='show-password-icon'/>}  
                                    </button>
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                        </Field>

                      

                        <Field label='Company' isRequired={true}>
                        <div className="w-full flex justify-between items-stretch gap-2">
                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                <input name="company" value={form.company} onChange={handleInput} className={`rounded text-left text-[14px] p-2 grow`} placeholder='Company'/>
                            </div>
                          
                        </div>
                        </Field>

                        <Field label='Privileges' isRequired={true}>
                        <div className="w-full flex justify-between items-stretch gap-2">
                            <div className='rounded border border-primary w-full flex justify-between items-stretch'>
                                <Select onChange={(e:any) => handleSelect(e, 'privileges')} isMulti options={options} value={application && !(application).includes('assay-central') ? form.privileges.filter(p => p !== 'train').map(p => ({value: p, label: getLabel(p)})) : form.privileges.map(p => ({value: p, label: getLabel(p)}))} className={`no-border text-left text-[14px] grow rounded`} placeholder='Privileges'/>
                            </div>
                           
                        </div>
                        </Field>

                        <Field label='Active Status' isRequired={true}>
                        <div className="w-full flex justify-start items-stretch gap-2">
                            <div className='rounded border border-primary flex justify-between items-stretch'>
                                <label className='flex justify-start items-center gap-2 py-3 px-3 bg-white grow rounded hover:cursor-pointer'>
                                    <p className="text-[14px]">{form.active ? 'Active' : 'Inactive'}</p>
                                    <label htmlFor="toggle-active" className="inline-flex items-center cursor-pointer">
                                        <input id='toggle-active' type="checkbox" checked={form.active} onChange={handleCheck} name='active' className="sr-only peer" />
                                        <div className=" relative w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-tertiary"></div>
                                    </label>
                                </label>
                                
                            </div>
                           
                        </div>
                        </Field>

                        {errorMessage && (
                            <div className='w-full border rounded border-error bg-error/[0.2] text-error p-3 flex justify-start items-center gap-3'>
                                <img src={Warning} alt='invalid-credentials-alert-icon' />
                                <p className='font-medium'>{errorMessage}</p>
                            </div>
                        )}

                        <div className='flex justify-end gap-2'>
                            
                                <button onClick={(e:any) => {toggleUserPopup(e); deselectItems()}} className='py-2 px-4 rounded border border-quaternary hover:bg-quaternary hover:text-white transition-all bg-white text-quaternary text-[14px] mr-2'>Cancel</button>
                                <button disabled={isDisabled} onClick={addOrEditUser} className='flex justify-center items-center gap-2 py-2 px-4 rounded text-white text-[14px] border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
                                {isLoading ? 
                                    <>
                                    <CircularProgress size={16} color='inherit'/>
                                    <p>Saving</p>
                                    </>
                                    : 
                                    <p>{isAdd ? 'Continue' : 'Save Changes'}</p>
                                }
                                </button>
                            
                        </div>
                    </div>
                    </div>

                    
                </div>
            </div>
        </Overlay>
    )
}