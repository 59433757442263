import { useSelector } from "react-redux"
import { selectAuth, selectTheme } from "../../store/selectors/auth.selector"
import { useState, MouseEvent, useEffect } from "react";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'


import EditIcon from '../../assets/edit.svg';
import SaveIcon from '../../assets/check.svg';
import ProfileDetails from "./components/ProfileDetails";
import Themes from "./components/Themes";



export default function Profile() {
    const theme = useSelector(selectTheme);
    const authUser = useSelector(selectAuth);
    const [ isAdd, setIsAdd ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const toggleAdd = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsAdd(!isAdd);
    }
    
    useEffect(() => {
        if (!authUser) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [authUser]);


    return (
        <>
            
                <ProfileDetails authUser={authUser}  />
                {/* <Themes /> */}
                {/* <div className="mb-16">
                    {isLoading ? (  
                        <div className="w-full"><Skeleton height={42}/></div>
                    ) : (
                        <div className="flex justify-start items-center gap-3">
                            <p className="font-semibold text-[2rem]">{authUser!.user.full_name}</p>
                            <img src={authUser!.user.active ? Active : Inactive} alt='user-status' className="w-[32px]" />
                        </div>
                    )}
                </div> */}

                {/* <div className="bg-white drop-shadow-default rounded w-full flex flex-col gap-6 justify-start items-start mt-6 p-8">
                    <div className="w-full flex justify-between items-center gap-6 mb-4">
                        <p className='text-[1.5rem] font-semibold text-nowrap text-primary'>Profile Details</p>
                        <div className="flex gap-3 justify-end items-center">
                            <button onClick={toggleAdd} className="flex justify-center items-center gap-3 py-2 px-4 rounded bg-tertiary hover:bg-secondary transition-all text-white whiten">
                                <img className="w-[18px]" src={isAdd ? SaveIcon : EditIcon} alt="edit-icon" />
                                <p className="font-medium">{isAdd ? 'Save Changes' : 'Edit Profile'}</p>
                            </button>
                            {isAdd && (
                                <button className="py-2 px-4 border rounded border-quaternary text-quaternary font-medium hover:bg-quaternary hover:text-white transition-all" onClick={toggleAdd}>Cancel</button>
                            )}
                        </div>
                    </div>
                    
                </div> */}
                
            
        </>
    )
}