
// hooks
import { useState, useEffect } from "react";

// data
import API from '../../../api';


// components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageHeader from "../../../components/Page/PageHeader";
import CreateDatasetDropdown from "../components/CreateDatasetDropdown";

// types
import usePrivileges from '../../../hooks/usePrivileges';
import { Dataset } from "../../../types/datasets.types";
import { useDispatch } from "react-redux";
import { getDatasets } from "../../../store/slices/datasets.slice";
import { useNavigate } from "react-router-dom";
import usePopup from "../../../hooks/usePopup";
import SeparateDatasets from "./SeparateDatasets";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import { selectDatasets } from "../../../store/selectors/datasets.selector";




export default function Datasets() {
    const [ continuous, setContinuous ] = useState<Dataset[]>([]);
    const [ singleClass, setSingleClass ] = useState<Dataset[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selection, setSelection ] = useState<"new" | "existing">("new");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { canCreate } = usePrivileges();
    const datasets = useSelector(selectDatasets);

    
    const theme = useSelector(selectTheme);

    const { 
        isPopupOpen,
        setPopupType,
        popupType,
        togglePopup
    } = usePopup();

    const toggleObj = { isPopupOpen, setPopupType, popupType, togglePopup }

    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchDatasets = async () => {
                    setIsLoading(true);
                    try {
    
                        const response = await API.get('datasets', { headers: { 'X-Auth': authKey } })
                        const data: Dataset[] = await response.data;
                        // triageDatasets(data);
                        dispatch(getDatasets(data));
    
                    } catch (err:any) {
    
                        console.log(err);
                        navigate('/500');
    
                    }
                    
                    setIsLoading(false);
                }
        
                fetchDatasets();
            } else {
                navigate('/401')
            }

        //eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if (datasets.length > 0) {
            triageDatasets(datasets);
        }
    }, [datasets]);

    const triageDatasets = (ds: Dataset[]) => {
        if (ds.length > 0) {
            let cont: Dataset[] = [];
            let single: Dataset[] = [];
            ds.forEach(set => {
                if (set.fields_mapping.length > 0) {
                    const field = set.fields_mapping.find(f => ['continuous-value', 'single-class-label', 'split-on-value'].includes(f.type));
                    if (field) {
                        if (field.type === 'continuous-value') {
                            cont.push(set);
                        } else {
                            single.push(set);
                        }
                    }
                }
            });

            setContinuous(cont);
            setSingleClass(single);
        }
    }

   


    return (
        <div>
        

            <PageHeader label='Datasets'>
                {canCreate && <CreateDatasetDropdown togglePopup={togglePopup} setPopupType={setPopupType} setSelection={setSelection}/>}
            </PageHeader>
            
            <Tabs>
                <TabList>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Continuous-Value</Tab>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Single-Class-Label</Tab>
                </TabList>

                <TabPanel>
                    <SeparateDatasets isLoading={isLoading} preloadedDatasets={continuous} selection={selection} toggleObj={toggleObj}/>
                </TabPanel>
                <TabPanel>
                    <SeparateDatasets isLoading={isLoading} preloadedDatasets={singleClass} selection={selection} toggleObj={toggleObj}/>
                </TabPanel>
            </Tabs>
            
            
       
        </div>
    )
}