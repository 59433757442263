// hooks
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import useLayout from "../../../hooks/useLayout";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useSelection from "../../../hooks/useEnableSelection";

// assets
import Info from '../../../assets/info-circle.svg';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// components
import Grid from "../../../components/Grid/Grid";
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import AdditionalInfoSideMenu from "../../../components/Popups/AdditionalInfoSideMenu";
import Pagination from "../../../components/Page/Pagination";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import ToggleView from "../../../components/Page/ToggleView";
import Button from "../../../components/Buttons/Button";
import TableControls from "../../../components/Table/TableControls";
import ChemicalPopup from "../../../components/Popups/ChemicalPopup";
import DeletePopup from "../../../components/Popups/DeletePopup";
import DataRepresentationBox from "../../../components/Data/DataRepresentationBox";
import BoxPlot from "../../../components/Data/BoxPlot";
import NumberLine from "../../../components/Data/NumberLine";
import ReadacrossPopup from "../../ReadAcross/components/ReadacrossPopup";
import ThresholdSlider from "../../../components/Data/ThresholdSlider";
import { getThresholdValue } from "../../../data/functions";
import { regressionMethodsArr } from "../../../data/models";
import SnackBar from "../../../components/Alerts/SnackBar";
import EmptyTable from "../../../components/Table/EmptyTable";
import useSingleResult from "../../../hooks/pageHooks/useSingleResult";
import { SideInfo } from "../../../types/all.types";
import { useSelector } from "react-redux";
import { selectNav } from "../../../store/selectors/nav.selector";
import useMessage from "../../../hooks/useMessage";
import GridItemBase from "../../../components/Grid/GridItemBase";
import SingleCompoundOptions from "../../../components/Dropdowns/SingleCompoundOptions";
import ImageCell from "../../../components/Data/ImageCell";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import OptionsDropdownBase from "../../../components/Dropdowns/OptionsDropdownBase";
import IndividualResultsetOptions from "../components/IndividualResultsetOptions";
import Overlay from "../../../components/Popups/Overlay";
import useModifyTableItems from "../../../hooks/useModifyTableItems";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";

const metricsList = ['DL', 'ad-score', 'ada', 'avg', 'bnb', 'knn', 'lreg', 'max', 'rf', 'svc', 'adar', 'br', 'min', 'rfr', 'svr', 'svc'];
const classificationMetrics = ['DL', 'ad-score', 'bnb', 'knn', 'lreg', 'svc', 'rf', 'ada'];
const median = (scores: any[]) => {
    scores.sort()
    let midpoint = scores[Math.floor(scores.length/2)]
    if (scores.length % 2 === 1) {
        return midpoint
    }
    else {
        let lower_midpoint = scores[Math.floor(scores.length/2)-1]
        return ( (midpoint + lower_midpoint) / 2 )
    }
}

export default function SingleResultsetPage() {
    const { id } = useParams();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const [ boxData, setBoxData ] = useState<any>();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ threshold, setThreshold ] = useState(0.5);
    const [ isClassification, setIsClassification ] = useState(false);
    const isNavOpen = useSelector(selectNav)

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();
    
    const {
        orderedRecords,
        modifiedRecords,
        modifyRecords,
        info,
        routes,
        headers,
        setHeaders,
        activityColumn,
        infoCells,
        predictionCells,
        limits,
        singleResult,
        deleteResults,
        downloadResultset,
        updateResultset,
        modelNames, 
        changeTab,
        itemIdentifier,
    } = useSingleResult({id, setPopupMessage});

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedRecords, count: 50});

    const {
        selectHeaders,
        setSortedColumn,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyRecords, setHeaders, searchInput, selectedHeader, setSelectedHeader })

    const { 
        isSideMenuOpen, 
        toggleSideMenu, 
        togglePopup,
        isPopupOpen,
        setPopupType,
        popupType
    } = usePopup();

    const { 
        toggleView, 
        isGrid 
    } = useLayout();

    const { 
        isChecked, 
        toggleChecked,
        addSingleItem,
        deselectItems,
        selectedRows
    } = useSelection({allItems: undefined});



    useEffect(() => {
        
        if (singleResult) {
            

            let vals = []
            let method_list = []
            let molecule_name = null
            let molecule_id = null

            for (let field of singleResult.fields_mapping) {
                if (field.type === 'predicted-value') {
                    method_list.push(field.name)
                }
                else if (field.type === 'chem-id') {
                    molecule_id = field.name
                }
                else if (field.type === 'chem-name') {
                    molecule_name = field.name
                }
            }

            for (let molecule of singleResult.records) {
                let mol_object: any = {}

                mol_object['name'] = 'Molecule' + molecule.ord
                
                mol_object['text'] = molecule.molecule['$oid']
                mol_object['type'] = 'box'
                mol_object['boxmean'] = true

                mol_object['hoverinfo'] = 'x'
                mol_object['hovertext'] = ''
                
                let method_preds = []

                for (let meth of method_list) {
                    if (!meth.includes('avg')) {
                        method_preds.push(molecule.fields[meth])
                    }
                }

                let med = median(method_preds)
                mol_object['sort_by'] = med

                let color_pallette = ['rgb(94,123,54)','rgb(148,186,51)','rgb(255,215,45)','rgb(249,160,41)','rgb(219,94,43)','rgb(216,29,56)']
                if (med > 0.9) {
                    mol_object['marker'] = {
                        color: color_pallette[0],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.8) {
                    mol_object['marker'] = {
                        color: color_pallette[1],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.7) {
                    mol_object['marker'] = {
                        color: color_pallette[2],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.6) {
                    mol_object['marker'] = {
                        color: color_pallette[3],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else if (med > 0.5) {
                    mol_object['marker'] = {
                        color: color_pallette[4],
                        size: 3,
                        opacity: 0.8
                    }              
                }
                else {
                    mol_object['marker'] = {
                        color: color_pallette[5],
                        size: 3,
                        opacity: 0.8
                    }          
                }
                mol_object['x'] = method_preds

                vals.push(mol_object)          
            }

            vals.sort((a, b) => (a.sort_by > b.sort_by) ? 1 : -1)
            setBoxData(vals);
        }

            
    }, [singleResult]);

    useEffect(() => {
        if (!orderedRecords.length) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [orderedRecords]);

    useEffect(() => {
        if (singleResult) {
            const predictField = singleResult.fields_mapping.find(f => f.type === 'predicted-value' && f.name.split('/')[0] === activityColumn);
            if (predictField) {
                const method = predictField.name.split('/')[1];
                if (regressionMethodsArr.includes(method)) {
                    setIsClassification(false);
                } else {
                    setIsClassification(true);
                }
            }
        }
        
    }, [singleResult, activityColumn]);

    

    

    const [ moleculeID, setMoleculeID ] = useState('');
    const [ popupInfo, setPopupInfo ] = useState<SideInfo[]>([])

    useEffect(() => {
        if (moleculeID) {
            const record = modifiedRecords.find(r => r.id === moleculeID);
            if (record) {
                let info: SideInfo[] = [];
                Object.keys(record).forEach(key => {
                    if (record[key] && key !== 'id' && !classificationMetrics.includes(key)) {
                        info.push({
                            label: key,
                            value: record[key],
                        })
                    }
                })

                setPopupInfo(info)
            }

            
        }
    }, [moleculeID, modifiedRecords]);




    return (
        <>
            

                <SinglePageHeader isLoading={isLoading} label={singleResult ? singleResult.name : 'Single Prediction'} routes={routes}>
                    <OptionsDropdownBase isLoading={isLoading}>
                        <IndividualResultsetOptions downloadFunc={downloadResultset} toggleDeletePopup={togglePopup} setPopupType={setPopupType} id={id} threshold={threshold} handleClose={() => {}}/>
                    </OptionsDropdownBase>
                    <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                        <img src={Info} alt='info-logo' className="w-[16px]"/>
                        <p className='font-medium text-nowrap'>Show Details</p>
                    </Button>
                </SinglePageHeader>

                <div className='mb-6'>
                    <DataRepresentationBox isLoading={isLoading} label='Prediction Values'>
                        <div className='w-full flex justify-center items-center gap-10'>
                        <BoxPlot data={boxData as any} />
                        </div>
                    </DataRepresentationBox>
                </div>

                <Tabs onSelect={(index: number, lastIndex: number, event: any) => changeTab(index)}>
                    {modelNames.length > 1 && (
                    <div className={`${isNavOpen ? 'table-container-expanded' : 'table-container'} overflow-x-auto overflow-y-hidden`}>
                        <TabList className='prediction-tab-list'>
                        {modelNames.map(name => (
                            <Tab>{name}</Tab>
                        ))}
                        </TabList>
                    </div>
                    )}

                    {modelNames.map(name => (
                        <TabPanel>
                            <TableControls applySearch={applySearch} identifier={itemIdentifier ? itemIdentifier : undefined} setInput={setSearchInput} searchInput={itemIdentifier ? searchInput : undefined}>
                                <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                                <ToggleView toggleView={toggleView} isGrid={isGrid} />
                            </TableControls>

                            {singleResult && singleResult.conf_pred && (
                                <div className="p-4 mb-2">
                                <ThresholdSlider threshold={threshold} setThreshold={setThreshold}/>
                                </div>
                            )}

                            <div className=''>
                            {isGrid ? (
                                <>
                                    <Grid isLoading={isLoading} noResults={!paginatedItems.length ? true : false}>
                                        {paginatedItems.length > 0 ? (
                                            <>
                                            {paginatedItems.map(item => {
                                                let title = '';
                                                const h = headers.find(header => header.isRequired && header.label !== 'Structure');
                                                if (h) {
                                                    title = item[h.value];
                                                }
                                                return (
                                                    <GridItemBase
                                                        title={title}
                                                        optionButton={
                                                            <IndividualOptionsDropdownBase isGrid={true}>
                                                                <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                                            </IndividualOptionsDropdownBase>
                                                        }
                                                        isChecked={false}
                                                        gridType="structure"
                                                        item={item}
                                                    >
                                                        <button onClick={(e:any) => {togglePopup(e); setPopupType('chemical'); setMoleculeID(item.id)}}>
                                                            <ImageCell type="grid" id={item.id} />
                                                        </button>
                                                        <div className='max-h-[180px] overflow-y-scroll'>
                                                            <div className='flex flex-col gap-1 justify-center items-stretch'>
                                                            <>
                                                            {Object.keys(item).filter(key => metricsList.includes(key)).map(key => {
                                                                const limitsObj = limits.find(limit => limit.value === key);
                                                                if (limitsObj && !Array.isArray(item[key])) {
                                                                    return (
                                                                        <NumberLine label={key} max={limitsObj.max} min={limitsObj.min} value={Number((Math.round(Number(item[key as keyof object]) * 100) / 100 as number).toFixed(2))} />
                                                                    )
                                                                } else {
                                                                    const { color, label } = getThresholdValue(item[key], threshold);

                                                                    return (
                                                                        <div className="w-full p-1 flex flex-col gap-1 justify-center items-start">
                                                                            <p className="font-semibold text-secondary uppercase">{key}</p>
                                                                            <div style={{backgroundColor: color}} className="py-1 px-3 w-full text-center text-[12px] rounded-full font-medium">
                                                                                {label}
                                                                            </div>

                                                                        </div>
                                                                    )
                                                                }
                                                            })}
                                                            </>
                                                            </div>
                                                        </div>      
                                                    </GridItemBase>
                                                )
                                            })}
                                            </>
                                        ) : (
                                            <EmptyTable isGrid={true} searchInput={searchInput} subject="This file contains no records"/>
                                        )}
                                        
                                    </Grid>
                                    {paginatedItems.length > 0 && (
                                        <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                        firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                                    )}
                                </>
                            ) : (
                                <>
                                {isLoading || paginatedItems.length > 0 ? (
                                    <>
                                        <TableBase
                                            isLoading={isLoading} 
                                            isPopupOpen={false}
                                            isChecked={false}
                                            hasOptions={isLoading ? false : true}
                                            headers={headers} 
                                            selectedHeader={selectedHeader} 
                                            setSortedColumn={setSortedColumn}
                                            prediction={{
                                                infoCells,
                                                predictionCells,
                                                activityColumn
                                            }}
                                        >
                                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                                <TableRowBase 
                                                    isChecked={false}
                                                    action={{togglePopup, setPopupType,setMoleculeID}}
                                                    item={item} 
                                                    headers={headers} 
                                                    threshold={singleResult && singleResult.conf_pred ? threshold : undefined} 
                                                    limits={limits}
                                                    rowType={singleResult && singleResult.conf_pred ? 'conformal' : isClassification ? 'color' : 'base'}
                                                >
                                                    <IndividualOptionsDropdownBase>
                                                        <SingleCompoundOptions handleClose={() => {}} addSingleItem={addSingleItem} togglePopup={togglePopup} setPopupType={setPopupType} moleculeID={item.id}/>
                                                    </IndividualOptionsDropdownBase>
                                                </TableRowBase>
                                            ))}
                                        </TableBase>
                                        {paginatedItems.length > 0 && (
                                            <Pagination count={50} total={orderedRecords.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                                        )}
                                    </>
                                ) : (
                                    <EmptyTable searchInput={searchInput} subject="This file contains no records"/>
                                )}
                                
                                </>
                                
                                )}
                            </div>
                            

                        </TabPanel>
                    ))}
                </Tabs>
                

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

       
            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} updateFunc={updateResultset} info={info} label='Result Details' togglePopup={toggleSideMenu} />

            )}
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deleteFunc={deleteResults} items={id ? [id] : []} path="/predictions" togglePopup={togglePopup} label='this resultset'/>
                    ) : popupType === 'readacross' ? (
                        <ReadacrossPopup togglePopup={togglePopup} deselectItems={deselectItems} items={selectedRows} />
                    ) : (
                        <ChemicalPopup setPopupMessage={setPopupMessage} info={popupInfo} isConfPred={singleResult && singleResult.conf_pred ? true : false} togglePopup={togglePopup} limits={limits}  id={moleculeID} records={modifiedRecords}/>
                    )}
                </Overlay>
            )}

        </>
    )
}