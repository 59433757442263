import { Link } from "react-router-dom";
import CreateResultsetCover from '../../../../assets/tutorialAssets/Making Prediction Set Cover.png';
import PredictionIcon from '../../../../assets/predictions.svg';
import ChevronDown from '../../../../assets/chevron-down.svg';
import Redirect from '../../../../assets/link.svg';
import Trash from '../../../../assets/trash.svg';
import TutorialNav from "../../components/TutorialNav";
import TutorialPagination from "../../components/TutorialPagination";
import TutorialHeader from "../../components/TutorialHeader";
export default function CreatingResultsetsTutorial() {

    const sections = [
        {id: 'where-do-i-make-sets-of-predictions', label: 'Where do I make sets of predictions?', type: 'main'},
        {id: 'forming-sets-of-predictions', label: 'Forming sets of predictions', type: 'main'},
    ]

    return (
        <>
        <div className="w-full min-h-screen p-10 bg-background text-primary">
            <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-start">

                <TutorialHeader page={{path: 'creating-resultsets', label: 'Creating Resultsets'}} />

                <p className="font-semibold text-[2.5rem] mb-10">Creating Resultsets</p>

                <div className="w-full rounded-lg overflow-hidden mb-10">
                    <img src={CreateResultsetCover} alt='create-resultset-cover' className="w-full h-full object-cover object-center"/>
                </div>

                <TutorialNav tutorials={sections} />

                <div className="w-full border-b border-[#b4b4b4] mt-10"></div>

                <div id='where-do-i-make-sets-of-predictions' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Where do I make sets of predictions?</p>
                    <ol className="list-decimal list-inside mb-4 flex flex-col gap-4">
                        <li>Navigate to the <span className="inline-block darken-icons"><img src={PredictionIcon} alt='predictions-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span><span className="font-semibold">Predictions</span> tab</li>
                        <li>Click on the <span className="font-semibold">Make New Prediction</span> <span className="inline-block darken-icons"><img src={ChevronDown} alt='dropdown-icon' className="w-[16px] relative bottom-[-4px] mr-1" /></span> button located on the right-hand corner of the screen</li>
                        <li>
                            From the dropdown menu, select <span className="font-semibold">From Set of Molecules</span>
                        </li>
                    </ol>
                    <p>After making your selection, you will be redirected to the <span className="font-semibold">Make New Prediction</span> page. You also have the option to make single predictions on this page by toggling the switch located on the top right-hand corner of the screen. More details about this can be found in the <Link to='/help/single-prediction'>Making single predictions</Link> tutorial.</p>
                </div>

                <div id='forming-sets-of-predictions' className="my-10 max-w-[900px] text-[16px]">
                    <p className="text-[2rem] font-semibold mb-6">Forming sets of predictions</p>
                    <div className="w-full rounded-lg overflow-hidden mb-6">
                        <video className="object-cover w-full" autoPlay loop muted><source src={'/PredictionSet.mp4'} type="video/mp4"/></video>
                    </div>
                    <p className="mb-4">This view will display three cards, with each card representing a step in the process of creating a new set of predictions. You cannot move onto the last step before all previous steps have been completed:</p>
                    <ol className="list-decimal list-inside my-6 ml-12 flex flex-col gap-6">
                        <li>
                            <span className="font-semibold">Selecting a file</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    Click on the <span className="font-semibold text-secondary">Select file to import</span> button inside the Select File card. This will trigger a <span className="font-semibold">Select File Type</span> popup where you can choose between importing an existing file, uploading a new file or creating a new file from a list of CAS registry numbers. Selecting an option and clicking <span className="font-semibold text-secondary">Continue</span> will trigger another dialog corresponding to that selection, as seen when creating a new dataset from the <span className="font-semibold">Datasets</span> page.
                                </li>
                                <li>
                                    After your file has been selected/created, it will appear inside the <span className="font-semibold text-secondary">Select File card</span>. You can get more information about the file by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the file. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside the file, which will redirect you to that file’s Individual File View in a new tab on your browser
                                </li>
                                <li>
                                    If you would like to replace your selected file with a different file, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon. This will reset your file selection and will not delete the file itself. 
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Selecting a model</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    <span>Averaging Mode</span> - This field contains options for how the model you’ve selected will make predictions:
                                    <ul>
                                        <li><span>Individual Model-Methods</span> - You can make predictions on all selected methods from the trained models available to you. Method selection will only be available once a model has been selected.</li>
                                        <li><span>Model Family</span> - You can also select models by name using all of its methods to make your predictions</li>
                                    </ul>
                                </li>
                                <li>
                                    To select a model, click on the <span className="font-semibold">Select model to import</span> button. This will trigger a <span className="font-semibold">Choose Existing Model</span> popup where you can select a model from a list of models that you have access to. Once you make a selection, click <span className="font-semibold">Continue</span>.
                                </li>
                                <li>
                                    After your model has been selected, it will appear inside the <span className="font-semibold text-secondary">Select Model</span> card. You can get more information about the dataset by toggling the <span className="font-semibold text-secondary">Show Information</span> button beneath the model. You can also click the <span className="inline-block darken-icons"><img src={Redirect} alt='redirect-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon inside the model, which will redirect you to that model's Individual Model View in a new tab on your browser.
                                </li>
                                <li>
                                    If you would like to replace your selected model with a different model, click on the <span className="inline-block darken-icons"><img src={Trash} alt='trash-icon' className="w-[16px] relative bottom-[-2px] mr-1" /></span> icon. This will reset your model selection and will not delete the model itself.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Setting result parameters</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                Once a file and model have been selected, you can set the parameters for the resultset to be generated inside the <span className="font-semibold">Set Resultset Parameters</span> card. Complete all required fields in this card before continuing:
                                    <ul className="list-circle list-inside ml-12 my-4 flex flex-col gap-4">
                                        <li><span className="font-semibold">Resultset Name</span> - A descriptive name for your resultset. Checking <span className="font-semibold text-secondary">Autogenerate</span> will match the name to the model you previously selected.</li>
                                        <li><span className="font-semibold">Chemical name</span> - The column in your selected file containing the name for each compound in that file.</li>
                                        <li><span className="font-semibold">Record ID</span> - The column in your selected file containing the unique identifier for each compound in that file. This can be the same as the Chemical Name field you chose if no such column exists.</li>
                                        <li><span className="font-semibold">Relevant Data (Non-Activity) <span className="text-secondary">(optional)</span></span> - The column(s) in your selected file containing additional information that you would like to include in your resultset.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className="font-semibold">Setting Known Values</span>
                            <ul className="list-disc list-inside ml-12 my-4 flex flex-col gap-4">
                                <li>
                                    This card will only appear if the activity column for your selected model is a Single Classification Label. Only set this field value if you know the actual values of the prediction you’re running - most often this is done to validate the model. 
                                </li>
                                <li>Note that resetting your selected file and model will also reset the fields inside the <span className="font-semibold">Set Result Parameters</span> and <span className="font-semibold">Set Known Values</span> cards.</li>
                            </ul>
                        </li>
                    </ol>
                    <p className="mb-4">Once all of the required steps have been completed, select <span className="font-semibold">Run</span>. If no issues were found in the information you provided, a <span className="font-semibold">Success dialog</span> will be shown stating that your prediction has started processing. Within this dialog, you can choose <span className="font-semibold">Make another prediction</span> to restart the process or <span className="font-semibold">Finish</span>, which will redirect you back to the Predictions page. </p>
                    <p className="mb-4">If there is an issue with initiating predictions, an <span className="font-semibold">Error dialog</span> will be shown. You can choose <span className="font-semibold">Try Again</span> to re-initiate model training or <span className="font-semibold">Start Over</span> to restart the process from scratch. </p> 
                    <p>As your prediction is being generated, it will appear as a task in your <span className="font-semibold">Job Queue</span>. Once this process has completed, your results will appear in the Resultsets table.</p>
                </div>

                <TutorialPagination nextPage={{page: 'single-prediction', label: 'Making single predictions'}} prevPage={{page: 'training-models', label: 'Training models'}} />
            </div>
        </div>
        </>
    )
}