import { ChangeEvent, Dispatch, ReactNode, SetStateAction, MouseEvent, useState, useEffect } from "react";
import Search from '../../assets/search.svg';
import Download from '../../assets/download.svg';
import Trash from '../../assets/trash.svg';
import Key from '../../assets/key.svg';
import SolidButton from "../Buttons/SolidButton";
import EnsembleDropdown from "../../routes/Models/components/EnsembleDropdown";
import { CircularProgress, Tooltip } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectApplication, selectAuth, selectTheme } from "../../store/selectors/auth.selector";
import Select from 'react-select';
import { PopupType } from "../../hooks/usePopup";
import { isAdmin } from "../../data/functions";

const options = [
    {value: 'search_model_name', label: 'Name'},
    {value: 'search_created_by', label: 'Created By'}
]

const getLabel = (value: string) => {
    const obj = options.find(opt => opt.value === value);
    if (obj) return obj.label;
}


export default function TableControls({ setPopupType, children, setInput, searchInput, selectedRows, setAggregate, aggregate, toggleDeletePopup, downloadFunc, toggleCheck, deselectItems, name, id, searchFunc, searchType, setSearchType, identifier, applySearch }: {
    children: ReactNode,
    setPopupType?: Dispatch<SetStateAction<PopupType>>,
    setInput?: Dispatch<SetStateAction<string>>,
    searchInput?: string,
    selectedRows?: string[] | {groupName: string, groupID: string, items: string[]}[],
    setAggregate?: Dispatch<SetStateAction<'max' | 'min'>>,
    aggregate?: 'max' | 'min',
    toggleDeletePopup?: (e: MouseEvent<HTMLButtonElement>) => void,
    downloadFunc?: (names: string[], name?: string, ids?: string[]) => Promise<void>,
    toggleCheck?: (e: MouseEvent<HTMLButtonElement>) => void,
    deselectItems?: () => void,
    name?: string,
    id?: string,
    searchFunc?: (e: MouseEvent<HTMLButtonElement>, input: string, type: 'search_model_name' | 'search_created_by') => void,
    searchType?: 'search_model_name' | 'search_created_by',
    setSearchType?: Dispatch<SetStateAction<'search_model_name' | 'search_created_by'>>,
    identifier?: string,
    applySearch?: (e:any, input: string) => void,
}) {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ hasMultiDelete, setHasMultiDelete ] = useState(false);
    const [ hasMultiExport, setHasMultiExport ] = useState(false);
    const [ hasMultiAccessControl, setHasMultiAccessControl ] = useState(false);

    const { pathname } = useLocation();
    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);
    const auth = useSelector(selectAuth);

    useEffect(() => {
        // Show multidelete or multiexport buttons based on pathname

        const mainPathname = pathname.split('/')[1];
        
        // All routes in assay central can have multidelete. In megatox, users can delete everything expect models.
        if (['files', 'datasets', 'predictions', 'jobs', 'users', 'readacross'].includes(mainPathname)) {
            setHasMultiDelete(true);
        } else if (['models'].includes(mainPathname)) {
            if (!(application).includes('assay-central')) {
                setHasMultiDelete(false);
            } else {
                setHasMultiDelete(true);
            }
        } else {
            setHasMultiDelete(false);
        }

        if (['files', 'datasets', 'predictions', 'readacross'].includes(mainPathname)) {
            setHasMultiAccessControl(true);
        } else if (['models'].includes(mainPathname)) {
            const splitPathname = pathname.split('/');
            if (splitPathname.length > 2) {
                setHasMultiAccessControl(false);
            } else {
                setHasMultiAccessControl(true);
            }
        } else {
            setHasMultiAccessControl(false);
        }

        // Only files and models can have multidownload. Individual model methods do not have multidownload.
        if (['files'].includes(mainPathname)) {
            setHasMultiExport(true);
        } else if (['models'].includes(mainPathname)) {
            const splitPathname = pathname.split('/');
            if (splitPathname.length > 2) {
                setHasMultiExport(false);
            } else {
                setHasMultiExport(true);
            }
        } else {
            setHasMultiExport(false);
        }

    }, [pathname, application]);
    
    
    const search = (e: ChangeEvent<HTMLInputElement>) => {
        setInput && setInput(e.target.value)
    };

    const downloadItems = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let selectedItems = selectedRows as string[];
        if (downloadFunc) {
            setIsLoading(true);
            if (name) {
                await downloadFunc([name], name, selectedItems);
            } else {
                await downloadFunc(selectedItems);
            }
            setIsLoading(false);
            
            if (toggleCheck) {
                toggleCheck(e);
            }
            if (deselectItems) {
                deselectItems();
            }
        }
    }
    

    return (
        <div id={id} className='w-full flex justify-between items-center p-4 gap-3'>
            <div className="flex gap-3 grow justify-start items-stretch">
                {searchInput !== undefined && 
                <div className="flex grow max-w-[600px]">
                    {searchType && setSearchType && (
                        <Select options={options} value={{ value: searchType as string, label: getLabel(searchType) }} onChange={(e:any) => setSearchType(e.value)} className="min-w-[130px]" styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '100%',
                                borderRadius: '3px 0px 0px 3px',
                                borderColor: '#e5e7eb',
                                borderRight: 'none',
                            }),
                            
                        }}/>
                    )}
                    <div className={`${theme === 'dark' ? 'bg-dark-background' : 'bg-darker-background'} w-full flex relative`}>
                        <input onChange={search} value={searchInput} className={`${searchType ? 'rounded-tl rounded-bl rounded-br-none rounded-bl-none' : 'rounded'} border-[#e5e7eb] border shadow-inner grow p-3 focus:outline-none font-medium ${theme === 'dark' ? 'bg-dark-background' : 'bg-darker-background'}`} placeholder={`Search ${identifier ? 'by ' + identifier : ''}`}/>
                        {/* <img src={Search} alt='search-bar-icon' className="w-[18px] ml-5 absolute top-[12px] right-[12px]"/> */}
                    </div>
                    {applySearch && (       
                        <button onClick={(e:any) => applySearch(e, searchInput)} className="whiten py-3 px-5 rounded-tr rounded-br bg-tertiary hover:bg-secondary transition-all font-medium text-white">
                            <img src={Search} alt='search-bar-icon' className="w-[18px]"/>
                        </button>             
                    )}
                </div>
                }
                {aggregate !== undefined && setAggregate !== undefined && (
                    <EnsembleDropdown setAggregate={setAggregate} aggregate={aggregate}/>
                )}
            </div>
            <div className="flex gap-3">
                {children}
                {selectedRows && selectedRows.length !== 0 && (
                    <>
                        {hasMultiExport && <SolidButton downloadItems={downloadItems} message="Export">
                            {isLoading ? (
                                <CircularProgress size={16} color='inherit'/>
                            ) : (
                                <img src={Download} alt='export-multiple-icon' className="w-[20px]"/>
                            )}                          
                        </SolidButton>}
                        {auth && isAdmin(auth) && hasMultiAccessControl && (
                            <Tooltip title='Modify Access Control'>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => {toggleDeletePopup && toggleDeletePopup(e); setPopupType && setPopupType('permissions')}} className="hover:bg-grad-one bg-grad-two transition-all text-primary flex justify-center items-center gap-2 py-2 px-5 rounded text-nowrap">
                                    <img src={Key} alt='permissions-icon' className="w-[20px]" />
                                </button>
                            </Tooltip>
                        )}
                        {hasMultiDelete && (
                            <Tooltip title='Delete'>
                                <button onClick={(e:MouseEvent<HTMLButtonElement>) => {toggleDeletePopup && toggleDeletePopup(e); setPopupType && setPopupType('delete')}} className={`hover:bg-grad-one bg-grad-two transition-all text-primary flex justify-center items-center gap-2 py-2 px-5 rounded text-nowrap`}>
                                    <img src={Trash} alt='delete-multiple-icon' className="w-[20px]"/>
                                </button>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}