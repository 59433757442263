import { AreaChart, YAxis, XAxis, Tooltip, CartesianGrid, Area, Legend, ResponsiveContainer } from "recharts";
import { DashboardData } from "../../../types/all.types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectApplication } from "../../../store/selectors/auth.selector";

export default function ActivityChart({ data }: { data: DashboardData[] }) {
    const [ cleanedData, setCleanedData ] = useState<{name: string, predictions: number, models?: number}[]>(data);
    const application = useSelector(selectApplication);

    useEffect(() => {
        if (application && !(application).includes('assay-central')) {
            setCleanedData(data.map(d => ({name: d.name, predictions: d.predictions})))
        } else {
            setCleanedData(data);
        }
    }, [application, data]);


    return (
        <ResponsiveContainer width='100%' height={350}>
        <AreaChart data={cleanedData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
                <linearGradient id="colorPredictions" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
                {application && (application).includes('assay-central') && 
                    <linearGradient id="colorModels" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                    </linearGradient>
                }
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="predictions" stroke="#8884d8" fillOpacity={1} fill="url(#colorPredictions)" />
            {application && (application).includes('assay-central') && <Area type="monotone" dataKey="models" stroke="#82ca9d" fillOpacity={1} fill="url(#colorModels)" />}
            <Legend verticalAlign="top" align="right" />
        </AreaChart>
        </ResponsiveContainer>
    )
}