import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDispatch } from "react-redux";

import { deleteExistingDatasets, getDatasets, updateExistingDataset } from "../../store/slices/datasets.slice";
import { filterItems, orderByDate, searchItems, sortByColumn } from "../../data/functions";
import API from '../../api';

import { getDateFromObjectId } from "../../data/functions";
import { datasetsTableHeaders, defaultDatasetFilter, defaultDatasetFilterFields } from "../../data/datasets";

import { Dataset, ParsedDataset } from "../../types/datasets.types";
import { FilterType, FilterFieldsType, Header } from "../../types/all.types";
import { download } from "../../data/functions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectDatasets } from "../../store/selectors/datasets.selector";

const useDataset = ({ setPopupMessage, preloadedDatasets }: { 
    setPopupMessage?: (popupMessage: string, isSuccessMessage: boolean) => void,
    preloadedDatasets?: Dataset[];
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const datasets = useSelector(selectDatasets);
  

    const [ allDatasets, setAllDatasets ] = useState<ParsedDataset[]>([]); 
    const [ modifiedDatasets, setModifiedDatasets ] = useState<ParsedDataset[]>([]); 
    const [ fileType, setFileType ] = useState<"new-file" | "cas-list">("new-file");
    const [ selection, setSelection ] = useState<"new" | "existing">("new");
    const [ allDatasetIDs, setAllDatasetIDs ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultDatasetFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultDatasetFilter);
    const [ headers, setHeaders ] = useState<Header[]>(datasetsTableHeaders);


    useEffect(() => {
        if (!preloadedDatasets) {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchDatasets = async () => {
                    setIsLoading(true);
                    try {
    
                        const response = await API.get('datasets', { headers: { 'X-Auth': authKey } })
                        const data: Dataset[] = await response.data;
                        dispatch(getDatasets(data));
    
                    } catch (err:any) {
    
                        console.log(err);
                        navigate('/500');
    
                    }
                    
                    setIsLoading(false);
                }
        
                fetchDatasets();
            } else {
                navigate('/401');
            }
        }    
        
    }, [dispatch, preloadedDatasets, navigate]);

    console.log(datasets)

    useEffect(() => {
        let originalDatasets: Dataset[] = [];
        if (preloadedDatasets && preloadedDatasets.length > 0) {
            originalDatasets = preloadedDatasets;
        } else if (datasets && datasets.length > 0) {
            originalDatasets = datasets;
        }

        if (originalDatasets && originalDatasets.length > 0) {
            const parsedDatasets: ParsedDataset[] = originalDatasets.map(dataset => {
                let label: string = '';

                if (dataset.fields_mapping.length > 0) {
                    const field = dataset.fields_mapping.find(f => ['continuous-value', 'single-class-label', 'split-on-value'].includes(f.type));
                    if (field) {
                        label = field.type;
                    }
                }

                return ({
                    id: dataset._id.$oid,
                    name: dataset.name ? dataset.name : '',
                    project: dataset.metadata.project === null ? '' : dataset.metadata.project,
                    measurement_type: dataset.metadata.measurement_type === null ? '' : dataset.metadata.measurement_type,
                    target: dataset.metadata.target === null ? '' : dataset.metadata.target,
                    organism: dataset.metadata.organism === null ? '' : dataset.metadata.organism,
                    records: dataset.records_number ? dataset.records_number : 0,
                    created_by: dataset.user ? dataset.user.full_name : '',
                    access_type: dataset.acl ? dataset.acl.access : '',
                    date_created: getDateFromObjectId(dataset._id.$oid).toLocaleString('en-US', {timeZone: 'EST'}),
                    label,
                })
            });
    
            const ordered = orderByDate(parsedDatasets);
    
            setAllDatasets(ordered);
            setModifiedDatasets(ordered);
        }
    }, [datasets, preloadedDatasets]);


    useEffect(() => {
        if (allDatasets.length > 0) {
            const projects = allDatasets.map(file => file.project);
            const measurements = allDatasets.map(file => file.measurement_type);
            const targets = allDatasets.map(file => file.target);
            const organisms = allDatasets.map(file => file.organism);
            const records = allDatasets.map(file => file.records);
            const access = allDatasets.map(file => file.access_type);
            const created_by = allDatasets.map(file => file.created_by);
            setFilterFields({
                project: Array.from(new Set(projects)).filter(a => a).map(p => p),
                measurement_type: Array.from(new Set(measurements)).filter(a => a).map(p => p),
                target: Array.from(new Set(targets)).filter(a => a).map(p => p),
                organism: Array.from(new Set(organisms)).filter(a => a).map(p => p),
                record: Array.from(new Set(records)).filter(a => a),
                access_type: Array.from(new Set(access)).filter(a => a),
                created_by: Array.from(new Set(created_by)).filter(a => a).map(p => p),
            })
        }
        
    }, [allDatasets])

   

    useEffect(() => {
        if (modifiedDatasets.length > 0) {
            setAllDatasetIDs(modifiedDatasets.map(dataset => dataset.id))
        }
    }, [modifiedDatasets]);


    const modifyDatasets = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, allDatasets);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedDatasets(filteredArr);
    }

    
    const updateDatasetPermissions = async (ids: string[], owner: string, access: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = ids.map(async (id) => {
            const aclObj = {owner, access, read: [], write: []};
            return API.put(`datasets/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                .then(() => API.get(`datasets/${id}`,  { headers: { 'X-Auth': authKey } }))
                .then(res => dispatch(updateExistingDataset(res.data)));
        });

        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'datasets you selected were' : 'dataset you selected was'} updated successfully!`, true);
                
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating ${ids.length > 1 ? 'these datasets' : 'this dataset'}`, false);
            }
        }
    };

    const deleteDatasets = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`datasets/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingDatasets(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage(`The ${ids.length > 1 ? 'datasets you selected were' : 'dataset you selected was'} deleted successfully`, true);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these datasets' : 'this dataset'}`, false)
            }
        }
    };

    const downloadDataset = async (filename: string, id: string, ext:string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`datasets/${id}/download?format=${ext}`, { headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                download(filename, data);
                setPopupMessage && setPopupMessage(`The dataset you selected was successfully exported!`, true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error downloading this dataset', false)
            }
        }
    }

    return {
        allDatasets,
        filterFields,
        fileType,
        setFileType,
        selection,
        setSelection,
        allDatasetIDs,
        isLoading,
        deleteDatasets,
        downloadDataset,
        updateDatasetPermissions,
        modifiedDatasets,
        modifyDatasets,
        setFilter,
        filter,
        setHeaders,
        headers,
    }
    

}

export default useDataset;