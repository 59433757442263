import PageHeader from "../../../components/Page/PageHeader";
import Plus from '../../../assets/plus.svg';
import { useNavigate } from "react-router-dom";
import useReadacross from "../../../hooks/pageHooks/useReadacross";
import usePagination from "../../../hooks/usePagination";
import usePopup from "../../../hooks/usePopup";
import useEnableSelection from "../../../hooks/useEnableSelection";
import { useState } from "react";
import { defaultReadacrossFilter } from "../../../data/readacross";

import TableControls from "../../../components/Table/TableControls";
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import FilterButton from "../../../components/Buttons/FilterButton";
import EnableSelectionButton from "../../../components/Buttons/EnableSelectionButton";
import EmptyTable from "../../../components/Table/EmptyTable";
import Pagination from "../../../components/Page/Pagination";

import DeletePopup from "../../../components/Popups/DeletePopup";
import FilterPopup from "../../../components/Popups/FilterPopup";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import IndividualReadAcrossOptions from "../components/IndividualReadAcrossOptions";
import Overlay from "../../../components/Popups/Overlay";
import SnackBar from "../../../components/Alerts/SnackBar";
import useMessage from "../../../hooks/useMessage";
import ModifyPermissionPopup from "../../../components/Popups/ModifyPermissionsPopup";
import useModifyTableItems from "../../../hooks/useModifyTableItems";


export default function ReadAcross() {
    const navigate = useNavigate();
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const theme = useSelector(selectTheme);

    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();

    const {
        allReadacross,
        modifiedReadacross,
        modifyReadacross,
        allReadacrossIDs,
        setFilter,
        filter,
        setHeaders,
        headers,
        isLoading,
        deleteReadacross,
        filterFields,
        downloadReadacross,
        updateReadacrossPermissions
    } = useReadacross({ setPopupMessage });

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyReadacross, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })

    

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedReadacross, count: 50});

    const { 
        isPopupOpen,
        togglePopup,
        setPopupType,
        popupType,
    } = usePopup();

    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        selectAllRows,
        allAreSelected,
        addSingleItem,
        deselectItems,
        toggleChecked
    } = useEnableSelection({allItems: allReadacrossIDs});
    

    return (
        <>
       

            <PageHeader isLoading={isLoading} label='Read-Across'>
                <button onClick={(e:any) => navigate('/readacross/create-readacross')} className="flex justify-center items-center gap-3 py-3 px-5 rounded bg-tertiary hover:bg-secondary transition-all text-white whiten">
                    <img className="w-[18px]" src={Plus} alt="add-icon" />
                    <p className="font-medium">Create Read-Across</p>
                </button>
            </PageHeader>

            <div className={`w-full rounded ${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'} drop-shadow-default`}>

                <TableControls applySearch={applySearch} identifier="Name" toggleDeletePopup={togglePopup} setPopupType={setPopupType} setInput={setSearchInput} searchInput={searchInput} selectedRows={selectedRows}>
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType} />
                    <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />
                </TableControls>

                {isLoading || paginatedItems.length > 0 ? (
                    <>
                        <TableBase 
                            isLoading={isLoading}
                            isChecked={isChecked} 
                            selectAllRows={selectAllRows} 
                            allAreSelected={allAreSelected} 
                            hasOptions={isLoading ? false : true} 
                            headers={headers} 
                            selectedHeader={selectedHeader} 
                            setSortedColumn={setSortedColumn}
                            isPopupOpen={false}
                        >
                            {paginatedItems.length > 0 && paginatedItems.map(item => (
                                <TableRowBase
                                    selectedRows={selectedRows} 
                                    editSelectedRows={editSelectedRows} 
                                    item={item} headers={headers} 
                                    isChecked={isChecked}
                                    action={{path: 'readacross'}}
                                    rowType="base"
                                >
                                    <IndividualOptionsDropdownBase>
                                        <IndividualReadAcrossOptions handleClose={() => {}} downloadFunc={downloadReadacross} toggleDelete={togglePopup} setPopupType={setPopupType} id={item.id} addSingleItem={addSingleItem} />
                                    </IndividualOptionsDropdownBase>
                                </TableRowBase>
                            ))}
                        </TableBase>
                        {paginatedItems.length > 0 && (
                            <Pagination count={50} total={allReadacross.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                            firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                        )}
                    </>
                ) : (
                    <EmptyTable message="Create a new readacross" subject="No readacross found" searchInput={searchInput} />
                )}

                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  
            
            </div>
            {isPopupOpen && (
                <Overlay togglePopup={togglePopup}>
                    {popupType === 'delete' ? (
                        <DeletePopup isChecked={isChecked} deleteFunc={deleteReadacross} toggleChecked={toggleChecked} deselectItems={deselectItems} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these results' : 'this result'}`}/>
                    ) : popupType === 'filter' ? (
                        <FilterPopup applyFilter={applyFilter} itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} defaultFilter={defaultReadacrossFilter} fieldsObj={filterFields} />
                    ) : (
                        <ModifyPermissionPopup toggleChecked={toggleChecked} deselectItems={deselectItems} updateFunc={updateReadacrossPermissions} items={selectedRows} togglePopup={togglePopup} />
                    )}
                </Overlay>
            )}   

        </>
    )
}