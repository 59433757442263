import { Header } from "../types/all.types";

export const jobHeaders: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Status',
        value: 'status',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Type',
        value: 'type',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Dataset',
        value: 'dataset',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Result',
        value: 'result',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    
    {
        label: 'Execution Time',
        value: 'execution_time',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Container',
        value: 'container',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Created By',
        value: 'created_by',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Date Created',
        value: 'date_created',
        isRequired: false,
        isAscending: false,
        isSelected: true,
        isSortable: true,
    }
]


export const defaultJobsFilter = {
    type: [],
    dataset: [],
    result: [],
    status: [],
    execution_time: {
        op: '',
        quantity: '',
    },
    container: [],
}

export const defaultJobsFilterFields = {
    type: [],
    dataset: [],
    result: [],
    status: [],
    execution_time: [],
    container: [],
}