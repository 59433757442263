import { useSelector } from "react-redux";
import { selectAuth } from "../store/selectors/auth.selector";
import { useEffect, useState } from "react";


const usePrivileges = () => {
    const authUser = useSelector(selectAuth);
    const [ canCreate, setCanCreate ] = useState(false);
    const [ canTrain, setCanTrain ] = useState(false);
    const [ canPredict, setCanPredict ] = useState(false);

    const canUserCreate = () => {
        if (authUser) {
            if (authUser.user.privileges.includes('admin') || authUser.user.privileges.includes('create')) {
                setCanCreate(true)
            } else {
                setCanCreate(false);
            }
        } else {
            setCanCreate(false);
        }
        
    };
    
    const canUserTrain = () => {
        if (authUser) {
            if (authUser.user.privileges.includes('admin') || authUser.user.privileges.includes('train')) {
                setCanTrain(true);
            } else {
                setCanTrain(false);
            }
        } else {
            setCanTrain(false);
        }
        
    };
    
    const canUserPredict = () => {
        if (authUser) {
            if (authUser.user.privileges.includes('admin') || authUser.user.privileges.includes('predict')) {
                setCanPredict(true);
            } else {
                setCanPredict(false);
            }
        } else {
            setCanPredict(false);
        }
        
    };

    const canDelete = (id: string) => {
        if (authUser) {
            if (authUser.user.privileges.includes('admin') || authUser.user._id.$oid === id) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
        
    };

    useEffect(() => {

        canUserCreate();
        canUserPredict();
        canUserTrain();

    }, []);

    return {
        canCreate,
        canPredict,
        canTrain,
        canDelete,
    }


}

export default usePrivileges;