import { useState, useEffect, SetStateAction, Dispatch } from "react";
import { useDispatch } from "react-redux";
import API from '../../api';

import { filterItems, getDateFromObjectId, getDatedFilename, orderByDate, searchItems, sortByColumn } from "../../data/functions";
import { defaultFilesFilter, defaultFilesFilterFields, filesTableHeaders } from "../../data/files";
import { deleteExistingFiles, getFiles, updateExistingFile } from "../../store/slices/files.slice";

import { FileType, ParsedFile } from "../../types/files.types";
import { FilterFieldsType, FilterType, Header } from "../../types/all.types";
import { download } from "../../data/functions";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFiles } from "../../store/selectors/files.selectors";



const useFile = ({ setPopupMessage}: { 
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const files = useSelector(selectFiles);

    const [ allFiles, setAllFiles ] = useState<ParsedFile[]>([]); 
    const [ modifiedFiles, setModifiedFiles ] = useState<ParsedFile[]>([]);
    const [ fileType, setFileType ] = useState<"new-file" | "cas-list">("new-file");
    const [ allFileIDs, setAllFilesIDs ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>(filesTableHeaders);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultFilesFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultFilesFilter);


    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                const fetchFiles = async () => {
                    setIsLoading(true);

                    try {

                        const response = await API.get('files', { headers: { 'X-Auth': authKey } });
                        const data: FileType[] = await response.data;
                        dispatch(getFiles(data));                        

                    } catch (err:any) {

                        console.log(err);
                        navigate('/500');

                    }
                    
                    setIsLoading(false);
                    
                }
        
                fetchFiles();
            } else {
                navigate('/401')
            }
        //eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (files && files.length > 0) {
            const parsedFiles: ParsedFile[] = files.map(file => {

                return ({
                id: file._id.$oid,
                name: file.name ? file.name : '',
                project: file.metadata.project === null ? '' : file.metadata.project,
                measurement_type: file.metadata.measurement_type === null ? '' : file.metadata.measurement_type,
                target: file.metadata.target === null ? '' : file.metadata.target,
                organism: file.metadata.organism === null ? '' : file.metadata.organism,
                size: file.size ? file.size : 0,
                records: file.records_number ? file.records_number : 0,
                access_type: file.acl ? file.acl.access : '',
                created_by: file.user ? file.user.username : '',
                date_created: getDateFromObjectId(file._id.$oid).toLocaleString('en-US', {timeZone: 'EST'})
            })
            });
    
            const ordered = orderByDate(parsedFiles);
    
            setAllFiles(ordered);  
            setModifiedFiles(ordered);
        }
    }, [files]);
    


    useEffect(() => {
        if (allFiles && allFiles.length > 0) {
            const projects = allFiles.map(file => file.project);
            const measurements = allFiles.map(file => file.measurement_type);
            const targets = allFiles.map(file => file.target);
            const organisms = allFiles.map(file => file.organism);
            const records = allFiles.map(file => file.records);
            const size = allFiles.map(file => file.size);
            const created_by = allFiles.map(file => file.created_by);
            const access = allFiles.map(file => file.access_type);
            setFilterFields({
                project: Array.from(new Set(projects)).filter(a => a).map(p => p),
                measurement_type: Array.from(new Set(measurements)).filter(a => a).map(p => p),
                target: Array.from(new Set(targets)).filter(a => a).map(p => p),
                organism: Array.from(new Set(organisms)).filter(a => a).map(p => p),
                record: Array.from(new Set(records)).filter(a => a),
                size: Array.from(new Set(size)).filter(a => a),
                created_by: Array.from(new Set(created_by)).filter(a => a).map(p => p),
                access_type: Array.from(new Set(access)).filter(a => a),
            })
        }
        
    }, [allFiles]);

    const modifyFiles = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, allFiles);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedFiles(filteredArr);
    }


    useEffect(() => {
        if (modifiedFiles && modifiedFiles.length > 0) {
            setAllFilesIDs(modifiedFiles.map(file => file.id));
        }
    }, [modifiedFiles])


    const updateFilePermissions = async (ids: string[], owner: string, access: string) => {
        const authKey = localStorage.getItem('X-Auth');

        const promises = ids.map(async (id) => {
            const aclObj = {owner, access, read: [], write: []};
            return API.put(`files/${id}/acl`, aclObj, { headers: { 'X-Auth': authKey } })
                .then(() => API.get(`files/${id}`,  { headers: { 'X-Auth': authKey } }))
                .then(res => dispatch(updateExistingFile(res.data)));
        });

        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage('The files you selected were updated successfully!', true);
                
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error updating ${ids.length > 1 ? 'these files' : 'this file'}`, false);
            }
        }
    };

    const deleteFiles = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`files/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingFiles(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
                setPopupMessage && setPopupMessage('The files you selected were deleted successfully', true);
            } catch(err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage(`There was an error deleting ${ids.length > 1 ? 'these files' : 'this file'}`, false)
            }
        }
    };

    const downloadFile = async (filename: string, id: string) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.get(`files/${id}/download`, { headers: { 'X-Auth': authKey } } );
                const data = await response.data;
                download(filename, data);
                setPopupMessage && setPopupMessage('The file you selected was successfully exported!', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error exporting this file', false)
            }
        }
    }

    const downloadFiles = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {
                const response = await API.post(`files/download`, { ids: ids } ,{ headers: { 'X-Auth': authKey }, responseType: 'blob' } );
                const data = await response.data;
                const filename = getDatedFilename('files', 'zip');
                download(filename, data);
                setPopupMessage && setPopupMessage('The files you selected were successfully exported!', true);
            } catch (err:any) {
                console.log(err);
                setPopupMessage && setPopupMessage('There was an error exporting these files', false)
            }
        }
    }




    return {
        allFiles,
        filterFields,
        fileType,
        setFileType,
        allFileIDs,
        deleteFiles,
        downloadFile,
        downloadFiles,
        updateFilePermissions,
        headers, 
        setHeaders,
        filter,
        setFilter,
        modifiedFiles,
        modifyFiles,
        isLoading,

    }
    

}

export default useFile;