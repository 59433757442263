import ChevronLeft from '../../assets/chevron-left-black.svg';
import ChevronRight from '../../assets/chevron-right-black.svg';

export default function ToggleArrows({ firstCondition, secondCondition, firstAction, secondAction }: {
    firstCondition: boolean,
    secondCondition: boolean,
    firstAction: (e:any) => void,
    secondAction: (e:any) => void,
}) {
    return (
        <div className='flex justify-center items-center rounded bg-darker-background shadow-inner'>
            <button disabled={firstCondition ? false : true} onClick={firstAction} className={`${firstCondition ? 'border-secondary' : 'border-quaternary'} border-t border-b border-l darken-icons flex justify-center items-center px-3 py-1 rounded-tl rounded-bl h-full relative toggle-btn`}>
                {firstCondition && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                <img src={ChevronLeft} alt='left-arrow' className='w-[14px]' />
            </button>
            <button disabled={secondCondition ? false : true} onClick={secondAction} className={`${secondCondition ? 'border-secondary' : 'border-quaternary'} border darken-icons flex justify-center items-center px-3 py-1 rounded-tr rounded-br h-full relative toggle-btn`}>
                {secondCondition && <div className='absolute top-0 left-0 right-0 bottom-0' />}
                <img src={ChevronRight} alt='right-arrow' className='w-[14px]' />
            </button>
        </div>
    )
}