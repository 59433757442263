import { Header } from "../types/all.types";

export const userHeaders: Header[] = [
    {
        label: 'Name',
        value: 'name',
        isRequired: true,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Username',
        value: 'username',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Email',
        value: 'email',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Company',
        value: 'company',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
    {
        label: 'Privileges',
        value: 'privileges',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: false,
    },
    {
        label: 'Role',
        value: 'role',
        isRequired: false,
        isAscending: true,
        isSelected: false,
        isSortable: true,
    },
    {
        label: 'Active Status',
        value: 'active_status',
        isRequired: false,
        isAscending: true,
        isSelected: true,
        isSortable: true,
    },
]


export const defaultUserFilter = {
    company: [],
    privileges: [],
}

export const defaultUserFilterFields = {
    company: [],
    privileges: [],
}