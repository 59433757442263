import { useParams } from "react-router-dom"
import UploadFileTutorial from "./tutorialPages/UploadFileTutorial";
import CreateDatasetTutorial from "./tutorialPages/CreateDatasetTutorial";
import TrainingModelsTutorial from "./tutorialPages/TrainingModelsTutorial";
import CreatingResultsetsTutorial from "./tutorialPages/CreatingResultsetsTutorial";
import SinglePredictionTutorial from "./tutorialPages/SinglePredictionTutorial";
import GeneratingReadacrossTutorial from "./tutorialPages/GeneratingReadacrossTutorial";
import ManagingUsersTutorial from "./tutorialPages/ManagingUsersTutorial";
import QuickStart from "./tutorialPages/QuickStart";

export default function TutorialPage() {
    const { title } = useParams();


    if (title === 'uploading-files') {
        return (
            <UploadFileTutorial />
        )
    } else if (title === 'creating-datasets') {
        return (
            <CreateDatasetTutorial />
        )
    } else if (title === 'training-models') {
        return (
            <TrainingModelsTutorial />
        )
    } else if (title === 'creating-resultsets') {
        return (
            <CreatingResultsetsTutorial />
        )
    } else if (title === 'single-prediction') {
        return (
            <SinglePredictionTutorial />
        )
    } else if (title === 'generating-readacross') {
        return (
            <GeneratingReadacrossTutorial />
        )
    } else if (title === 'managing-users') {
         return (
            <ManagingUsersTutorial />
         )
    } else if (title === 'getting-started') {
        return (
            <QuickStart />
        )
    } else {
        return <></>
    }

}