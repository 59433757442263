import { useEffect, useState } from "react";
import useSortByHeaders from "../../../hooks/useSortByHeaders";
import { allModelHeaders } from "../../../data/models";
import EmptyTable from "../../../components/Table/EmptyTable";

import API from '../../../api';
import { AllModels, Model } from "../../../types/models.types";
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";

export default function RecentModels() {

    const [ isLoading, setIsLoading ] = useState(false);
    const [ allModels, setAllModels ] = useState<AllModels[]>([]);
    const [ sortedModels, setSortedModels ] = useState<AllModels[]>([]);

    const setupModels = (models: Model[]) => {
        if (models.length > 0) {
            const arr: AllModels[] = models.map(m => ({
                name: m.name,
                n: m.n,
                model_type: m.auc ? 'Classification' : 'Regression',
                created_by: m.created_by,
                creation_time: new Date(m.creation_time).toLocaleString('en-US', {timeZone: 'EST'}),
            }))

            const ordered = arr.sort((a,b) => {
                if ((new Date(a.creation_time as string)) > (new Date(b.creation_time as string))) {
                    return -1
                } else if ((new Date(a.creation_time as string)) < (new Date(b.creation_time as string))) {
                    return 1;
                } else {
                    return 0;
                }
            })
    
            setAllModels(ordered);
            setSortedModels(ordered);
        }
    }

    useEffect(() => {
        const fetchModels = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                setIsLoading(true);

                try {

                    const response = await API.get(`models?aggr=max`, { headers: { 'X-Auth': authKey } });
                    const data: Model[] = await response.data;
                    setupModels(data);
  

                } catch (err: any) {

                    console.log(err);
                    
                }
                
                setIsLoading(false);

            }

            
        }

        fetchModels();


    }, []);
    
    useEffect(() => {
        
        setHeaders(allModelHeaders)

    }, []);

    const { 
        headers, 
        setHeaders, 
        selectedHeader, 
        setSortedColumn 
    } = useSortByHeaders({ allItems: allModels, setSortedItems: setSortedModels, setFilteredItems: setSortedModels });


    return (
        <>
        {isLoading || sortedModels.length > 0 ? (
            <TableBase
                isLoading={isLoading}
                isPopupOpen={false}
                isChecked={false}
                hasOptions={false} 
                headers={headers} 
                selectedHeader={selectedHeader} 
                setSortedColumn={setSortedColumn}
                quantity={3}
            >
                {sortedModels.length > 0 && sortedModels.slice(0,3).map(item => (
                    <TableRowBase 
                        rowType="base"
                        key={item.name} 
                        item={item} 
                        headers={headers} 
                        isChecked={false}
                        action={{path: 'models'}}
                    />
                ))}
            </TableBase>
        ) : (
            <EmptyTable isPopup={true} message="Create a new model" subject="No models found" searchInput='' />
        )}
        </>
    )
}