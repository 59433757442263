import { useSelector } from "react-redux";
import { selectDatasets } from "../store/selectors/datasets.selector";
import { selectFiles } from "../store/selectors/files.selectors";
import { selectModels } from "../store/selectors/models.selector";
import { selectPredictions } from "../store/selectors/predictions.selector";
import { selectApplication } from "../store/selectors/auth.selector";

const useExists = () => {
    const datasets = useSelector(selectDatasets);
    const files = useSelector(selectFiles);
    const models = useSelector(selectModels);
    const results = useSelector(selectPredictions);
    const application = useSelector(selectApplication);

    const fileExists = (path: string) => {
        const pathArr = path.split('/');
        const id = pathArr[pathArr.length-1];

        if (path.includes('datasets')) {
            if (!(application).includes('assay-central')) {
                return false;
            } else {
                const ds = datasets.find(d => d._id.$oid === id);
                if (ds) {
                    return true;
                } else {
                    return false;
                }
            }
        } else if (path.includes('files')) {
            const file = files.find(f => f._id.$oid === id);
            if (file) {
                return true;
            } else {
                return false;
            }
        } else if (path.includes('models')) {
            const mod = models.find(m => m.name === id);
                if (mod) {
                    return true
                } else {
                    return false
                }               
        } else if (path.includes('predictions')) {
            const res = results.find(r => r._id.$oid === id);
            if (res) {
                return true;
            } else {
                return false;
            }
        }

        
    }

    return {
        fileExists,
    }
}

export default useExists;