// components
import Field from "../../../../components/Form/Field"
import FlowCard from "../../../../components/Data/FlowCard"
import Select from 'react-select';

// function
import { selectSingleFile } from "../../../../store/selectors/files.selectors";

// hooks
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";

// types
import { ChangeEvent } from "react";
import { CompleteModel } from "../../../../types/models.types";
import { MakePredictionForm } from "../../../../types/predictions.types";
import { FieldMappingForm, FieldMappingType } from "../../../../types/all.types";

const defaultSingleClass: FieldMappingType = {
    name: '',
    type: 'single-class-label',
    op: null,
    value: null,
}


export default function SetPredictionValues({ numberOne, numberTwo, selectedModels, form, setForm, fieldsMapping, setFieldsMapping, singleClassField, setSingleClassField }: {
    numberOne: number,
    numberTwo: number,
    selectedModels: CompleteModel[] | null,
    form: MakePredictionForm,
    setForm: Dispatch<SetStateAction<MakePredictionForm>>,
    fieldsMapping: FieldMappingForm,
    setFieldsMapping: Dispatch<SetStateAction<FieldMappingForm>>,
    singleClassField: FieldMappingType | null,
    setSingleClassField: Dispatch<SetStateAction<FieldMappingType | null>>,
}) {
    const selectedFile = useSelector(selectSingleFile);
    const [ isAuto, setIsAuto ] = useState(true);
    const [ fieldOptions, setFieldOptions ] = useState<{value:string, label: string}[]>([]);
    const [ isClassification, setIsClassification ] = useState(false);


    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setForm({...form, [e.target.name]: e.target.value})
    };

    const handleSelect = (e:any, name: string) => {
        if (name === 'single-class-label') {
            setSingleClassField({
                ...singleClassField!,
                name: !e ? '' : e.value,
            })
        } else {
            const fieldName = name as keyof FieldMappingForm;
            setFieldsMapping({
                ...fieldsMapping,
                [fieldName]: {
                    ...(fieldsMapping[fieldName] as FieldMappingType),
                    name: e.value,
                }
            })   
        }
            
    };


    const handleMultiSelect = (e:any) => {
        setFieldsMapping({
            ...fieldsMapping,
            ['non-activity' as keyof object]: e.map((val:any) => ({
                name: val.value,
                op: null,
                type: 'non-activity',
                value: null,
            }))
        })
    }


    const handleAuto = (e: ChangeEvent<HTMLInputElement>) => {
        setIsAuto(e.target.checked);
        if (e.target.checked === true && selectedFile) {
            setForm({...form, name: selectedFile.name})
        }
    }
    
    useEffect(() => {
        if (selectedFile) {
            if (isAuto) {
                setForm({...form, name: selectedFile.name, file_ids: [selectedFile._id.$oid]})
            } else {
                setForm({...form, file_ids: [selectedFile._id.$oid] })
            }
        }
    }, [isAuto, selectedFile]);

    useEffect(() => {
        if (selectedFile) {
            setFieldOptions(selectedFile.fields.map(field => ({value: field, label: field})));
            
        }
    }, [selectedFile]);

    useEffect(() => {
        if (selectedModels) {
            let labels = [];
            selectedModels.forEach(model => {
                if (model.auc !== undefined) {
                    labels.push(model);
                }
            })

            if (labels.length > 0) {
                setIsClassification(true);
                setSingleClassField(defaultSingleClass)
            } else {
                setIsClassification(false);
                setSingleClassField(null);
            }

        } else {
            setIsClassification(false);
            setSingleClassField(null);
        }
    }, [selectedModels]) 


    return (
        <div className="flex flex-col gap-4 w-full min-w-[400px] h-[70vh]">
            <FlowCard label="Set Result Parameters" number={numberOne} isClassification={isClassification} isLocked={selectedModels && selectedFile ? false : true}>
                <div className="flex flex-col gap-6 justify-start items-stretch">
                    <Field label='Result Set Name' isRequired={true}>
                        <div className='rounded w-full flex justify-between items-stretch'>
                            <input onChange={handleInput} name='name' value={form.name} className='border-l border-t border-b border-primary  rounded-tl rounded-bl text-left text-[14px] p-2 grow' placeholder='Result Name'/>
                            <label className={`px-3 border ${isAuto ? 'border-secondary' : 'border-primary'} cursor-pointer flex items-center gap-2 rounded-tr rounded-br relative`}>
                                {isAuto && <div className="absolute top-0 left-0 right-0 bottom-0 bg-secondary bg-opacity-10" />}
                                <input onChange={handleAuto} type='checkbox' checked={isAuto} className='accent-secondary'/>
                                <p className={`text-[12px] ${isAuto ? 'font-medium text-secondary' : 'font-regular text-primary'}`}>Auto-generate</p>
                            </label>
                        </div> 
                    </Field>

                    <Field label='Chemical Name' isRequired={false}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.body} onChange={(e:any) => handleSelect(e, 'chem-name')} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Chemical Name'/>
                    </Field>

                    <Field label='Record ID' isRequired={false}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.body} onChange={(e:any) => handleSelect(e, 'chem-id')} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Record ID'/>
                    </Field>

                    <Field label='Relevant Data (Non-Activity)' isRequired={false}>
                        <Select maxMenuHeight={100} menuPortalTarget={document.body} isMulti onChange={handleMultiSelect} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Relevant Data'/>
                    </Field>
                    
                </div>
            </FlowCard>
            {isClassification && (
            <FlowCard label="Set Known Values" number={numberTwo} isClassification={isClassification} isLocked={selectedModels && selectedFile ? false : true}>
                <div className="flex flex-col gap-6 justify-start items-stretch">

                    <Field label='Single Class Label' isRequired={false}>
                        <Select isClearable maxMenuHeight={100} menuPortalTarget={document.body} onChange={(e:any) => handleSelect(e, 'single-class-label')} options={fieldOptions} className='text-left text-[14px] rounded w-full' placeholder='Known Values'/>
                    </Field>

                </div>
            </FlowCard>
            )}
        </div>
    )
}