// assets
import DatasetImg from '../../../../assets/dataset-colored.png';
import Trash from '../../../../assets/trash.svg';
import Upload from '../../../../assets/upload.svg';
import View from '../../../../assets/link.svg';

// components
import FlowCard from "../../../../components/Data/FlowCard";
import FlowCardInfo from '../../../../components/Data/FlowCardInfo';

// hooks
import { useState, useEffect, Dispatch, SetStateAction } from 'react';

// types
import { Dataset } from "../../../../types/datasets.types"
import { MouseEvent } from 'react';
import { SideInfo } from '../../../../types/all.types';
import { PopupType } from '../../../../hooks/usePopup';

export default function UploadDatasetCard({number, selectedDataset, toggleExistingDataset, setPopupType ,reset}: {
    number: number, 
    selectedDataset: Dataset | null, 
    toggleExistingDataset: (e:MouseEvent<HTMLButtonElement>) => void,
    reset?: (e:MouseEvent<HTMLButtonElement>) => void,
    setPopupType: Dispatch<SetStateAction<PopupType>>,
}) {

    const [ isShown, setIsShown ] = useState(false);
    const [ info, setInfo ] = useState<SideInfo[]>([]);

    const toggleShow = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsShown(!isShown);
    }

    useEffect(() => {
        if (selectedDataset) {
            let valueField: {name: string, type: string} | null = null;

            const obj = selectedDataset.fields_mapping.find(field => ['single-class-label', 'continuous-value'].includes(field.type));
            if (obj) valueField = obj;

            setInfo([
                {
                    label: 'Project',
                    value: !selectedDataset.metadata.project ? 'No project' : selectedDataset.metadata.project,
                }, 
                {
                    label: 'Measurement Type',
                    value: !selectedDataset.metadata.measurement_type ? 'No measurement type' : selectedDataset.metadata.measurement_type,
                },
                {
                    label: 'Value Type',
                    value: valueField ? valueField.type : '',
                },
                {
                    label: 'Activity Column',
                    value: valueField ? valueField.name : '',
                },
                {
                    label: selectedDataset.stats.actives !== undefined ? 'Active Compounds' : selectedDataset.stats.high_value !== undefined ? 'High Value' : 'Active Compounds',
                    value: selectedDataset.stats.actives !== undefined ? selectedDataset.stats.actives : selectedDataset.stats.high_value !== undefined ? (Math.round(Number(selectedDataset.stats.high_value) * 100) / 100 as number).toFixed(2) : '' ,
                },
                {
                    label: selectedDataset.stats.inactives !== undefined ? 'Inactive Compounds' : selectedDataset.stats.low_value !== undefined ? 'Low Value' : 'Inactive Compounds',
                    value: selectedDataset.stats.inactives !== undefined ? selectedDataset.stats.inactives : selectedDataset.stats.low_value !== undefined ? (Math.round(Number(selectedDataset.stats.low_value) * 100) / 100 as number).toFixed(2) : '' ,
                },
                {
                    label: 'Organism',
                    value: !selectedDataset.metadata.organism ? 'No organism' : selectedDataset.metadata.organism,
                },
                {
                    label: 'Target',
                    value: !selectedDataset.metadata.target ? 'No target' : selectedDataset.metadata.target,
                },
                {
                    label: 'Access Type',
                    value: selectedDataset.acl.access,
                }
            ])
        }
    }, [selectedDataset])

    return (
        <FlowCard label="Select Dataset" number={number} isLocked={false} >
            <div className="flex flex-col gap-4">
                {selectedDataset ? (
                <div className='flex flex-col justify-start items-stretch gap-2'>
                    <div className='flex justify-between items-stretch gap-2 p-6 border-2 border-quaternary bg-white rounded w-full'>
                        <div className='grow flex justify-start items-center gap-4'>
                            <img src={DatasetImg} alt='any-dataset-icon' className="w-[80px]"/>
                            <div>
                                <p className="font-semibold text-primary mb-[2px] break-all">{selectedDataset.name}</p>
                            </div>
                        </div>
                        <div className="h-full flex justify-center items-start gap-2">
                            <a href={`${process.env.REACT_APP_HOST_URL}/datasets/${selectedDataset._id.$oid}`} target='_blank' rel='noreferrer' className="p-1 rounded-full border-2 border-quaternary hover:bg-quaternary lighten transition-all"><img src={View} alt='view-icon' className="w-[20px]"/></a>
                            <button onClick={reset} className="p-1 rounded-full border-2 border-quaternary hover:bg-quaternary lighten transition-all"><img src={Trash} alt='trash-icon' className="w-[20px]"/></button>
                        </div>
                    </div>
                    <FlowCardInfo toggleShow={toggleShow} isShown={isShown} info={info} />
                </div>
                ) : (
                <div className='flex justify-center items-center p-6 border-2 border-quaternary/[0.5] border-dashed bg-white rounded'>
                    <button onClick={(e:any) => {toggleExistingDataset(e); setPopupType('existing-dataset')}} className='lighten transition-all rounded border-2 border-secondary text-secondary text-[14px] font-medium flex justify-center items-center gap-2 bg-white hover:bg-secondary hover:text-white px-4 py-2'>
                        <img src={Upload} className='w-[18px]' alt='upload-icon' />
                        <p>Select dataset to import</p>
                    </button>
                </div>
                )}
            </div>
        </FlowCard>
    )
}