import { createSlice } from "@reduxjs/toolkit";

export interface LocationState {
    prevPath: string;
}

const initialState: LocationState = {
    prevPath: '',
}

export const locationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setPrevPath: (state, action) => {
            state.prevPath = action.payload;
        }
    }
})

export const { setPrevPath } = locationSlice.actions;

export const locationReducer = locationSlice.reducer;
