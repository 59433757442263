import { useState, MouseEvent, useEffect } from "react";
import Field from "../Form/Field";
import Select from 'react-select';
import ErrorMessage from "../Alerts/ErrorMessage";
import FormButtons from "../Buttons/FormButtons";
import { useSelector } from "react-redux";
import { selectUsers } from "../../store/selectors/users.selector";

export interface PermissionsType {
    access: 'private' | 'authenticated';
    ownerId: string;
    ownerName: string;
};

const defaultPermissions: PermissionsType = {
    access: 'private',
    ownerId: '',
    ownerName: '',
};

const accessOptions = [
    {value: 'private', label: 'private'},
    {value: 'authenticated', label: 'authenticated'},
];

export default function ModifyPermissionPopup({ items, deselectItems, updateFunc, togglePopup, toggleChecked }: {
    items: string[],
    deselectItems: () => void,
    updateFunc: (names: string[], owner: string, access: string) => Promise<void>,
    togglePopup: (e:any) => void,
    toggleChecked: (e: MouseEvent<HTMLButtonElement>) => void,
}) {
    const [ permissions, setPermissions ] = useState<PermissionsType>(defaultPermissions);
    const [ userOptions, setUserOptions ] = useState<{value: string, label: string}[]>([]);

    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    const users = useSelector(selectUsers);

    useEffect(() => {
        if (users && users.length) {
            setUserOptions(users.map(u => ({value: u._id.$oid, label: u.username})))
        }
    }, [users]);


    const [ errorMessage, setErrorMessage ] = useState('');

    const handleSelect = (e:any, name: string) => {
        if (name === 'owner') {
            setPermissions({...permissions, ownerId: e.value, ownerName: e.label})
        } else {
            setPermissions({...permissions, [name]: e.value});
        }
    };

    useEffect(() => {
        if (!permissions.ownerId) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [permissions]);


    const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        try {
            await updateFunc(items, permissions.ownerId, permissions.access);
            deselectItems();
            toggleChecked(e);
            setErrorMessage('');
            togglePopup(e);
            
        } catch (err:any) {
            setErrorMessage('We encountered an issue modifying access control for these files.');
        }

    };

    const cancelSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        deselectItems();
        toggleChecked(e);
        togglePopup(e);
    }


    return (
        <>
           <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className={`bg-background rounded p-8 max-w-[600px] w-full popup-menu`}>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-center items-stretch'>        
                            <div className='w-full flex justify-between items-center'>
                                <p className='text-primary font-semibold text-[1.5rem]'>Edit Access Control</p>
                            </div>

                            <Field label='Access Type' isRequired={true}>
                                <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'access')} options={accessOptions} value={{value: permissions.access, label: permissions.access}}  className='text-left text-[14px] rounded w-full' placeholder='Access Type'/>
                            </Field>

                            <Field label='Owner' isRequired={true}>
                                <Select maxMenuHeight={100} menuPortalTarget={document.getElementById('overlay')} onChange={(e:any) => handleSelect(e, 'owner')} options={userOptions} value={{value: permissions.ownerId, label: permissions.ownerName}}  className='text-left text-[14px] rounded w-full' placeholder='Owner'/>
                            </Field>
                            

                            {errorMessage && (
                                <ErrorMessage errorMessage={errorMessage} />
                            )}

                            <FormButtons continueFunction={handleSubmit} cancelFunction={cancelSubmit} isDisabled={isDisabled} isLoading={isLoading}/>
                        </div> 
                        </div>
                    </div>
                </div>
        </>
    )
}