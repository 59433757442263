import { Tooltip } from "@mui/material";
import { ReactNode, MouseEvent } from "react";

export default function SolidButton({children, togglePopup, downloadItems, message}: {
    children: ReactNode, 
    togglePopup?: (e:MouseEvent<HTMLButtonElement>) => void,
    downloadItems?: (e:MouseEvent<HTMLButtonElement>) => Promise<void>,
    message?: string;
}) {
    return (
        <Tooltip title={message}>
        <button onClick={(e:MouseEvent<HTMLButtonElement>) => {togglePopup ? togglePopup(e) : downloadItems && downloadItems(e)}} className={`hover:bg-grad-one bg-grad-two transition-all text-primary flex justify-center items-center gap-2 py-2 px-5 rounded text-nowrap`}>
            {children}
        </button>
        </Tooltip>
    )
}