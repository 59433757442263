import FlowCard from "../../../../components/Data/FlowCard";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useState } from "react";
import Search from '../../../../assets/search.svg';
import Select from 'react-select';
import Ketcher from "../../../../components/Data/Ketcher";
import API from '../../../../api';
import { CircularProgress, Autocomplete, TextField, InputAdornment, AutocompleteInputChangeReason } from "@mui/material";

const options = [
    {value: 'Name', label: 'Name'},
    {value: 'CAS Number', label: 'CAS Number'},
    {value: 'SMILES', label: "SMILES"},
]

interface AutoCompleteResponse {
    cas: string,
    itn: string,
    label: string,
    name: string,
    subsKey: string,
    value: string,
}

export default function KetcherCard({ number, setSmiles, smiles }: {
    number: number,
    setSmiles: Dispatch<SetStateAction<string>>,
    smiles: string,

}) {
    // const [ searchInput, setSearchInput ] = useState('');
    const [ selection, setSelection ] = useState({value: 'Name', label: 'Name'});

    const [ results, setResults ] = useState<{label: string, cas: string}[]>([]);
    const [ ketcherInstance, setKetcherInstance ] = useState<any | null>(null);
    const [ isLoading, setIsLoading ] = useState(false);

    
    const handleAutoComplete = async (e: ChangeEvent<HTMLInputElement>, inputValue?: string) => {

            const value = inputValue ? inputValue : e.target.value;
            // setSearchInput(value);

            let list: {label: string, cas: string}[] = [];

            if (!value) {
                list = [];
            } else {
                setIsLoading(true);
                if (selection.value === 'Name') {
                    try {
                        const response = await API.get(`https://cdxappsdev.epacdx.net/oms-substance-registry-services/rest-api/autoComplete/nameSearch?term=${value}&qualifier=begins`);
                        const data: AutoCompleteResponse[] = await response.data;
                        list = data.map(d => ({label: d.name, cas: d.cas}));
                    } catch (err:any) {
                        console.log(err)
                    }
                } else if (selection.value === 'CAS Number') {
                    try {
                        const response = await API.get(`https://cdxappsdev.epacdx.net/oms-substance-registry-services/rest-api/autoComplete/casSearch?term=${value}`);
                        const data: AutoCompleteResponse[] = await response.data;
                        list = data.map(d => ({label: d.cas, cas: d.cas}));
                    } catch (err:any) {
                        console.log(err)
                    }
                } else if (selection.value === 'SMILES') {
                    try {
                        await ketcherInstance.setMolecule(value);
                        setSmiles(value);
                        list = [];
                        
                    
                    } catch (err:any) {
                        console.log(err);
                    }
                }
                setIsLoading(false);
            }

            setResults(list);
        
    }

    const handleSelection = async (e:any) => {
        setSelection({value: e.value, label: e.value});
        // setSearchInput('');
        setResults([]);
        await ketcherInstance.setMolecule('');
    }

    const handleMoleculeSelection = async (e: MouseEvent<HTMLButtonElement>, result: {label: string, cas: string}) => {
        e.preventDefault();
        const { cas } = result;
        setResults([]);
        // setSearchInput(label);

        try {
            const response = await API.get(`https://cdxapps.epa.gov/oms-substance-registry-services/rest-api/substance/cas/${cas}`);
            const data = await response.data;
            const smiles = data[0].smilesNotation;
            
            
            await ketcherInstance.setMolecule(smiles);
            setSmiles(smiles);

        } catch (err:any) {
            console.log(err);
        }
 
    }

    

    return (
        <FlowCard label="Select Compound" number={number} isLocked={false} isKetcher={true}>
            <div className="flex flex-col gap-4 h-full">
                <div className="w-full flex justify-between items-stretch rounded card">
                    <Select onChange={handleSelection} className="new-search min-w-[160px]" options={options} value={selection} styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '100%',
                                borderRadius: '3px 0px 0px 3px',
                                borderColor: '#e5e7eb',
                                borderRight: 'none',
                            }),
                            
                    }}/>
                    <Autocomplete
                            id="free-solo-demo"
                            freeSolo
                            disableClearable
                            sx={{
                                width: '100%',
                                backgroundColor: '#F3F5FF',
                                fontFamily: 'Montserrat',
                            }}
                            componentsProps={{
                                paper: {
                                  sx: {
                                    '& .MuiAutocomplete-listbox': {
                                      fontFamily: 'Montserrat, sans-serif',
                                    },
                                  },
                                },
                              }}

                            onChange={(e:any, option: any) => handleMoleculeSelection(e, option)}
                            onInputChange={(e:any, value: string, reason: AutocompleteInputChangeReason) => handleAutoComplete(e, value)}
                            options={results}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField sx={{fontFamily: 'Montserrat, sans-serif'}} {...params} label="" placeholder="Search" InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <img src={Search} alt='search-icon' className="ml-2"/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                    {isLoading ? <CircularProgress size={20} color="inherit" className="mr-4"/> : null}
                                    </>
                                ),
                                type: 'search',
                                style: {
                                    fontFamily: 'Montserrat, sans-serif', 
                                    padding: '4px', 
                                    borderRadius: '0px 3px 3px 0px', 
                                    borderColor: '#e5e7eb'
                                }
                              }}/>}
                    />
                </div>
                <div className="rounded w-full grow border relative">
                    <Ketcher setSmiles={setSmiles} setKetcherInstance={setKetcherInstance}/>
                    {!ketcherInstance && <div className="flex justify-center items-center absolute top-0 right-0 bottom-0 left-0"><CircularProgress size={80} color="inherit" /></div>}
                </div>
                {smiles && (
                    <div className="w-full p-3 bg-background rounded">
                        <p className="font-medium"><span className="text-secondary">SMILES:</span> <span data-testid='smiles-string' className="font-semibold">{smiles}</span></p>
                    </div>
                )}
            </div>
        </FlowCard>
    )
}