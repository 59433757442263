

// hooks
import { useState, Dispatch, SetStateAction, useEffect } from "react";
import usePopup from "../../../hooks/usePopup";
import useEnableSelection from "../../../hooks/useEnableSelection";


// data
import { classificationHeaders, classificationLimits, defaultClassificationModelFilter, defaultRegressionModelFilter, regressionHeaders, regressionLimits } from "../../../data/models";

// components
import HeadersDropdown from "../../../components/Dropdowns/HeadersDropdown";
import DeletePopup from "../../../components/Popups/DeletePopup";
import Pagination from "../../../components/Page/Pagination";
import EmptyTable from "../../../components/Table/EmptyTable";

// assets
import EnableSelectionButton from '../../../components/Buttons/EnableSelectionButton';
import TableControls from '../../../components/Table/TableControls';
import FilterButton from '../../../components/Buttons/FilterButton';
import TableBase from "../../../components/Table/TableBase";
import TableRowBase from "../../../components/Table/TableRowBase";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../store/selectors/auth.selector";
import IndividualOptionsDropdownBase from "../../../components/Dropdowns/IndividualOptionsDropdownBase";
import IndividualModelOptions from "../components/IndividualModelOptions";
import useModels from "../../../hooks/pageHooks/useModels";
import Overlay from "../../../components/Popups/Overlay";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";
import ModifyPermissionPopup from "../../../components/Popups/ModifyPermissionsPopup";
import usePagination from "../../../hooks/usePagination";
import FilterPopup from "../../../components/Popups/FilterPopup";
import useModifyTableItems from "../../../hooks/useModifyTableItems";


export default function ModelTemplate({isPopup, isClassification, application, setSelection}: {
    isPopup: boolean,
    isClassification: boolean,
    application: string,
    setSelection?: Dispatch<SetStateAction<string>>,
}) {
    const [ searchInput, setSearchInput ] = useState('');
    const [ selectedHeader, setSelectedHeader ] = useState('');
    const theme = useSelector(selectTheme);
    const [ aggregate, setAggregate ] = useState<'max' | 'min'>('max');

    const {
        open,
        message,
        isSuccess,
        setPopupMessage,
        setOpen,
    } = useMessage();

    const {
        allModelNames,
        isLoading,
        downloadModels,
        deleteModels,
        updateModelPermissions,
        modifiedModels,
        modifyModels,
        filter,
        setFilter,
        headers,
        setHeaders,
        filterFields
    } = useModels({ isClassification, aggr: aggregate, setPopupMessage });

    const {
        selectHeaders,
        setSortedColumn,
        applyFilter,
        applySearch,
    } = useModifyTableItems({ modifyItems: modifyModels, setHeaders, filter, setFilter, searchInput, selectedHeader, setSelectedHeader })


    const { 
        handleChange, 
        isChecked, 
        selectedRows, 
        editSelectedRows,
        allAreSelected,
        selectAllRows,
        toggleChecked,
        deselectItems,
        addSingleItem
    } = useEnableSelection({allItems: allModelNames});


    const { 
        isPopupOpen,
        togglePopup,
        setPopupType,
        popupType
    } = usePopup();

    const { 
        currentPage, 
        setItemsPerPage, 
        paginatedItems, 
        paginate, 
        itemsPerPage 
    } = usePagination({items: modifiedModels, count: 50});

    useEffect(() => {
        if (isClassification) {
            setFilter(defaultClassificationModelFilter)
            setHeaders(classificationHeaders);
        } else {
            setFilter(defaultRegressionModelFilter);
            setHeaders(regressionHeaders);
        }

        //eslint-disable-next-line
    }, [isClassification]);



    return (
        <div className={`${theme === 'dark' ? 'bg-dark-accent' : 'bg-white'}`}>    
                
                <TableControls 
                applySearch={applySearch}
                identifier="Name"
                deselectItems={deselectItems} 
                toggleDeletePopup={togglePopup} 
                setPopupType={setPopupType}
                searchInput={searchInput} 
                setInput={setSearchInput} 
                selectedRows={selectedRows} 
                aggregate={aggregate} 
                setAggregate={setAggregate} 
                downloadFunc={downloadModels} 
                toggleCheck={toggleChecked}
                >
                    <HeadersDropdown headers={headers} updateHeaders={selectHeaders}/>
                    <FilterButton filter={filter} toggleFilterPopup={togglePopup} setPopupType={setPopupType}/>
                    {!isPopup && <EnableSelectionButton isChecked={isChecked} handleChange={handleChange} />}
                </TableControls>

                
                <div className={`${isPopup && 'max-h-[420px] overflow-y-scroll rounded'}`}>
                    {isLoading || paginatedItems.length > 0 ? (
                        <>
                            <TableBase
                                isLoading={isLoading}
                                isPopupOpen={isPopup}
                                isChecked={isChecked} 
                                selectAllRows={selectAllRows} 
                                allAreSelected={allAreSelected} 
                                headers={headers} 
                                selectedHeader={selectedHeader} 
                                setSortedColumn={setSortedColumn} 
                                hasOptions={isLoading ? false : true}
                            >
                                {paginatedItems.length > 0 && paginatedItems.map(item => (
                                    <TableRowBase
                                        rowType="color"
                                        limits={isClassification ? classificationLimits : regressionLimits}
                                        setSelection={setSelection} 
                                        selectedRows={selectedRows} 
                                        editSelectedRows={editSelectedRows} 
                                        isChecked={isChecked} 
                                        item={item} 
                                        headers={headers}
                                        action={{path: 'models'}}
                                    >
                                        <IndividualOptionsDropdownBase>
                                            <IndividualModelOptions setPopupType={setPopupType} handleClose={() => {}} application={application} addSingleItem={addSingleItem} name={item.name} downloadFunc={downloadModels} toggleDelete={togglePopup}/>
                                        </IndividualOptionsDropdownBase>
                                    </TableRowBase>
                                ))}
                            </TableBase>
                            {paginatedItems.length > 0 && (
                                <Pagination count={50} total={modifiedModels.length} setItemsPerPage={setItemsPerPage} paginate={paginate} currentPage={currentPage}
                                firstIndex={(currentPage * itemsPerPage) - itemsPerPage} lastIndex={currentPage * itemsPerPage}/>
                            )}
                        </>
                    ) : (
                        <EmptyTable isPopup={isPopup} message={`Train a new ${isClassification ? 'classification' : 'regression' } model`} subject="No models found" searchInput={searchInput} />
                    )}
                    
                </div>
                
                <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

                {isPopupOpen && (
                    <Overlay togglePopup={togglePopup}>
                        {popupType === 'delete' ? (
                            <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} deselectItems={deselectItems} deleteFunc={deleteModels} items={selectedRows} togglePopup={togglePopup} label={`${selectedRows.length > 1 ? 'these models' : 'this model'}`}/>
                        ) :popupType === 'filter' ? (
                            <FilterPopup itemsFilter={filter} setItemsFilter={setFilter} searchInput={searchInput} togglePopup={togglePopup} applyFilter={applyFilter} defaultFilter={isClassification ? defaultClassificationModelFilter : defaultRegressionModelFilter} fieldsObj={filterFields} />
                        ) : (
                            <ModifyPermissionPopup toggleChecked={toggleChecked} deselectItems={deselectItems} updateFunc={updateModelPermissions} items={selectedRows} togglePopup={togglePopup} />
                        )}
                    </Overlay>
                )}
                
        </div>
    )
}