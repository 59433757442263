import { ReactNode } from "react"
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function Grid({ children, noResults, isLoading }: {
    children: ReactNode,
    noResults?: boolean,
    isLoading: boolean,
}) {
    return (
        <>
        {isLoading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto gap-6 p-4 rounded bg-darker-background">
                {Array.from(Array(4).keys()).map(k => (
                    <div className="w-full h-[400px]"><Skeleton height={400}/></div>
                ))}
            </div>
        ) : noResults ? (
            <div className="w-full h-full p-4 rounded bg-darker-background">
                {children}
            </div>
        ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto gap-6 p-4 rounded bg-darker-background">
                {children}
            </div>
        )}
            
        </>
    )
}