// hooks
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// data

// functions
import { getPredictions } from "../../../store/slices/predictions.slice";
import { selectPredictions } from "../../../store/selectors/predictions.selector";
import API from '../../../api';

// types
import { Prediction } from "../../../types/predictions.types";


// components
import MakePredictionDropdown from "../components/MakePredictionDropdown"
import PageHeader from "../../../components/Page/PageHeader"
import SeparatePredictions from "./SeparatePredictions";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useNavigate } from "react-router-dom";
import usePrivileges from "../../../hooks/usePrivileges";
import { selectTheme } from "../../../store/selectors/auth.selector";

export default function Predictions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const predictions = useSelector(selectPredictions);
    const { canPredict } = usePrivileges();
    const [ classificationPredictions, setClassificationPredictions ] = useState<Prediction[]>([]);
    const [ regressionPredictions, setRegressionPredictions ] = useState<Prediction[]>([]);
    const [ multiPredictions, setMultiPredictions ] = useState<Prediction[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const theme = useSelector(selectTheme);
    

    useEffect(() => {

        const fetchResults = async () => {
            const authKey = localStorage.getItem('X-Auth');
            if (authKey) {

                setIsLoading(true);

                try {

                    const response = await API.get(`resultsets`, { headers: { 'X-Auth': authKey } });
                    const data: Prediction[] = await response.data;
                    dispatch(getPredictions(data));


                } catch (err: any) {

                    console.log(err);
                    navigate('/500');
                    
                }

                setIsLoading(false);
                
            } else {
                navigate('/401');
            }
            
        }

        fetchResults();
        //eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        if (predictions && predictions.length > 0) {
            let classification: Prediction[] = [];
            let regression: Prediction[] = [];
            let multi: Prediction[] = [];

            predictions.forEach(prediction => {
                let mapping = prediction.fields_mapping;
                let modelArr: string[] = [];
                prediction.fields_mapping.forEach(m => {
                    if (m.type === 'predicted-value') {
                        modelArr.push(m.name.split('/')[0])
                    }
                })
                let noDuplicates = Array.from(new Set(modelArr));
                let ad = mapping.find(map => map.type === 'ad-score');
                if (noDuplicates.length > 1) {
                    multi.push(prediction)
                } else if (ad) {
                    classification.push(prediction);
                } else {
                    regression.push(prediction);
                }
            });



            setClassificationPredictions(classification);
            setRegressionPredictions(regression);  
            setMultiPredictions(multi)
        }
    }, [predictions]);



    return (
        <>

            <PageHeader label='Predictions'>
                {canPredict && <MakePredictionDropdown />}
            </PageHeader>

            <Tabs>
                <TabList>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Classification</Tab>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Regression</Tab>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Multiple</Tab>
                </TabList>

                <TabPanel>
                    <SeparatePredictions isLoading={isLoading} predictions={classificationPredictions} isRegression={false}/>
                </TabPanel>
                <TabPanel>
                    <SeparatePredictions isLoading={isLoading} predictions={regressionPredictions} isRegression={true}/>
                </TabPanel>
                <TabPanel>
                    <SeparatePredictions isLoading={isLoading} predictions={multiPredictions} isRegression={false}/>
                </TabPanel>
            </Tabs>
        </>
    )
}