import { Header } from "../../types/all.types";
import { ChangeEvent, ReactNode } from "react";
import Ascending from '../../assets/sort-ascend.svg';
import Descending from '../../assets/sort-descend.svg';
import LoadingRow from "./LoadingRow";
import { useSelector } from "react-redux";
import { selectNav } from "../../store/selectors/nav.selector";
import { useLocation } from "react-router-dom";


export default function TableBase({ 
    isLoading, 
    headers, 
    selectedHeader,
    children,
    isChecked,
    selectAllRows,
    allAreSelected,
    setSortedColumn,
    activeField,
    hasOptions,
    isPopupOpen,
    prediction,
    needsShadow,
    quantity,
}: {
    isLoading: boolean,
    headers: Header[],
    selectedHeader: string,
    children: ReactNode,
    isChecked: boolean,
    selectAllRows?: (e:ChangeEvent<HTMLInputElement>) => void,
    allAreSelected?: boolean,
    setSortedColumn: (e:any, label: string) => void,
    hasOptions: boolean,
    isPopupOpen: boolean,
    activeField?: {[key:string]: string, name: string, type: string} | null,
    prediction?: {
        activityColumn: string,
        infoCells: number,
        predictionCells: number,
    },
    needsShadow?: boolean,
    quantity?: number,
}) {
    const isNavOpen = useSelector(selectNav);
    const { pathname } = useLocation();

    // changes max width of table depending on layout
    const changeTableWidth = () => {
        if (isNavOpen) {
            if (pathname.includes('dashboard')) {
                return 'dashboard-container-expanded';
            } else {
                return 'table-container-expanded';
            }
        } else if (isPopupOpen) {
            return 'mini-table-container';
        } else if (pathname.includes('dashboard')) {
            return 'dashboard-container';
        } else {
            return 'table-container';
        }
    }


    const selectionEnabled = (index: number) => {
        if (index === 0 && isChecked) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className={`w-full ${changeTableWidth()} overflow-x-auto bg-white rounded ${needsShadow && 'drop-shadow-default'}`}>
                <table className="w-full">
                    {prediction && !isLoading && (
                        <tr className=''>
                            <th colSpan={prediction.infoCells} className='font-semibold text-nowrap py-4 px-6 text-center bg-background'>Molecule Information</th>
                            <th colSpan={prediction.predictionCells} className='font-semibold text-nowrap py-4 px-6 text-center bg-[#C8C8C8]/[0.3]'>{prediction.activityColumn}</th>
                            {hasOptions && <th className='bg-[#C8C8C8]/[0.3]'><div className='w-[24px] h-[24px]'></div></th>}
                        </tr>
                    )}
                    <tr className='bg-gradient-to-r from-grad-one to-grad-two text-quaternary'>
                        {isLoading ? (
                            <th className='w-full py-4 px-6'><div className='h-[14px]'/></th>
                        ) : (
                        <>
                        
                        {headers.map((header:Header, i:number)=> {
                            if (header.isSelected) {
                                return (
                                    <th key={i} className={`text-left text-nowrap min-w-[150px] text-[14px]`}>
                                        <div className={`flex ${header.isRequired && i === 0 ? 'justify-start' : 'justify-center'} items-center py-4 px-6`}>
                                            {selectionEnabled(i) && <input onChange={selectAllRows} checked={allAreSelected} type="checkbox" className="accent-secondary mr-6 hover:cursor-pointer"/>}
                                            <button name={header.label} disabled={header.isSortable ? false : true} onClick={(e:any) => setSortedColumn(e, header.value)} className={`${header.isRequired && i === 0 ? 'justify-start' : 'justify-center'} hover:text-secondary disabled:text-quaternary flex items-center gap-3 w-full`}>
                                                <p className={`${activeField && header.label === activeField.name ? 'text-[#FF8484]' : 'text-primary'}`}>{header.label}</p>
                                                {selectedHeader === header.value && ( 
                                                    <>
                                                    {header.isAscending ? (
                                                    <img src={Ascending} alt='sort-ascending-icon' className='w-[18px]'/>
                                                    ) : (
                                                    <img src={Descending} alt='sort-descending-icon' className='w-[18px]'/>
                                                    )}
                                                    </>
                                                )}             
                                            </button> 
                                        </div>
                                    </th>
                                )
                            } else {
                                return null;
                            }
                        })}
                        </>
                        )}

                        {hasOptions && <th className='py-4 px-6 text-left text-nowrap'><div className='w-[24px] h-[24px]'></div></th>}

                    </tr>

                    <tbody>
                        {isLoading ? (
                            <>
                                {Array.from(Array(quantity ? quantity : 10).keys()).map((k,i) => (
                                    <LoadingRow />
                                ))}
                            </>
                        ) : (
                            <>{children}</>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}