//components
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CreateModelDropdown from "../components/CreateModelDropdown"
import PageHeader from "../../../components/Page/PageHeader"

import { useSelector } from "react-redux";
import usePrivileges from '../../../hooks/usePrivileges';
import { selectApplication, selectTheme } from '../../../store/selectors/auth.selector';
import ModelTemplate from './ModelTemplate';

export default function Models() {
    const { canTrain } = usePrivileges();
    const application = useSelector(selectApplication);
    const theme = useSelector(selectTheme);



    return (
        <>

            <PageHeader label='Models'>
                {canTrain && application && (application).includes('assay-central') && <CreateModelDropdown />}
            </PageHeader>

            <Tabs>
                <TabList>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Classification</Tab>
                    <Tab selectedClassName={`react-tabs__tab--selected ${theme === 'dark' && 'dark-tabs'}`}>Regression</Tab>
                </TabList>

                <TabPanel>
                    <ModelTemplate application={application} isClassification={true} isPopup={false}/>
                </TabPanel>
                <TabPanel>
                    <ModelTemplate application={application} isClassification={false} isPopup={false}/>
                </TabPanel>
            </Tabs>

        </>
    )
}