import { ReactNode } from "react";

export default function Button({ togglePopup, children, isLoading }: { 
    togglePopup: (e:any) => void, 
    children: ReactNode,
    isLoading: boolean,
}) {

    return (
        <button disabled={isLoading} onClick={togglePopup} className={`whiten text-white bg-primary hover:bg-black transition-all flex justify-center items-center gap-3 py-3 px-5 rounded`}>
            {children}
        </button>
    )
}