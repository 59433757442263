import { useState, useEffect, ChangeEvent, MouseEvent, Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CreateDatasetForm, Dataset } from "../../types/datasets.types";
import { FieldMappingForm } from "../../types/all.types";

import { selectSingleFile } from "../../store/selectors/files.selectors";
import { cleanFileName } from "../../data/functions";
import { addDataset, setSelectedDataset } from "../../store/slices/datasets.slice";
import { setSelectedFile } from "../../store/slices/files.slice";
import API from '../../api';


const defaultFieldMapping = {
    'chem-name': {
        name: '',
        op: null,
        type: 'chem-name',
        value: null,
    },
    'chem-id': {
        name: '',
        op: null,
        type: 'chem-id',
        value: null,
    },
    'continuous-value': {
        name: '',
        op: 'log',
        type: 'continuous-value',
        value: null,
    },
    'single-class-label': {
        name: '',
        op: null,
        type: 'single-class-label',
        value: null,
    },
    'split-on-value': {
        name: '',
        op: '',
        type: 'split-on-value',
        value: null,
    },
    'non-activity': [],
}

const defaultDatasetForm: CreateDatasetForm = {
    file_ids: [],
    name: '',
    mode: null,
    average_mode: 'name',
    model_names: null,
    stdizer: 'Simple',
    model_ids: [],
    fields_mapping: [],
}

export interface FieldMappingType {
    name: string,
    op: string | null,
    type: string,
    value: null,
}

export interface DatasetFieldMappingForm {
    'chem-name': {
        name: string,
        op: null,
        type: string,
        value: null,
    },
    'chem-id': {
        name: string,
        op: null,
        type: string,
        value: null,
    },
    [key: string] : FieldMappingType | FieldMappingType[] | null,
}

const useCreateDatasetHandlers = ({
    isFlow,
    redirect,
    togglePopup,
    goBack,
    setPopupMessage,
    form, 
    setForm,
    fieldsMapping,
    setFieldsMapping,
    activityColumn, 
    setActivityColumn,
} : {
    isFlow?: boolean,
    redirect?: boolean,
    togglePopup?: (e:any) => void,
    goBack?: (e:any) => void,
    setPopupMessage? : (popupMessage: string, isSuccessMessage: boolean) => void,
    form: CreateDatasetForm,
    setForm: Dispatch<SetStateAction<CreateDatasetForm>>,
    fieldsMapping: FieldMappingForm,
    setFieldsMapping: Dispatch<SetStateAction<FieldMappingForm>>,
    activityColumn: string,
    setActivityColumn: Dispatch<SetStateAction<string>>,
}) => {
    const selectedFile = useSelector(selectSingleFile);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ isAuto, setIsAuto ] = useState(true);
    const [ fieldOptions, setFieldOptions ] = useState<{value:string, label: string}[]>([]);
    const [ modelType, setModelType ] = useState('');
    const [ valueType, setValueType ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');


    const handleCheck = (e:ChangeEvent<HTMLInputElement>) => {
        setIsAuto(!isAuto)
    }

    const handleInput = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setForm({...form, [e.target.name]: e.target.value});
    }


    const handleSelect = (e:any, name: string) => {
        const fieldName = name as keyof FieldMappingForm;
        setFieldsMapping({
            ...fieldsMapping,
            [fieldName]: {
                ...(fieldsMapping[fieldName] as FieldMappingType),
                name: !e ? '' : e.value,
            }
        })       
    };

    const handleMultiSelect = (e:any) => {
        setFieldsMapping({
            ...fieldsMapping,
            ['non-activity' as keyof object]: e.map((val:any) => ({
                name: val.value,
                op: null,
                type: 'non-activity',
                value: null,
            }))
        })
    }

    const selectValueType = (e:any) => {
        setValueType(e.value);
        if (e.value === 'single-class-label') {
            setFieldsMapping({
                ...fieldsMapping,
                ['single-class-label' as keyof object]: {
                    name: '',
                    op: null,
                    type: 'single-class-label',
                    value: null,
                },
                ['split-on-value' as keyof object]: null,
            })
        } else {
            setFieldsMapping({
                ...fieldsMapping,
                ['split-on-value' as keyof object]: {
                    name: '',
                    op: null,
                    type: 'split-on-value',
                    value: null,
                },
                ['single-class-label' as keyof object]: null,
            })
        }
    }

    

    const setQuantifier = (e: any) => {
        if (modelType === 'regression') {
            setFieldsMapping({
                ...fieldsMapping,
                ['continuous-value' as keyof object]: {
                    ...fieldsMapping['continuous-value'],
                    op: e.value
                }
            })
        } else {
            setFieldsMapping({
                ...fieldsMapping,
                ['split-on-value' as keyof object]: {
                    ...fieldsMapping['split-on-value'],
                    op: e.value
                }
            })
        }
    }

    const setThreshold = (e: ChangeEvent<HTMLInputElement>) => {
        setFieldsMapping({
            ...fieldsMapping,
            ['split-on-value' as keyof object]: {
                ...fieldsMapping['split-on-value'],
                value: e.target.value,
            }
        })
    }

    const selectModelType = (e:any) => {
        setModelType(e.value);
        if (e.value === 'regression') {
            setFieldsMapping({
                ...fieldsMapping,
                ['continuous-value' as keyof object]: {
                    name: '',
                    op: '',
                    type: 'continuous-value',
                    value: null,
                },
                ['single-class-label' as keyof object]: null,
                ['split-on-value' as keyof object]: null,
            })
        } else if (e.value === 'classification') {
            setFieldsMapping({
                ...fieldsMapping,
                ['continuous-value' as keyof object]: null,
                ['single-class-label' as keyof object]: {
                    name: '',
                    op: '',
                    type: 'single-class-label',
                    value: null,
                },
                ['split-on-value' as keyof object]: {
                    name: '',
                    op: '',
                    type: 'split-on-value',
                    value: '',
                },
            })
        }
    }


    useEffect(() => {
        if (selectedFile && selectedFile.fields.length) {
            setFieldOptions(selectedFile.fields.map(field => ({value: field, label: field})));
        }
    }, [selectedFile]);


    useEffect(() => {
        if (selectedFile) {
            if (isAuto) {
                setForm({...form, name: selectedFile.name.split('.')[0], file_ids: [selectedFile._id.$oid]});
            } else {
                setForm({...form, file_ids: [selectedFile._id.$oid]});
            }
        } else {
            setForm(defaultDatasetForm);
            setFieldsMapping(defaultFieldMapping);
            setModelType('');
            setValueType('');
            setActivityColumn('');
            setModelType('');
        }
        //eslint-disable-next-line
    }, [selectedFile, isAuto])

    const handleSubmit = async (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsLoading(true);
        setIsDisabled(true);

        let fields: FieldMappingForm = {
            'chem-name': fieldsMapping['chem-name'] && fieldsMapping['chem-name'].name ? fieldsMapping['chem-name'] : null,
            'chem-id': fieldsMapping['chem-id'] && fieldsMapping['chem-id'].name ? fieldsMapping['chem-id'] : null,
            'continuous-value': fieldsMapping['continuous-value'],
            'single-class-label': fieldsMapping['single-class-label'],
            'split-on-value': fieldsMapping['split-on-value'],
        };

        (fieldsMapping['non-activity'] as FieldMappingType[]).forEach((field, i) => {
            fields[`non-activity-${i}` as keyof object] = {...field};
        })

        const values = ['continuous-value', 'single-class-label', 'split-on-value'];
        let mapping: FieldMappingType[] = [];
        Object.keys(fields).forEach(key => {
            if (fields[key] !== null) {
                if (values.includes(key)) {
                    mapping.push({ ...fields[key], name: activityColumn } as FieldMappingType)
                } else {
                    mapping.push(fields[key] as FieldMappingType)
                }
                
            }
        }) 

        const newForm: CreateDatasetForm = {...form, name: cleanFileName(form.name),  fields_mapping: mapping};
        const authKey = localStorage.getItem('X-Auth');

        if (authKey) {
            try {

                const response = await API.post('datasets', newForm, { headers: { 'X-Auth': authKey } });
                const data: Dataset = await response.data[0];

                if (data.records_number > 0) {

                    dispatch(addDataset(data));

                    if (isFlow) {
                        dispatch(setSelectedDataset(data));
                    } else {
                        dispatch(setSelectedDataset(null));
                        dispatch(setSelectedFile(null));
                    }

                    if (redirect) {
                        navigate(`/datasets/${data._id.$oid}`)
                    }

                    togglePopup && togglePopup(e);
                    setErrorMessage('');
                    setPopupMessage && setPopupMessage('Your dataset has been created successfully!', true);


                } else {

                    const getResponse = await API.get(`datasets/${data._id.$oid}`, { headers: { 'X-Auth': authKey } });
                    const fetchedDataset: Dataset = await getResponse.data;

                    if (fetchedDataset.records.length) {

                        dispatch(addDataset(data));

                        if (isFlow) {
                            dispatch(setSelectedDataset(data));
                        } else {
                            dispatch(setSelectedDataset(null));
                            dispatch(setSelectedFile(null));
                        }

                        if (redirect) {
                            navigate(`/datasets/${data._id.$oid}`)
                        }

                        togglePopup && togglePopup(e);
                        setErrorMessage('');


                    } else {
                        await API.delete(`datasets/${data._id.$oid}`, { headers: { 'X-Auth': authKey } });
                        setErrorMessage('This dataset contains no records');

                    }

                    

                }

                
                setIsLoading(false);
                setIsDisabled(false);
                

            } catch (err: any) {
                console.log(err);
                const details = err.response.data.detail;
                if (details) {
                    setErrorMessage(details);
                } else {
                    setErrorMessage('There was an error creating your dataset');
                }

            }

           
        }

    }

    useEffect(() => {
        if (!form.file_ids.length || !form.name || !modelType || !activityColumn ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [form, fieldsMapping, modelType, valueType, activityColumn]);




    const backFunction = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        goBack && goBack(e);
        dispatch(setSelectedDataset(null))
    };


    const cancelFunction = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        togglePopup && togglePopup(e); 
        dispatch(setSelectedDataset(null)); 
        !isFlow && dispatch(setSelectedFile(null));
    };

    const continueFunction = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        handleSubmit(e);
    }

    return {
        handleCheck,
        handleInput,
        handleMultiSelect,
        handleSelect,
        setThreshold,
        selectValueType,
        setQuantifier,
        selectModelType,

        backFunction,
        cancelFunction,
        continueFunction,

        fieldOptions,
        setActivityColumn,
        activityColumn,
        isDisabled,
        isLoading,
        errorMessage,

        form,
        isAuto,
        modelType,
        valueType,
        fieldsMapping,
    }
};

export default useCreateDatasetHandlers;