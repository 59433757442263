// assets
import ChevronUp from '../../../assets/chevron-up.svg';
import ChevronDown from '../../../assets/chevron-down.svg';
import Info from '../../../assets/info-circle.svg';

// components
import AdditionalInfoSideMenu from '../../../components/Popups/AdditionalInfoSideMenu';
import Button from '../../../components/Buttons/Button';
import SinglePageHeader from "../../../components/Page/SinglePageHeader";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import DeletePopup from '../../../components/Popups/DeletePopup';

// hooks
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import usePopup from '../../../hooks/usePopup';

// types
// import useJobs from '../../../hooks/pageHooks/useJobs';
import OptionsDropdown from '../components/OptionsDropdown';
import useEnableSelection from '../../../hooks/useEnableSelection';
import SnackBar from '../../../components/Alerts/SnackBar';
import useSingleJob from '../../../hooks/pageHooks/useSingleJob';
import useMessage from '../../../hooks/useMessage';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../store/selectors/auth.selector';
import Overlay from '../../../components/Popups/Overlay';


export default function SingleJobPage() {
    const { id } = useParams();
    const [ isJobOutputOpen, setIsJobOutputOpen ] = useState(true);
    const [ isErrorOutputOpen, setIsErrorOutputOpen ] = useState(false);
    const [ isTraceOpen, setIsTraceOpen ] = useState(false);
    const [ jobName, setJobName ] = useState('Single Job');
    const [ isLoading, setIsLoading ] = useState(false);
    const theme = useSelector(selectTheme);
    
    const {
        open,
        setOpen,
        message,
        isSuccess,
        setPopupMessage
    } = useMessage();

    const { 
        toggleSideMenu, 
        isSideMenuOpen, 
        isPopupOpen,
        togglePopup,
    } = usePopup();

    const {
        singleJob,
        routes,
        info,
        deleteJobs
    } = useSingleJob({id: id, setPopupMessage});

    const {
        isChecked,
        toggleChecked
    } = useEnableSelection({allItems: undefined})


    const toggleJobOutput = (e:any) => {
        e.preventDefault();
        setIsJobOutputOpen(!isJobOutputOpen);
    }

    const toggleErrorOutput = (e:any) => {
        e.preventDefault();
        setIsErrorOutputOpen(!isErrorOutputOpen);
    }

    const toggleTrace = (e:any) => {
        e.preventDefault();
        setIsTraceOpen(!isTraceOpen);
    }

    useEffect(() => {
        if (singleJob) {
            setIsLoading(false);
            if (!singleJob.params.model_name) {
                setJobName(singleJob.params.ds_name)
            } else {
                if (singleJob.params.method) {
                    setJobName(singleJob.params.model_name + ' - ' + singleJob.params.method)
                } else {
                    setJobName(singleJob.params.model_name)
                }
            }
        } else {
            setIsLoading(true);
        }
    }, [singleJob]);


    return (
        <>

                    <SinglePageHeader isLoading={isLoading} status={singleJob && singleJob.status} label={jobName} routes={routes}>
                        <OptionsDropdown toggleDelete={togglePopup} />
                        <Button isLoading={isLoading} togglePopup={toggleSideMenu}>
                            <img src={Info} alt='info-logo' className="w-[16px]"/>
                            <p className='font-medium text-nowrap'>Show Details</p>
                        </Button>
                    </SinglePageHeader>

                    <div className="flex flex-col gap-4">
                        
                        <div className={`w-full ${theme === 'dark' ? 'bg-dark-accent': 'bg-white'} rounded drop-shadow-default flex flex-col gap-4 p-3`}>
                            <div onClick={toggleJobOutput} className="w-full flex justify-between items-center px-3">
                                <p className='text-[1.5rem] font-semibold'>Job Output</p>
                                {isJobOutputOpen ? (
                                    <img src={ChevronUp} className="w-[20px]" alt='close-job-output-icon' />
                                ) : (
                                    <img src={ChevronDown} className="w-[20px]" alt='open-job-output-icon' />
                                )}
                            </div>
                            {isJobOutputOpen && (
                            <div className="max-h-[500px] overflow-y-auto">
                                <div className={`w-full p-4 rounded ${theme === 'dark' ? 'bg-dark-background' : 'bg-darker-background'}`}>
                                    <div className="w-full">
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <>{singleJob && singleJob.stdout}</>
                                        )}
                                    </div>
                                </div>
                            </div>
                            )}
                        </div>

                        {singleJob && singleJob.stderr && (
                            <div className="w-full bg-white rounded drop-shadow-default flex flex-col gap-4 p-3">
                                <div onClick={toggleErrorOutput} className="w-full flex justify-between items-center px-3">
                                    <p className='text-[1.5rem] text-primary font-semibold'>Error Output</p>
                                    {isErrorOutputOpen ? (
                                        <img src={ChevronUp} className="w-[20px]" alt='close-error-output-icon' />
                                    ) : (
                                        <img src={ChevronDown} className="w-[20px]" alt='open-error-output-icon' />
                                    )}
                                </div>
                                {isErrorOutputOpen && (
                                <div className="max-h-[500px] overflow-y-auto">
                                    <div className="w-full p-4 rounded bg-darker-background">
                                        <div className="w-full">
                                            {singleJob.stderr}
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        )}

                        {singleJob && singleJob.stack_trace && (
                            <div className="w-full bg-white rounded drop-shadow-default flex flex-col gap-4 p-3">
                                <div onClick={toggleTrace} className="w-full flex justify-between items-center px-3">
                                    <p className='text-[1.5rem] text-primary font-semibold'>Stack Trace</p>
                                    {isTraceOpen ? (
                                        <img src={ChevronUp} className="w-[20px]" alt='close-error-output-icon' />
                                    ) : (
                                        <img src={ChevronDown} className="w-[20px]" alt='open-error-output-icon' />
                                    )}
                                </div>
                                {isTraceOpen && (
                                <div className="max-h-[500px] overflow-y-auto">
                                    <div className="w-full p-4 rounded bg-darker-background">
                                        <div className="w-full">
                                            {singleJob.stack_trace}
                                        </div>
                                    </div>
                                </div>
                                )}
                            </div>
                        )}
                        
                    </div>

                    <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/> 


            {isSideMenuOpen && (
                <AdditionalInfoSideMenu id={id} info={info} label='Job Details' togglePopup={toggleSideMenu} />
            )}
            {isPopupOpen &&
                <Overlay togglePopup={togglePopup}>
                    <DeletePopup isChecked={isChecked} toggleChecked={toggleChecked} togglePopup={togglePopup} deleteFunc={deleteJobs} items={id ? [id] : []} label='this job' path='/jobs'/>
                </Overlay>
            }
        </>
    )
}