import { useRef, Dispatch, SetStateAction } from "react"


export default function Ketcher({setKetcherInstance, setSmiles}: {
    setKetcherInstance: Dispatch<SetStateAction<any | null>>,
    setSmiles: Dispatch<SetStateAction<string>>,
}) {
    const ref = useRef<HTMLIFrameElement>(null);
    // const [ document, setDocument ] = useState<any | null>(null);

    const handleLoad = async (ketcher:any) => {
        if (ref.current) {
            const win: any = ref.current.contentWindow;
            if (!win.ketcher) {
                window.setTimeout(handleLoad, 100)
            } else {
                let ketch = win.ketcher;
                await ketch.editor.subscribe('change', async () => { 
                    const response = await win.ketcher.getSmiles();
                    setSmiles(response);
                });
                setKetcherInstance(ketch);
                
            }
            
        }
        
    }






    


    return (
        <>
        <iframe onLoad={handleLoad} ref={ref} title='ketcher' id="ifKetcher" src="/ketcher/index.html" width="100%" height='100%' ></iframe>
        </>
    )
}