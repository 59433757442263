import { createSlice } from "@reduxjs/toolkit";
import { AuthUser } from "../../types/users.types";
import { MetaData } from "../../types/all.types";

export interface AuthState {
    auth: AuthUser | null,
    metadata: MetaData | null,
    application: 'megatox' | 'assay-central' | 'megatrans' | 'assay-central-demo',
    theme: 'default' | 'dark',
}

const initialState: AuthState = {
    auth: null,
    metadata: null,
    application: 'assay-central',
    theme: 'default',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUser: (state, action) => {
            state.auth = action.payload;
        },
        logoutUser: (state) => {
            state.auth = null;
            state.metadata = null;
        },
        setMetadata: (state, action) => {
            state.metadata = action.payload;
        },
        setApplication: (state, action) => {
            state.application = action.payload;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        }
    }
})

export const { loginUser, logoutUser, setMetadata, setApplication, setTheme } = authSlice.actions;

export const authReducer = authSlice.reducer;
