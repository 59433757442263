import { Link } from 'react-router-dom';
import UnAuthImage from '../../assets/unauthorized.png';

export default function Unauthorized() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="flex flex-col justify-center items-center">
                <div className='max-w-[300px] w-full mb-6'>
                    <img src={UnAuthImage} className='object-cover w-full' alt='401-error-icon'/>
                </div>
                
                <p className='text-[20px] mb-3 font-medium text-secondary'>401 - UNAUTHORIZED</p>
                <p className='mb-6 max-w-[400px] text-center'>You do not have permission to access this page.</p>
                <Link to='/' className='rounded-full py-3 px-10 bg-tertiary hover:bg-secondary font-medium text-white transition-all'>Return to Login</Link>
            </div>
        </div>
    )
}