
import { useState, MouseEvent, ChangeEvent } from 'react';
import useAuth from '../../../hooks/useAuth';

import ACLogo from '../../../assets/assay-central-logo.png';
import MTLogo from '../../../assets/megatox.png';
import MTRLogo from '../../../assets/megatrans.png';
import Eye from '../../../assets/eye.svg';
import EyeOff from '../../../assets/eye-off.svg';
import ErrorMessage from '../../../components/Alerts/ErrorMessage';
import { CircularProgress } from '@mui/material';

const defaultCredentials = {
    username: '',
    password: '',
}

export default function LoginCard({ application }: { application: string }) {
    const [ credentials, setCredentials ] = useState<{username: string, password: string}>(defaultCredentials);
    const [ errorCredentials, setErrorCredentials ] = useState<{username: boolean, password: boolean}>({username: false, password: false});
    const [ show, setShow ] = useState(false);
    const { login, errorMessage, isDisabled, isLoading } = useAuth({setErrorCredentials});


    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        })
        setErrorCredentials({
            ...errorCredentials,
            [e.target.name]: false,
        })
    }

    const togglePassword = (e:MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShow(!show);
    }



    return (
        <div className='p-10 bg-white rounded flex flex-col gap-10'>
            <div className='flex gap-3 justify-start items-center'>
                {application === 'megatox' ? (
                    <>
                        <img src={MTLogo} className='w-[52px]' alt='megatox-logo' />
                        <p className='text-[1.5rem] text-secondary font-semibold'>MegaTox</p>
                    </>
                ) : application === 'megatrans' ? (
                    <>
                        <img src={MTRLogo} className='w-[52px]' alt='assay-central-logo' />
                        <p className='text-[1.5rem] text-secondary font-semibold'>MegaTrans</p>
                    </>
                ) : (
                    <>
                        <img src={ACLogo} className='w-[52px]' alt='assay-central-logo' />
                        <p className='text-[1.5rem] text-secondary font-semibold'>Assay Central</p>
                    </>
                )}
            </div>
            <p className='text-primary text-[36px] font-semibold leading-10'>Sign In</p>
            <form id='login-form' className='w-full flex flex-col justify-center items-center gap-8'>
                <div className='w-full flex flex-col gap-1 justify-center items-start'>
                    <label htmlFor='username' className='text-[12px] font-semibold text-quaternary'>Username</label>
                    <input id='username' required onChange={handleInput} value={credentials.username} name='username' className={`w-full rounded border-2 ${errorCredentials.username ? 'border-red-500' : 'border-quaternary'} focus:border-tertiary focus:outline-none p-3`} placeholder='Username'/>
                    {errorCredentials.username && <p data-testid='emptyUsername' className='text-red-500 italic text-[12px]'>This is a required field.</p>}
                </div>

                <div className='w-full flex flex-col gap-1 justify-center items-start'>
                    <label htmlFor='password' className='text-[12px] font-semibold text-quaternary'>Password</label>
                    <div className={`w-full rounded border-2 ${errorCredentials.password ? 'border-red-500' : 'border-quaternary'} focus-within:border-tertiary flex justify-center items-center pr-3`}>
                        <input id='password' required onChange={handleInput} value={credentials.password} name='password' type={show ? 'text' : 'password'} className={`grow focus:outline-none p-3`} placeholder='Password'/>
                        <button onClick={togglePassword}>
                            {show ? <img src={EyeOff} alt='hide-password-icon'/> : <img src={Eye} alt='show-password-icon'/>}  
                        </button>
                    </div>
                    {errorCredentials.password && <p data-testid='emptyPassword' className='text-red-500 italic text-[12px]'>This is a required field.</p>}
                </div>
            </form>
            {errorMessage && (
                <ErrorMessage errorMessage={errorMessage} />
            )}
            <button title='Log In' disabled={isDisabled} onClick={(e:any) => login(e, credentials)} className='transition-all flex justify-center items-center gap-3 disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3] w-full p-3 text-white bg-secondary bg-opacity-100 rounded font-medium hover:bg-darken'>
            {isLoading ? 
            <>
            <CircularProgress size={16} color='inherit'/>
            <p>Logging in</p>
            </>
            : 
            <p>Login</p>
            }
            </button>
        </div>
    )
}