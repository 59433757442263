// assets
import Existing from '../../../assets/existing-file.svg';
import New from '../../../assets/new-file.svg';

// components
import Overlay from "../../../components/Popups/Overlay";

// hooks
import { useState, useEffect } from 'react';

// types
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction } from "react";

export default function SelectFileTypePopup({ togglePopup, next, setSelectedType, selectedType }: { togglePopup: (e:MouseEvent<HTMLButtonElement>) => void, next?: (e:MouseEvent<HTMLButtonElement>) => void, setSelectedType: Dispatch<SetStateAction<string>>, selectedType: string }) {
    const [ isDisabled, setIsDisabled ] = useState(true);
    

    useEffect(() => {
        if (!selectedType) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false);
        }
    }, [selectedType])

    const handleInput = (e:ChangeEvent<HTMLInputElement>) => {
        setSelectedType(e.target.value);
    }

    return (
        <Overlay togglePopup={togglePopup}>
            <div onClick={(e:any) => e.stopPropagation()} className='w-full h-full flex justify-center items-center p-10'>
                <div className='popup-menu bg-background rounded p-8  max-w-[600px] w-full'>
                    <div className='popup-max-height overflow-y-auto'>
                        <div className='flex flex-col gap-8 justify-center items-stretch'>
                        
                            <div className='w-full text-center'>
                                <p className='text-primary font-semibold text-[1.5rem]'>Select File Type</p>
                            </div>

                            <div className='w-full flex gap-8'>
                                <label className={`lighten ${selectedType === 'new-file' && 'whiten'} hover:cursor-pointer relative z-20 w-1/2 transition-all ${selectedType === 'new-file' ? 'bg-tertiary text-white' : 'bg-white text-tertiary'} hover:bg-tertiary text-tertiary hover:text-white aspect-square flex justify-center items-center border-2 border-tertiary rounded`}>
                                    <input onChange={handleInput} type='radio' name='select-file' value='new-file' checked={selectedType === 'new-file' ? true : false} className='absolute top-[4px] right-[4px] z-10 accent-highlight hidden'/>
                                    <div className='flex flex-col justify-center items-center gap-4'>
                                        <img src={New} className='w-[80px]' alt='new-file-icon'/>
                                        <p className='text-[18px]  font-semibold'>New File</p>
                                    </div>
                                </label>

                                <label className={`lighten ${selectedType === 'existing-file' && 'whiten'} hover:cursor-pointer relative z-20 w-1/2 transition-all ${selectedType === 'existing-file' ? 'bg-tertiary text-white' : 'bg-white text-tertiary'} hover:bg-tertiary text-tertiary hover:text-white aspect-square flex justify-center items-center border-2 border-tertiary rounded`}>
                                    <input onChange={handleInput} type='radio' name='select-file' value='existing-file' checked={selectedType === 'existing-file' ? true : false} className='absolute top-[4px] right-[4px] z-10 accent-highlight hidden'/>
                                    <div className='flex flex-col justify-center items-center gap-4'>
                                        <img src={Existing} className='w-[80px]' alt='existing-file-icon'/>
                                        <p className='text-[18px]  font-semibold'>Existing File</p>
                                    </div>
                                </label>

                            </div>

                            <div className='flex justify-end'>
                                <div>
                                    <button onClick={(e:any) => {togglePopup(e); setSelectedType('')}} className='py-2 px-4 rounded border border-quaternary hover:bg-quaternary hover:text-white transition-all bg-white text-quaternary text-[14px] mr-2'>Cancel</button>
                                    <button disabled={isDisabled} onClick={(e:MouseEvent<HTMLButtonElement>) => {next && next(e)}} className='py-2 px-4 rounded text-white text-[14px] border transition-all hover:border-secondary hover:bg-secondary border-tertiary bg-tertiary disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            
        </Overlay>
    )
}