import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";

import { defaultUserFilter, defaultUserFilterFields, userHeaders } from "../../data/users";
import { selectUsers } from "../../store/selectors/users.selector";
import { getUsers, deleteExistingUser } from "../../store/slices/users.slice";

import { ParsedUser } from "../../types/users.types";
import { FilterFieldsType, FilterType, Header } from "../../types/all.types";
import { filterItems, searchItems, sortByColumn } from "../../data/functions";
import API from '../../api';
import { useNavigate } from "react-router-dom";

const useUsers = ({ setPopupMessage }: { setPopupMessage: (popupMessage: string, isSuccessMessage: boolean) => void }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector(selectUsers);

    const [ allUsers, setAllUsers ] = useState<ParsedUser[]>([]); 
    const [ modifiedUsers, setModifiedUsers ] = useState<ParsedUser[]>([]); 
    const [ allUserIDs, setAllUserIDs ] = useState<string[]>([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ headers, setHeaders ] = useState<Header[]>(userHeaders);

    const [ filterFields, setFilterFields ] = useState<FilterFieldsType>(defaultUserFilterFields);
    const [ filter, setFilter ] = useState<FilterType>(defaultUserFilter);

    useEffect(() => {
        const authKey = localStorage.getItem('X-Auth');
            if (authKey) {
                setIsLoading(true);
                const fetchUsers = async () => {

                    try {

                        const response = await API.get('users', { headers: { 'X-Auth': authKey } });
                        dispatch(getUsers(response.data));

                    } catch (err:any) {

                        console.log(err);
                        navigate('/500');

                    }
                    
                    setIsLoading(false);
                    
                }
        
                fetchUsers();
            } else {
                navigate('/401');
            }
        //eslint-disable-next-line
    }, [dispatch]);

    console.log(users)

    useEffect(() => {
        if (users && users.length > 0) {
            const arr: ParsedUser[] = users.map(u => ({
                id: u._id ? u._id.$oid : '',
                name: u.full_name ? u.full_name : 'user',
                username: u.username,
                email: u.email,
                company: u.company,
                privileges: u.privileges,
                role: u.roles.length ? u.roles[0] : 'Unknown',
                active_status: u.active,
                
            }))

            setAllUsers(arr);
            setModifiedUsers(arr);
        }
    }, [users]);

    useEffect(() => {
        if (modifiedUsers.length > 0) {
            const company = modifiedUsers.map(file => file.company);
            const privileges = modifiedUsers.map(file => file.privileges);
            setFilterFields({
                company: Array.from(new Set(company)).filter(a => a).map(p => p),
                privileges: Array.from(new Set(privileges.flat())).filter(a => a).map(p => p),
            })
        }
        
    }, [modifiedUsers]);

    const modifyUsers = (searchInput: string, selectedHeader: string, updateHeaders?: Dispatch<SetStateAction<Header[]>>, filter?: FilterType) => {
        const searchArr = searchItems(searchInput, allUsers);
        const sortedArr = sortByColumn(selectedHeader, headers, false, searchArr, updateHeaders);
        const filteredArr = filterItems(filter!, sortedArr);
        setModifiedUsers(filteredArr);
    }

    useEffect(() => {
        if (modifiedUsers.length > 0) {
            setAllUserIDs(modifiedUsers.map(user => user.id));
        }
    }, [modifiedUsers]);

    const deleteUsers = async (ids: string[]) => {
        const authKey = localStorage.getItem('X-Auth');
        const promises = ids.map(async (id) => {
            return API.delete(`users/${id}`, { headers: { 'X-Auth': authKey } }).then(res => dispatch(deleteExistingUser(id)));      
        })
        if (authKey) {
            try {
                await Promise.all(promises);
            } catch(err:any) {
                console.log(err);
            }
        }
    };

    

    return {
        allUsers,
        modifiedUsers,
        modifyUsers,
        filter,
        setFilter,
        headers,
        setHeaders,
        filterFields,
        allUserIDs,
        isLoading,
        deleteUsers,
        users
    }
}

export default useUsers;