import TrainingModelsCover from '../../../assets/tutorialAssets/Training Models Cover.png';
import ResultsetCover from '../../../assets/tutorialAssets/Making Prediction Set Cover.png';
import SingleResultCover from '../../../assets/tutorialAssets/Making Single Prediction Cover.png';
import UploadFilesCover from '../../../assets/tutorialAssets/Upload Files Cover.png';
import CreateDatasetCover from '../../../assets/tutorialAssets/Create Dataset Cover.png';
import ReadacrossCover from '../../../assets/tutorialAssets/Readacross Cover.png';
// import JobCover from '../../../assets/tutorialAssets/Job Queue Cover.png';
import UserCover from '../../../assets/tutorialAssets/Managing Users Cover.png';
import TutorialBox from "./TutorialBox";
// import StartCover from '../../../assets/tutorialAssets/Quick Start Cover.png';


export default function Tutorials() {

    return (
        <div className="w-full max-w-[1200px] mx-auto flex flex-col justify-start items-stretch py-[80px] px-10">
                    
            <p className="font-semibold text-[2rem] mb-6">Tutorials</p>

            <div className="w-full grid grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))] grid-rows-[repeat(auto-fit,_1fr)] gap-10">
                {/* <Link to='/help/getting-started' className="flex flex-col rounded bg-white drop-shadow-default hover:text-tertiary hover:bg-secondary/[0.2] hover:drop-shadow-none">
                    <div className="w-full h-[300px] bg-[#eeeeee]">
                        <img src={StartCover} className="w-full h-full object-cover object-left-top" alt='getting-started-cover'/>
                    </div>
                    <div className="flex flex-col p-4 justify-start items-start">
                        <p className="font-semibold text-[18px]">Getting started</p>
                    </div>
                </Link> */}
                <TutorialBox pathname="/help/uploading-files" title="Uploading files">
                    <img src={UploadFilesCover} className="w-full h-full object-cover object-left-top" alt='upload-files-cover'/>
                </TutorialBox>

                <TutorialBox pathname="/help/creating-datasets" title="Creating datasets">
                    <img src={CreateDatasetCover} className="w-full h-full object-cover object-left-top" alt='create-dataset-cover'/>
                </TutorialBox>

                <TutorialBox pathname="/help/training-models" title="Training models">
                    <img src={TrainingModelsCover} className="w-full h-full object-cover object-left-top" alt='training-models-cover'/>
                </TutorialBox>

                <TutorialBox pathname="/help/creating-resultsets" title="Creating resultsets">
                    <img src={ResultsetCover} className="w-full h-full object-cover object-left-top" alt='prediction-set-cover'/>
                </TutorialBox>

                <TutorialBox pathname="/help/single-prediction" title="Making single predictions">
                    <img src={SingleResultCover} className="w-full h-full object-cover object-left-top" alt='single-prediction-cover'/>
                </TutorialBox>

                <TutorialBox pathname="/help/generating-readacross" title="Generating readacross values">
                    <img src={ReadacrossCover} className="w-full h-full object-cover object-left-top" alt='read-across-cover'/>
                </TutorialBox>


                {/* <Link to='/help/navigating-job-queue' className="flex flex-col rounded bg-white drop-shadow-default hover:text-tertiary">
                    <div className="w-full h-[300px] bg-[#eeeeee]">
                        <img src={JobCover} className="w-full h-full object-cover object-left-top" alt='job-queue-cover'/>
                    </div>
                    <div className="flex flex-col p-4 justify-start items-start">
                        <p className="font-semibold text-[16px]">Navigating the job queue</p>
                    </div>
                </Link> */}

                <TutorialBox pathname="/help/managing-users" title="Managing users">
                    <img src={UserCover} className="w-full h-full object-cover object-left-top" alt='managing-users-cover'/>
                </TutorialBox>
                
            </div>
        </div>
    )
}