
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import ChevronDown from '../../../assets/chevron-down.svg';
import ChevronUp from '../../../assets/chevron-up.svg'
import { Dispatch, SetStateAction } from 'react';

const sx = {
    fontFamily: 'Montserrat',
    fontWeight: 'medium',
    color: '#35424A',
    padding: '12px 20px',
}


export default function EnsembleDropdown({ setAggregate, aggregate } : { setAggregate: Dispatch<SetStateAction<'max' | 'min'>>, aggregate: 'max' | 'min' }) {
   
    const [ label, setLabel ] = useState(aggregate === 'max' ? 'Best' : 'Worst');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

   

    return (
        <div>
            <button onClick={handleClick}>
                <div className={`transition-all hover:bg-grad-one bg-grad-two text-primary flex justify-center items-center gap-3 py-3 px-5 rounded w-full`}>
                    <p className='font-medium text-nowrap'>{label} Scores</p>
                    {open ? <img src={ChevronUp} alt='close-dropdown-icon' /> : <img src={ChevronDown} alt='open-dropdown-icon' />}
                </div>
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{marginTop: '8px'}}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                {label === 'Best' ? (
                    <MenuItem sx={sx} onClick={(e:any) => {setLabel('Worst'); setAggregate('min'); handleClose()}}>Worst Scores</MenuItem>
                ) : (
                    <MenuItem sx={sx} onClick={(e:any) => {setLabel('Best'); setAggregate('max'); handleClose()}}>Best Scores</MenuItem>

                )}
            </Menu>
        </div>
         
    )
}