import { useState, useEffect } from "react"
import { Header } from "../../types/all.types"
import useSizeFormat from "../../hooks/useSizeFormat";
import { useSelector } from "react-redux";
import { selectApplication } from "../../store/selectors/auth.selector";
import Active from '../../assets/green-check.svg';
import Inactive from '../../assets/red-x.svg';
import { getTimezoneOffset } from "../../data/functions";

const defaultPermissions = {
    admin: {
        color: '#47AD24',
        label: 'Administrator',
    },
    train: {
        color: '#2429AD',
        label: 'Train Models'
    },
    predict: {
        color: '#AD248E',
        label: 'Make Predictions'
    },
    create: {
        color: '#FFA461',
        label: 'Create Files & Datasets'
    }
    
}

export default function BaseRow({
    headerObj,
    item,
    itemKey,
    isSelected,
}: {
    headerObj: Header,
    item: any,
    itemKey: string,
    isSelected: boolean,
}) {
    
    const { formatBytes } = useSizeFormat();

    const application = useSelector(selectApplication);
    const [ permissions, setPermissions ] = useState<{[key:string]: {[key:string]: string}}>(defaultPermissions);


    useEffect(() => {
        if (application && !(application).includes('assay-central')) {
            setPermissions({
                admin: {
                    color: '#47AD24',
                    label: 'Administrator',
                },
                predict: {
                    color: '#AD248E',
                    label: 'Make Predictions'
                },
                create: {
                    color: '#FFA461',
                    label: 'Create Files'
                }
                
            })
        } else {
            setPermissions(defaultPermissions);
        }
    }, [application]);


    if (!headerObj.isRequired && itemKey !== 'privileges' && typeof item[itemKey] !== 'boolean') {
        return (
            <td className={`relative py-4 px-6 text-center  ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                <p className={`max-w-[400px] mx-auto ${!item[itemKey] ? 'text-[#A9A9A9]' : 'text-primary'} ${headerObj.value === 'date_created' ? 'text-nowrap' : 'text-wrap'}`}>
                    {!item[itemKey] ? 'No value' : headerObj.value === 'size' ? formatBytes(item[itemKey]).toString() : String(item[itemKey])} {headerObj.value === 'date_created' ? getTimezoneOffset(item[itemKey]) : ''}
                </p>
            </td>
        )
    } else if (itemKey === 'privileges') {
        let arr: string[] = [];

        if (application && !(application).includes('assay-central')) {
            arr = (item[itemKey]).filter((k:string) => k !== 'train');
        } else {
            arr = item[itemKey] as string[];
        }
        
        return (
            <td className={`py-4 px-6 relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                <div className="flex justify-center items-center gap-2 w-full">
                    <>
                    {arr.includes('admin') ? (
                        <div style={{color: `${permissions.admin.color}`, borderColor: `${permissions.admin.color}`}} className="text-nowrap py-2 px-4 text-[12px] text-center bg-none border rounded">
                            {permissions.admin.label}
                        </div>
                    ) : (
                        <>
                        {arr.map(a => {
                            const { color, label } = permissions[a as keyof object];
                            return (
                                <div style={{color: `${color}`, borderColor: `${color}`}} className="text-nowrap py-2 px-4 text-[12px] text-center bg-none border rounded">
                                    {label}
                                </div>
                            )
                        })}
                        </>
                    )}
                    </>              
                </div>
            </td>
        )
    } else if (typeof item[itemKey] === 'boolean') {
                    
        return (
            <td className={`py-4 px-6 relative ${headerObj && headerObj.isSelected ? 'table-cell' : 'hidden'}`}>
                {isSelected && <div data-testid='overlay' className="absolute top-0 right-0 bottom-0 left-0 bg-secondary bg-opacity-30"></div>}
                <div className="flex justify-center items-center w-full">
                {item[itemKey] === true ? (
                    <img src={Active} alt='active-status-icon' />
                ) : (
                    <img src={Inactive} alt='inactive-status-icon'/>
                )} 
                </div>
            </td>
        )
    } else {
        return null;
    }
}