// components
// import GenerateDataset from "../components/Cards/GenerateDataset";
import SetTrainingParameters from "../components/Cards/SetTrainingParameters";
import SinglePageHeader from "../../../components/Page/SinglePageHeader"
import TogglePage from "../../../components/Page/TogglePage";
import UploadFileCard from "../components/Cards/UploadFileCard";
import { CircularProgress } from "@mui/material";

// functions
import { selectSingleDataset } from "../../../store/selectors/datasets.selector";
import { selectSingleFile } from "../../../store/selectors/files.selectors";

// hooks
import { useEffect, useState } from "react";
import usePopup from "../../../hooks/usePopup";
import { useSelector } from "react-redux";


// types

import UploadDatasetCard from "../components/Cards/UploadDatasetCard";
import ExistingDatasetPopup from "../../../components/Popups/ExistingDatasetPopup";
import CreateDatasetPopup from "../../Datasets/components/CreateDatasetPopup";
import ErrorPopup from "../../../components/Popups/ErrorPopup";
import usePrivileges from "../../../hooks/usePrivileges";
import Overlay from "../../../components/Popups/Overlay";
import SelectFileTypePopup from "../../Files/components/SelectFileTypePopup";
import UploadFile from "../../Files/components/UploadFile";
import ExistingFilePopup from "../../../components/Popups/ExistingFilePopup";
import SuccessPopup from "../../../components/Popups/SuccessPopup";
import useCreateNewModelHandler from "../../../hooks/handlers/useCreateNewModelHandlers";
import CreateDataset from "../components/Cards/CreateDataset";
import { CreateDatasetForm } from "../../../types/datasets.types";
import { FieldMappingForm } from "../../../types/all.types";
import useMessage from "../../../hooks/useMessage";
import SnackBar from "../../../components/Alerts/SnackBar";


const defaultFieldMapping = {
    'chem-name': {
        name: '',
        op: null,
        type: 'chem-name',
        value: null,
    },
    'chem-id': {
        name: '',
        op: null,
        type: 'chem-id',
        value: null,
    },
    'continuous-value': {
        name: '',
        op: 'log',
        type: 'continuous-value',
        value: null,
    },
    'single-class-label': {
        name: '',
        op: null,
        type: 'single-class-label',
        value: null,
    },
    'split-on-value': {
        name: '',
        op: '',
        type: 'split-on-value',
        value: null,
    },
    'non-activity': [],
}

const defaultDatasetForm: CreateDatasetForm = {
    file_ids: [],
    name: '',
    mode: null,
    average_mode: 'name',
    model_names: null,
    stdizer: 'Simple',
    model_ids: [],
    fields_mapping: [],
}

export default function CreateNewModel() {
    const selectedFile = useSelector(selectSingleFile);
    const selectedDataset = useSelector(selectSingleDataset);
    const [ datasetForm, setDatasetForm ] = useState<CreateDatasetForm>(defaultDatasetForm);
    const [ fieldsMapping, setFieldsMapping ] = useState<FieldMappingForm>(defaultFieldMapping);
    const [ activityColumn, setActivityColumn ] = useState('');
    const [ selectedType, setSelectedType ] = useState('');

   const { canCreate } = usePrivileges();

   const {
    open,
    message,
    isSuccess,
    setPopupMessage,
    setOpen,
} = useMessage();

   const { 
        isPopupOpen,
        setPopupType,
        togglePopup,
        popupType,
    } = usePopup();


   const {
        query,
        routes, 
        isDisabled,
        isLoading,
        next,
        goBack,
        resetFile,
        resetUploadedDataset,
        resetValues,
        handleSubmit,
        handleDatasetCreation,
        form,
        setForm,
    } = useCreateNewModelHandler({ setPopupType, togglePopup, selectedType, datasetForm, fieldsMapping, activityColumn, setPopupMessage });



   
   return (
    <>
    

        <SinglePageHeader label='Train New Model' routes={routes}>
            {canCreate && <TogglePage query={query} param='?dataset=' pathOne="new" pathTwo="existing" labelOne="New Dataset" labelTwo="Existing Dataset"/>}
        </SinglePageHeader>

        <div className='w-full table-container overflow-x-auto py-2'>
            <div className='w-full flex justify-start items-stretch gap-4'>
            {query === 'new' ? (
                <>
                    <UploadFileCard number={1} selectedFile={selectedFile} toggleSelectFile={togglePopup} setPopupType={setPopupType} reset={resetFile}/>
                    <CreateDataset activityColumn={activityColumn} setActivityColumn={setActivityColumn} number={2} selectedFile={selectedFile} setForm={setDatasetForm} form={datasetForm} fieldsMapping={fieldsMapping} setFieldsMapping={setFieldsMapping}/>
                    <SetTrainingParameters selectedFile={selectedFile} number={3} selectedDataset={selectedDataset} form={form} setForm={setForm} fieldsMapping={fieldsMapping}/>
                </>
            ) : (
                <>
                    <UploadDatasetCard number={1} selectedDataset={selectedDataset} toggleExistingDataset={togglePopup} setPopupType={setPopupType} reset={selectedDataset ? resetUploadedDataset : undefined}/>
                    <SetTrainingParameters number={2} selectedDataset={selectedDataset} form={form} setForm={setForm}/>
                </>
            )}
            </div>
        </div>

        <div className='mt-4 w-full flex justify-end items-center'>
            <button onClick={selectedFile && query === 'new' ? handleDatasetCreation : handleSubmit} disabled={isDisabled} className='flex justify-center items-center gap-2 max-w-[150px] w-full py-2 px-4 rounded text-white text-[14px] border border-tertiary bg-tertiary hover:bg-secondary hover:border-secondary transition-all disabled:border-quaternary/[0.3] disabled:bg-quaternary/[0.3]'>
            {isLoading ? 
                <>
                <CircularProgress size={16} color='inherit'/>
                <p>Running</p>
                </>
                : 
                <p>Run</p>
            }
            </button>
        </div>

        <SnackBar open={open} setOpen={setOpen} message={message} isSuccess={isSuccess}/>  

        {isPopupOpen && (
            <Overlay togglePopup={togglePopup}>
                {popupType === 'existing-dataset' ? (
                    <ExistingDatasetPopup togglePopup={togglePopup}/>
                ) : popupType === 'create-dataset' ? (
                    <CreateDatasetPopup isFlow={true} togglePopup={togglePopup}/>
                ) : popupType === 'success' ? (
                    <SuccessPopup togglePopup={togglePopup} type="models" resetValues={resetValues}/>
                ) : popupType === 'error' ? (
                    <ErrorPopup type='models' togglePopup={togglePopup} resetValues={resetValues}/>
                ) : popupType === 'select-file-type' ? (
                    <SelectFileTypePopup togglePopup={togglePopup} setSelectedType={setSelectedType} selectedType={selectedType} next={next}/>
                ) : popupType === 'upload-file' ? (
                    <UploadFile togglePopup={togglePopup} goBack={goBack} isFlow={true}/>
                ) : (
                    <ExistingFilePopup togglePopup={togglePopup} goBack={goBack}/>
                )}
            </Overlay>
        )}
    
    
    </>
   )
    
}