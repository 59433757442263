import { Link } from "react-router-dom";
import Right from '../../assets/chevron-right-black.svg';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { ModelStats } from "../../types/models.types";
import { convertDecimal } from "../../data/functions";
import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTheme } from "../../store/selectors/auth.selector";

function truncate(str:string, n:number){
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };

const getStatusColor = (status: string) => {
    switch(status) {
        case "Pending": 
            return '#FFF278';
        
        case "Rescheduled":
            return '#FFF278';
            
        case "Running":
            return '#FFC061';

        case "Done":
            return '#C7DB78';

        case "Failed":
            return '#FF8484';

        default:
            return '#EEEEEE';
    }
}

export default function SinglePageHeader(props:any) {
    const status: string | undefined = props.status;
    const isLoading: boolean = props.isLoading;
    const stats: ModelStats | null = props.stats;
    const theme = useSelector(selectTheme);
    const id: string | undefined = props.id;

    return (
        <div className="w-full flex justify-between items-start mb-16 gap-10">
            <div className='flex flex-col justify-start items-start gap-1 grow'>
                {isLoading ? (
                <>
                    <div className="w-full"><Skeleton height={42}/></div>
                    <div className="w-full"><Skeleton height={14}/></div>
                </>
                ) : (            
                <>
                    <div className="flex justify-start items-start gap-4">
                        <p className="font-semibold text-[2rem] max-w-[1000px] break-all">
                            {props.label}
                        </p>
                        {status && (
                            <div style={{backgroundColor: `${getStatusColor(status)}`}} className="rounded-full py-2 px-4 mt-1">
                                <p className="font-semibold text-primary">{status}</p>
                            </div>
                        )}
                        {stats && !isNaN(Number(stats.records_number)) && (
                            <div className="rounded-full py-2 w-[64px] bg-[#EEEEEE] mt-1 relative flex justify-center items-center">
                                <Tooltip title='Dataset Records'>
                                    <p className="font-semibold text-nowrap text-primary">{stats.records_number}</p>
                                </Tooltip>
                                {stats.stats && (
                                    <>
                                    {stats.stats.inactives && stats.stats.actives ? (
                                        <Tooltip title='Inactives / Actives' slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0,-8]}}]}}}>
                                            <div className="text-primary flex justify-center items-center py-1 px-3 gap-1 rounded-full bg-tertiary text-white text-[12px] font-semibold absolute top-[-8px] left-[54px]">
                                                <p>{stats.stats.inactives}</p>
                                                <p>/</p>
                                                <p>{stats.stats.actives}</p>
                                            </div>
                                        </Tooltip>
                                    ) : stats.stats.low_value && stats.stats.high_value ? (
                                        <Tooltip title='Low Value / High Value' slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0,-8]}}]}}}>
                                            <div className="flex justify-center items-center py-1 px-3 gap-1 rounded-full bg-tertiary text-white text-[12px] font-semibold absolute top-[-8px] left-[54px]">
                                                <p>{convertDecimal(stats.stats.low_value)}</p>
                                                <p>/</p>
                                                <p>{convertDecimal(stats.stats.high_value)}</p>
                                            </div>
                                        </Tooltip>
                                    ) : null}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={`flex justify-center items-center gap-1 ${theme === 'dark' && 'whiten'}`}>
                    {props.routes.map((r:{label:string,path:string}, i:number) => (
                        <>
                            <Link to={`/${r.path}`} className={`text-[14px] font-medium hover:underline transition-all ${i === props.routes.length-1 && 'text-tertiary'}`}>{truncate(r.label, 50)}</Link>
                            {i < props.routes.length-1 && <img src={Right} alt='chevron-right-icon' className={`w-[12px]`}/>}
                        </>
                    ))}
                    </div>
                </>
                )}
            </div>
            <div id={id} className='flex gap-3'>
                {props.children}
            </div>
        </div>
    )
}