import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginUser, logoutUser, setMetadata } from "../store/slices/auth.slice";
import { AuthUser } from "../types/users.types";
import API from '../api';
import axios from 'axios';
import md5 from 'md5';
import { useState, MouseEvent, Dispatch, SetStateAction } from "react";


const useAuth = ({ setErrorCredentials, setOpen }: { setErrorCredentials?: Dispatch<SetStateAction<{username: boolean, password: boolean}>>, setOpen?: Dispatch<SetStateAction<boolean>>}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);

    const getMetadata = async (authKey: string) => {
        if (authKey) {
            const fetchMeta = async () => {
                try {
                    const response = await API.get('metadata', { headers: { 'X-Auth': authKey } });
                    const data = await response.data;

                    dispatch(setMetadata(data));

                } catch (err:any) {
                    console.log(err);
                } 
            }

            fetchMeta();
        }
    }

    const login = async (e: MouseEvent<HTMLButtonElement>, credentials: { username: string, password: string }) => {
        e.preventDefault();

        const { username, password } = credentials;

        
        // cancels form submission and displays error for corresponding empty fields 
        if (!username || !password) {
            if (setErrorCredentials) {
                let noUn = false;
                let noPw = false;
                if (!username) {
                    noUn = true;
                }
                if (!password) {
                    noPw = true;
                }
                setErrorCredentials({
                    username: noUn,
                    password: noPw,
                })
            }

            return;
        }

        const hashedCredentials = { username, password_hash: md5(password) };
        setErrorMessage('');
        setIsLoading(true);
        setIsDisabled(true);

        try {

            const response = await API.post('login', hashedCredentials);
            const { user, session, users } = response.data;

            const privileges = response.data.privileges ? response.data.privileges : response.data.user.privileges;

            const x_auth = session._id.$oid;
            
            axios.defaults.headers.common['X-Auth'] = x_auth;
            localStorage.setItem('X-Auth', x_auth);

            await getMetadata(x_auth);

            const authUser: AuthUser = {
                user,
                session,
                privileges: privileges.map((p:any) => ({ value: p.name, text: p.description })),
                users: users.map((p:any) => ({ value: p._id.$oid, text: `${p.full_name || p.username} <${p.email}>` }))
            }

            dispatch(loginUser(authUser));
            navigate('/files');
            setErrorMessage('');
            

        } catch (err:any) {
            console.log(err);
            setErrorMessage('Incorrect username or password');

        }

        setIsDisabled(false);
        setIsLoading(false);
    }

    const logout = async () => {
        const authKey = localStorage.getItem('X-Auth');
        if (authKey) {

            try {

                await API.delete('logout', { headers: { 'X-Auth': authKey } });
                delete API.defaults.headers.common['X-Auth'];
                localStorage.removeItem('X-Auth');
        
                dispatch(logoutUser());
                navigate('/');
    
            } catch (err:any) {
    
                console.log(err);
                setErrorMessage('There was a problem logging you out');
                setOpen && setOpen(true);
            }
    
            

        }

    }

    return {
        login,
        logout,
        errorMessage,
        isLoading,
        isDisabled
    }

}

export default useAuth;