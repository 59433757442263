import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

export default function PageHeader(props:any) {
    const isLoading = props.isLoading;

    return (
        <div className="w-full flex justify-between items-start mb-16 gap-10">
            <div className='flex flex-col justify-start items-start gap-1 grow'>
                {isLoading !== undefined && isLoading ? (
                    <div className="w-full"><Skeleton height={42}/></div>
                ) : (
                    <p className="font-semibold text-[2.5rem]">{props.label}</p>
                )}
            </div>
            <div className='flex gap-3'>
                {props.children}
            </div>
        </div>
    )
}